import { PACKAGECONST, USER_MANAGEMENT, UPM } from './';

export const packageManagementAction = {
  getTable: query => ({
    type: PACKAGECONST.TABLE.INIT,
    id: 'TABLE',
    query,
  }),
  get: param => ({
    type: PACKAGECONST.ONE.INIT,
    id: 'ONE',
    param,
  }),
  add: data => ({
    type: PACKAGECONST.ADD.INIT,
    id: 'ADD',
    data,
  }),
  addClear: () => ({
    type: PACKAGECONST.ADD.CLEAR,
    id: 'ADD',
  }),
  edit: (param, data) => ({
    type: PACKAGECONST.EDIT.INIT,
    id: 'EDIT',
    param,
    data,
  }),
  editClear: () => ({
    type: PACKAGECONST.EDIT.CLEAR,
    id: 'EDIT',
  }),
  delete: param => ({
    type: PACKAGECONST.DELETE.INIT,
    id: 'DELETE',
    param,
  }),
  deleteClear: () => ({
    type: PACKAGECONST.DELETE.CLEAR,
    id: 'DELETE',
  }),
};

export const userManagementAction = {
  getTable: query => ({
    type: USER_MANAGEMENT.TABLE.INIT,
    id: 'TABLE',
    query,
  }),
  get: param => ({
    type: USER_MANAGEMENT.ONE.INIT,
    id: 'ONE',
    param,
  }),
  add: data => ({
    type: USER_MANAGEMENT.ADD.INIT,
    id: 'ADD',
    data,
  }),
  addClear: () => ({
    type: USER_MANAGEMENT.ADD.CLEAR,
    id: 'ADD',
  }),
  edit: (param, data) => ({
    type: USER_MANAGEMENT.EDIT.INIT,
    id: 'EDIT',
    param,
    data,
  }),
  editClear: () => ({
    type: USER_MANAGEMENT.EDIT.CLEAR,
    id: 'EDIT',
  }),
  delete: param => ({
    type: USER_MANAGEMENT.DELETE.INIT,
    id: 'DELETE',
    param,
  }),
  deleteClear: () => ({
    type: USER_MANAGEMENT.DELETE.CLEAR,
    id: 'DELETE',
  }),
};

export const UPMAction = {
  getTable: query => ({
    type: UPM.TABLE.INIT,
    id: 'TABLE',
    query,
  }),
  get: param => ({
    type: UPM.ONE.INIT,
    id: 'ONE',
    param,
  }),
  add: data => ({
    type: UPM.ADD.INIT,
    id: 'ADD',
    data,
  }),
  addClear: () => ({
    type: UPM.ADD.CLEAR,
    id: 'ADD',
  }),
  edit: (param, data) => ({
    type: UPM.EDIT.INIT,
    id: 'EDIT',
    param,
    data,
  }),
  editClear: () => ({
    type: UPM.EDIT.CLEAR,
    id: 'EDIT',
  }),
  delete: param => ({
    type: UPM.DELETE.INIT,
    id: 'DELETE',
    param,
  }),
  deleteClear: () => ({
    type: UPM.DELETE.CLEAR,
    id: 'DELETE',
  }),
  // Dropdown
  getUsersList: () => ({
    type: UPM.USERS.INIT,
    id: 'USERS',
  }),
  getPackagesList: () => ({
    type: UPM.PACKAGES.INIT,
    id: 'PACKAGES',
  }),
  getMenusList: () => ({
    type: UPM.MENUS.INIT,
    id: 'MENUS',
  }),
};
