import * as constant from "../constants/EventConstant";

const initialState = {

    //EVENT DASHBOARD

  eventcount: [],
  eventcount_loading: true,
  eventcount_error: null,

  eventfraudcategory: [],
  eventfraudcategory_loading: true,
  eventfraudcategory_error: null,

  eventcleanpublishername: [],
  eventcleanpublishername_loading: true,
  eventcleanpublishername_error: null,

  eventfraudpublishername: [],
  eventfraudpublishername_loading: true,
  eventfraudpublishername_error: null,

  fraudeventbymonth: [],
  fraudeventbymonth_loading: true,
  fraudeventbymonth_error: null,

  
  fraudeventbydate: [],
  fraudeventbydate_loading: true,
  fraudeventbydate_error: null,

  
  fraudeventpublisher: [],
  fraudeventpublisher_loading: true,
  fraudeventpublisher_error: null,


};

const EventReducer = (
  state = initialState,
  { type, response, setSideDrawer, error }
) => {
  switch (type) {
    case constant.EVENT_COUNT_LIST_INIT:
      return {
        ...state,
        eventcount_loading: true,
      };
    case constant.EVENT_FRAUD_CATEGORY_INIT:
      return {
        ...state,
        eventfraudcategory_loading: true,
      };

    case constant.EVENT_CLEAN_PUBLISHER_NAME_INIT:
      return {
        ...state,
        eventcleanpublishername_loading: true,
      };

    case constant.EVENT_FRAUD_PUBLISHER_NAME_INIT:
      return {
        ...state,
        eventfraudpublishername_loading: true,
      };

    case constant.FRAUD_EVENT_BY_MONTH_INIT:
      return {
        ...state,
        fraudeventbymonth_loading: true,
      };

    case constant.FRAUD_EVENT_BY_DATE_INIT:
      return {
        ...state,
        fraudeventbydate_loading: true,
      };

    case constant.FRAUD_PUBLISHER_WISE:
      return {
        ...state,
        fraudeventpublisher_loading: true,
      };

    // success

    case constant.EVENT_COUNT_LIST_INIT_SUCCESS:
      return {
        ...state,
        eventcount_loading: false,
        eventcount: response.data,
      };

    case constant.EVENT_FRAUD_CATEGORY_INIT_SUCCESS:
      return {
        ...state,
        eventfraudcategory_loading: false,
        eventfraudcategory: response.data,
      };

    case constant.EVENT_CLEAN_PUBLISHER_NAME_INIT_SUCCESS:
      return {
        ...state,
        eventcleanpublishername_loading: false,
        eventcleanpublishername: response.data,
      };

    case constant.EVENT_FRAUD_PUBLISHER_NAME_INIT_SUCCESS:
      return {
        ...state,
        eventfraudpublishername_loading: false,
        eventfraudpublishername: response.data,
      };

    case constant.FRAUD_EVENT_BY_MONTH_INIT_SUCCESS:
      return {
        ...state,
        fraudeventbymonth_loading: false,
        fraudeventbymonth: response.data,
      };

      case constant.FRAUD_EVENT_BY_DATE_INIT_SUCCESS:
        return {
          ...state,
          fraudeventbydate_loading: false,
          fraudeventbydate: response.data,
        };

        case constant.FRAUD_PUBLISHER_WISE_SUCCESS:
          return {
            ...state,
            fraudeventpublisher_loading: false,
            fraudeventpublisher: response.data,
          };

    // error

    case constant.EVENT_COUNT_LIST_INIT_ERROR:
      return {
        ...state,
        eventcount_loading: false,
        eventcount_error: response,
      };


    case constant.EVENT_FRAUD_CATEGORY_INIT_ERROR:
      return {
        ...state,
        eventfraudcategory_loading: false,
        eventfraudcategory_error: response,
      };

    case constant.EVENT_CLEAN_PUBLISHER_NAME_INIT_ERROR:
      return {
        ...state,
        eventcleanpublishername_loading: false,
        eventcleanpublishername_error: response,
      };

      case constant.EVENT_FRAUD_PUBLISHER_NAME_INIT_ERROR:
        return {
          ...state,
          eventfraudpublishername_loading: false,
          eventfraudpublishername_error: response,
        };

    case constant.FRAUD_EVENT_BY_MONTH_INIT_ERROR:
      return {
        ...state,
        fraudeventbymonth_loading: false,
        fraudeventbymonth_error: response,
      };

      
    case constant.FRAUD_EVENT_BY_DATE_INIT_ERROR:
      return {
        ...state,
        fraudeventbydate_loading: false,
        fraudeventbydate_error: response,
      };

      
    case constant.FRAUD_PUBLISHER_WISE_ERROR:
      return {
        ...state,
        fraudeventpublisher_loading: false,
        fraudeventpublisher_error: response,
      };



    default:
      return state;
  }
};
export default EventReducer;
