import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Field, Formik } from 'formik';
import 'react-multi-carousel/lib/styles.css';
import { Input } from '../Components/Common/Form';
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import Axios from 'axios';
import toast from 'react-hot-toast';
import AuthLayout from '../Components/Common/AuthLayout';

export default function VerifyOTP() {
  return (
    <AuthLayout>
      <VerifyOTPForm />
    </AuthLayout>
  );
}

const initValue = {
  otp: '',
};

const schema = Yup.object().shape({
  otp: Yup.string().required('OTP is required'),
});

function VerifyOTPForm() {
  const { state } = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const url =
      'https://dashboard-api.mfilterit.net/api/onboarding/email/' + state.from;
    Axios.get(url).finally(() => setIsLoading(false));
  }, []);
  async function handleSubmit(value) {
    if (isLoading) return;
    setIsLoading(true);
    return await toast.promise(validateOTP(value), {
      success: 'OTP verified successfully',
      loading: 'Loading...',
      error: 'Something went wrong!',
    });
  }

  async function validateOTP(value) {
    const body = {
      email: state.from,
      otp: value.otp,
    };

    const url = 'https://dashboard-api.mfilterit.net/api/onboarding/verifyemail';
    await Axios.post(url, body)
      .then(() =>
        setTimeout(() => {
          history.push('/');
        }, 5000)
      )
      .finally(() => setIsLoading(false));
  }
  if (!state?.from) return history.goBack();
  return (
    <>
      <Formik
        initialValues={initValue}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        {({ errors, touched, handleSubmit }) => (
          <>
            <span style={{ marginBottom: '2rem', fontSize: '14px' }}>
              OTP is sent to <a href={'mailto:' + state.from}>{state.from}</a>
            </span>
            <form
              className='d-flex flex-column flex-center mt-lg-0 gap-3'
              style={{ gap: '1.5rem', width: '50%' }}
              onSubmit={handleSubmit}>
              <div className='w-100'>
                <Field
                  readOnly={false}
                  name='otp'
                  component={Input}
                  placeholder='OTP'
                  isSearchable='true'
                  required='required'
                  type='text'
                />
                {touched.otp && errors.otp && (
                  <p className='text-danger'>{errors.otp}</p>
                )}
              </div>
              <div className=''>
                <Button
                  className='btn btn-primary font-weight-bold px-9 my-3'
                  style={{
                    backgroundImage: 'linear-gradient(indigo,purple,purple)',
                    minWidth: '10ch',
                    maxWidth: '200px',
                  }}
                  type='submit'
                  disabled={isLoading}>
                  {isLoading ? 'Loading...' : 'Validate'}
                </Button>
                <Button
                  className='btn btn-secondary px-5 mx-2'
                  style={{
                    color: 'black',
                    backgroundColor: 'white',
                    border: '1px solid purple',
                  }}
                  type='button'
                  onClick={() => history.goBack()}>
                  Cancel
                </Button>
              </div>
            </form>
          </>
        )}
      </Formik>
    </>
  );
}
