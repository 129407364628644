import * as constant from '../constants/PackageConstants';
import { takeLatest, put, call } from 'redux-saga/effects';
import PackageService from '../services/PackageService';
import { API } from '../setupAxios';
import { ApiUrl, EndPoints } from '../helpers/Endpoints';

export function* ViewPackageDetailsSaga(payload) {
  try {
    // const response = yield call(PackageService.ViewPackageDetails,payload.page,payload.limit);
    // console.log(payload,'56565656');
    let url =
      ApiUrl +
      EndPoints.getpackage +
      '?page=' +
      payload.page.page +
      '&limit=' +
      payload.page.limit;
    const response = yield API.get(url);
    // console.log(payload.page.page, payload.page.limit, "qqqqqqqqq");
    yield put({ type: constant.PACKAGE_DETAILS_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PACKAGE_DETAILS_INIT_ERROR, error });
  }
}

//get one package
export function* ViewGetonePackageSaga(payload) {
  try {
    // const response = yield call(PackageService.ViewPackageDetails,payload.page,payload.limit);
    // console.log(payload.id,'getoneapi22222222');
    let url = ApiUrl + EndPoints.getonepackage + '/' + payload.id;

    const response = yield API.get(url);
    // console.log(payload.id, "getoneapi22222222");
    yield put({ type: constant.GETONE_PACKAGE_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.GETONE_PACKAGE_INIT_ERROR, error });
  }
}

export function* ViewPackageSaveSaga(payload) {
  try {
    const response = yield call(PackageService.ViewPackageSave, payload.values);
    yield put({ type: constant.PACKAGE_SAVE_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PACKAGE_SAVE_INIT_ERROR, error });
  }
}

export function* ViewPackageUpdateSaga(payload) {
  try {
    // console.log(payload,'kkkkkkkkkk');
    const response = yield call(
      PackageService.ViewPackageUpdate,
      payload.values
    );
    yield put({ type: constant.PACKAGE_UPDATE_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PACKAGE_UPDATE_INIT_ERROR, error });
  }
}

export function* ViewPackageDeleteSaga(payload) {
  try {
    // console.log(payload.id, 'getoneapi22222222');
    const response = yield call(
      PackageService.ViewPackageDelete,
      // payload.packageName,
      payload.id
    );
    yield put({ type: constant.PACKAGE_DELETE_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PACKAGE_DELETE_INIT_ERROR, error });
  }
}

export default function* PackageSaga() {
  yield takeLatest(constant.PACKAGE_DETAILS_INIT, ViewPackageDetailsSaga);
  yield takeLatest(constant.PACKAGE_SAVE_INIT, ViewPackageSaveSaga);
  yield takeLatest(constant.PACKAGE_UPDATE_INIT, ViewPackageUpdateSaga);
  yield takeLatest(constant.PACKAGE_DELETE_INIT, ViewPackageDeleteSaga);
  yield takeLatest(constant.GETONE_PACKAGE_INIT, ViewGetonePackageSaga);
}
