import { takeLatest, put } from 'redux-saga/effects';
import * as constant from '../constants/UploadsConstant';

import { API } from '../setupAxios';
import { EndPoints, PythonApiUrl} from '../helpers/Endpoints';

export function* ViewUploadsSaga(payload) {
  try {
    let url = PythonApiUrl + EndPoints.fetch_downloads;
    const params = payload.package_name;
    const response = yield API.get(url, { params });
    yield put({ type: constant.UPLOADS_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.UPLOADS_INIT_ERROR, error });
  }
}

export function* ViewUploadspageSaga(payload) {
  try {
    let url = PythonApiUrl + EndPoints.uploads;
    const params = payload.package_name;
    const response = yield API.get(url, { params });
    yield put({ type: constant.UPLOADS_PAGE_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.UPLOADS_PAGE_INIT_ERROR, error });
  }
}





export default function* UploadsSaga() {
  yield takeLatest(constant.UPLOADS_INIT, ViewUploadsSaga);
  yield takeLatest(constant.UPLOADS_PAGE_INIT, ViewUploadspageSaga);

}
