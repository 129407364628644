export const LANDINGREPORT_INIT = 'LANDINGREPORT_INIT';
export const LANDINGREPORT_INIT_SUCCESS = 'LANDINGREPORT_INIT_SUCCESS';
export const LANDINGREPORT_INIT_ERROR = 'LANDINGREPORT_INIT_ERROR';

export const LANDINGURL_ADD =  'LANDINGURL_ADD'
export const LANDINGURL_ADD_SUCCESS = 'LANDINGURL_ADD_SUCCESS'
export const LANDINGURL_ADD_ERROR = 'LANDINGURL_ADD_ERROR'
export const LANDINGURL_ADD_CLEAR = 'LANDINGURL_ADD_CLEAR'



