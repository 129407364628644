export const CAMPAIGN_ADD =  'CAMPAIGN_ADD'
export const CAMPAIGN_ADD_SUCCESS = 'CAMPAIGN_ADD_SUCCESS'
export const CAMPAIGN_ADD_ERROR = 'CAMPAIGN_ADD_ERROR'
export const CAMPAIGN_ADD_CLEAR = 'CAMPAIGN_ADD_CLEAR'

export const CAMPAIGN_GET = 'CAMPAIGN_GET'
export const CAMPAIGN_GET_SUCCESS = 'CAMPAIGN_GET_SUCCESS'
export const CAMPAIGN_GET_ERROR  = 'CAMPAIGN_GET_ERROR'
export const CAMPAIGN_GET_CLEAR = 'CAMPAIGN_GET_CLEAR'

export const CAMPAIGN_UPDATE = 'CAMPAIGN_UPDATE'
export const CAMPAIGN_UPDATE_SUCCESS = 'CAMPAIGN_UPDATE_SUCCESS'
export const CAMPAIGN_UPDATE_ERROR  = 'CAMPAIGN_UPDATE_ERROR'
export const CAMPAIGN_UPDATE_CLEAR = 'CAMPAIGN_UPDATE_CLEAR'

export const CAMPAIGN_DELETE = 'CAMPAIGN_DELETE'
export const CAMPAIGN_DELETE_SUCCESS = 'CAMPAIGN_DELETE_SUCCESS'
export const CAMPAIGN_DELETE_ERROR  = 'CAMPAIGN_DELETE_ERROR'
export const CAMPAIGN_DELETE_CLEAR = 'CAMPAIGN_DELETE_CLEAR'
