import AppPubConfigConst from '../constants/AppPublisherConfigConst';

const initialState = {
  table: {
    loading: false,
    data: [],
    error: null,
  },
  add: {
    loading: false,
    data: null,
    error: null,
  },
  edit: {
    loading: false,
    data: null,
    error: null,
  },
  del: {
    loading: false,
    data: null,
    error: null,
  },
  dropdown: {
    publisher: { loading: false, data: null, error: null },
    fraudType: { loading: false, data: null, error: null },
    excludeFraud: { loading: false, data: null, error: null},
    eventtype:{ loading: false, data: null, error: null },
  },
};

const AppPubConfigReducer = (state = initialState, { type, error, data }) => {
  switch (type) {
    case AppPubConfigConst.getTable.INIT: {
      const table = { loading: true, data: [], error: null };
      return { ...state, table };
    }
    case AppPubConfigConst.getTable.SUCCESS: {
      const table = { loading: false, data: data?.data ?? [], error: null };
      return { ...state, table };
    }
    case AppPubConfigConst.getTable.ERROR: {
      const table = { loading: false, data: [], error };
      return { ...state, table };
    }
    case AppPubConfigConst.getTable.CLEAR: {
      const table = { loading: false, data: [], error: null };
      return { ...state, table };
    }
    // Add publisher config
    case AppPubConfigConst.addConfgi.INIT: {
      const add = { loading: true, data: null, error: null };
      return { ...state, add };
    }
    case AppPubConfigConst.addConfgi.SUCCESS: {
      const add = { loading: false, data, error: null };
      return { ...state, add };
    }
    case AppPubConfigConst.addConfgi.ERROR: {
      const add = { loading: false, data: null, error };
      return { ...state, add };
    }
    case AppPubConfigConst.addConfgi.CLEAR: {
      const add = { loading: false, data: null, error: null };
      return { ...state, add };
    }
    // Delete publisher config
    case AppPubConfigConst.delete.INIT: {
      const del = { loading: true, data: null, error: null };
      return { ...state, del };
    }
    case AppPubConfigConst.delete.SUCCESS: {
      const del = { loading: false, data: data?.data, error: null };
      return { ...state, del };
    }
    case AppPubConfigConst.delete.ERROR: {
      const del = { loading: false, data: null, error };
      return { ...state, del };
    }
    case AppPubConfigConst.delete.CLEAR: {
      const del = { loading: false, data: null, error: null };
      return { ...state, del };
    }
    // Dropdown
    // 1.Publisher
    case AppPubConfigConst.filters.publisher.INIT: {
      const publisher = { loading: true, data: null, error: null };
      const dropdown = { ...state.dropdown, publisher };
      return { ...state, dropdown };
    }
    case AppPubConfigConst.filters.publisher.SUCCESS: {
      const publisher = { loading: false, data, error: null };
      const dropdown = { ...state.dropdown, publisher };
      return { ...state, dropdown };
    }
    case AppPubConfigConst.filters.publisher.ERROR: {
      const publisher = { loading: false, data: null, error };
      const dropdown = { ...state.dropdown, publisher };
      return { ...state, dropdown };
    }
    case AppPubConfigConst.filters.publisher.CLEAR: {
      const publisher = { loading: false, data: null, error: null };
      const dropdown = { ...state.dropdown, publisher };
      return { ...state, dropdown };
    }
    // 2.FraudType
    case AppPubConfigConst.filters.fraudType.INIT: {
      const fraudType = { loading: true, data: null, error: null };
      const dropdown = { ...state.dropdown, fraudType };
      return { ...state, dropdown };
    }
    case AppPubConfigConst.filters.fraudType.SUCCESS: {
      const fraudType = {
        loading: false,
        data: data?.resultset?.map(v => v[0]) ?? [],
        error: null,
      };
      const dropdown = { ...state.dropdown, fraudType };
      return { ...state, dropdown };
    }
    case AppPubConfigConst.filters.fraudType.ERROR: {
      const fraudType = { loading: false, data: null, error };
      const dropdown = { ...state.dropdown, fraudType };
      return { ...state, dropdown };
    }
    case AppPubConfigConst.filters.fraudType.CLEAR: {
      const fraudType = { loading: false, data: null, error: null };
      const dropdown = { ...state.dropdown, fraudType };
      return { ...state, dropdown };
    }
    // 3.Fraud Sub Category
    case AppPubConfigConst.filters.excludeFraud.INIT: {
      const excludeFraud = { loading: true, data: null, error: null };
      const dropdown = { ...state.dropdown, excludeFraud };
      return { ...state, dropdown };
    }
    case AppPubConfigConst.filters.excludeFraud.SUCCESS: {
      const excludeFraud = {
        loading: false,
        data: Array.isArray(data) ? data : [],
        error: null,
      };
      const dropdown = { ...state.dropdown, excludeFraud };
      return { ...state, dropdown };
    }
    case AppPubConfigConst.filters.excludeFraud.ERROR: {
      const excludeFraud = { loading: false, data: null, error };
      const dropdown = { ...state.dropdown, excludeFraud };
      return { ...state, dropdown };
    }
    case AppPubConfigConst.filters.excludeFraud.CLEAR: {
      const excludeFraud = { loading: false, data: null, error: null };
      const dropdown = { ...state.dropdown, excludeFraud };
      return { ...state, dropdown };
    }
    //4. Event Type
    case AppPubConfigConst.filters.eventType.INIT: {
      const eventType = { loading: true, data: null, error: null };
      const dropdown = { ...state.dropdown, eventType };
      return { ...state, dropdown };
    }
    case AppPubConfigConst.filters.eventType.SUCCESS: {
      const eventType = { loading: false, data, error: null };
      const dropdown = { ...state.dropdown, eventType };
      return { ...state, dropdown };
    }
    case AppPubConfigConst.filters.eventType.ERROR: {
      const eventType = { loading: false, data: null, error };
      const dropdown = { ...state.dropdown, eventType };
      return { ...state, dropdown };
    }
    case AppPubConfigConst.filters.eventType.CLEAR: {
      const eventType = { loading: false, data: null, error: null };
      const dropdown = { ...state.dropdown, eventType };
      return { ...state, dropdown };
    }

    default:
      return state;
  }
};

export default AppPubConfigReducer;
