import { takeLatest, put, call } from 'redux-saga/effects';
import * as constant from '../constants/MtrackConstants';

import { API } from '../setupAxios';
import { EndPoints, MtrackitApiUrl } from '../helpers/Endpoints';

export function* ViewTotalRevenueSaga(payload) {
  try {
    const params = { ...payload.dashboarddata };
    const url = MtrackitApiUrl + EndPoints.revenue;
    const response = yield API.get(url, { params });

    yield put({ type: constant.TOTAL_REVENUE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.TOTAL_REVENUE_ERROR, error });
  }
}
export function* ViewTotalCostSaga(payload) {
  try {
    const params = { ...payload.dashboarddata };
    const url = MtrackitApiUrl + EndPoints.cost;
    const response = yield API.get(url, { params });

    yield put({ type: constant.TOTAL_COST_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.TOTAL_COST_ERROR, error });
  }
}
export function* ViewTotalEventsSaga(payload) {
  try {
    const params = { ...payload.dashboarddata };
    const url = MtrackitApiUrl + EndPoints.events;
    const response = yield API.get(url, { params });

    yield put({ type: constant.TOTAL_EVENTS_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.TOTAL_EVENTS_ERROR, error });
  }
}
export function* ViewTotalClicksSaga(payload) {
  try {
    const params = { ...payload.dashboarddata };
    const url = MtrackitApiUrl + EndPoints.clicks;
    const response = yield API.get(url, { params });

    yield put({ type: constant.TOTAL_CLICKS_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.TOTAL_CLICKS_ERROR, error });
  }
}

export function* ViewTotalFraudCountSaga(payload) {
  try {
    const params = { ...payload.dashboarddata };
    const url = MtrackitApiUrl + EndPoints.total_fraud_counts;
    const response = yield API.get(url, { params });
    yield put({ type: constant.TOTAL_FRAUD_COUNTS_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.TOTAL_FRAUD_COUNTS_ERROR, error });
  }
}


export function* ViewTotalFraudPercentSaga(payload) {
  try {
    const params = { ...payload.dashboarddata };
    const url = MtrackitApiUrl + EndPoints.total_fraud_perc;
    const response = yield API.get(url, { params });
    yield put({ type: constant.TOTAL_FRAUD_PERCENTAGE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.TOTAL_FRAUD_PERCENTAGE_ERROR, error });
  }
}
export function* ViewTotalPubeventsSaga(payload) {
  try {
    const params = { ...payload.dashboarddata };
    const url = MtrackitApiUrl + EndPoints.pubevents;
    const response = yield API.get(url, { params });

    yield put({ type: constant.TOTAL_PUBEVENTS_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.TOTAL_PUBEVENTS_ERROR, error });
  }
}

export function* ViewTotalPubclicksSaga(payload) {
  try {
    const params = { ...payload.dashboarddata };
    const url = MtrackitApiUrl + EndPoints.pubcosts;
    const response = yield API.get(url, { params });

    yield put({ type: constant.TOTAL_PUBCLICKS_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.TOTAL_PUBCLICKS_ERROR, error });
  }
}

export function* ViewTotalOverpubclicksSaga(payload) {
  try {
    const params = { ...payload.dashboarddata };
    const url = MtrackitApiUrl + EndPoints.pubover;
    const response = yield API.get(url, { params });

    yield put({ type: constant.TOTAL_OVERPUBCLICKS_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.TOTAL_OVERPUBCLICKS_ERROR, error });
  }
}

export function* ViewTotalFraudpubclicksSaga(payload) {
  try {
    const params = { ...payload.dashboarddata };
    const url = MtrackitApiUrl + EndPoints.pubfraud;
    const response = yield API.get(url, { params });

    yield put({ type: constant.TOTAL_FRAUDPUBCLICKS_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.TOTAL_FRAUDPUBCLICKS_ERROR, error });
  }
}
export function* ViewTotalEventpubclicksSaga(payload) {
  try {
    const params = { ...payload.dashboarddata };
    const url = MtrackitApiUrl + EndPoints.pubevent;
    const response = yield API.get(url, { params });

    yield put({ type: constant.TOTAL_EVENTPUBCLICKS_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.TOTAL_EVENTPUBCLICKS_ERROR, error });
  }
}

export function* ViewMreportdashSaga(payload) {
  try {
    // const response = yield call(
    //   MreportServices.ViewMreportlist,
    //   payload.reportData
    // );
    let url = MtrackitApiUrl + EndPoints.mTrackIt_dashboard_report;
    const params = payload.mTrackreportData;
    const response = yield API.get(url, { params });
    yield put({ type: constant.MTRACKREPORT_TOP_PUBLISHER_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.MTRACKREPORT_TOP_PUBLISHER_ERROR, error });
  }
}

export default function* DashboardSaga() {
  yield takeLatest(constant.TOTAL_REVENUE, ViewTotalRevenueSaga);
  yield takeLatest(constant.TOTAL_COST, ViewTotalCostSaga);
  yield takeLatest(constant.TOTAL_EVENTS, ViewTotalEventsSaga);
  yield takeLatest(constant.TOTAL_CLICKS, ViewTotalClicksSaga);
  yield takeLatest(constant.TOTAL_PUBEVENTS, ViewTotalPubeventsSaga);
  yield takeLatest(constant.TOTAL_PUBCLICKS, ViewTotalPubclicksSaga);
  yield takeLatest(constant.TOTAL_OVERPUBCLICKS, ViewTotalOverpubclicksSaga);
  yield takeLatest(constant.TOTAL_FRAUDPUBCLICKS, ViewTotalFraudpubclicksSaga);
  yield takeLatest(constant.TOTAL_EVENTPUBCLICKS, ViewTotalEventpubclicksSaga);
  yield takeLatest(constant.TOTAL_FRAUD_COUNTS, ViewTotalFraudCountSaga);
  yield takeLatest(constant.TOTAL_FRAUD_PERCENTAGE, ViewTotalFraudPercentSaga);
  yield takeLatest(constant.MTRACKREPORT_TOP_PUBLISHER_INIT, ViewMreportdashSaga);
  
}
