import axios from 'axios';
import { TOKEN } from '../app/utils/const';

export const SESSION = {
  TOKEN: 'token',
  EXPIRED: 'session_expired',
  EXPIRED_ERROR_CODE: 310,
  PLAYER_ID: 'playerId',
  RESET_TOKEN: 'resetToken',
};

export const ERROR = {
  INVALID_RESPONSE: 'Invalid response',
  MSG: 'error',
};

export const HEADER = {
  TOKEN: 'Authorization',
  CONTENT_TYPE: 'application/json',
  MULTIPART_CONTENT_TYPE:
    'multipart/form-data,boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL',
  TIMEOUT: 60000,
};

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );
}

export const API = axios.create({
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    authorization: TOKEN.token,
  },
  timeout: HEADER.TIMEOUT,
});

const retryWrapper = (axios, options) => {
  const max_time = options.retry_time;
  axios.interceptors.response.use(null, error => {
    /** @type {import("axios").AxiosRequestConfig} */
    const config = error.config;
    config.counter = (error.config.counter ?? 0) + 1;
    // you could defined status you want to retry, such as 503
    // if (counter < max_time && error.response.status === retry_status_code) {
    if (config.counter < max_time) {
      return new Promise(resolve => {
        resolve(axios(config));
      });
    }
    return Promise.reject(error);
  });
};
retryWrapper(API, { retry_time: 5 });

API.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error)
);

// download
export const API_DOWNLOAD_CALL = axios.create({
  mode: 'cors',
  headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
});

API_DOWNLOAD_CALL.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error)
);
