import { takeLatest, put } from 'redux-saga/effects';
import { API } from '../setupAxios';
import {
  EndPoints,
  ApiUrl,
  AppFilterDomain,
  AppInstall,
} from '../helpers/Endpoints';
import AppPubConfigConst from '../constants/AppPublisherConfigConst';

export function* FetchAppPublisherTableSaga({ payload }) {
  try {
    let url = ApiUrl + EndPoints.APP_PUBLISHER_CONFIG;
    const response = yield API.get(url, { params: payload });
    yield put({ type: AppPubConfigConst.getTable.SUCCESS, data: response });
  } catch (error) {
    yield put({ type: AppPubConfigConst.getTable.ERROR, error });
  }
}

export function* DeleteAppPublisherTableSaga({ payload }) {
  try {
    let url = ApiUrl + EndPoints.APP_PUBLISHER_CONFIG;
    const response = yield API.delete(url, { params: payload });
    yield put({ type: AppPubConfigConst.delete.SUCCESS, data: response });
  } catch (error) {
    yield put({ type: AppPubConfigConst.delete.ERROR, error });
  }
}

export function* AddAppPublisherTableSaga({ payload }) {
  try {
    let url = ApiUrl + EndPoints.ADD_PUBLISHER_CONFIG;
    const response = yield API.post(url, payload);
    yield put({ type: AppPubConfigConst.addConfgi.SUCCESS, data: response });
  } catch (error) {
    yield put({ type: AppPubConfigConst.addConfgi.ERROR, error });
  }
}

export function* AppPublisherDwopDownSaga({ payload }) {
  try {
    let url = AppFilterDomain + EndPoints.app_F_publisher;
    const response = yield API.get(url, { params: payload });
    yield put({
      type: AppPubConfigConst.filters.publisher.SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({ type: AppPubConfigConst.filters.publisher.ERROR, error });
  }
}

export function* AppFraudTypeDwopDownSaga({ payload, dashboard_type }) {
  try {
    let url;
    if (dashboard_type === 'Event')
      url = AppInstall + EndPoints.app_event_fraud_category;
    else if (dashboard_type === 'Install')
      url = AppInstall + EndPoints.app_fraud_category;
    const response = yield API.get(url, { params: payload });
    yield put({
      type: AppPubConfigConst.filters.fraudType.SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({ type: AppPubConfigConst.filters.fraudType.ERROR, error });
  }
}

export function* AppExcludeFraudDwopDownSaga({ payload }) {
  try {
    let url = AppFilterDomain + EndPoints.app_Fraud_Sub_Category;
    const response = yield API.get(url, { params: payload });
    yield put({
      type: AppPubConfigConst.filters.excludeFraud.SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({ type: AppPubConfigConst.filters.excludeFraud.ERROR, error });
  }
}

export function* AppEventTypeDwopDownSaga({ payload }) {
  try {
    let url = AppFilterDomain + EndPoints.app_F_event;
    const response = yield API.get(url, { params: payload });
    yield put({
      type: AppPubConfigConst.filters.eventType.SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({ type: AppPubConfigConst.filters.eventType.ERROR, error });
  }
}


// eventType
export default function* AppPublisherSaga() {
  yield takeLatest(AppPubConfigConst.getTable.INIT, FetchAppPublisherTableSaga);
  yield takeLatest(AppPubConfigConst.addConfgi.INIT, AddAppPublisherTableSaga);
  yield takeLatest(AppPubConfigConst.delete.INIT, DeleteAppPublisherTableSaga);
  yield takeLatest( AppPubConfigConst.filters.eventType.INIT, AppEventTypeDwopDownSaga);
  yield takeLatest(
    AppPubConfigConst.filters.publisher.INIT,
    AppPublisherDwopDownSaga
  );
  yield takeLatest(
    AppPubConfigConst.filters.fraudType.INIT,
    AppFraudTypeDwopDownSaga
  );
  yield takeLatest(
    AppPubConfigConst.filters.excludeFraud.INIT,
    AppExcludeFraudDwopDownSaga
  );
}
