export const RECONCILATION_PAYMENT_MODAL = "RECONCILATION_PAYMENT_MODAL";
export const RECONCILATION_PAYMENT_MODAL_SUCCESS =
  "RECONCILATION_PAYMENT_MODAL_SUCCESS";
export const RECONCILATION_PAYMENT_MODAL_ERROR =
  "RECONCILATION_PAYMENT_MODAL_ERROR";

export const RECONCILATION_PAYMENT_MODAL_POST =
  "RECONCILATION_PAYMENT_MODAL_POST";
export const RECONCILATION_PAYMENT_MODAL_POST_SUCCESS =
  "RECONCILATION_PAYMENT_MODAL_POST_SUCCESS";
export const RECONCILATION_PAYMENT_MODAL_POST_ERROR =
  "RECONCILATION_PAYMENT_MODAL_POST_ERROR";

export const DELETE_RECONCILATION_INIT = "DELETE_RECONCILATION_INIT";
export const DELETE_RECONCILATION_INIT_SUCCESS =
  "DELETE_RECONCILATION_INIT_SUCCESS";
export const DELETE_RECONCILATION_INIT_ERROR =
  "DELETE_RECONCILATION_INIT_ERROR";

export const UPDATE_RECONCILATION_INIT = "UPDATE_RECONCILATION_INIT";
export const UPDATE_RECONCILATION_INIT_SUCCESS =
  "UPDATE_RECONCILATION_INIT_SUCCESS";
export const UPDATE_RECONCILATION_INIT_ERROR =
  "UPDATE_RECONCILATION_INIT_ERROR";
export const UPDATE_RECONCILATION_INIT_CLEAR =
  "UPDATE_RECONCILATION_INIT_CLEAR";

//Publisher Payment

export const RECONCILATION_PUBLISHER_PAYMENT_MODAL =
  "RECONCILATION_PUBLISHER_PAYMENT_MODAL";
export const RECONCILATION_PUBLISHER_PAYMENT_MODAL_SUCCESS =
  "RECONCILATION_PUBLISHER_PAYMENT_MODAL_SUCCESS";
export const RECONCILATION_PUBLISHER_PAYMENT_MODAL_ERROR =
  "RECONCILATION_PUBLISHER_PAYMENT_MODAL_ERROR";

export const RECONCILATION_PUBLISHER_PAYMENT_MODAL_POST =
  "RECONCILATION_PUBLISHER_PAYMENT_MODAL_POST";
export const RECONCILATION_PUBLISHER_PAYMENT_MODAL_POST_SUCCESS =
  "RECONCILATION_PUBLISHER_PAYMENT_MODAL_POST_SUCCESS";
export const RECONCILATION_PUBLISHER_PAYMENT_MODAL_POST_ERROR =
  "RECONCILATION_PUBLISHER_PAYMENT_MODAL_POST_ERROR";

export const DELETE_PUBLISHER_RECONCILATION_INIT =
  "DELETE_PUBLISHER_RECONCILATION_INIT";
export const DELETE_PUBLISHER_RECONCILATION_INIT_SUCCESS =
  "DELETE_PUBLISHER_RECONCILATION_INIT_SUCCESS";
export const DELETE_PUBLISHER_RECONCILATION_INIT_ERROR =
  "DELETE_PUBLISHER_RECONCILATION_INIT_ERROR";

export const UPDATE_PUBLISHER_RECONCILATION_INIT =
  "UPDATE_PUBLISHER_RECONCILATION_INIT";
export const UPDATE_PUBLISHER_RECONCILATION_INIT_SUCCESS =
  "UPDATE_PUBLISHER_RECONCILATION_INIT_SUCCESS";
export const UPDATE_PUBLISHER_RECONCILATION_INIT_ERROR =
  "UPDATE_PUBLISHER_RECONCILATION_INIT_ERROR";
export const UPDATE_PUBLISHER_RECONCILATION_INIT_CLEAR =
  "UPDATE_PUBLISHER_RECONCILATION_INIT_CLEAR";

//Settlement

export const RECONCILATION_SETTLEMENT_SETTING_MODAL =
  "RECONCILATION_SETTLEMENT_SETTING_MODAL";
export const RECONCILATION_SETTLEMENT_SETTING_MODAL_SUCCESS =
  "RECONCILATION_SETTLEMENT_SETTING_MODAL_SUCCESS";
export const RECONCILATION_SETTLEMENT_SETTING_MODAL_ERROR =
  "RECONCILATION_SETTLEMENT_SETTING_MODAL_ERROR";

export const RECONCILATION_SETTLEMENT_SETTING_MODAL_POST =
  "RECONCILATION_SETTLEMENT_SETTING_MODAL_POST";
export const RECONCILATION_SETTLEMENT_SETTING_MODAL_POST_SUCCESS =
  "RECONCILATION_SETTLEMENT_SETTING_MODAL_POST_SUCCESS";
export const RECONCILATION_SETTLEMENT_SETTING_MODAL_POST_ERROR =
  "RECONCILATION_SETTLEMENT_SETTING_MODAL_POST_ERROR";

export const DELETE_SETTLEMENT_SETTING_INIT = "DELETE_SETTLEMENT_SETTING_INIT";
export const DELETE_SETTLEMENT_SETTING_INIT_SUCCESS =
  "DELETE_SETTLEMENT_SETTING_INIT_SUCCESS";
export const DELETE_SETTLEMENT_SETTING_INIT_ERROR =
  "DELETE_SETTLEMENT_SETTING_INIT_ERROR";

export const UPDATE_SETTLEMENT_SETTING_INIT = "UPDATE_SETTLEMENT_SETTING_INIT";
export const UPDATE_SETTLEMENT_SETTING_INIT_SUCCESS =
  "UPDATE_SETTLEMENT_SETTING_INIT_SUCCESS";
export const UPDATE_SETTLEMENT_SETTING_INIT_ERROR =
  "UPDATE_SETTLEMENT_SETTING_INIT_ERROR";
export const UPDATE_SETTLEMENT_SETTING_INIT_CLEAR =
  "UPDATE_SETTLEMENT_SETTING_INIT_CLEAR";

export const RECONCILATION_CURRENCY_MODAL = "RECONCILATION_CURRENCY_MODAL";
export const RECONCILATION_CURRENCY_INIT_SUCCESS =
  "RECONCILATION_CURRENCY_INIT_SUCCESS";
export const RECONCILATION_CURRENCY_INIT_ERROR =
  "RECONCILATION_CURRENCY_INIT_ERROR";

export const RECONCILATION_PAYMENT_MODAL_ID = "RECONCILATION_PAYMENT_MODAL_ID";
export const RECONCILATION_PAYMENT_ID_INIT_SUCCESS =
  "RECONCILATION_PAYMENT_ID_INIT_SUCCESS";
export const RECONCILATION_PAYMENT_ID_INIT_ERROR =
  "RECONCILATION_PAYMENT_ID_INIT_ERROR";

export const RECONCILATION_ORDER_STATUS_MODAL =
  "RECONCILATION_ORDER_STATUS_MODAL";
export const RECONCILATION_ORDER_STATUS_INIT_SUCCESS =
  "RECONCILATION_ORDER_STATUS_INIT_SUCCESS";
export const RECONCILATION_ORDER_STATUS_INIT_ERROR =
  "RECONCILATION_ORDER_STATUS_INIT_ERROR";

//Alert Page

export const ALERT_PAGE = "ALERT_PAGE";
export const ALERT_PAGE_SUCCESS = "ALERT_PAGE_SUCCESS";
export const ALERT_PAGE_ERROR = "ALERT_PAGE_ERROR";

export const ALERT_PAGE_POST = "ALERT_PAGE_POST";
export const ALERT_PAGE_POST_SUCCESS = "ALERT_PAGE_POST_SUCCESS";
export const ALERT_PAGE_POST_ERROR = "ALERT_PAGE_POST_ERROR";

export const DELETE_ALERT_PAGE_INIT = "DELETE_ALERT_PAGE_INIT";
export const DELETE_ALERT_PAGE_INIT_SUCCESS = "DELETE_ALERT_PAGE_INIT_SUCCESS";
export const DELETE_ALERT_PAGE_INIT_ERROR = "DELETE_ALERT_PAGE_INIT_ERROR";

export const UPDATE_ALERT_PAGE_INIT = "UPDATE_ALERT_PAGE_INIT";
export const UPDATE_ALERT_PAGE_INIT_SUCCESS = "UPDATE_ALERT_PAGE_INIT_SUCCESS";
export const UPDATE_ALERT_PAGE_INIT_ERROR = "UPDATE_ALERT_PAGE_INIT_ERROR";
export const UPDATE_ALERT_PAGE_INIT_CLEAR = "UPDATE_ALERT_PAGE_INIT_CLEAR";

//Report Page
export const REPORT_PAGE_INIT = "REPORT_PAGE_INIT";
export const CLEAR_REPORT_PAGE = "CLEAR_REPORT_PAGE";
export const REPORT_PAGE_SUCCESS = "REPORT_PAGE_SUCCESS";
export const REPORT_PAGE_ERROR = "REPORT_PAGE_ERROR";

export const REPORT_PAGE_INVOICE_INIT = "REPORT_PAGE_INVOICE_INIT";
export const REPORT_PAGE_INVOICE_SUCCESS = "REPORT_PAGE_INVOICE_SUCCESS";
export const REPORT_PAGE_INVOICE_ERROR = "REPORT_PAGE_INVOICE_ERROR";
