import { takeLatest, put, call } from 'redux-saga/effects';
import * as constant from '../constants/DashboardConstants';
import DashboardServices from '../services/DashboardServices';

import { API, API_DOWNLOAD_CALL } from '../setupAxios';
import {
  EndPoints,
  ApiUrl,
  AppInstall,
  AppFilterDomain,
} from '../helpers/Endpoints';

export function* ViewTotalIncidentsSaga(payload) {
  try {
    // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
    const params = { ...payload.dashboarddata };
    const url = ApiUrl + EndPoints.counts_data;
    const response = yield API.get(url, { params });

    yield put({ type: constant.TOTAL_INCIDENTS_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.TOTAL_INCIDENTS_ERROR, error });
  }
}

export function* ViewIncidentVolumeSaga(payload) {
  try {
    // const response = yield call(DashboardServices.ViewIncidentVolumes, payload.dashboarddata);
    const params = { ...payload.dashboarddata };

    const url = ApiUrl + EndPoints.incident_volume;

    const response = yield API.get(url, { params });
    yield put({ type: constant.INCIDENT_VOLUMES_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.INCIDENT_VOLUMES_ERROR, error });
  }
}

export function* ViewActivecasesbyChannel(payload) {
  try {
    // const response = yield call(DashboardServices.ViewActivecasesbyChannel, payload.dashboarddata);
    const params = { ...payload.dashboarddata };

    const url = ApiUrl + EndPoints.activecases_channel;
    const response = yield API.get(url, { params });
    yield put({ type: constant.ACTIVECASE_CHANNEL_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.ACTIVECASE_CHANNEL_ERROR, error });
  }
}

export function* ViewSubChannel(payload) {
  try {
    // const response = yield call(DashboardServices.ViewSubchannel, payload.dashboarddata);
    const params = { ...payload.dashboarddata };

    const url = ApiUrl + EndPoints.sub_channel;
    const response = yield API.get(url, { params });
    yield put({ type: constant.SUBCHANNEL_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.SUBCHANNEL_ERROR, error });
  }
}

export function* ViewToptenLocationSage(payload) {
  try {
    // const response = yield call(DashboardServices.ViewToptenlocation, payload.dashboarddata);
    const params = { ...payload.dashboarddata };

    const url = ApiUrl + EndPoints.topten_location;
    const response = yield API.get(url, { params });
    yield put({ type: constant.TOPTEN_LOCATION_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.TOPTEN_LOCATION_ERROR, error });
  }
}

export function* ViewCategorylevelcountSage(payload) {
  try {
    // const response = yield call(DashboardServices.ViewCategorylevelcount, payload.dashboarddata);
    const params = { ...payload.dashboarddata };

    const url = ApiUrl + EndPoints.category_level_count;
    const response = yield API.get(url, { params });
    yield put({ type: constant.CATEGORY_LEVEL_COUNT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CATEGORY_LEVEL_COUNT_ERROR, error });
  }
}

export function* ViewPublisherlevelcountSage(payload) {
  try {
    // const response = yield call(DashboardServices.ViewPubli  sherlevelcount, payload.dashboarddata);
    const params = { ...payload.dashboarddata };

    const url = ApiUrl + EndPoints.publisher_level_count;
    const response = yield API.get(url, { params });
    yield put({ type: constant.PUBLISHER_LEVEL_COUNT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PUBLISHER_LEVEL_COUNT_ERROR, error });
  }
}
export function* ViewTotalCountsSaga(payload) {
  try {
    // const response = yield call(DashboardServices.ViewTotalCount, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = ApiUrl + EndPoints.web_counts_data;
    const response = yield API.get(url, { params });
    yield put({ type: constant.WEB_TOTAL_INCIDENTS_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.WEB_TOTAL_INCIDENTS_ERROR, error });
  }
}

export function* ViewFraudReasonTrendSaga(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudReasonTrend, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = ApiUrl + EndPoints.web_fraud_reason_trend;
    const response = yield API.get(url, { params });
    yield put({ type: constant.FRAUD_REASON_TREND_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FRAUD_REASON_TREND_ERROR, error });
  }
}

export function* ViewFraudVisit(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = ApiUrl + EndPoints.web_fraud_visit;
    const response = yield API.get(url, { params });
    yield put({ type: constant.FRAUD_VISIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FRAUD_VISIT_ERROR, error });
  }
}

export function* ViewCumulativeImpressionTrend(payload) {
  try {
    // const response = yield call(DashboardServices.ViewCumulativeImpressionTrend, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = ApiUrl + EndPoints.web_cumulative_impression_trend;
    const response = yield API.get(url, { params });
    yield put({ type: constant.CUMULATIVE_IMPRESSION_TREND_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CUMULATIVE_IMPRESSION_TREND_ERROR, error });
  }
}

export function* ViewWebToptenLocationSage(payload) {
  try {
    // const response = yield call(DashboardServices.ViewWebToptenLocation, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = ApiUrl + EndPoints.web_topten_location;
    const response = yield API.get(url, { params });
    yield put({ type: constant.TOPTEN_LOCATION_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.TOPTEN_LOCATION_ERROR, error });
  }
}

export function* ViewTopexchangetraffic(payload) {
  try {
    // const response = yield call(DashboardServices.ViewTopexchangetraffic, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = ApiUrl + EndPoints.web_top_exchange_traffic;
    const response = yield API.get(url, { params });
    yield put({ type: constant.TOPTEN_WEB_LOCATION_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.TOPTEN_WEB_LOCATION_ERROR, error });
  }
}

export function* ViewTopfivecleanpublisher(payload) {
  try {
    // const response = yield call(DashboardServices.ViewTopfivecleanpublisher, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = ApiUrl + EndPoints.web_clean_publisher;
    const response = yield API.get(url, { params });
    yield put({ type: constant.TOP_FIVE_CLEAN_PUBLISHER_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.TOP_FIVE_CLEAN_PUBLISHER_ERROR, error });
  }
}

export function* ViewTopfivefraudpublisher(payload) {
  try {
    // const response = yield call(DashboardServices.ViewTopfivefraudpublisher, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = ApiUrl + EndPoints.web_fraud_publisher;
    const response = yield API.get(url, { params });
    yield put({ type: constant.TOP_FIVE_FRAUD_PUBLISHER_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.TOP_FIVE_FRAUD_PUBLISHER_ERROR, error });
  }
}

export function* ViewFraudtrend(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudtrend, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = ApiUrl + EndPoints.web_fraud_trend_day;
    const response = yield API.get(url, { params });
    yield put({ type: constant.FRAUD_TREND_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FRAUD_TREND_ERROR, error });
  }
}

export function* ViewFraudtrendMonthly(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudtrendMonthly, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = ApiUrl + EndPoints.web_fraud_trend_month;
    const response = yield API.get(url, { params });
    yield put({ type: constant.FRAUD_TREND_MONTHLY_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FRAUD_TREND_MONTHLY_ERROR, error });
  }
}

export function* ViewHighFraudTrafficcountSage(payload) {
  try {
    // const response = yield call(DashboardServices.ViewHighFraudTraffic, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = ApiUrl + EndPoints.web_high_fraud_traffic;
    const response = yield API.get(url, { params });
    yield put({ type: constant.HIGH_FRAUD_TRAFFIC_COUNT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.HIGH_FRAUD_TRAFFIC_COUNT_ERROR, error });
  }
}

export function* ViewTopTenPlacementcountSage(payload) {
  try {
    // const response = yield call(DashboardServices.ViewTopTenPlacement, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = ApiUrl + EndPoints.web_topten_placement;
    const response = yield API.get(url, { params });
    yield put({ type: constant.TOP_TEN_PLACEMENT_COUNT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.TOP_TEN_PLACEMENT_COUNT_ERROR, error });
  }
}

// export function* ViewTotalEvent(payload) {
//     try {
//         // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
//         const params = {...payload.dashboarddata }
//         const url = ApiUrl + EndPoints.total_events
//         const response = yield API.get(url, { params });

//         yield put({ type: constant.TOTAL_EVENT_LIST_INIT_SUCCESS, response })
//     } catch (error) {
//         yield put({ type: constant.TOTAL_EVENT_LIST_INIT_ERROR, error })
//     }
// }

// export function* ViewFraudPercent(payload) {
//     try {
//         // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
//         const params = {...payload.dashboarddata }
//         const url = ApiUrl + EndPoints.event_fraud_percent
//         const response = yield API.get(url, { params });

//         yield put({ type: constant.FRAUD_PERCENT_EVENT_LIST_INIT_SUCCESS, response })
//     } catch (error) {
//         yield put({ type: constant.FRAUD_PERCENT_EVENT_LIST_INIT_ERROR, error })
//     }
// }

export function* ViewFraudTrendPublisher(payload) {
  try {
    // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
    const params = { ...payload.dashboarddata };
    const url = ApiUrl + EndPoints.web_fraud__trend_publisher_wise;
    const response = yield API.get(url, { params });

    yield put({ type: constant.FRAUD_PUBLISHER_WISE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FRAUD_PUBLISHER_WISE_ERROR, error });
  }
}

// export function* ViewFraudEvent(payload) {
//     try {
//         // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
//         const params = {...payload.dashboarddata }
//         const url = ApiUrl + EndPoints.fraud_events
//         const response = yield API.get(url, { params });

//         yield put({ type: constant.FRAUD_EVENT_LIST_INIT_SUCCESS, response })
//     } catch (error) {
//         yield put({ type: constant.FRAUD_EVENT_LIST_INIT_ERROR, error })
//     }
// }
// export function* ViewEventFraudCategory(payload) {
//     try {
//         // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
//         const params = {...payload.dashboarddata }
//         const url = ApiUrl + EndPoints.event_fraud_category
//         const response = yield API.get(url, { params });

//         yield put({ type: constant.EVENT_FRAUD_CATEGORY_INIT_SUCCESS, response })
//     } catch (error) {
//         yield put({ type: constant.EVENT_FRAUD_CATEGORY_INIT_ERROR, error })
//     }
// }

// export function* ViewEventCleanPublisherName(payload) {
//     try {
//         // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
//         const params = {...payload.dashboarddata }
//         const url = ApiUrl + EndPoints.event_clean_publisher_name
//         const response = yield API.get(url, { params });

//         yield put({ type: constant.EVENT_CLEAN_PUBLISHER_NAME_INIT_SUCCESS, response })
//     } catch (error) {
//         yield put({ type: constant.EVENT_CLEAN_PUBLISHER_NAME_INIT_ERROR, error })
//     }
// }

// export function* ViewMonthwiseFraud(payload) {
//     try {
//         // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
//         const params = {...payload.dashboarddata }
//         const url = ApiUrl + EndPoints.monthwise_fraud_event
//         const response = yield API.get(url, { params });

//         yield put({ type: constant.MONTHWISE_FRAUD_TREND_INIT_SUCCESS, response })
//     } catch (error) {
//         yield put({ type: constant.MONTHWISE_FRAUD_TREND_INIT_ERROR, error })
//     }
// }

// export function* ViewEventFraudPublisherName(payload) {
//     try {
//         // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
//         const params = {...payload.dashboarddata }
//         const url = ApiUrl + EndPoints.event_fraud_publisher_name
//         const response = yield API.get(url, { params });

//         yield put({ type: constant.EVENT_FRAUD_PUBLISHER_NAME_INIT_SUCCESS, response })
//     } catch (error) {
//         yield put({ type: constant.EVENT_FRAUD_PUBLISHER_NAME_INIT_ERROR, error })
//     }
// }
// export function* ViewEventNotFraudPublisherName(payload) {
//     try {
//         // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
//         const params = {...payload.dashboarddata }
//         const url = ApiUrl + EndPoints.event_not_fraud_publisher_name
//         const response = yield API.get(url, { params });

//         yield put({ type: constant.EVENT_NOT_FRAUD_PUBLISHER_NAME_INIT_SUCCESS, response })
//     } catch (error) {
//         yield put({ type: constant.EVENT_NOT_FRAUD_PUBLISHER_NAME_INIT_ERROR, error })
//     }
// }
// export function* ViewTotalEventFraudEvent(payload) {
//     try {
//         // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
//         const params = {...payload.dashboarddata }
//         const url = ApiUrl + EndPoints.total_event_fraud_event
//         const response = yield API.get(url, { params });

//         yield put({ type: constant.TOTAL_EVENT_FRAUD_EVENT_INIT_SUCCESS, response })
//     } catch (error) {
//         yield put({ type: constant.TOTAL_EVENT_FRAUD_EVENT_INIT_ERROR, error })
//     }
// }
// export function* ViewFraudEventByDate(payload) {
//     try {
//         // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
//         const params = {...payload.dashboarddata }
//         const url = ApiUrl + EndPoints.fraud_event_by_date
//         const response = yield API.get(url, { params });

//         yield put({ type: constant.FRAUD_EVENT_BY_DATE_INIT_SUCCESS, response })
//     } catch (error) {
//         yield put({ type: constant.FRAUD_EVENT_BY_DATE_INIT_ERROR, error })
//     }
// }

// export function* ViewEventTable(payload) {
//     try {
//         // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
//         const params = {...payload.dashboarddata }
//         const url = ApiUrl + EndPoints.event_tabel
//         const response = yield API.get(url, { params });

//         yield put({ type: constant.EVENT_TABEL_INIT_SUCCESS, response })
//     } catch (error) {
//         yield put({ type: constant.EVENT_TABEL_INIT_ERROR, error })
//     }
// }

//click

export function* ViewClickTopfivecleanpublisher(payload) {
  try {
    // const response = yield call(DashboardServices.ViewClickTopfivecleanpublisher, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = ApiUrl + EndPoints.web_click_clean_publisher;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.CLICK_TOP_FIVE_CLEAN_PUBLISHER_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.CLICK_TOP_FIVE_CLEAN_PUBLISHER_ERROR, error });
  }
}

export function* ViewClickTopfivefraudpublisher(payload) {
  try {
    // const response = yield call(DashboardServices.ViewTopfivefraudpublisher, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = ApiUrl + EndPoints.web_click_fraud_publisher;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.CLICK_TOP_FIVE_FRAUD_PUBLISHER_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.CLICK_TOP_FIVE_FRAUD_PUBLISHER_ERROR, error });
  }
}

export function* ViewClickFraudtrend(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudtrend, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = ApiUrl + EndPoints.web_click_fraud_trend_day;
    const response = yield API.get(url, { params });
    yield put({ type: constant.CLICK_FRAUD_TREND_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CLICK_FRAUD_TREND_ERROR, error });
  }
}

export function* ViewClickFraudtrendMonthly(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudtrendMonthly, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = ApiUrl + EndPoints.web_click_fraud_trend_month;
    const response = yield API.get(url, { params });
    yield put({ type: constant.CLICK_FRAUD_TREND_MONTHLY_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CLICK_FRAUD_TREND_MONTHLY_ERROR, error });
  }
}

export function* ViewClickTotalIncidentsSaga(payload) {
  try {
    // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
    const params = { ...payload.dashboarddata };
    const url = ApiUrl + EndPoints.web_click_counts_data;
    const response = yield API.get(url, { params });

    yield put({ type: constant.CLICK_TOTAL_INCIDENTS_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CLICK_TOTAL_INCIDENTS_ERROR, error });
  }
}

export function* ViewClickFraudVisit(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = ApiUrl + EndPoints.web_click_fraud_visit;
    const response = yield API.get(url, { params });
    yield put({ type: constant.CLICK_FRAUD_VISIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CLICK_FRAUD_VISIT_ERROR, error });
  }
}

export function* ViewInstallTotalInstalls(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_total_install;

    const response = yield API.get(url, { params });
    yield put({ type: constant.INSTALL_TOTAL_INSTALL_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.INSTALL_TOTAL_INSTALL_INIT_ERROR, error });
  }
}

export function* ViewInstallTotalFraudInstalls(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_fraudulent_install;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.INSTALL_FRAUDULENT_INSTALL_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.INSTALL_FRAUDULENT_INSTALL_INIT_ERROR, error });
  }
}

export function* ViewInstallCleanInstalls(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_clean_install;
    const response = yield API.get(url, { params });
    yield put({ type: constant.INSTALL_CLEAN_EVENT_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.INSTALL_CLEAN_EVENT_INIT_ERROR, error });
  }
}

export function* ViewInstallFraudInstalls(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_fraud_install;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.INSTALL_FRAUDULENT_EVENT_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.INSTALL_FRAUDULENT_EVENT_INIT_ERROR, error });
  }
}

export function* ViewInstallFraudCategory(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_fraud_category;
    const response = yield API.get(url, { params });
    yield put({ type: constant.INSTALL_FRAUD_CATEGORY_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.INSTALL_FRAUD_CATEGORY_INIT_ERROR, error });
  }
}

export function* ViewInstallFraudSubCategory(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_fraud_sub_category;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.INSTALL_FRAUD_SUB_CATEGORY_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.INSTALL_FRAUD_SUB_CATEGORY_INIT_ERROR, error });
  }
}

export function* ViewInstallCleanDeviceCount(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.device_fraud_count;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.INSTALL_CLEAN_DEVICE_COUNT_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.INSTALL_CLEAN_DEVICE_COUNT_INIT_ERROR, error });
  }
}

export function* ViewInstallClickFraudDate(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_click_fraud_date;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.INSTALL_CLICK_FRAUD_DATE_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.INSTALL_CLICK_FRAUD_DATE_INIT_ERROR, error });
  }
}

export function* ViewInstallClickFraudPublisher(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_click_fraud_publisher;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.INSTALL_CLICK_FRAUD_PUBLISHER_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.INSTALL_CLICK_FRAUD_PUBLISHER_INIT_ERROR,
      error,
    });
  }
}

export function* ViewInstallFraudDate(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_install_fraud_date;
    const response = yield API.get(url, { params });
    yield put({ type: constant.INSTALL_FRAUD_DATE_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.INSTALL_FRAUD_DATE_INIT_ERROR, error });
  }
}

export function* ViewInstallFraudPublisher(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_install_fraud_publisher;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.INSTALL_FRAUD_PUBLISHER_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.INSTALL_FRAUD_PUBLISHER_INIT_ERROR, error });
  }
}

export function* ViewInstallFraudSubCategoryDate(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_sub_fraud_category_date;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.INSTALL_SUB_FRAUD_CATEGORY_DATE_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.INSTALL_SUB_FRAUD_CATEGORY_DATE_INIT_ERROR,
      error,
    });
  }
}

export function* ViewInstallFraudSubCategoryPublisher(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_sub_fraud_category_publisher;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.INSTALL_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.INSTALL_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_ERROR,
      error,
    });
  }
}

export function* ViewInstallSubPublisherDate(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppFilterDomain + EndPoints.app_fraud_sub_publisher_date;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.INSTALL_SUB_PUBLISHER_DATE_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.INSTALL_SUB_PUBLISHER_DATE_INIT_ERROR, error });
  }
}

export function* ViewInstallSubPublisher(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppFilterDomain + EndPoints.app_fraud_sub_publisher;
    const response = yield API.get(url, { params });
    yield put({ type: constant.INSTALL_SUB_PUBLISHER_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.INSTALL_SUB_PUBLISHER_INIT_ERROR, error });
  }
}

export function* ViewColors(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = 'https://dashboard-api.mfilterit.net/api/config/color';
    const response = yield API.get(url, { params });
    yield put({ type: constant.COLORS_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.COLORS_INIT_ERROR, error });
  }
}

//event

export function* ViewEventTotalEvents(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_event_total_event;

    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_INSTALL_TOTAL_INSTALL_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.APP_INSTALL_TOTAL_INSTALL_INIT_ERROR, error });
  }
}

export function* ViewEventTotalFraudEvent(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_event_fraudulent_event;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_EVENT_FRAUDULENT_EVENT_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.APP_EVENT_FRAUDULENT_EVENT_INIT_ERROR, error });
  }
}

export function* ViewEventCleanEvents(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_event_clean_event;
    const response = yield API.get(url, { params });
    yield put({ type: constant.APP_EVENT_CLEAN_EVENT_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.APP_EVENT_CLEAN_EVENT_INIT_ERROR, error });
  }
}

export function* ViewEventFraudEvents(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_event_fraud_event;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_EVENT_FRAUDULENT_EVENTS_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.APP_EVENT_FRAUDULENT_EVENTS_INIT_ERROR, error });
  }
}

export function* ViewEventsFraudCategory(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_event_fraud_category;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_EVENT_FRAUD_CATEGORY_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.APP_EVENT_FRAUD_CATEGORY_INIT_ERROR, error });
  }
}

export function* ViewEventFraudSubCategory(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_event_fraud_sub_category;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_EVENT_FRAUD_SUB_CATEGORY_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_EVENT_FRAUD_SUB_CATEGORY_INIT_ERROR,
      error,
    });
  }
}

export function* ViewEventCleanDeviceCount(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_event_device_fraud_count;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_EVENT_CLEAN_DEVICE_COUNT_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_EVENT_CLEAN_DEVICE_COUNT_INIT_ERROR,
      error,
    });
  }
}

export function* ViewEventClickFraudDate(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_event_click_fraud_date;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_EVENT_CLICK_FRAUD_DATE_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.APP_EVENT_CLICK_FRAUD_DATE_INIT_ERROR, error });
  }
}

export function* ViewEventClickFraudPublisher(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_event_click_fraud_publisher;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_EVENT_CLICK_FRAUD_PUBLISHER_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_EVENT_CLICK_FRAUD_PUBLISHER_INIT_ERROR,
      error,
    });
  }
}

export function* ViewEventFraudDate(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_event_fraud_date;
    const response = yield API.get(url, { params });
    yield put({ type: constant.APP_EVENT_FRAUD_DATE_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.APP_EVENT_FRAUD_DATE_INIT_ERROR, error });
  }
}

export function* ViewEventFraudPublisher(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_event_fraud_publisher;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_EVENT_FRAUD_PUBLISHER_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.APP_EVENT_FRAUD_PUBLISHER_INIT_ERROR, error });
  }
}

export function* ViewEventSubPublisher(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppFilterDomain + EndPoints.app_fraud_sub_publisher;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_EVENT_FRAUD_SUB_PUBLISHER_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_EVENT_FRAUD_SUB_PUBLISHER_INIT_ERROR,
      error,
    });
  }
}

export function* ViewEventFraudSubCategoryDate(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_event_click_fraud_date;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT_ERROR,
      error,
    });
  }
}

export function* ViewEventFraudSubCategoryPublisher(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_event_click_fraud_publisher;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_ERROR,
      error,
    });
  }
}

//reengagement

export function* ViewReengagementTotalEvents(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_reengagement_event_total_event;

    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_TOTAL_INSTALL_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_TOTAL_INSTALL_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementTotalFraudEvent(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_reengagement_event_fraudulent_event;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENT_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENT_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementNotFraudEvents(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_reengagement_event_clean_event;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_NOT_FRAUD_EVENT_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_NOT_FRAUD_EVENT_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementFraudEvents(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_reengagement_event_fraud_event;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENTS_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENTS_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementsFraudCategory(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_reengagement_event_fraud_category;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_FRAUD_CATEGORY_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_FRAUD_CATEGORY_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementFraudSubCategory(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url =
      AppInstall + EndPoints.app_reengagement_event_fraud_sub_category;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_FRAUD_SUB_CATEGORY_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_FRAUD_SUB_CATEGORY_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementCleanDeviceCount(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url =
      AppInstall + EndPoints.app_reengagement_event_device_fraud_count;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_CLEAN_DEVICE_COUNT_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_CLEAN_DEVICE_COUNT_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementClickFraudDate(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_reengagement_event_click_fraud_date;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_CLICK_FRAUD_DATE_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_CLICK_FRAUD_DATE_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementClickFraudPublisher(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url =
      AppInstall + EndPoints.app_reengagement_event_click_fraud_publisher;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_CLICK_FRAUD_PUBLISHER_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_CLICK_FRAUD_PUBLISHER_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementFraudDate(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_reengagement_event_fraud_date;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_FRAUD_DATE_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_FRAUD_DATE_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementFraudPublisher(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_reengagement_event_fraud_publisher;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_FRAUD_PUBLISHER_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_FRAUD_PUBLISHER_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementSubPublisher(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppFilterDomain + EndPoints.app_fraud_sub_publisher;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_FRAUD_SUB_PUBLISHER_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_FRAUD_SUB_PUBLISHER_ERROR,
      error,
    });
  }
}

export function* ViewReengagementFraudSubCategoryDate(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_reengagement_event_click_fraud_date;
    const response = yield API.get(url, { params });
    yield put({
      type:
        constant.APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementFraudSubCategoryPublisher(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url =
      AppInstall + EndPoints.app_reengagement_event_click_fraud_publisher;
    const response = yield API.get(url, { params });
    yield put({
      type:
        constant.APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type:
        constant.APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementTotalClick(payload) {
  try {
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_total_re_engagement_click;

    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_TOTAL_INSTALL_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_TOTAL_INSTALL_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementFraudulentClick(payload) {
  try {
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_fraudulent_re_engagement_click;

    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICK_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICK_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementFraudCategory(payload) {
  try {
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_fraud_category_re_engagement_click;

    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_FRAUD_CATEGORY_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_FRAUD_CATEGORY_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementFraudSubCategoryClicks(payload) {
  try {
    const params = payload.dashboarddata;
    const url =
      AppInstall + EndPoints.app_fraud_sub_category_re_engagement_click;

    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_FRAUD_SUB_CATEGORY_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_FRAUD_SUB_CATEGORY_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementCleanClick(payload) {
  try {
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_clean_re_engagement_click;

    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_CLEAN_CLICK_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_CLEAN_CLICK_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementClickCleanDeviceCount(payload) {
  try {
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.device_fraud_count_re_engagement_click;

    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_CLEAN_DEVICE_COUNT_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_CLEAN_DEVICE_COUNT_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementClickFraudClick(payload) {
  try {
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_fraud_re_engagement_click;

    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICKS_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICKS_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementClickFraudDateSaga(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_click_fraud_date_re_engagement_click;

    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_CLICK_FRAUD_DATE_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_CLICK_FRAUD_DATE_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementClickFraudPublisherClick(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url =
      AppInstall + EndPoints.app_click_fraud_publisher_re_engagement_click;

    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_CLICK_FRAUD_PUBLISHER_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_CLICK_FRAUD_PUBLISHER_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementFraudDateClick(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_click_fraud_date_re_engagement_click;

    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_FRAUD_DATE_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_FRAUD_DATE_INIT_ERROR,
      error,
    });
  }
}

//rengagement clicks click/fraud

export function* ViewReengagementClickSubFraudCategoryDate(payload) {
  try {
    // const response = yield call(DashboardServices.ViewFraudVisit, payload.dashboarddata);
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_fraud_date_re_engagement_click;

    const response = yield API.get(url, { params });
    yield put({
      type:
        constant.APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_DATE_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_DATE_INIT_ERROR,
      error,
    });
  }
}

export function* ViewReengagementClickSubFraudPublisher(payload) {
  try {
    const params = payload.dashboarddata;
    const url = AppInstall + EndPoints.app_fraud_publisher_re_engagement_click;

    const response = yield API.get(url, { params });
    yield put({
      type:
        constant.APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type:
        constant.APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_ERROR,
      error,
    });
  }
}

//sub publisher

export function* ViewReengagementClickSubPublisher(payload) {
  try {
    const params = payload.dashboarddata;
    const url =
      AppFilterDomain + EndPoints.app_fraud_sub_publisher_re_engagement_click;

    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_SUB_PUBLISHER_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_REENGAGEMENT_CLICK_SUB_PUBLISHER_INIT_ERROR,
      error,
    });
  }
}

export function* ViewGetAppPublisherSaga(payload) {
  try {
    const params = payload.dashboarddata;
    const url = AppFilterDomain + EndPoints.app_publisher;
    const response = yield API.get(url, { params });
    yield put({ type: constant.PUBLISHER_APP_GET_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PUBLISHER_APP_GET_INIT_ERROR, error });
  }
}

export function* ViewGetAppPublisherDownloadSaga(payload) {
  try {
    const params = payload.dashboarddata;
    const url = AppFilterDomain + EndPoints.app_publisher_download;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.PUBLISHER_APP_GET_DOWNLOAD_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.PUBLISHER_APP_GET_DOWNLOAD_INIT_ERROR, error });
  }
}

export default function* DashboardSaga() {
  yield takeLatest(constant.TOTAL_INCIDENTS, ViewTotalIncidentsSaga);
  yield takeLatest(constant.INCIDENT_VOLUMES, ViewIncidentVolumeSaga);
  yield takeLatest(constant.ACTIVECASE_CHANNEL, ViewActivecasesbyChannel);
  yield takeLatest(constant.SUBCHANNEL_INIT, ViewSubChannel);
  yield takeLatest(constant.TOPTEN_LOCATION_INIT, ViewToptenLocationSage);
  yield takeLatest(constant.CATEGORY_LEVEL_COUNT, ViewCategorylevelcountSage);
  yield takeLatest(constant.PUBLISHER_LEVEL_COUNT, ViewPublisherlevelcountSage);
  yield takeLatest(constant.WEB_TOTAL_INCIDENTS, ViewTotalCountsSaga);
  yield takeLatest(constant.FRAUD_REASON_TREND, ViewFraudReasonTrendSaga);
  yield takeLatest(constant.FRAUD_VISIT, ViewFraudVisit);
  yield takeLatest(
    constant.CUMULATIVE_IMPRESSION_TREND_INIT,
    ViewCumulativeImpressionTrend
  );
  yield takeLatest(constant.TOPTEN_LOCATION_INIT, ViewWebToptenLocationSage);
  yield takeLatest(constant.TOPTEN_WEB_LOCATION_INIT, ViewTopexchangetraffic);
  yield takeLatest(constant.FRAUD_PUBLISHER_WISE, ViewFraudTrendPublisher);
  yield takeLatest(
    constant.TOP_FIVE_CLEAN_PUBLISHER,
    ViewTopfivecleanpublisher
  );
  yield takeLatest(
    constant.TOP_FIVE_FRAUD_PUBLISHER,
    ViewTopfivefraudpublisher
  );
  yield takeLatest(constant.FRAUD_TREND, ViewFraudtrend);
  yield takeLatest(constant.FRAUD_TREND_MONTHLY, ViewFraudtrendMonthly);
  yield takeLatest(
    constant.HIGH_FRAUD_TRAFFIC_COUNT,
    ViewHighFraudTrafficcountSage
  );
  yield takeLatest(
    constant.TOP_TEN_PLACEMENT_COUNT,
    ViewTopTenPlacementcountSage
  );
  // yield takeLatest(constant.TOTAL_EVENT_LIST_INIT, ViewTotalEvent);
  // yield takeLatest(constant.FRAUD_PERCENT_EVENT_LIST_INIT, ViewFraudPercent);

  // yield takeLatest(constant.FRAUD_EVENT_LIST_INIT, ViewFraudEvent);
  // yield takeLatest(constant.EVENT_FRAUD_CATEGORY_INIT, ViewEventFraudCategory);
  // yield takeLatest(constant.EVENT_CLEAN_PUBLISHER_NAME_INIT, ViewEventCleanPublisherName);
  // yield takeLatest(constant.MONTHWISE_FRAUD_TREND_INIT, ViewMonthwiseFraud);
  // yield takeLatest(constant.EVENT_FRAUD_PUBLISHER_NAME_INIT, ViewEventFraudPublisherName);
  // yield takeLatest(constant.EVENT_NOT_FRAUD_PUBLISHER_NAME_INIT, ViewEventNotFraudPublisherName);
  // yield takeLatest(constant.TOTAL_EVENT_FRAUD_EVENT_INIT, ViewTotalEventFraudEvent);
  // yield takeLatest(constant.FRAUD_EVENT_BY_DATE_INIT, ViewFraudEventByDate);
  // yield takeLatest(constant.EVENT_TABEL_INIT, ViewEventTable);
  yield takeLatest(
    constant.CLICK_TOP_FIVE_CLEAN_PUBLISHER,
    ViewClickTopfivecleanpublisher
  );
  yield takeLatest(
    constant.CLICK_TOP_FIVE_FRAUD_PUBLISHER,
    ViewClickTopfivefraudpublisher
  );
  yield takeLatest(constant.CLICK_FRAUD_TREND, ViewClickFraudtrend);
  yield takeLatest(
    constant.CLICK_FRAUD_TREND_MONTHLY,
    ViewClickFraudtrendMonthly
  );
  yield takeLatest(constant.CLICK_TOTAL_INCIDENTS, ViewClickTotalIncidentsSaga);
  yield takeLatest(constant.CLICK_FRAUD_VISIT, ViewClickFraudVisit);
  yield takeLatest(
    constant.INSTALL_TOTAL_INSTALL_INIT,
    ViewInstallTotalInstalls
  );
  yield takeLatest(
    constant.INSTALL_FRAUDULENT_INSTALL_INIT,
    ViewInstallTotalFraudInstalls
  );
  yield takeLatest(constant.INSTALL_CLEAN_EVENT_INIT, ViewInstallCleanInstalls);
  yield takeLatest(
    constant.INSTALL_FRAUDULENT_EVENT_INIT,
    ViewInstallFraudInstalls
  );
  yield takeLatest(
    constant.INSTALL_FRAUD_CATEGORY_INIT,
    ViewInstallFraudCategory
  );
  yield takeLatest(
    constant.INSTALL_FRAUD_SUB_CATEGORY_INIT,
    ViewInstallFraudSubCategory
  );
  yield takeLatest(
    constant.INSTALL_CLEAN_DEVICE_COUNT_INIT,
    ViewInstallCleanDeviceCount
  );
  yield takeLatest(
    constant.INSTALL_CLICK_FRAUD_DATE_INIT,
    ViewInstallClickFraudDate
  );
  yield takeLatest(
    constant.INSTALL_CLICK_FRAUD_PUBLISHER_INIT,
    ViewInstallClickFraudPublisher
  );
  yield takeLatest(constant.INSTALL_FRAUD_DATE_INIT, ViewInstallFraudDate);
  yield takeLatest(
    constant.INSTALL_FRAUD_PUBLISHER_INIT,
    ViewInstallFraudPublisher
  );
  yield takeLatest(
    constant.INSTALL_SUB_FRAUD_CATEGORY_DATE_INIT,
    ViewInstallFraudSubCategoryDate
  );
  yield takeLatest(
    constant.INSTALL_SUB_FRAUD_CATEGORY_PUBLISHER_INIT,
    ViewInstallFraudSubCategoryPublisher
  );
  yield takeLatest(
    constant.INSTALL_SUB_PUBLISHER_DATE_INIT,
    ViewInstallSubPublisherDate
  );
  yield takeLatest(
    constant.INSTALL_SUB_PUBLISHER_INIT,
    ViewInstallSubPublisher
  );
  yield takeLatest(constant.APP_EVENT_TOTAL_EVENT_INIT, ViewEventTotalEvents);
  yield takeLatest(
    constant.APP_EVENT_FRAUDULENT_EVENTS_INIT,
    ViewEventTotalFraudEvent
  );
  yield takeLatest(constant.APP_EVENT_CLEAN_EVENT_INIT, ViewEventCleanEvents);
  yield takeLatest(
    constant.APP_EVENT_FRAUDULENT_EVENT_INIT,
    ViewEventFraudEvents
  );
  yield takeLatest(
    constant.APP_EVENT_FRAUD_CATEGORY_INIT,
    ViewEventsFraudCategory
  );
  yield takeLatest(
    constant.APP_EVENT_FRAUD_SUB_CATEGORY_INIT,
    ViewEventFraudSubCategory
  );
  yield takeLatest(
    constant.APP_EVENT_CLEAN_DEVICE_COUNT_INIT,
    ViewEventCleanDeviceCount
  );
  yield takeLatest(
    constant.APP_EVENT_CLICK_FRAUD_DATE_INIT,
    ViewEventClickFraudDate
  );
  yield takeLatest(
    constant.APP_EVENT_CLICK_FRAUD_PUBLISHER_INIT,
    ViewEventClickFraudPublisher
  );
  yield takeLatest(constant.APP_EVENT_FRAUD_DATE_INIT, ViewEventFraudDate);
  yield takeLatest(
    constant.APP_EVENT_FRAUD_PUBLISHER_INIT,
    ViewEventFraudPublisher
  );
  yield takeLatest(
    constant.APP_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT,
    ViewEventFraudSubCategoryDate
  );
  yield takeLatest(
    constant.APP_EVENT_FRAUD_SUB_PUBLISHER_INIT,
    ViewEventSubPublisher
  );
  yield takeLatest(
    constant.APP_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT,
    ViewEventFraudSubCategoryPublisher
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_EVENT_TOTAL_EVENT_INIT,
    ViewReengagementTotalEvents
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENT_INIT,
    ViewReengagementTotalFraudEvent
  );
  // yield takeLatest(constant.APP_REENGAGEMENT_EVENT_CLEAN_EVENT_INIT, ViewReengagementCleanEvents);
  yield takeLatest(
    constant.APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENTS_INIT,
    ViewReengagementFraudEvents
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_EVENT_FRAUD_CATEGORY_INIT,
    ViewReengagementsFraudCategory
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_EVENT_FRAUD_SUB_CATEGORY_INIT,
    ViewReengagementFraudSubCategory
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_EVENT_CLEAN_DEVICE_COUNT_INIT,
    ViewReengagementCleanDeviceCount
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_EVENT_CLICK_FRAUD_DATE_INIT,
    ViewReengagementClickFraudDate
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_EVENT_CLICK_FRAUD_PUBLISHER_INIT,
    ViewReengagementClickFraudPublisher
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_EVENT_FRAUD_DATE_INIT,
    ViewReengagementFraudDate
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_EVENT_FRAUD_PUBLISHER_INIT,
    ViewReengagementFraudPublisher
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT,
    ViewReengagementFraudSubCategoryDate
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT,
    ViewReengagementFraudSubCategoryPublisher
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_EVENT_NOT_FRAUD_EVENT_INIT,
    ViewReengagementNotFraudEvents
  );

  yield takeLatest(
    constant.APP_REENGAGEMENT_CLICK_TOTAL_CLICK_INIT,
    ViewReengagementTotalClick
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICKS_INIT,
    ViewReengagementFraudulentClick
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_CLICK_FRAUD_CATEGORY_INIT,
    ViewReengagementFraudCategory
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_CLICK_FRAUD_SUB_CATEGORY_INIT,
    ViewReengagementFraudSubCategoryClicks
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_CLICK_FRAUD_DATE_INIT,
    ViewReengagementFraudDateClick
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_CLICK_CLEAN_CLICK_INIT,
    ViewReengagementCleanClick
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_CLICK_CLEAN_DEVICE_COUNT_INIT,
    ViewReengagementClickCleanDeviceCount
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICK_INIT,
    ViewReengagementClickFraudClick
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_CLICK_CLICK_FRAUD_DATE_INIT,
    ViewReengagementClickFraudDateSaga
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_CLICK_CLICK_FRAUD_PUBLISHER_INIT,
    ViewReengagementClickFraudPublisherClick
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_DATE_INIT,
    ViewReengagementClickSubFraudCategoryDate
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_PUBLISHER_INIT,
    ViewReengagementClickSubFraudPublisher
  );
  yield takeLatest(
    constant.APP_REENGAGEMENT_CLICK_SUB_PUBLISHER_INIT,
    ViewReengagementClickSubPublisher
  );

  yield takeLatest(constant.COLORS_INIT, ViewColors);
  yield takeLatest(constant.PUBLISHER_APP_GET_INIT, ViewGetAppPublisherSaga);
  yield takeLatest(
    constant.APP_REENGAGEMENT_EVENT_FRAUD_SUB_PUBLISHER_INIT,
    ViewReengagementSubPublisher
  );
  yield takeLatest(
    constant.PUBLISHER_APP_GET_DOWNLOAD_INIT,
    ViewGetAppPublisherDownloadSaga
  );
}
