import * as constant from "../constants/DownloadConstants";
const initialState = {
  download_report_list:[],
  error:"",
  error_status:"",


  event_download_report_list:[],
  event_error:"",
  event_error_status:"",

};

const DownloadReducer = (state = initialState, { type, response,error }) => {
    switch (type) {



        case constant.DOWNLOAD_INIT:
  
            return {
                ...state,
                downloadLoading: true,
            };

        case constant.DOWNLOAD_INIT_SUCCESS:
   
            return {
                ...state,
                downloadLoading: false,
                status: true,
                download_report_list: response,
            };

        case constant.DOWNLOAD_INIT_ERROR:
   
            return {
                ...state,
                downloadLoading: false,
                error_status: error.response.status,
                error: error.response.data.error,
            };
        case constant.DOWNLOAD_INIT_CLEAR:
            return {
                ...state,
                status: false,
                error: '',
                error_status:""
            };


            //event download



            case constant.EVENT_DOWNLOAD_INIT:
  
                return {
                    ...state,
                    event_downloadLoading: true,
                };
    
            case constant.EVENT_DOWNLOAD_SUCCESS:
       
                return {
                    ...state,
                    event_downloadLoading: false,
                    event_status: true,
                    event_download_report_list: response,
                };
    
            case constant.EVENT_DOWNLOAD_ERROR:
       
                return {
                    ...state,
                    event_downloadLoading: false,
                    event_error_status: error.response.status,
                    event_error: error.response.data.error,
                };
            case constant.EVENT_DOWNLOAD_INIT_CLEAR:
                return {
                    ...state,
                    event_status: false,
                    event_error: '',
                    event_error_status:""
                };

            default:
                return state;


    }
}
export default DownloadReducer;