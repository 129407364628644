import { put, takeLatest } from 'redux-saga/effects';
import { ApiUrl } from '../helpers/Endpoints';
import { API } from '../setupAxios';
import CONST from './const';

const Endpoint = {
  DASHBOARD: '/api/user/click_integrity',
};

function sagaGenerator(endpoint, constant) {
  if (!endpoint) throw Error(`Endpoint not defined`);
  if (!constant) throw Error(`Constant not defined`);
  return function*(payload) {
    console.log(payload);
    try {
      const params = payload.query;
      if (typeof endpoint === 'object') {
        if (!endpoint.end && !endpoint.domain)
          throw Error('Missing parameters in Endpoint.');
        const url = endpoint.domain + endpoint.end;
        switch (endpoint.method) {
          case 'GET': {
            const response = yield API.get(url, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'POST': {
            const data = payload.data;
            const response = yield API.post(url, data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'DELETE': {
            const response = yield API.delete(url, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          default: {
            const response = yield API.get(url, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
          }
        }
      } else {
        const url = ApiUrl + endpoint;
        const response = yield API.get(url, { params });
        yield put({
          type: constant.SUCCESS,
          id: payload.id,
          data: response,
        });
      }
    } catch (error) {
      console.error(payload.id, error);
      yield put({
        type: constant.ERROR,
        id: payload.id,
        error,
      });
    }
  };
}

export default function* ClickIntehritySaga() {
  yield takeLatest(
    CONST.DASHBOARD.INIT,
    sagaGenerator(Endpoint.DASHBOARD, CONST.DASHBOARD)
  );
}
