import { combineReducers } from 'redux';
import DashboardReducer from './reducers/DashboardReducer';
import AppFraudReducer from './AppFraud/Reducer';
import ReconcilationReducerNew from './Reco/Reducer';
import ClickIntegrity from './ClickIntegrity/Reducer';
import EventReducer from './reducers/EventReducer';
import CommonReducer from './reducers/CommonReducer';
import DownloadReducer from './reducers/DownloadReducer';
import LoginpagReducer from './reducers/LoginReducer';
import ReportReducer from './reducers/ReportReducer';
import LandingReportReducer from './MtrackIt/LandingPage/reducers';
import MreportReducer from './MtrackIt/Reports/reducers';
import UploadReducer from './MtrackIt/Uploads/reducers';
import CampaignpageReducer from './MtrackIt/Campaign/CampaignReducer';
import PublisherReducer from './MtrackIt/Publisher/reducers';
import MtrackReducer from './reducers/MtrackReducer';
import IncentDetailsReducer from './reducers/IncentDetailsReducer';
import UserManagement from './reducers/UserManagementReducer';
import PackageReducer from './reducers/PackageReducer';
import AppPubConfigReducer from './reducers/AppPublisherConfigReduced';
import WhiteListingReducer from './reducers/WhiteListingReducer';
import EXternalEmbededReducer from './reducers/ExternalEmbededReducer';
import ReconcilationReducer from './reducers/Reconcilation';
import AnalyticsDashboardReducer from './reducers/AnalyticsDashboardReducer';
import VisitDashboardReducer from './WebFraud/reducer';
import { UserManagementReducer } from './Common/UserManagement';
import ImpressionIntegrity from './ImpressionIntegrity/Reducer';
import BrandRelevancy from './BrandRelevancy/Reducer';

export default function createReducer() {
  const rootReducer = combineReducers({
    webdashboards: VisitDashboardReducer,
    dashboard: DashboardReducer,
    common: CommonReducer,
    login: LoginpagReducer,
    download: DownloadReducer,
    report: ReportReducer,
    PublisherAdd: PublisherReducer,
    campaign: CampaignpageReducer,
    revenue: MtrackReducer,
    incentDetails: IncentDetailsReducer,
    UserManagement: UserManagement,
    Package: PackageReducer,
    Event: EventReducer,
    AppPubConfig: AppPubConfigReducer,
    EXternalEmbeded: EXternalEmbededReducer,
    mreport: MreportReducer,
    upload: UploadReducer,
    landingreport: LandingReportReducer,
    WhiteList: WhiteListingReducer,
    ReconcilationList: ReconcilationReducer,
    AnalyticsDashboard: AnalyticsDashboardReducer,
    AppFraud: AppFraudReducer,
    Reconcilation: ReconcilationReducerNew,
    ClickIntegrity,
    UserManagementNew: UserManagementReducer,
    ImpressionIntegrity,
    BrandRelevancy,
  });
  return rootReducer;
}
