export default {
  DASHBOARD: {
    VISIT: {
      TOP_CLEAN_PUBLISHER: {
        INIT: 'WEB_DASHBOARD_VISIT_TOP_FIVE_CLEAN_PUBLISHER',
        SUCCESS: 'WEB_DASHBOARD_VISIT_TOP_FIVE_CLEAN_PUBLISHER_SUCCESS',
        ERROR: 'WEB_DASHBOARD_VISIT_TOP_FIVE_CLEAN_PUBLISHER_ERROR',
      },
      TOTAL_VISIT_CARDS: {
        INIT: 'WEB_DASHBOARD_VISIT_TOTAL_CARDS_INIT',
        SUCCESS: 'WEB_DASHBOARD_VISIT_TOTAL_CARDS_SUCCESS',
        ERROR: 'WEB_DASHBOARD_VISIT_TOTAL_CARDS_ERROR',
      },
      TOP_FRAUD_PUBLISHER: {
        INIT: 'WEB_DASHBOARD_VISIT_TOP_FIVE_FRAUD_PUBLISHER',
        SUCCESS: 'WEB_DASHBOARD_VISIT_TOP_FIVE_FRAUD_PUBLISHER_SUCCESS',
        ERROR: 'WEB_DASHBOARD_VISIT_TOP_FIVE_FRAUD_PUBLISHER_ERROR',
      },
      DAILY_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_VISIT_DAILY_FRAUD_TREND',
        SUCCESS: 'WEB_DASHBOARD_VISIT_DAILY_FRAUD_TREND_SUCCESS',
        ERROR: 'WEB_DASHBOARD_VISIT_DAILY_FRAUD_TREND_ERROR',
      },
      MONTHLY_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_VISIT_FRAUD_TREND_MONTHLY',
        SUCCESS: 'WEB_DASHBOARD_VISIT_FRAUD_TREND_MONTHLY_SUCCESS',
        ERROR: 'WEB_DASHBOARD_VISIT_FRAUD_TREND_MONTHLY_ERROR',
      },
      PUBLISHERWISE_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_VISIT_FRAUD_PUBLISHER_WISE',
        SUCCESS: 'WEB_DASHBOARD_VISIT_FRAUD_PUBLISHER_WISE_SUCCESS',
        ERROR: 'WEB_DASHBOARD_VISIT_FRAUD_PUBLISHER_WISE_ERROR',
      },
      CATEGORYWISE_FRAUD: {
        INIT: 'WEB_DASHBOARD_VISIT_CATEGORY_WISE_FRAUD',
        SUCCESS: 'WEB_DASHBOARD_VISIT_CATEGORY_WISE_FRAUD_SUCCESS',
        ERROR: 'WEB_DASHBOARD_VISIT_CATEGORY_WISE_FRAUD_ERROR',
      },
      SUB_CATEGORYWISE_FRAUD: {
        INIT: 'WEB_DASHBOARD_VISIT_SUB_CATEGORY_WISE_FRAUD',
        SUCCESS: 'WEB_DASHBOARD_VISIT_SUB_CATEGORY_WISE_FRAUD_SUCCESS',
        ERROR: 'WEB_DASHBOARD_VISIT_SUB_CATEGORY_WISE_FRAUD_ERROR',
      },
      CAMPAIGN_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_VISIT_FRAUD_TREND_CAMPAIGN_FRAUD_TREND',
        SUCCESS: 'WEB_DASHBOARD_VISIT_FRAUD_TREND_CAMPAIGN_FRAUD_TREND_SUCCESS',
        ERROR: 'WEB_DASHBOARD_VISIT_FRAUD_TREND_CAMPAIGN_FRAUD_TREND_ERROR',
      },
      PLACEMENT_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_VISIT_FRAUD_TREND_PLACEMENT',
        SUCCESS: 'WEB_DASHBOARD_VISIT_FRAUD_TREND_PLACEMENT_SUCCESS',
        ERROR: 'WEB_DASHBOARD_VISIT_FRAUD_TREND_PLACEMENT_ERROR',
      },
      CHANNEL_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_VISIT_FRAUD_TREND_CHANNEL',
        SUCCESS: 'WEB_DASHBOARD_VISIT_FRAUD_TREND_CHANNEL_SUCCESS',
        ERROR: 'WEB_DASHBOARD_VISIT_FRAUD_TREND_CHANNEL_ERROR',
      },
      WEEKLY_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_VISIT_FRAUD_TREND_WEEKLY',
        SUCCESS: 'WEB_DASHBOARD_VISIT_FRAUD_TREND_WEEKLY_SUCCESS',
        ERROR: 'WEB_DASHBOARD_VISIT_FRAUD_TREND_WEEKLY_ERROR',
      },
    },
    CLICK: {
      TOTAL_CLICK: {
        INIT: 'WEB_DASHBOARD_CLICK_TOTAL_CLICK',
        SUCCESS: 'WEB_DASHBOARD_CLICK_TOTAL_CLICK_SUCCESS',
        ERROR: 'WEB_DASHBOARD_CLICK_TOTAL_CLICK_ERROR',
      },
      CATEGORYWISE_FRAUD: {
        INIT: 'WEB_DASHBOARD_CLICK_CATEGORYWISE_FRAUD',
        SUCCESS: 'WEB_DASHBOARD_CLICK_CATEGORYWISE_FRAUD_SUCCESS',
        ERROR: 'WEB_DASHBOARD_CLICK_CATEGORYWISE_FRAUD_ERROR',
      },
      SUB_CATEGORYWISE_FRAUD: {
        INIT: 'WEB_DASHBOARD_CLICK_SUB_CATEGORY_WISE_FRAUD',
        SUCCESS: 'WEB_DASHBOARD_CLICK_SUB_CATEGORY_WISE_FRAUD_SUCCESS',
        ERROR: 'WEB_DASHBOARD_CLICK_SUB_CATEGORY_WISE_FRAUD_ERROR',
      },
      TOP_CLEAN_PUBLISHER: {
        INIT: 'WEB_DASHBOARD_CLICK_TOP_FIVE_CLEAN_PUBLISHER',
        SUCCESS: 'WEB_DASHBOARD_CLICK_TOP_FIVE_CLEAN_PUBLISHER_SUCCESS',
        ERROR: 'WEB_DASHBOARD_CLICK_TOP_FIVE_CLEAN_PUBLISHER_ERROR',
      },
      TOP_INVALID_PUBLISHER: {
        INIT: 'WEB_DASHBOARD_CLICK_TOP_FIVE_FRAUD_PUBLISHER',
        SUCCESS: 'WEB_DASHBOARD_CLICK_TOP_FIVE_FRAUD_PUBLISHER_SUCCESS',
        ERROR: 'WEB_DASHBOARD_CLICK_TOP_FIVE_FRAUD_PUBLISHER_ERROR',
      },
      DAILY_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_CLICK_DAILY_FRAUD_TREND',
        SUCCESS: 'WEB_DASHBOARD_CLICK_DAILY_FRAUD_TREND_SUCCESS',
        ERROR: 'WEB_DASHBOARD_CLICK_DAILY_FRAUD_TREND_ERROR',
      },
      MONTHLY_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_CLICK_FRAUD_TREND_MONTHLY',
        SUCCESS: 'WEB_DASHBOARD_CLICK_FRAUD_TREND_MONTHLY_SUCCESS',
        ERROR: 'WEB_DASHBOARD_CLICK_FRAUD_TREND_MONTHLY_ERROR',
      },
      CAMPAIGN_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_CLICK_FRAUD_TREND_CAMPAIGN_FRAUD_TREND',
        SUCCESS: 'WEB_DASHBOARD_CLICK_FRAUD_TREND_CAMPAIGN_FRAUD_TREND_SUCCESS',
        ERROR: 'WEB_DASHBOARD_CLICK_FRAUD_TREND_CAMPAIGN_FRAUD_TREND_ERROR',
      },
      PLACEMENT_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_CLICK_FRAUD_TREND_PLACEMENT',
        SUCCESS: 'WEB_DASHBOARD_CLICK_FRAUD_TREND_PLACEMENT_SUCCESS',
        ERROR: 'WEB_DASHBOARD_CLICK_FRAUD_TREND_PLACEMENT_ERROR',
      },
      CHANNEL_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_CLICK_FRAUD_TREND_CHANNEL',
        SUCCESS: 'WEB_DASHBOARD_CLICK_FRAUD_TREND_CHANNEL_SUCCESS',
        ERROR: 'WEB_DASHBOARD_CLICK_FRAUD_TREND_CHANNEL_ERROR',
      },
      WEEKLY_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_CLICK_FRAUD_TREND_WEEKLY',
        SUCCESS: 'WEB_DASHBOARD_CLICK_FRAUD_TREND_WEEKLY_SUCCESS',
        ERROR: 'WEB_DASHBOARD_CLICK_FRAUD_TREND_WEEKLY_ERROR',
      },
      CLICK_PUBLISHERWISE_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_CLICK_FRAUD_PUBLISHER_WISE',
        SUCCESS: 'WEB_DASHBOARD_CLICK_FRAUD_PUBLISHER_WISE_SUCCESS',
        ERROR: 'WEB_DASHBOARD_CLICK_FRAUD_PUBLISHER_WISE_ERROR',
      },
    },
    EVENT: {
      TOTAL_EVENT_COUNT: {
        INIT: 'WEB_DASHBOARD_EVENT_COUNT_LIST_INIT',
        SUCCESS: 'WEB_DASHBOARD_EVENT_COUNT_LIST_SUCCESS',
        ERROR: 'WEB_DASHBOARD_EVENT_COUNT_LIST_ERROR',
      },
      EVENT_CATEGORYWISE_FRAUD: {
        INIT: 'WEB_DASHBOARD_EVENT_FRAUD_CATEGORY_INIT',
        SUCCESS: 'WEB_DASHBOARD_EVENT_FRAUD_CATEGORY_SUCCESS',
        ERROR: 'WEB_DASHBOARD_EVENT_FRAUD_CATEGORY_ERROR',
      },
      SUB_CATEGORYWISE_FRAUD: {
        INIT: 'WEB_DASHBOARD_EVENT_SUB_CATEGORY_WISE_FRAUD',
        SUCCESS: 'WEB_DASHBOARD_EVENT_SUB_CATEGORY_WISE_FRAUD_SUCCESS',
        ERROR: 'WEB_DASHBOARD_EVENT_SUB_CATEGORY_WISE_FRAUD_ERROR',
      },
      EVENT_CLEAN_TRAFFIC: {
        INIT: 'WEB_DASHBOARD_EVENT_CLEAN_TRAFFIC_INIT',
        SUCCESS: 'WEB_DASHBOARD_EVENT_CLEAN_TRAFFIC_SUCCESS',
        ERROR: 'WEB_DASHBOARD_EVENT_CLEAN_TRAFFIC_ERROR',
      },
      EVENT_FRAUD_TRAFFIC: {
        INIT: 'WEB_DASHBOARD_EVENT_FRAUD_TRAFFIC_INIT',
        SUCCESS: 'WEB_DASHBOARD_EVENT_FRAUD_TRAFFIC_SUCCESS',
        ERROR: 'WEB_DASHBOARD_EVENT_FRAUD_TRAFFIC_ERROR',
      },
      EVENT_MONTHLY_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_FRAUD_EVENT_BY_MONTH_INIT',
        SUCCESS: 'WEB_DASHBOARD_FRAUD_EVENT_BY_MONTH_SUCCESS',
        ERROR: 'WEB_DASHBOARD_FRAUD_EVENT_BY_MONTH_ERROR',
      },
      EVENT_DAILY_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_EVENT_DAILY_FRAUD_TREND_INIT',
        SUCCESS: 'WEB_DASHBOARD_EVENT_DAILY_FRAUD_TREND_SUCCESS',
        ERROR: 'WEB_DASHBOARD_EVENT_DAILY_FRAUD_TREND_ERROR',
      },
      EVENT_PUBLISHERWISE_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_EVENT_PUBLISHERWISE_FRAUD_TREND',
        SUCCESS: 'WEB_DASHBOARD_EVENT_PUBLISHERWISE_FRAUD_TREND_SUCCESS',
        ERROR: 'WEB_DASHBOARD_EVENT_PUBLISHERWISE_FRAUD_TREND_ERROR',
      },
      MONTHLY_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_EVENT_FRAUD_TREND_MONTHLY',
        SUCCESS: 'WEB_DASHBOARD_EVENT_FRAUD_TREND_MONTHLY_SUCCESS',
        ERROR: 'WEB_DASHBOARD_EVENT_FRAUD_TREND_MONTHLY_ERROR',
      },
      CAMPAIGN_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_EVENT_FRAUD_TREND_CAMPAIGN_FRAUD_TREND',
        SUCCESS: 'WEB_DASHBOARD_EVENT_FRAUD_TREND_CAMPAIGN_FRAUD_TREND_SUCCESS',
        ERROR: 'WEB_DASHBOARD_EVENT_FRAUD_TREND_CAMPAIGN_FRAUD_TREND_ERROR',
      },
      PLACEMENT_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_EVENT_FRAUD_TREND_PLACEMENT',
        SUCCESS: 'WEB_DASHBOARD_EVENT_FRAUD_TREND_PLACEMENT_SUCCESS',
        ERROR: 'WEB_DASHBOARD_EVENT_FRAUD_TREND_PLACEMENT_ERROR',
      },
      CHANNEL_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_EVENT_FRAUD_TREND_CHANNEL',
        SUCCESS: 'WEB_DASHBOARD_EVENT_FRAUD_TREND_CHANNEL_SUCCESS',
        ERROR: 'WEB_DASHBOARD_EVENT_FRAUD_TREND_CHANNEL_ERROR',
      },
      WEEKLY_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_EVENT_FRAUD_TREND_WEEKLY',
        SUCCESS: 'WEB_DASHBOARD_EVENT_FRAUD_TREND_WEEKLY_SUCCESS',
        ERROR: 'WEB_DASHBOARD_EVENT_FRAUD_TREND_WEEKLY_ERROR',
      },
    },
    IMPRESSION: {
      TOTAL_IMPRESSION: {
        INIT: 'WEB_DASHBOARD_IMPRESSION_TOTAL_COUNTS',
        SUCCESS: 'WEB_DASHBOARD_IMPRESSION_TOTAL_COUNTS_SUCCESS',
        ERROR: 'WEB_DASHBOARD_IMPRESSION_TOTAL_COUNTS_ERROR',
      },
      DAILY_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_IMPRESSION_DAILY_FRAUD_TREND',
        SUCCESS: 'WEB_DASHBOARD_IMPRESSION_DAILY_FRAUD_TREND_SUCCESS',
        ERROR: 'WEB_DASHBOARD_IMPRESSION_DAILY_FRAUD_TREND_ERROR',
      },
      MONTHLY_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_IMPRESSION_FRAUD_TREND_MONTHLY',
        SUCCESS: 'WEB_DASHBOARD_IMPRESSION_FRAUD_TREND_MONTHLY_SUCCESS',
        ERROR: 'WEB_DASHBOARD_IMPRESSION_FRAUD_TREND_MONTHLY_ERROR',
      },
      PUBLISHERWISE_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_IMPRESSION_FRAUD_PUBLISHER_WISE',
        SUCCESS: 'WEB_DASHBOARD_IMPRESSION_FRAUD_PUBLISHER_WISE_SUCCESS',
        ERROR: 'WEB_DASHBOARD_IMPRESSION_FRAUD_PUBLISHER_WISE_ERROR',
      },
      CAMPAIGN_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_IMPRESSION_FRAUD_TREND_CAMPAIGN_FRAUD_TREND',
        SUCCESS:
          'WEB_DASHBOARD_IMPRESSION_FRAUD_TREND_CAMPAIGN_FRAUD_TREND_SUCCESS',
        ERROR:
          'WEB_DASHBOARD_IMPRESSION_FRAUD_TREND_CAMPAIGN_FRAUD_TREND_ERROR',
      },
      PLACEMENT_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_IMPRESSION_FRAUD_TREND_PLACEMENT',
        SUCCESS: 'WEB_DASHBOARD_IMPRESSION_FRAUD_TREND_PLACEMENT_SUCCESS',
        ERROR: 'WEB_DASHBOARD_IMPRESSION_FRAUD_TREND_PLACEMENT_ERROR',
      },
      CHANNEL_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_IMPRESSION_FRAUD_TREND_CHANNEL',
        SUCCESS: 'WEB_DASHBOARD_IMPRESSION_FRAUD_TREND_CHANNEL_SUCCESS',
        ERROR: 'WEB_DASHBOARD_IMPRESSION_FRAUD_TREND_CHANNEL_ERROR',
      },
      WEEKLY_FRAUD_TREND: {
        INIT: 'WEB_DASHBOARD_IMPRESSION_FRAUD_TREND_WEEKLY',
        SUCCESS: 'WEB_DASHBOARD_IMPRESSION_FRAUD_TREND_WEEKLY_SUCCESS',
        ERROR: 'WEB_DASHBOARD_IMPRESSION_FRAUD_TREND_WEEKLY_ERROR',
      },
      TOP_FRAUD_PUBLISHER: {
        INIT: 'WEB_DASHBOARD_IMPRESSION_TOP_FIVE_FRAUD_PUBLISHER',
        SUCCESS: 'WEB_DASHBOARD_IMPRESSION_TOP_FIVE_FRAUD_PUBLISHER_SUCCESS',
        ERROR: 'WEB_DASHBOARD_IMPRESSION_TOP_FIVE_FRAUD_PUBLISHER_ERROR',
      },
      TOP_CLEAN_PUBLISHER: {
        INIT: 'WEB_DASHBOARD_IMPRESSION_TOP_FIVE_CLEAN_PUBLISHER',
        SUCCESS: 'WEB_DASHBOARD_IMPRESSION_TOP_FIVE_CLEAN_PUBLISHER_SUCCESS',
        ERROR: 'WEB_DASHBOARD_IMPRESSION_TOP_FIVE_CLEAN_PUBLISHER_ERROR',
      },
      CATEGORYWISE_FRAUD: {
        INIT: 'WEB_DASHBOARD_IMPRESSION_CATEGORY_WISE_FRAUD',
        SUCCESS: 'WEB_DASHBOARD_IMPRESSION_CATEGORY_WISE_FRAUD_SUCCESS',
        ERROR: 'WEB_DASHBOARD_IMPRESSION_CATEGORY_WISE_FRAUD_ERROR',
      },
      SUB_CATEGORYWISE_FRAUD: {
        INIT: 'WEB_DASHBOARD_IMPRESSION_SUB_CATEGORY_WISE_FRAUD',
        SUCCESS: 'WEB_DASHBOARD_IMPRESSION_SUB_CATEGORY_WISE_FRAUD_SUCCESS',
        ERROR: 'WEB_DASHBOARD_IMPRESSION_SUB_CATEGORY_WISE_FRAUD_ERROR',
      },
      CREATIVE_ID: {
        INIT: 'WEB_DASHBOARD_CREATIVE_ID',
        SUCCESS: 'WEB_DASHBOARD_CREATIVE_ID_SUCCESS',
        ERROR: 'WEB_DASHBOARD_CREATIVE_ID_ERROR',
      },
      ///////////////// old //////////////////////////////
      FRAUD_REASON_TREND: {
        INIT: 'WEB_DASHBOARD_IMPRESSION_FRAUD_REASON_TREND',
        SUCCESS: 'WEB_DASHBOARD_IMPRESSION_FRAUD_REASON_TREND_SUCCESS',
        ERROR: 'WEB_DASHBOARD_IMPRESSION_FRAUD_REASON_TREND_ERROR',
      },
      FRAUD_DISTRIBUTION: {
        INIT: 'WEB_DASHBOARD_IMPRESSION_FRAUD_DISTRIBUTION',
        SUCCESS: 'WEB_DASHBOARD_IMPRESSION_FRAUD_DISTRIBUTION_SUCCESS',
        ERROR: 'WEB_DASHBOARD_IMPRESSION_FRAUD_DISTRIBUTION_ERROR',
      },
      FRAUD_CLEAN_IMPRESSION: {
        INIT: 'WEB_DASHBOARD_IMPRESSION_FRAUD_CLEAN_IMPRESSION_INIT',
        SUCCESS: 'WEB_DASHBOARD_IMPRESSION_FRAUD_CLEAN_IMPRESSION_SUCCESS',
        ERROR: 'WEB_DASHBOARD_IMPRESSION_FRAUD_CLEAN_IMPRESSION_ERROR',
      },
      TOP_EXCHANGE_TRAFFIC: {
        INIT: 'WEB_DASHBOARD_IMPRESSION_TOP_EXCHANGE_TRAFFIC_INIT',
        SUCCESS: 'WEB_DASHBOARD_IMPRESSION_TOP_EXCHANGE_TRAFFIC_SUCCESS',
        ERROR: 'WEB_DASHBOARD_IMPRESSION_TOP_EXCHANGE_TRAFFIC_ERROR',
      },
      TOP_CONTRIBUTING_PLACEMENT: {
        INIT: 'WEB_DASHBOARD_IMPRESSION_TOP_CONTRIBUTING_PLACEMENT',
        SUCCESS: 'WEB_DASHBOARD_IMPRESSION_TOP_CONTRIBUTING_PLACEMENT_SUCCESS',
        ERROR: 'WEB_DASHBOARD_IMPRESSION_TOP_CONTRIBUTING_PLACEMENT_ERROR',
      },
      TOP_PLACEMENT: {
        INIT: 'WEB_DASHBOARD_IMPRESSION_TOP_PLACEMENT',
        SUCCESS: 'WEB_DASHBOARD_IMPRESSION_TOP_PLACEMENT_SUCCESS',
        ERROR: 'WEB_DASHBOARD_IMPRESSION_TOP_PLACEMENT_ERROR',
      },
    },
    VAST: {
      TOTAL_COUNTS: {
        INIT: 'WEB_DASHBOARD_VAST_TOTAL_COUNTS',
        SUCCESS: 'WEB_DASHBOARD_VAST_TOTAL_COUNTS_SUCCESS',
        ERROR: 'WEB_DASHBOARD_VAST_TOTAL_COUNTS_ERROR',
      },
      VAST_TABLE_DOWNLOAD: {
        INIT: 'WEB_DASHBOARD_VAST_TOTAL_COUNTS',
        SUCCESS: 'WEB_DASHBOARD_VAST_TOTAL_COUNTS_SUCCESS',
        ERROR: 'WEB_DASHBOARD_VAST_TOTAL_COUNTS_ERROR',
        CLEAR: 'WEB_DASHBOARD_VAST_TOTAL_COUNTS_CLEAR',
      },
      COMULATIVE_CAMPAIGN_SUMMARY: {
        INIT: 'WEB_DASHBOARD_VAST_COMULATIVE_CAMPAIGN_SUMMARY',
        SUCCESS: 'WEB_DASHBOARD_VAST_COMULATIVE_CAMPAIGN_SUMMARY_SUCCESS',
        ERROR: 'WEB_DASHBOARD_VAST_COMULATIVE_CAMPAIGN_SUMMARY_ERROR',
      },
      IMPRESSION_DISTRIBUTION: {
        INIT: 'WEB_DASHBOARD_IMPRESSION_DISTRIBUTION_SUMMARY',
        SUCCESS: 'WEB_DASHBOARD_IMPRESSION_DISTRIBUTION_SUMMARY_SUCCESS',
        ERROR: 'WEB_DASHBOARD_IMPRESSION_DISTRIBUTION_SUMMARY_ERROR',
      },
      VAST_TRENDS_BY_DATE: {
        INIT: 'WEB_DASHBOARD_VAST_TRENDS_BY_DATE_SUMMARY',
        SUCCESS: 'WEB_DASHBOARD_VAST_TRENDS_BY_DATE_SUMMARY_SUCCESS',
        ERROR: 'WEB_DASHBOARD_VAST_TRENDS_BY_DATE_SUMMARY_ERROR',
      },
      VAST_TRENDS: {
        INIT: 'WEB_DASHBOARD_VAST_TRENDS_SUMMARY',
        SUCCESS: 'WEB_DASHBOARD_VAST_TRENDS_SUMMARY_SUCCESS',
        ERROR: 'WEB_DASHBOARD_VAST_TRENDS_SUMMARY_ERROR',
      },
    },
    GOOGLE_DASHBOARD: {
      ANALYTICS: {
        INIT: 'WEB_DASHBOARD_GOOGLE_DASHBOARD_ANALYTICS',
        SUCCESS: 'WEB_DASHBOARD_GOOGLE_DASHBOARD_ANALYTICS_SUCCESS',
        ERROR: 'WEB_DASHBOARD_GOOGLE_DASHBOARD_ANALYTICS_ERROR',
      },
      TOP_CAMPAIGNS: {
        INIT: 'WEB_DASHBOARD_GOOGLE_DASHBOARD_TOP_CAMPAIGNS',
        SUCCESS: 'WEB_DASHBOARD_GOOGLE_DASHBOARD_TOP_CAMPAIGNS_SUCCESS',
        ERROR: 'WEB_DASHBOARD_GOOGLE_DASHBOARD_TOP_CAMPAIGNS_ERROR',
      },
      FRAUD_GEO: {
        INIT: 'WEB_DASHBOARD_GOOGLE_DASHBOARD_FRAUD_GEO',
        SUCCESS: 'WEB_DASHBOARD_GOOGLE_DASHBOARD_FRAUD_GEO_SUCCESS',
        ERROR: 'WEB_DASHBOARD_GOOGLE_DASHBOARD_FRAUD_GEO_ERROR',
      },
      FRAUD_CATEGORY: {
        INIT: 'WEB_DASHBOARD_GOOGLE_DASHBOARD_FRAUD_CATEGORY',
        SUCCESS: 'WEB_DASHBOARD_GOOGLE_DASHBOARD_FRAUD_CATEGORY_SUCCESS',
        ERROR: 'WEB_DASHBOARD_GOOGLE_DASHBOARD_FRAUD_CATEGORY_ERROR',
      },
    },
    // dashboard ends

    COLORS: {
      colors: {
        INIT: 'COLORS_INIT',
        SUCCESS: 'COLORS_SUCCESS',
        ERROR: 'COLORS_ERROR',
      },
    },
    PUBLISHER_REPORT: {
      CONFIG_DASHBOARD_TYPE: {
        INIT: 'WEB_PUBLISHER_REPORT_PUB_DASHBOARD_TYPE_INIT',
        SUCCESS: 'WEB_PUBLISHER_REPORT_PUB_DASHBOARD_TYPE_SUCCESS',
        ERROR: 'WEB_PUBLISHER_REPORT_PUB_DASHBOARD_TYPE_ERROR',
      },
      CONFIG_LIST: {
        INIT: 'WEB_PUBLISHER_REPORT_PUB_CONFIG_LIST_INIT',
        SUCCESS: 'WEB_PUBLISHER_REPORT_PUB_CONFIG_LIST_SUCCESS',
        ERROR: 'WEB_PUBLISHER_REPORT_PUB_CONFIG_LIST_ERROR',
      },
      CONFIG_FRAUD_CATEGORY: {
        INIT: 'WEB_PUBLISHER_REPORT_PUB_CONFIG_FRAUD_CATEGORIES_LIST_INIT',
        SUCCESS:
          'WEB_PUBLISHER_REPORT_PUB_CONFIG_FRAUD_CATEGORIES_LIST_SUCCESS',
        ERROR: 'WEB_PUBLISHER_REPORT_PUB_CONFIG_FRAUD_CATEGORIES_LIST_ERROR',
      },
      CONFIG_FRAUD_SUB_CATEGORY: {
        INIT: 'WEB_PUBLISHER_REPORT_PUB_CONFIG_SUB_FRAUD_CATEGORY_LIST_INIT',
        SUCCESS:
          'WEB_PUBLISHER_REPORT_PUB_CONFIG_SUB_FRAUD_CATEGORY_LIST_SUCCESS',
        ERROR: 'WEB_PUBLISHER_REPORT_PUB_CONFIG_SUB_FRAUD_CATEGORY_LIST_ERROR',
      },
      CONFIG_PUB_VARIATION: {
        INIT: 'WEB_PUBLISHER_REPORT_PUB_CONFIG_VARIATION_LIST_INIT',
        SUCCESS: 'WEB_PUBLISHER_REPORT_PUB_CONFIG_VARIATION_LIST_SUCCESS',
        ERROR: 'WEB_PUBLISHER_REPORT_PUB_CONFIG_VARIATION_LIST_ERROR',
      },
      CONFIG_SAVE: {
        INIT: 'WEB_PUBLISHER_REPORT_PUB_CONFIG_SAVE_LIST_INIT',
        SUCCESS: 'WEB_PUBLISHER_REPORT_PUB_CONFIG_SAVE_LIST_SUCCESS',
        ERROR: 'WEB_PUBLISHER_REPORT_PUB_CONFIG_SAVE_LIST_ERROR',
        CLEAR: 'WEB_PUBLISHER_REPORT_PUB_CONFIG_SAVE_LIST_CLEAR',
      },
      SASS_ACCOUNT: {
        INIT: 'SASS_ACCOUNT_LIST_INIT',
        SUCCESS: 'SASS_ACCOUNT_LIST_SUCCESS',
        ERROR: 'SASS_ACCOUNT_LIST_ERROR',
        CLEAR: 'SASS_ACCOUNT_LIST_CLEAR',
      },
      CONFIG_TABEL: {
        INIT: 'WEB_PUBLISHER_REPORT_CONFIG_REPORT_LIST_INIT',
        SUCCESS: 'WEB_PUBLISHER_REPORT_CONFIG_REPORT_LIST_SUCCESS',
        ERROR: 'WEB_PUBLISHER_REPORT_CONFIG_REPORT_LIST_ERROR',
        CLEAR: 'WEB_PUBLISHER_REPORT_CONFIG_REPORT_LIST_CLEAR',
      },
      CONFIG_UPDATE: {
        INIT: 'WEB_PUBLISHER_REPORT_CONFIG_EDIT_LIST_INIT',
        SUCCESS: 'WEB_PUBLISHER_REPORT_CONFIG_EDIT_LIST_SUCCESS',
        ERROR: 'WEB_PUBLISHER_REPORT_CONFIG_EDIT_LIST_ERROR',
        CLEAR: 'WEB_PUBLISHER_REPORT_CONFIG_EDIT_LIST_CLEAR',
      },
      CONFIG_DELETE: {
        INIT: 'WEB_PUBLISHER_REPORT_CONFIG_DELETE_INIT',
        SUCCESS: 'WEB_PUBLISHER_REPORT_CONFIG_DELETE_SUCCESS',
        ERROR: 'WEB_PUBLISHER_REPORT_CONFIG_DELETE_ERROR',
        CLEAR: 'WEB_PUBLISHER_REPORT_CONFIG_DELETE_CLEAR',
      },
    },
    REPORT: {
      REPORT_LIST: {
        INIT: 'WEB_REPORT_LIST_INIT',
        SUCCESS: 'WEB_REPORT_LIST_SUCCESS',
        ERROR: 'WEB_REPORT_LIST_ERROR',
      },
      REFETCH_LIST: {
        REFETCH: 'WEB_REPORT_LIST_REFETCH',
      },
      GENERATE_REPORT: {
        INIT: 'WEB_GENERATE_REPORT_LIST_INIT',
        SUCCESS: 'WEB_GENERATE_REPORT_LIST_SUCCESS',
        ERROR: 'WEB_GENERATE_REPORT_LIST_ERROR',
        CLEAR: 'WEB_GENERATE_REPORT_LIST_CLEAR',
      },
    },
  },
  FILTER: {
    CLICK: {
      CLICK_DEVICE_TYPE: {
        INIT: 'CLICK_FILTER_DEVICE_TYPE_INIT',
        SUCCESS: 'CLICK_FILTER_DEVICE_TYPE_SUCCESS',
        ERROR: 'CLICK_FILTER_DEVICE_TYPE_ERROR',
      },
      CLICK_FRAUD_CATEGORY: {
        INIT: 'CLICK_FILTER_FRAUD_CATEGORY_INIT',
        SUCCESS: 'CLICK_FILTER_FRAUD_CATEGORY_SUCCESS',
        ERROR: 'CLICK_FILTER_FRAUD_CATEGORY_ERROR',
      },
      CLICK_FRAUD_SUB_CATEGORY: {
        INIT: 'CLICK_FILTER_FRAUD_SUB_CATEGORY_INIT',
        SUCCESS: 'CLICK_FILTER_FRAUD_SUB_CATEGORY_SUCCESS',
        ERROR: 'CLICK_FILTER_FRAUD_SUB_CATEGORY_ERROR',
      },
      CLICK_PUBLISHER_NAME: {
        INIT: 'CLICK_FILTER_PUBLISHER_NAME_INIT',
        SUCCESS: 'CLICK_FILTER_PUBLISHER_NAME_SUCCESS',
        ERROR: 'CLICK_FILTER_PUBLISHER_NAME_ERROR',
      },
      CLICK_CAMPAIGN_ID: {
        INIT: 'CLICK_FILTER_CAMPAIGN_ID_INIT',
        SUCCESS: 'CLICK_FILTER_CAMPAIGN_ID_SUCCESS',
        ERROR: 'CLICK_FILTER_CAMPAIGN_ID_ERROR',
      },
      CLICK_FILTER_LIST: {
        INIT: 'CLICK_FILTER_FILTERLIST_INIT',
        SUCCESS: 'CLICK_FILTER_FILTERLIST_SUCCESS',
        ERROR: 'CLICK_FILTER_FILTERLIST_ERROR',
      },
    },
    EVENT: {
      EVENT_CAMPAIGN_LIST: {
        INIT: 'EVENT_FILTER_CAMPAIGN_LIST_INIT',
        SUCCESS: 'EVENT_FILTER_CAMPAIGN_LIST_SUCCESS',
        ERROR: 'EVENT_FILTER_CAMPAIGN_LIST_ERROR',
      },
      EVENT_PAGE_LIST: {
        INIT: 'EVENT_FILTER_PAGE_LIST_INIT',
        SUCCESS: 'EVENT_FILTER_PAGE_LIST_SUCCESS',
        ERROR: 'EVENT_FILTER_PAGE_LIST_ERROR',
      },
      EVENT_SUB_PUBLISHER_LIST: {
        INIT: 'EVENT_FILTER_SUB_PUBLISHER_LISTS_INIT',
        SUCCESS: 'EVENT_FILTER_SUB_PUBLISHER_LISTS_SUCCESS',
        ERROR: 'EVENT_FILTER_SUB_PUBLISHER_LISTS_ERROR',
      },
      EVENT_PUBLISHER_LIST: {
        INIT: 'EVENT_FILTER_PUBLISHER_LISTS_INIT',
        SUCCESS: 'EVENT_FILTER_PUBLISHER_LISTS_SUCCESS',
        ERROR: 'EVENT_FILTER_PUBLISHER_LISTS_ERROR',
      },
      EVENT_CHANNEL_LIST: {
        INIT: 'EVENT_FILTER_CHANNEL_LISTS_INIT',
        SUCCESS: 'EVENT_FILTER_CHANNEL_LISTS_SUCCESS',
        ERROR: 'EVENT_FILTER_CHANNEL_LISTS_ERROR',
      },
      EVENT_EVENT_LIST: {
        INIT: 'EVENT_FILTER_EVENT_LISTS_INIT',
        SUCCESS: 'EVENT_FILTER_EVENT_LISTS_SUCCESS',
        ERROR: 'EVENT_FILTER_EVENT_LISTS_ERROR',
      },
      EVENT_FILTER_LIST: {
        INIT: 'EVENT_FILTER_LIST_INIT',
        SUCCESS: 'EVENT_FILTER_LIST_SUCCESS',
        ERROR: 'EVENT_FILTER_LIST_ERROR',
      },
    },
    IMPRESSION: {
      IMPRESSION_PUBLISHER_ID_LIST: {
        INIT: 'IMPRESSION_FILTER_PUBLISHER_ID_LIST_INIT',
        SUCCESS: 'IMPRESSION_FILTER_PUBLISHER_ID_LIST_SUCCESS',
        ERROR: 'IMPRESSION_FILTER_PUBLISHER_ID_LIST_ERROR',
      },
      IMPRESSION_CHANNEL_LIST: {
        INIT: 'IMPRESSION_FILTER_CHANNEL_LIST_INIT',
        SUCCESS: 'IMPRESSION_FILTER_CHANNEL_LIST_SUCCESS',
        ERROR: 'IMPRESSION_FILTER_CHANNEL_LIST_ERROR',
      },
      IMPRESSION_CAMPAIGN_LIST: {
        INIT: 'IMPRESSION_FILTER_CAMPAIGN_LIST_INIT',
        SUCCESS: 'IMPRESSION_FILTER_CAMPAIGN_LIST_SUCCESS',
        ERROR: 'IMPRESSION_FILTER_CAMPAIGN_LIST_ERROR',
      },
      IMPRESSION_CREATIVE_LIST: {
        INIT: 'IMPRESSION_FILTER_CREATIVE_LIST_INIT',
        SUCCESS: 'IMPRESSION_FILTER_CREATIVE_LIST_SUCCESS',
        ERROR: 'IMPRESSION_FILTER_CREATIVE_LIST_ERROR',
      },
      IMPRESSION_SYSTEM_DOMAIN_LIST: {
        INIT: 'IMPRESSION_FILTER_SYSTEM_DOMAIN_LIST_INIT',
        SUCCESS: 'IMPRESSION_FILTER_SYSTEM_DOMAIN_LIST_SUCCESS',
        ERROR: 'IMPRESSION_FILTER_SYSTEM_DOMAIN_LIST_ERROR',
      },
      IMPRESSION_PLACEMENT_ID_LIST: {
        INIT: 'IMPRESSION_FILTER_PLACEMENT_ID_LIST_INIT',
        SUCCESS: 'IMPRESSION_FILTER_PLACEMENT_ID_LIST_SUCCESS',
        ERROR: 'IMPRESSION_FILTER_PLACEMENT_ID_LIST_ERROR',
      },
      IMPRESSION_FRAUD_CATEGORY_LIST: {
        INIT: 'IMPRESSION_FILTER_FRAUD_CATEGORY_LIST_INIT',
        SUCCESS: 'IMPRESSION_FILTER_FRAUD_CATEGORY_LIST_SUCCESS',
        ERROR: 'IMPRESSION_FILTER_FRAUD_CATEGORY_LIST_ERROR',
      },
      IMPRESSION_FRAUD_SUB_CATEGORY_LIST: {
        INIT: 'IMPRESSION_FILTER_FRAUD_SUB_CATEGORY_LIST_INIT',
        SUCCESS: 'IMPRESSION_FILTER_FRAUD_SUB_CATEGORY_LIST_SUCCESS',
        ERROR: 'IMPRESSION_FILTER_FRAUD_SUB_CATEGORY_LIST_ERROR',
      },
      IMPRESSION_PAGE_ID_LIST: {
        INIT: 'IMPRESSION_FILTER_PAGE_ID_LIST_INIT',
        SUCCESS: 'IMPRESSION_FILTER_PAGE_ID_LIST_SUCCESS',
        ERROR: 'IMPRESSION_FILTER_PAGE_ID_LIST_ERROR',
      },
      IMPRESSION_SUB_PUBLISHER_LIST: {
        INIT: 'IMPRESSION_FILTER_SUB_PUBLISHER_LIST_INIT',
        SUCCESS: 'IMPRESSION_FILTER_SUB_PUBLISHER_LIST_SUCCESS',
        ERROR: 'IMPRESSION_FILTER_SUB_PUBLISHER_LIST_ERROR',
      },
      IMPRESSION_FILTER_LIST: {
        INIT: 'IMPRESSION_FILTER_LIST_INIT',
        SUCCESS: 'IMPRESSION_FILTER_LIST_SUCCESS',
        ERROR: 'IMPRESSION_FILTER_LIST_ERROR',
      },
    },
    VISIT: {
      VISIT_FILTER_LIST: {
        INIT: 'VISIT_FILTERLIST_INIT',
        SUCCESS: 'VISIT_FILTERLIST_SUCCESS',
        ERROR: 'VISIT_FILTERLIST_ERROR',
      },
      VISIT_CHANNEL_LIST: {
        INIT: 'VISIT_FILTER_CHANNEL_LIST_INIT',
        SUCCESS: 'VISIT_FILTER_CHANNEL_LIST_SUCCESS',
        ERROR: 'VISIT_FILTER_CHANNEL_LIST_ERROR',
      },
      VISIT_CAMPAIGN_LIST: {
        INIT: 'VISIT_FILTER_CAMPAIGN_LIST_INIT',
        SUCCESS: 'VISIT_FILTER_CAMPAIGN_LIST_SUCCESS',
        ERROR: 'VISIT_FILTER_CAMPAIGN_LIST_ERROR',
      },
      VISIT_PLACEMENT_ID_LIST: {
        INIT: 'VISIT_FILTER_PLACEMENT_ID_LIST_INIT',
        SUCCESS: 'VISIT_FILTER_PLACEMENT_ID_LIST_SUCCESS',
        ERROR: 'VISIT_FILTER_PLACEMENT_ID_LIST_ERROR',
      },
      VISIT_FRAUD_CATEGORY_LIST: {
        INIT: 'VISIT_FILTER_FRAUD_CATEGORY_LIST_INIT',
        SUCCESS: 'VISIT_FILTER_FRAUD_CATEGORY_LIST_SUCCESS',
        ERROR: 'VISIT_FILTER_FRAUD_CATEGORY_LIST_ERROR',
      },
      VISIT_FRAUD_SUB_CATEGORY_LIST: {
        INIT: 'VISIT_FILTER_FRAUD_SUB_CATEGORY_LIST_INIT',
        SUCCESS: 'VISIT_FILTER_FRAUD_SUB_CATEGORY_LIST_SUCCESS',
        ERROR: 'VISIT_FILTER_FRAUD_SUB_CATEGORY_LIST_ERROR',
      },
      VISIT_PAGE_ID_LIST: {
        INIT: 'VISIT_FILTER_PAGE_ID_LIST_INIT',
        SUCCESS: 'VISIT_FILTER_PAGE_ID_LIST_SUCCESS',
        ERROR: 'VISIT_FILTER_PAGE_ID_LIST_ERROR',
      },
      VISIT_SUB_PUBLISHER_LIST: {
        INIT: 'VISIT_FILTER_SUB_PUBLISHER_LIST_INIT',
        SUCCESS: 'VISIT_FILTER_SUB_PUBLISHER_LIST_SUCCESS',
        ERROR: 'VISIT_FILTER_SUB_PUBLISHER_LIST_ERROR',
      },
      VISIT_PLACEMENT_TYPE_LIST: {
        INIT: 'VISIT_FILTER_PLACEMENT_TYPE_LIST_INIT',
        SUCCESS: 'VISIT_FILTER_PLACEMENT_TYPE_LIST_SUCCESS',
        ERROR: 'VISIT_FILTER_PLACEMENT_TYPE_LIST_ERROR',
      },
      VISIT_PUBLISHER_LIST: {
        INIT: 'VISIT_FILTER_PUBLISHER_LIST_INIT',
        SUCCESS: 'VISIT_FILTER_PUBLISHER_LIST_SUCCESS',
        ERROR: 'VISIT_FILTER_PUBLISHER_LIST_ERROR',
      },
      
    },
    VAST: {
      VAST_LOCATION_FILTER_LIST: {
        INIT: 'VAST_LOCATION_FILTER_LIST_INIT',
        SUCCESS: 'VAST_LOCATION_FILTER_LIST_SUCCESS',
        ERROR: 'VAST_LOCATION_FILTER_LIST_ERROR',
      },
      VAST_CAMPAIGN_FILTER_LIST: {
        INIT: 'VAST_CAMPAIGN_FILTER_LIST_INIT',
        SUCCESS: 'VAST_CAMPAIGN_FILTER_LIST_SUCCESS',
        ERROR: 'VAST_CAMPAIGN_FILTER_LIST_ERROR',
      },
      VAST_PUBLISHER_FILTER_LIST: {
        INIT: 'VAST_PUBLISHER_FILTER_LIST_INIT',
        SUCCESS: 'VAST_PUBLISHER_FILTER_LIST_SUCCESS',
        ERROR: 'VAST_PUBLISHER_FILTER_LIST_ERROR',
      },
      VAST_FILTER_LIST: {
        INIT: 'VAST_FILTERLIST_INIT',
        SUCCESS: 'VAST_FILTERLIST_SUCCESS',
        ERROR: 'VAST_FILTERLIST_ERROR',
      },
    },
    REPORT_FILTERS: {
      FIELD_LIST: {
        INIT: 'EVENT_REPORT_FILTER_FIELD_LIST_INIT',
        SUCCESS: 'EVENT_REPORT_FILTER_FIELD_LIST_SUCCESS',
        ERROR: 'EVENT_REPORT_FILTER_FIELD_LIST_ERROR',
      },
      FRAUD_CATEGORY: {
        INIT: 'EVENT_REPORT_FILTER_FRAUD_CATEGORY_LIST_INIT',
        SUCCESS: 'EVENT_REPORT_FILTER_FRAUD_CATEGORY_LIST_SUCCESS',
        ERROR: 'EVENT_REPORT_FILTER_FRAUD_CATEGORY_LIST_ERROR',
      },
      PUBLISHER_LIST: {
        INIT: 'EVENT_REPORT_FILTER_PUBLISHER_LIST_INIT',
        SUCCESS: 'EVENT_REPORT_FILTER_PUBLISHER_SUCCESS',
        ERROR: 'EVENT_REPORT_FILTER_PUBLISHER_ERROR',
      },
      REPORT_FILTER_LIST: {
        INIT: 'EVENT_REPORT_FILTER_LIST_INIT',
        SUCCESS: 'EVENT_REPORT_FILTER_SUCCESS',
        ERROR: 'EVENT_REPORT_FILTER_ERROR',
      },
    },
  },

  // VAST: {
  //   CONFIG_TABEL: {
  //     INIT: "VAST_TRACKER_LIST_INIT",
  //     SUCCESS: "VAST_TRACKER_LIST_SUCCESS",
  //     ERROR: "VAST_TRACKER_LIST_ERROR",
  //     CLEAR: "VAST_TRACKER_LIST_CLEAR",
  //   },
  //   // CONFIG_UPDATE: {
  //   //   INIT: "WEB_PUBLISHER_REPORT_CONFIG_EDIT_LIST_INIT",
  //   //   SUCCESS: "WEB_PUBLISHER_REPORT_CONFIG_EDIT_LIST_SUCCESS",
  //   //   ERROR: "WEB_PUBLISHER_REPORT_CONFIG_EDIT_LIST_ERROR",
  //   //   CLEAR: "WEB_PUBLISHER_REPORT_CONFIG_EDIT_LIST_CLEAR",
  //   // },
  //   CONFIG_DELETE: {
  //     INIT: "VAST_TRACKER_LIST_DELETE_INIT",
  //     SUCCESS: "VAST_TRACKER_LIST_DELETE_SUCCESS",
  //     ERROR: "VAST_TRACKER_LIST_DELETE_ERROR",
  //     CLEAR: "VAST_TRACKER_LIST_DELETE_CLEAR",
  //   },
  // },

  SIDE_DRAWER: {
    SET_SIDE_DRAWER: {
      SUCCESS: 'SET_SIDE_DRAWER',
    },
  },
};
