import React, { Suspense, lazy } from 'react';
import { Redirect, Switch, useLocation, Route } from 'react-router-dom';
import { ContentRoute } from '../_metronic/layout';
import PageLoader from './PageLoader';
import { connect } from 'react-redux';
import { getLocalStorage } from '../app/utils/helpers';
import { AUTH_DATA } from '../app/utils/const';
import ScrollProgressBar from '../Components/Common/ProgressScrollBar';

//
const TestPackageManagement = lazy(() =>
  import('../Pages/UserManagement/packages/routes')
);
const TestUserManagement = lazy(() =>
  import('../Pages/UserManagement/users/routes')
);
/* mTrackit Dashboard */
const Mtrackit = lazy(() => import('../Pages/MtrackIt'));
const MtrackitDashboard = lazy(() =>
  import('../Pages/MtrackIt/Dashboard/index')
);

const MtrackitPublisher = lazy(() =>
  import('../Pages/MtrackIt/Publisher/index')
);
const MtrackitCampaign = lazy(() => import('../Pages/MtrackIt/Campaign/index'));

const MTrackItPublisherReport = lazy(() =>
  import('./views/app/mtrackit/ReportMtrack/publisher_report/mtrackitReport')
);
// const MTrackItEventHeathReport = lazy(() =>
//   import('../Pages/MtrackIt/Reports/event_health_report/index')
// );
const MTrackItCampaignReport = lazy(() =>
  import('../Pages/MtrackIt/Reports/campaign_report/index')
);
const LandingPage = lazy(() =>
  import('../Pages/MtrackIt/LandingPage/landingPageReport')
);
const CapPage = lazy(() => import('../Pages/MtrackIt/Capping/capPage'));
const MTrackItPostbackReport = lazy(() =>
  import('../Pages/MtrackIt/Reports/postback_report/index')
);
const MTrackItEpcReport = lazy(() =>
  import('../Pages/MtrackIt/Reports/epc_report/index')
);
const MTrackItEventReport = lazy(() =>
  import('../Pages/MtrackIt/Reports/event_report/index')
);

const MTrackItDownloadReport = lazy(() =>
  import('../Pages/MtrackIt/Downloads/index')
);
const Uploads = lazy(() => import('../Pages/MtrackIt/Uploads/index'));

/* Adword affiliate and adward competitor */

const AdwordAffiliate = lazy(() =>
  import('../Pages/BrandKeyBidding/adwordAffiliate/index')
);

const AdwordCompetitor = lazy(() =>
  import('../Pages/BrandKeyBidding/adwordCompetitor/index')
);

const Brandbidingdownload = lazy(() =>
  import('../Pages/BrandKeyBidding/Downloads/index')
);
const Brandbidingcoupon = lazy(() =>
  import('../Pages/BrandKeyBidding/Coupons/index')
);

/*Brand Safety Dashboard*/
const BrandRelevancy = lazy(() => import('../Pages/BrandRelevancy/index'));
const BrandRelevancyDVtable = lazy(() =>
  import('../Pages/BrandRelevancy/DVtable/index')
);
const BrandRelevancyBreifName = lazy(() =>
  import('../Pages/BrandRelevancy/Brief/index')
);

const BrandRelevancyacknowledgement = lazy(() =>
  import('../Pages/BrandRelevancy/acknowledgement/index')
);

const BrandRelevancyKeywordManipulationn = lazy(() =>
  import('../Pages/BrandRelevancy/KeyWordManipulation/index')
);

const BrandRelevancyDashboard = lazy(() =>
  import('../Pages/BrandRelevancy/Dashboard/index')
);
const BrandRelevancyAdd = lazy(() =>
  import('../Pages/BrandRelevancy/Brief/briefAdd')
);

const BrandRelevancyEdit = lazy(() =>
  import('../Pages/BrandRelevancy/Brief/briefEdit')
);

const DvEdit = lazy(() => import('../Pages/BrandRelevancy/DVtable/editConfig'));

const DVAdd = lazy(() => import('../Pages/BrandRelevancy/DVtable/addConfig'));

const Brandsafetydashboard = lazy(() =>
  import('../Pages/BrandSafety/bsdashboard/index')
);

const Brandsafetydownload = lazy(() =>
  import('../Pages/BrandSafety/bsreport/index')
);

const ExternalEmbededDashboard = lazy(() =>
  import('./views/app/external-embeded/externalEmbeded')
);
const ClickIntegrity = lazy(() => import('../Pages/ClickIntegrity/dashboard'));

const VastDashboard = lazy(() =>
  import('../Pages/WebFraud/VAST/Dashboard/index')
);

const ImpressionIntegrity = lazy(() =>
  import('../Pages/ImpressionIntegrity/dashboard')
);

const ImpressionIntegrityGenUrl = lazy(() =>
  import('../Pages/ImpressionIntegrity/ImpressionIntegrityGenUrl')
);

const ClickIntegrityGenUrlCopy = lazy(() =>
  import('../Pages/ClickIntegrity/URL/ImpressionIntegrityGenUrlCopy.jsx')
);

const YoutubeRelevancy = lazy(() =>
  import('../Pages/YoutubeRelevancy/index.jsx')
);


const BrandHygene = lazy(() =>
  import('../Pages/BrandHygene/index.jsx')
);

const ImpressionIntegrityReport = lazy(() =>
  import('../Pages/ImpressionIntegrity/Report/index.jsx')
);

const ClickIntegrityReport = lazy(() =>
  import('../Pages/ClickIntegrity/Report/index.jsx')
);

// App pages
const WebImpressionDashboard = lazy(() =>
  import('../Pages/WebFraud/Dashboard/Impression/index')
);
const WebImpressionDashboardNew = lazy(() =>
  import('../Pages/WebFraud/Dashboard/Impression/ref')
);
const WebVisitDashboard = lazy(() =>
  import('../Pages/WebFraud/Dashboard/Visit')
);
const WebVisitDashboardNew = lazy(() =>
  import('../Pages/WebFraud/Dashboard/Visit/ref')
);
const WebEventDashboard = lazy(() =>
  import('../Pages/WebFraud/Dashboard/Event')
);
const WebEventDashboardNew = lazy(() =>
  import('../Pages/WebFraud/Dashboard/Event/ref')
);
const WebClickDashboard = lazy(() =>
  import('../Pages/WebFraud/Dashboard/Click/ref')
);
const WebGoogleAdsDashboard = lazy(() =>
  import('../Pages/WebFraud/Dashboard/GoogleAds')
);
const BlackListingSetting = lazy(() =>
  import('../Pages/WebFraud/Tools/BlackListingSetting')
);
const WebGoogleAdsConfig = lazy(() =>
  import('../Pages/WebFraud/Tools/GoogleAdsConfig.jsx')
);
const WebClickDashboardNew = lazy(() =>
  import('../Pages/WebFraud/Dashboard/Click/index')
);
const WebVastCreative = lazy(() =>
  import('../Pages/WebFraud/VAST/VastCreative')
);
const WebVastDashboard = lazy(() =>
  import('../Pages/WebFraud/VAST/Dashboard.jsx')
);
const WebVastCreativeAdd = lazy(() =>
  import('../Pages/WebFraud/VAST/VastCreativeAdd')
);
const WebEventPublisher = lazy(() =>
  import('../Pages/WebFraud/Publisher/index')
);
const WebEventReports = lazy(() =>
  import('../Pages/WebFraud/Report/Event/index')
);
const WebAddConfig = lazy(() =>
  import('../Pages/WebFraud/Publisher/configuration')
);
//

const WebDashboard = lazy(() => import('./views/app/web-dashboard/impression'));
const VisitDashboard = lazy(() => import('./views/app/web-dashboard/Visit'));
const AlertPage = lazy(() => import('./views/app/aler-page/alert'));
const AlertPageTest = lazy(() => import('../Pages/AppFraud/Alert'));
//
// Reconcilation
const TestPaymentModel = lazy(() =>
  import('../Pages/reconcilation/paymentConfiguration')
);
const TestPublisherPaymentModel = lazy(() =>
  import('../Pages/reconcilation/publisherConfiguration')
);
const TestPaymentModelAdd = lazy(() =>
  import('../Pages/reconcilation/paymentConfigurationAdd')
);
const TestAddPubPaymentModelAdd = lazy(() =>
  import('../Pages/reconcilation/addPublisherConfiguration')
);
const TestSettlementConfig = lazy(() =>
  import('../Pages/reconcilation/invoicePerforma')
);
const TestSettlementConfigAdd = lazy(() =>
  import('../Pages/reconcilation/invoicePerformaAdd')
);
const TestReconciliationReport = lazy(() =>
  import('../Pages/reconcilation/report-page')
);
const InvoiceConfigReconcilation = lazy(() =>
  import('../Pages/reconcilation/InvoiceConfig')
);
const MetaCampaignAppFraud = lazy(() =>
  import('../Pages/AppFraud/MetaCampaign/MetaCampaign')
);
//
const AppPubConfigRoute = lazy(() =>
  import('./views/app/app-pub-config/routes')
);
const AppPubConfigNEWRoute = lazy(() =>
  import('../Pages/AppFraud/PublisherReport/routes')
);
const ClickDashboard = lazy(() => import('./views/app/web-dashboard/Click'));

const WhitelistingRoute = lazy(() =>
  import('./views/app/app-dashboard/whitelisting/routes')
);

const WhitelistingNewRoute = lazy(() =>
  import('../Pages/AppFraud/WhiteListing/routes')
);

const EventPublisher = lazy(() =>
  import('./views/app/web-report/publisherconfig')
);

const EventDashboard = lazy(() => import('./views/app/web-dashboard/Event'));
const InstallDashboard = lazy(() =>
  import('./views/app/app-dashboard/install')
);

const TestInstallDashboard = lazy(() =>
  import('../Pages/AppFraud/Dashboard/InstallDashboard')
);

const NewTestInstallDashboard = lazy(() =>
  import('../Pages/AppFraud/Dashboard/InstallDashboard/ref')
);

const NewTestClickIntegrityDashboard = lazy(() =>
  import('../Pages/AppFraud/Dashboard/ClickIntegrityCopy/ref.jsx')
);

const NewTestImpressionIntegrityDashboard = lazy(() =>
  import('../Pages/AppFraud/Dashboard/ImpressionIntegrity/ref.jsx')
);

const NewTestReEventlDashboard = lazy(() =>
  import('../Pages/AppFraud/Dashboard/AppReengagementEventDashboard/ref')
);

const NewTestEventDashboard = lazy(() =>
  import('../Pages/AppFraud/Dashboard/EventDashboard/ref')
);
const NewTestReengagementClickDashboard = lazy(() =>
  import('../Pages/AppFraud/Dashboard/AppReengagementClickDashboard/ref')
);
const ReengagementClick = lazy(() =>
  import('../Pages/AppFraud/Dashboard/AppReengagementClickDashboard')
);

const ReengagementEvent = lazy(() =>
  import('../Pages/AppFraud/Dashboard/AppReengagementEventDashboard')
);

const TestEventDashboard = lazy(() =>
  import('../Pages/AppFraud/Dashboard/EventDashboard')
);
const ReattributionEvent = lazy(() =>
  import('../Pages/reconcilation/Reattribution/attributionEventSummary')
);
const ReattributionInstall = lazy(() =>
  import('../Pages/reconcilation/Reattribution/attributionInstallSummary')
);

const ReengagementDashboard = lazy(() =>
  import('./views/app/app-dashboard/reengagementEvent')
);
const PublisherSummary = lazy(() =>
  import('./views/app/app-dashboard/publisher-summary')
);

const PublisherSummaryTest = lazy(() =>
  import('../Pages/AppFraud/PublisherSummary/oldIndex')
);
const PublisherSummaryNew = lazy(() =>
  import('../Pages/AppFraud/PublisherSummary')
);
const AppReengagementClickDashboard = lazy(() =>
  import('./views/app/app-dashboard/reengagementClick')
);
const EventPublisherSummary = lazy(() =>
  import('./views/app/app-dashboard/publisher-summary-event')
);
const ClickIntegrityConfiguration = lazy(() =>
  import('../Pages/ClickIntegrity/ClickIntegrityConfiguration')
);
const ClickIntegrityConfigurationTesting = lazy(() =>
  import('../Pages/ClickIntegrity/ClickIntegrityConfigurationTesting')
);
const ClickIntegrityRuleConfiguration = lazy(() =>
  import('../Pages/ClickIntegrity/ClickIntegrityRuleConfiguration')
);
const ClickIntegrityRuleConfigurationEdit = lazy(() =>
  import('../Pages/ClickIntegrity/ClickIntegrityRuleConfigurationEdit')
);
const ClickIntegrityWhitelistEdit = lazy(() =>
  import('../Pages/ClickIntegrity/ClickIntegrityWhitelistEdit')
);
const ClickIntegrityGenUrl = lazy(() =>
  import('../Pages/ClickIntegrity/ClickIntegrityGenUrl')
);
// ----------------------------------------
const ImpressionIntegrityConfiguration = lazy(() =>
  import('../Pages/ImpressionIntegrity/ImpressionIntegrityConfiguration')
);
const ImpressionIntegrityConfigurationTesting = lazy(() =>
  import('../Pages/ImpressionIntegrity/ImpressionIntegrityConfigurationTesting')
);
const ImpressionIntegrityRuleConfiguration = lazy(() =>
  import('../Pages/ImpressionIntegrity/ImpressionIntegrityRuleConfiguration')
);
const ImpressionIntegrityRuleConfigurationEdit = lazy(() =>
  import(
    '../Pages/ImpressionIntegrity/ImpressionIntegrityRuleConfigurationEdit'
  )
);
const ImpressionIntegrityWhitelistEdit = lazy(() =>
  import('../Pages/ImpressionIntegrity/ImpressionIntegrityWhitelistEdit')
);
const ImpressionIntegrityGenUrlCopy = lazy(() =>
  import('../Pages/ImpressionIntegrity/URL/ImpressionIntegrityGenUrlCopy')
);
const UserAcquisitionRuleConfig = lazy(() =>
  import('../Pages/ImpressionIntegrity/User/UserAcquisitionRuleConf')
);

const UserAcquisitionRuleConfigAdd = lazy(() =>
  import('../Pages/ImpressionIntegrity/User/PublisherConfigurationAdd.jsx')
);

const UserAcquisitionRuleConfigEdit = lazy(() =>
  import('../Pages/ImpressionIntegrity/User/PublisherConfigEdit.jsx')
);

const AppEventDashboard = lazy(() =>
  import('./views/app/app-dashboard/event.js')
);
const WebEventReport = lazy(() => import('./views/app/web-report/Event'));
const AddConfig = lazy(() => import('./views/app/web-report/addConfig'));
const IncentReport = lazy(() => import('./views/app/incent_report/'));
const AppEventReport = lazy(() =>
  import('./views/app/app-dashboard/reports/Event')
);
const TestIncentReport = lazy(() => import('../Pages/AppFraud/IncentDetails'));
const TestAppEventReport = lazy(() =>
  import('../Pages/AppFraud/Report/Event/Index')
);

const VastTracker = lazy(() => import('../Pages/WebFraud/VAST/Tracker'));
const VastTrackerAdd = lazy(() =>
  import('../Pages/WebFraud/VAST/Tracker/add/index')
);
const VastTrackerEdit = lazy(() =>
  import('../Pages/WebFraud/VAST/Tracker/edit/index')
);
const ReengagementEventReport = lazy(() =>
  import('../Pages/AppFraud/Report/ReengagementEvent/')
);
const ReengagementClickReport = lazy(() =>
  import('../Pages/AppFraud/Report/ReengagementClick/')
);
const AppInstallReport = lazy(() =>
  import('./views/app/app-dashboard/reports/appinstallreports')
);

const TestAppInstallReport = lazy(() =>
  import('../Pages/AppFraud/Report/Install/Index')
);
const ChangePassword = lazy(() => import('./views/app/changepassword'));

const AnalyticsDashboardBrandSafety = lazy(() =>
  import('./views/app/analytics-dashboard/brandsafety/index.jsx')
);
const AnalyticsDashboardImpression = lazy(() =>
  import('./views/app/analytics-dashboard/impression/index.jsx')
);
const AnalyticsDashboardClick = lazy(() =>
  import('./views/app/analytics-dashboard/click/index.jsx')
);

const AnalyticsDashboard = lazy(() =>
  import('./views/app/analytics-dashboard')
);
const UserManagementRoute = lazy(() =>
  import('./views/app/usermanagement/routes')
);

const AWSDashboard = lazy(() => import('./views/app/aws-dashboard/index.jsx'));

const PackagesRoutes = lazy(() => import('./views/app/packages/routes'));
export function AppPages({ menuLists }) {
  const isAuthorized = JSON.parse(getLocalStorage(AUTH_DATA.AUTH));
  const pathName = useLocation().pathname;
  const getMenuList = getLocalStorage('MENU_LIST_VALUE');
  const menuList = getMenuList !== 'undefined' ? JSON.parse(getMenuList) : '[]';
  const getMenuRoute = arr => {
    if (Array.isArray(arr)) return arr.map(v => v.route);
    return [];
  };

  const checkValid = path => {
    for (const e of getMenuRoute(menuList)) if (path.includes(e)) return true;
    return false;
  };

  function isValidComponent(component) {
    // debugger;
    if (isAuthorized && checkValid(pathName)) return component;
    else return <Redirect to={menuList[0]?.route} />;
  }

  return (
    <>
      <ScrollProgressBar />
      <Suspense fallback={<PageLoader />}>
        <Switch>
          {/* Package management */}
          <ContentRoute
            path='/management/package*'
            render={() => isValidComponent(<TestPackageManagement />)}
          />
          {/* User management */}
          <ContentRoute
            path='/management/user*'
            render={() => isValidComponent(<TestUserManagement />)}
          />
          {/* Web new */}
          {/* <ContentRoute
            path='/web/dashboard/impression'
            render={() => isValidComponent(<WebImpressionDashboard />)}
          /> */}
          {/* <ContentRoute
            path='/web/dashboard/visit'
            render={() => isValidComponent(<WebVisitDashboard />)}
          /> */}
          <ContentRoute
            path='/web/dashboard/visit'
            render={() => isValidComponent(<WebVisitDashboardNew />)}
          />
          {/* <ContentRoute
            path='/web/dashboard/event'
            render={() => isValidComponent(<WebEventDashboard />)}
          /> */}
          <ContentRoute
            path='/web/dashboard/event'
            render={() => isValidComponent(<WebEventDashboardNew />)}
          />
          {/* <ContentRoute
            path='/web/dashboard/click'
            render={() => isValidComponent(<WebClickDashboard />)}
          /> */}
          <ContentRoute
            path='/web/vast/creative'
            render={() => isValidComponent(<WebVastCreative />)}
          />
          <ContentRoute
            path='/vast/dashboard-integrate'
            render={() => isValidComponent(<WebVastDashboard />)}
          />
          <ContentRoute
            exact
            path='/web/vast-creative/upload'
            render={() => <WebVastCreativeAdd />}
          />
          <ContentRoute
            path='/web/dashboard/click'
            render={() => isValidComponent(<WebClickDashboardNew />)}
          />
          <ContentRoute
            path='/web/dashboard/impression'
            render={() => isValidComponent(<WebImpressionDashboardNew />)}
          />
          <ContentRoute
            path='/web/dashboard/click'
            render={() => isValidComponent(<WebClickDashboard />)}
          />
          <ContentRoute
            path='/web/dashboard/google_ads_analytics'
            render={() => isValidComponent(<WebGoogleAdsDashboard />)}
          />
            <ContentRoute
            path='/web/tools/blacklisting-setting'
            render={() => isValidComponent(<BlackListingSetting />)}
          />
          <ContentRoute
            path='/web/tools/linked-accounts'
            render={() => isValidComponent(<WebGoogleAdsConfig />)}
          />
          <ContentRoute
            path='/web/publisher-report'
            render={() => isValidComponent(<WebEventPublisher />)}
          />
          <ContentRoute
            path='/web/report/event'
            render={props => isValidComponent(<WebEventReports {...props} />)}
          />
          <ContentRoute
            path='/web/publisher_report/add'
            component={WebAddConfig}
          />
          {/*  */}
          {/* Reconcilation Starts */}
          <ContentRoute
            path='/reconcilation/payment-configuration'
            render={() => isValidComponent(<TestPaymentModel />)}
          />

          <ContentRoute
            path='/reconcilation/publisher-configuration'
            render={() => isValidComponent(<TestPublisherPaymentModel />)}
          />
          <ContentRoute
            path='/reconcilation/payment-configuration-add'
            render={() => isValidComponent(<TestPaymentModelAdd />)}
          />
          <ContentRoute
            path='/reconcilation/publisher-configuration-add'
            render={() => isValidComponent(<TestAddPubPaymentModelAdd />)}
          />
          <ContentRoute
            path='/reconcilation/invoice-configuration'
            render={() => isValidComponent(<TestSettlementConfig />)}
          />
          <ContentRoute
            path='/reconcilation/invoice-configuration-add'
            render={() => isValidComponent(<TestSettlementConfigAdd />)}
          />
          <ContentRoute
            path='/reconcilation/report'
            render={() => isValidComponent(<TestReconciliationReport />)}
          />
          <ContentRoute
            path='/reconcilation/invoice-config'
            render={() => isValidComponent(<InvoiceConfigReconcilation />)}
          />
          {/* Reconcilation Ends */}

          <ContentRoute
            path='/analytics-dashboard/brandsafety'
            render={() => isValidComponent(<AnalyticsDashboardBrandSafety />)}
          />

          <ContentRoute
            path='/analytics-dashboard/impression'
            render={() => isValidComponent(<AnalyticsDashboardImpression />)}
          />

          <ContentRoute
            path='/analytics-dashboard/click'
            render={() => isValidComponent(<AnalyticsDashboardClick />)}
          />

          <ContentRoute
            path='/analytics-dashboard'
            render={() => isValidComponent(<AnalyticsDashboard />)}
          />

          <ContentRoute
            path='/aws-dashboard'
            render={() => isValidComponent(<AWSDashboard />)}
          />

          <ContentRoute
            path='/impression-dashboard'
            render={() => isValidComponent(<WebDashboard />)}
          />
          <ContentRoute
            path='/visit-dashboard'
            render={() => isValidComponent(<VisitDashboard />)}
          />
          <ContentRoute
            path='/alert-page'
            render={() => isValidComponent(<AlertPage />)}
          />
          <ContentRoute
            path='/app/alert-page'
            render={() => isValidComponent(<AlertPageTest />)}
          />
          <ContentRoute
            path='/webfraud-custom-report'
            render={() => isValidComponent(<WebEventReport />)}
          />
          <ContentRoute
            path='/mtrackit_event_report'
            render={() => isValidComponent(<MTrackItEventReport />)}
            // render={props => <MTrackItEventReport {...props} />}
          />
          <ContentRoute
            path='/download_reports'
            render={() => isValidComponent(<MTrackItDownloadReport />)}
            // render={props => <MTrackItDownloadReport {...props} />}
          />
          <ContentRoute
            path='/app-event-publisher-report'
            render={() => isValidComponent(<AppEventReport />)}
          />
          <ContentRoute
            path='/app/report/install'
            render={() => isValidComponent(<TestAppInstallReport />)}
          />
          <ContentRoute
            path='/event-dashboard'
            render={() => isValidComponent(<EventDashboard />)}
          />
          <ContentRoute
            path='/click-dashboard'
            render={() => isValidComponent(<ClickDashboard />)}
          />
          <ContentRoute
            path='/white-listing'
            render={() => isValidComponent(<WhitelistingRoute />)}
          />
          <ContentRoute
            path='/app-event-dashboard'
            render={() => isValidComponent(<AppEventDashboard />)}
          />
          <ContentRoute
            path='/app-reengagement-click-dashboard'
            render={() => isValidComponent(<AppReengagementClickDashboard />)}
          />
          <ContentRoute
            path='/install-dashboard'
            render={() => isValidComponent(<InstallDashboard />)}
          />
          {/* NS App dashboard begin */}
          {/* <ContentRoute
            path='/app/dashboard/install'
            render={() => isValidComponent(<TestInstallDashboard />)}
          /> */}
          <ContentRoute
            path='/app/dashboard/install'
            render={() => isValidComponent(<NewTestInstallDashboard />)}
          />
          <ContentRoute
            path='/app/dashboard/event'
            render={() => isValidComponent(<NewTestEventDashboard />)}
          />
          <ContentRoute
            path='/integrity/dashboard/click'
            render={() => isValidComponent(<NewTestClickIntegrityDashboard />)}
          />
          <ContentRoute
            path='/integrity/dashboard/impression'
            render={() => isValidComponent(<NewTestImpressionIntegrityDashboard />)}
          />
          <ContentRoute
            path='/app/dashboard/event1'
            render={() => isValidComponent(<NewTestEventDashboard />)}
          />
          
          <ContentRoute
            path='/app/dashboard/reengagement-clicks1'
            render={() => isValidComponent(<NewTestReengagementClickDashboard/>)}
          />
          <ContentRoute
            path='/app/dashboard/reengagement-events1'
            render={() => isValidComponent(<NewTestReEventlDashboard />)}
          />
          <ContentRoute
            path='/app/dashboard/reengagement-clicks'
            render={() =>
              isValidComponent(<NewTestReengagementClickDashboard />)
            }
          />
          <ContentRoute
            path='/app/dashboard/reengagement-events'
            render={() => isValidComponent(<NewTestReEventlDashboard />)}
          />
          {/* <ContentRoute
            path='/app/dashboard/reengagement-clicks'
            render={() => isValidComponent(<ReengagementClick />)}
          /> */}
          {/* <ContentRoute
            path='/app/dashboard/reengagement-events'
            render={() => isValidComponent(<ReengagementEvent />)}
          /> */}
          <ContentRoute
            path='/app/dashboard/Event'
            render={() => isValidComponent(<TestEventDashboard />)}
          />
          <ContentRoute
            path='/reconcilation/reattribution/install'
            render={() => isValidComponent(<ReattributionInstall />)}
          />
          <ContentRoute
            path='/reconcilation/reattribution/event'
            render={() => isValidComponent(<ReattributionEvent />)}
          />
          {/* NS App dashboard end */}
          {/* NS App Whitelisting */}
          <ContentRoute
            path='/app/white-listing'
            render={() => isValidComponent(<WhitelistingNewRoute />)}
          />

          <ContentRoute
            path='/app-reengagement-event-dashboard'
            render={() => isValidComponent(<ReengagementDashboard />)}
          />
          <ContentRoute
            path='/publisher-summary'
            render={() => isValidComponent(<PublisherSummary />)}
          />
          <ContentRoute
            path='/app/publisher-summary'
            render={() => isValidComponent(<PublisherSummaryNew />)}
          />
          <ContentRoute
            path='/app/publisher-summary-test'
            render={() => isValidComponent(<PublisherSummaryNew />)}
          />
          <ContentRoute
            path='/event-publisher-summary'
            render={() => isValidComponent(<EventPublisherSummary />)}
          />
          <ContentRoute
            path='/app_incent_report'
            render={() => isValidComponent(<IncentReport />)}
          />
          <ContentRoute
            path='/app/incent-details'
            render={() => isValidComponent(<TestIncentReport />)}
          />

          <ContentRoute
            path='/webfraud-publisher-report'
            render={() => isValidComponent(<EventPublisher />)}
          />
          <ContentRoute path='/add-configuration' component={AddConfig} />
          {/* App publisher config */}

          <ContentRoute
            path='/app/meta-campaign'
            render={() => isValidComponent(<MetaCampaignAppFraud />)}
          />
          <ContentRoute
            path='/app_publisher_config*'
            render={() => isValidComponent(<AppPubConfigRoute />)}
          />
          <ContentRoute
            path='/app/publisher_report*'
            render={() => isValidComponent(<AppPubConfigNEWRoute />)}
          />
          <ContentRoute
            path='/external-embeded'
            render={() => isValidComponent(<ExternalEmbededDashboard />)}
          />
          <ContentRoute
            path='/click-integrity/dashboard'
            render={() => isValidComponent(<ClickIntegrity />)}
          />
          <ContentRoute
            path='/vast/dashboard'
            render={() => isValidComponent(<VastDashboard />)}
          />
          <ContentRoute
            path='/click-integrity/campaign_geo_location'
            render={() => isValidComponent(<ClickIntegrityConfiguration />)}
          />
          <ContentRoute
            path='/click-integrity/configuration_testing'
            render={() =>
              isValidComponent(<ClickIntegrityConfigurationTesting />)
            }
          />

          <ContentRoute
            exact
            path='/click-integrity/rule_configuration'
            render={() => isValidComponent(<ClickIntegrityRuleConfiguration />)}
          />
          <ContentRoute
            exact
            path='/click-integrity/rule_configuration/edit'
            render={() =>
              isValidComponent(<ClickIntegrityRuleConfigurationEdit />)
            }
          />
          <ContentRoute
            exact
            path='/click-integrity/rule_configuration/whitelisting_edit'
            render={() => isValidComponent(<ClickIntegrityWhitelistEdit />)}
          />
          <ContentRoute
            path='/click-integrity/generate_url'
            render={() => isValidComponent(<ClickIntegrityGenUrl />)}
          />
          <ContentRoute
            path='/integrity/click'
            render={() => isValidComponent(<ClickIntegrityGenUrlCopy />)}
          />
          <ContentRoute
            path='/youtube-relevancy'
            render={() => isValidComponent(<YoutubeRelevancy />)}
          />

          <ContentRoute
            path='/brand-hygen'
            render={() => <BrandHygene />}
          />
          {/* -------------------------------- */}

          <ContentRoute
            path='/impression-integrity/dashboard'
            render={() => isValidComponent(<ImpressionIntegrity />)}
          />

          <ContentRoute
            path='/impression-integrity/report'
            render={() => isValidComponent(<ImpressionIntegrityReport />)}
          />

          <ContentRoute
            path='/click-integrity/report'
            render={() => isValidComponent(<ClickIntegrityReport />)}
          />

          <ContentRoute
            path='/impression-integrity/campaign_geo_location'
            render={() =>
              isValidComponent(<ImpressionIntegrityConfiguration />)
            }
          />
          <ContentRoute
            path='/impression-integrity/configuration_testing'
            render={() =>
              isValidComponent(<ImpressionIntegrityConfigurationTesting />)
            }
          />

          <ContentRoute
            exact
            path='/impression-integrity/rule_configuration'
            render={() =>
              isValidComponent(<ImpressionIntegrityRuleConfiguration />)
            }
          />
          <ContentRoute
            exact
            path='/impression-integrity/rule_configuration/edit'
            render={() =>
              isValidComponent(<ImpressionIntegrityRuleConfigurationEdit />)
            }
          />
          <ContentRoute
            exact
            path='/impression-integrity/rule_configuration/whitelisting_edit'
            render={() =>
              isValidComponent(<ImpressionIntegrityWhitelistEdit />)
            }
          />
          <ContentRoute
            path='/impression-integrity/generate_url'
            render={() => isValidComponent(<ImpressionIntegrityGenUrl />)}
          />

          <ContentRoute
            path='/click-integrity/generate_url'
            render={() => isValidComponent(<ImpressionIntegrityGenUrl />)}
          />

          <ContentRoute
            path='/integrity/impression'
            render={() => isValidComponent(<ImpressionIntegrityGenUrlCopy />)}
          />

          <ContentRoute
            path='/user-acquisition/rule-configuration/add'
            render={() => isValidComponent(<UserAcquisitionRuleConfigAdd />)}
          />

          
<ContentRoute
            path='/user-acquisition/rule-configuration/edit'
            render={() => isValidComponent(<UserAcquisitionRuleConfigEdit />)}
          />

           <ContentRoute
            path='/user-acquisition/rule-configuration'
            render={() => isValidComponent(<UserAcquisitionRuleConfig />)}
          />

          {/* <ContentRoute
            path='/reconcilation-report'
            render={() => isValidComponent(<ReconciliationReport />)}
          /> */}
          {/* User management */}
          <ContentRoute
            path='/user*'
            render={() => isValidComponent(<UserManagementRoute />)}
          />
          {/* User management End */}
          {/* Package configuration */}
          <ContentRoute
            path='/package*'
            render={() => isValidComponent(<PackagesRoutes />)}
          />
          {/* Package configuration end */}
          <ContentRoute
            exact
            path='/mtrackit_uploads'
            render={() => isValidComponent(<Uploads />)}
          />

          <ContentRoute
            path='/landing_page'
            render={() => isValidComponent(<LandingPage />)}
          />
          <ContentRoute
            path='/changepassword'
            render={props =>
              isAuthorized ? (
                <ChangePassword {...props} />
              ) : (
                <Redirect to='/incident-dashboard' />
              )
            }
          />
          <ContentRoute
            path='/mtrackit_dashboard'
            render={props => isValidComponent(<MtrackitDashboard {...props} />)}
          />

          {/* Adword affiliate and adward competitor */}

          <ContentRoute
            path='/adword_affiliate'
            render={props => isValidComponent(<AdwordAffiliate {...props} />)}
          />

          <ContentRoute
            path='/adword_competitor'
            render={props => isValidComponent(<AdwordCompetitor {...props} />)}
          />

          <ContentRoute
            path='/coupons'
            render={props => isValidComponent(<Brandbidingcoupon {...props} />)}
          />

          <ContentRoute
            path='/keyword-bidding-download'
            render={props =>
              isValidComponent(<Brandbidingdownload {...props} />)
            }
          />

          {/*Brand Safety Dashboard */}

          <ContentRoute
            path='/bs-dashboard'
            render={props =>
              isValidComponent(<Brandsafetydashboard {...props} />)
            }
          />

          <ContentRoute
            path='/bs-download'
            render={props =>
              isValidComponent(<Brandsafetydownload {...props} />)
            }
          />
          <ContentRoute
            exact
            path='/brand-relevancy/brief-config'
            render={props => <BrandRelevancyBreifName />}
          />
          <ContentRoute
            exact
            path='/brand-relevancy/acknowledgement'
            render={props => <BrandRelevancyacknowledgement />}
          />
          <ContentRoute
            exact
            path='/brand-relevancy/keyword-manipulation'
            render={props => <BrandRelevancyKeywordManipulationn />}
          />
          <ContentRoute
            exact
            path='/brand-relevancy/dashboard'
            render={props => <BrandRelevancyDashboard />}
          />
          <ContentRoute
            exact
            path='/brand-relevancy/downloads'
            render={props => <BrandRelevancy />}
          />
          <ContentRoute
            exact
            path='/brand-relevancy/dv-table'
            render={props => <BrandRelevancyDVtable />}
          />
          <ContentRoute
            exact
            path='/brand-relevancy/brief/add'
            render={props => <BrandRelevancyAdd />}
          />
          <ContentRoute
            exact
            path='/brand-relevancy/brief/edit'
            render={props => <BrandRelevancyEdit />}
          />
          <ContentRoute
            exact
            path='/brand-relevancy/dv/edit'
            render={props => <DvEdit />}
          />
          <ContentRoute
            exact
            path='/brand-relevancy/dv/add'
            render={props => <DVAdd />}
          />
          {/* <ContentRoute
            exact
            path='/brand-relevancy/edit'
            render={props => <BrandRelevancyEdit />}
          /> */}
          <ContentRoute
            path='/event-dashboard'
            render={props => isValidComponent(<EventDashboard {...props} />)}
          />
          <ContentRoute
            path='/mtrackit_report'
            render={props =>
              isValidComponent(<MTrackItPublisherReport {...props} />)
            }
          />

          <ContentRoute
            path='/publisher-summary'
            render={props => isValidComponent(<PublisherSummary {...props} />)}
          />

          <ContentRoute
            path='/event-publisher-summary'
            render={props =>
              isValidComponent(<EventPublisherSummary {...props} />)
            }
          />

          <ContentRoute
            path='/app-event-dashboard'
            render={props => isValidComponent(<AppEventDashboard {...props} />)}
          />
          <ContentRoute
            path='/app-reengagement-click-dashboard'
            render={props =>
              isValidComponent(<AppReengagementClickDashboard {...props} />)
            }
          />
          <ContentRoute
            path='/webfraud-custom-report'
            render={props => isValidComponent(<WebEventReport {...props} />)}
          />
          <ContentRoute
            path='/app/report/event'
            render={props =>
              isValidComponent(<TestAppEventReport {...props} />)
            }
          />
          <ContentRoute
            path='/web/vast/tracker/add'
            render={props => isValidComponent(<VastTrackerAdd {...props} />)}
          />
          <ContentRoute
            path='/web/vast/tracker/edit'
            render={props => isValidComponent(<VastTrackerEdit {...props} />)}
          />
          <ContentRoute
            path='/web/vast/tracker'
            render={props => isValidComponent(<VastTracker {...props} />)}
          />

          <ContentRoute
            path='/app/report/reengagement-events'
            render={props =>
              isValidComponent(<ReengagementEventReport {...props} />)
            }
          />
          <ContentRoute
            path='/app/report/reengagement-clicks'
            render={props =>
              isValidComponent(<ReengagementClickReport {...props} />)
            }
          />
          <ContentRoute
            path='/app-event-publisher-report'
            render={props => isValidComponent(<AppEventReport {...props} />)}
          />
          <ContentRoute
            path='/mtrackit_capping'
            render={props => <CapPage {...props} />}
          />
          <ContentRoute
            path='/app-install-publisher-report'
            render={props => isValidComponent(<AppInstallReport {...props} />)}
          />
          <ContentRoute
            path='/mtrackit_campaign_report'
            render={props =>
              isValidComponent(<MTrackItCampaignReport {...props} />)
            }
          />
          <ContentRoute
            path='/mtrackit_postback_report'
            render={props =>
              isValidComponent(<MTrackItPostbackReport {...props} />)
            }
          />
          <ContentRoute
            path='/mtrackit_epc_report'
            render={props => <MTrackItEpcReport {...props} />}
          />
          <ContentRoute
            path='/mtrackit_publisher'
            render={props =>
              isAuthorized && checkValid(pathName) ? (
                <MtrackitPublisher {...props} />
              ) : (
                <Redirect to='/mtrackit_publisher' />
              )
            }
          />
          <ContentRoute
            path='/mtrackit_campaigns'
            render={props =>
              isAuthorized && checkValid(pathName) ? (
                <MtrackitCampaign {...props} />
              ) : (
                <Redirect to='/mtrackit_campaigns' />
              )
            }
          />
          {/* FIXME: */}
          <ContentRoute
            exact
            path='/mtrackit/campaign'
            render={props =>
              isAuthorized ? (
                <MtrackitDashboard {...props} />
              ) : (
                <Redirect to='/auth/login' />
              )
            }
          />
          {/* mtrackit/addPublisher */}
          <ContentRoute
            exact
            path='/mtrackit/addPublisher'
            render={props =>
              isAuthorized ? (
                <Mtrackit {...props} />
              ) : (
                <Redirect to='/mtrackit_dashboard' />
              )
            }
          />

          <ContentRoute
            exact
            path='/mtrackit/addCapping'
            render={props =>
              isAuthorized ? (
                <Mtrackit {...props} />
              ) : (
                <Redirect to='/mtrackit_capping' />
              )
            }
          />

          <ContentRoute
            exact
            path='/mtrackit/addCampaign'
            render={props =>
              isAuthorized ? (
                <Mtrackit {...props} />
              ) : (
                <Redirect to='/mtrackit_dashboard' />
              )
            }
          />
          <ContentRoute
            exact
            path='/mtrackit/updatePublisher/:id'
            render={props => isValidComponent(<Mtrackit {...props} />)}
          />
          {/* <ContentRoute
          exact
          path='/mtrackit/addCampaign'
          render={props => isValidComponent(<Mtrackit {...props} />)}
        /> */}
          <ContentRoute
            exact
            path='/mtrackit/updateCampaign/:id'
            render={props => isValidComponent(<Mtrackit {...props} />)}
          />
          <ContentRoute
            path='*'
            render={() => isAuthorized && <Redirect to={menuList[0]?.route} />}
          />
        </Switch>
      </Suspense>
    </>
  );
}

const mapStateToProps = state => {
  const { common } = state;
  return {
    menuLists: common && common?.menu_list ? common.menu_list?.menus : [],
  };
};
export default connect(mapStateToProps)(AppPages);
