export const REPORT_INCIDENTS = 'REPORT_INCIDENTS';
export const REPORT_INCIDENTS_SUCCESS = 'REPORT_INCIDENTS_SUCCESS';
export const REPORT_INCIDENTS_ERROR = 'REPORT_INCIDENTS_ERROR';

export const DOWNLOAD_REPORT_INIT = 'DOWNLOAD_REPORT_INIT';
export const DOWNLOAD_REPORT_SUCCESS = 'DOWNLOAD_REPORT_INIT_SUCCESS';
export const DOWNLOAD_REPORT_ERROR = 'DOWNLOAD_REPORT_ERROR';



export const UPDATE_PRIORITY_STATUS_INIT = 'UPDATE_PRIORITY_STATUS_INIT';
export const UPDATE_PRIORITY_STATUS_SUCCESS = 'UPDATE_PRIORITY_STATUS_SUCCESS';
export const UPDATE_PRIORITY_STATUS_ERROR = 'UPDATE_PRIORITY_STATUS_ERROR';
export const UPDATE_PRIORITY_STATUS_CLEAR = 'UPDATE_PRIORITY_STATUS_CLEAR';

export const CREATE_TICKET_INIT = 'CREATE_TICKET_INIT';
export const CREATE_TICKET_INIT_SUCCESS = 'CREATE_TICKET_INIT_SUCCESS';
export const CREATE_TICKET_INIT_ERROR = 'CREATE_TICKET_INIT_ERROR';
export const CREATE_TICKET_CLEAR = 'CREATE_TICKET_CLEAR';


export const CREATE_MULTIPLE_TICKET_INIT = 'CREATE_MULTIPLE_TICKET_INIT';
export const CREATE_MULTIPLE_TICKET_SUCCESS = 'CREATE_MULTIPLE_TICKET_SUCCESS';
export const CREATE_MULTIPLE_TICKET_ERROR = 'CREATE_MULTIPLE_TICKET_ERROR';
export const CREATE_MULTIPLE_TICKET_CLEAR = 'CREATE_MULTIPLE_TICKET_CLEAR';

export const TICKET_DETAILS_INIT = 'TICKET_DETAILS_INIT';
export const TICKET_DETAILS_SUCCESS = 'TICKET_DETAILS_SUCCESS';
export const TICKET_DETAILS_ERROR = 'TICKET_DETAILS_ERROR';
export const TICKET_DETAILS_CLEAR = 'TICKET_DETAILS_CLEAR';


export const TICKET_CUSTOMER_INIT = 'TICKET_CUSTOMER_INIT';
export const TICKET_CUSTOMER_SUCCESS = 'TICKET_CUSTOMER_SUCCESS';
export const TICKET_CUSTOMER_ERROR = 'TICKET_CUSTOMER_ERROR';

export const ClOSE_TICKET_INIT = 'ClOSE_TICKET_INIT';
export const ClOSE_TICKET_INIT_SUCCESS = 'ClOSE_TICKET_INIT_SUCCESS';
export const ClOSE_TICKET_INIT_ERROR = 'ClOSE_TICKET_INIT_ERROR';
export const ClOSE_TICKET_INIT_CLEAR = 'ClOSE_TICKET_INIT_CLEAR';
