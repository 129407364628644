import { takeLatest, put, call } from 'redux-saga/effects';
import * as constant from '../constants/Publisher';
import PublisherServices from '../services/PublisherServices';
import { API } from '../setupAxios';
import {
  EndPoints,
  ApiUrl,
  PythonApiUrl,
  MtrackitApiUrl,
} from '../helpers/Endpoints';
import { ContactsOutlined } from '@material-ui/icons';
import swal from 'sweetalert';
export function* ViewPublisherSaga(payload) {
  try {
    const url = PythonApiUrl + EndPoints.publisher_add;
    const response = yield API.post(url, payload.publisherAddData);
    // const response = yield call(PublisherServices.ViewPublisher ,payload.publisherData);
    yield put({ type: constant.PUBLISHER_ADD_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PUBLISHER_ADD_ERROR, error: '400' });
  }
}
export function* ViewGetPublisherSaga(payload) {
  try {
    let url = PythonApiUrl + EndPoints.publisher;
    const params = payload.publisherData;
    const response = yield API.get(url, { params });
    console.log(response, 'sagaresponse');
    yield put({ type: constant.PUBLISHER_GET_SUCCESS_MTRACKIT, response });
  } catch (error) {
    yield put({ type: constant.PUBLISHER_GET_ERROR_MTRACKIT, error });
  }
}
export function* ViewPublisherUpdateSaga(payload) {
  try {
    // const url = ApiUrl + EndPoints.publisher
    // console.log('url ViewPublisherSaga',url);
    // const response = yield API.put(url,payload);
    const { id, formData } = payload;
    const response = yield API.put(
      `${PythonApiUrl}${EndPoints.publisher}/${id}`,
      formData
    );
    // const response = yield call(PublisherServices.ViewPublisherUpdate, payload);
    yield put({ type: constant.PUBLISHER_UPDATE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PUBLISHER_UPDATE_ERROR, error });
  }
}
export function* ViewPublisherDeleteSaga(payload) {
  try {
    const { id } = payload;
    // const url = ApiUrl + EndPoints.publisher
    const response = yield API.delete(
      `${MtrackitApiUrl}${EndPoints.publisher}/${id}`
    );
    // let url = ApiUrl + EndPoints.publisher;
    // const response = yield API.delete(url,payload);
    // console.log('url ViewPublisherSaga',response);
    // const response = yield call(PublisherServices.ViewPublisherDelete ,payload);
    yield put({ type: constant.PUBLISHER_DELETE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PUBLISHER_DELETE_ERROR, error });
  }
}
export function* ViewWebPublisherSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.publisher;
    const response = yield API.post(url, payload.publisherData);
    // const response = yield call(PublisherServices.ViewPublisher ,payload.publisherData);
    yield put({ type: constant.PUBLISHER_ADD_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PUBLISHER_ADD_ERROR, error: '400' });
  }
}
// export function* ViewPublisherSaveSaga(payload) {
//   try {
//     const url = ApiUrl + EndPoints.save_config;
//     const response = yield API.post(url, payload.publisherConfigData);
//       if(response[0]?.data?.acknowledged ===true){
//         swal('',"Created successfully !", "success");
//       }else{
//         swal('',"Something Went wrong!", "error");
//       }
//     yield put({ type: constant.PUB_CONFIG_SAVE_SUCCESS, response });
//   } catch (error) {
//     yield put({ type: constant.PUB_CONFIG_SAVE_ERROR, error: "400" });
//   }
// }
export function* ViewWebPublisherSaveSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.save_config;
    const response = yield API.post(url, payload.publisherConfigData);
    // modifiedCount
    if (response[0]?.data?.acknowledged === true) {
      //
      if (response[0]?.data?.modifiedCount === 1) {
        swal('', 'Updated successfully !', 'success');
      } else {
        swal('', 'Created successfully !', 'success');
      }
    } else {
      swal('', 'Something Went wrong!', 'error');
    }
    yield put({ type: constant.PUB_CONFIG_SAVE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PUB_CONFIG_SAVE_ERROR, error: '400' });
  }
}
export function* ViewWebGetPublisherSaga(payload) {
  const pack = payload.packageName;
  try {
    // const response = yield call(PublisherServices.ViewPublisherGet ,payload.publisherGetData);
    const url = ApiUrl + EndPoints.publisher + '?package_name=' + pack;
    const response = yield API.get(url);
    yield put({ type: constant.PUBLISHER_GET_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PUBLISHER_GET_ERROR, error });
  }
}
export function* ViewWebPublisherUpdateSaga(payload) {
  try {
    const { id, formData } = payload;
    const response = yield API.put(
      `${ApiUrl}${EndPoints.publisher}/${id}`,
      formData
    );
    // const response = yield call(PublisherServices.ViewPublisherUpdate, payload);
    yield put({ type: constant.PUBLISHER_UPDATE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PUBLISHER_UPDATE_ERROR, error });
  }
}
export function* ViewWebPublisherDeleteSaga(payload) {
  try {
    const { id } = payload;
    // const url = ApiUrl + EndPoints.publisher
    const response = yield API.delete(`${ApiUrl}${EndPoints.publisher}/${id}`);
    // let url = ApiUrl + EndPoints.publisher;
    // const response = yield API.delete(url,payload);
    // console.log('url ViewPublisherSaga',response);
    // const response = yield call(PublisherServices.ViewPublisherDelete ,payload);
    yield put({ type: constant.PUBLISHER_DELETE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PUBLISHER_DELETE_ERROR, error });
  }
}
//event publisher config data
export function* ViewWebPublisherDashtypeSaga(payload) {
  const pack = payload.packageName;
  try {
    const url =
      ApiUrl + EndPoints.configuration_dashboard_type + '?package_name=' + pack;
    const response = yield API.get(url);
    yield put({ type: constant.PUB_CONFIG_DASHBOARD_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PUB_CONFIG_DASHBOARD_INIT_ERROR, error });
  }
}
export function* ViewWebpublisherListSaga(payload) {
  const dashtype = payload.dashtype;
  const params = {
    package_name: payload.packageName,
    fromDate: payload.fromDate,
    toDate: payload.toDate,
  };
  if (['Visit', 'Impression'].includes(dashtype)) {
    params.dashboard_type = dashtype;
  }
  const end = dash => {
    switch (dash) {
      case 'Click':
        return EndPoints.web_click_publisher;
      case 'Event':
        return EndPoints.event_nl_publisher_list;
      case 'Impression':
        return EndPoints.web_publisherid_list;
      case 'Visit':
        return EndPoints.web_publisher_name_list;
      default:
    }
  };
  try {
    const url = ApiUrl + end(dashtype);
    const response = yield API.get(url, { params });
    if (['Visit', 'Impression'].includes(dashtype))
      return yield put({
        type: constant.PUB_CONFIG_LIST_INIT_SUCCESS,
        response,
      });
    else
      return yield put({
        type: constant.PUB_CONFIG_LIST_INIT_SUCCESS,
        response: response.data,
      });
  } catch (error) {
    yield put({ type: constant.PUB_CONFIG_LIST_INIT_ERROR, error });
  }
}
export function* ViewWebpublisherFraudCategoryListSaga(payload) {
  const pack = payload.packageName;
  const dashtype = payload.dashtype;
  try {
    const url =
      ApiUrl +
      EndPoints.publisher_fraud_categories +
      '?package_name=' +
      pack +
      '&dashboard_type=' +
      dashtype;
    const response = yield API.get(url);
    yield put({
      type: constant.PUB_CONFIG_FRAUD_CATEGORIES_LIST_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.PUB_CONFIG_FRAUD_CATEGORIES_LIST_INIT_ERROR,
      error,
    });
  }
}
export function* ViewWebpublisherFraudSubCategoryListSaga(payload) {
  const pack = payload.packageName;
  const dashtype = payload.dashtype;
  // const subfraud = payload.subfraud;
  const params = {
    package_name: pack,
    dashboard_type: dashtype,
    // fraud_category: subfraud?.join(",") ?? [],
  };
  try {
    // const response = yield call(PublisherServices.ViewPublisherGet ,payload.publisherGetData);
    const url = ApiUrl + EndPoints.publisher_fraud_sub_categories;
    //    +
    //   "?package_name=" +
    //   pack +
    //   "&dashboard_type=" +
    //   dashtype +
    //   "&fraud_category=" +
    //   subfraud;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.PUB_CONFIG_SUB_FRAUD_CATEGORY_LIST_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.PUB_CONFIG_SUB_FRAUD_CATEGORY_LIST_INIT_ERROR,
      error,
    });
  }
}
export function* ViewWebPublisherConfigVariation(payload) {
  // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
  // const pack= payload.packageName
  const dashtype = payload.dashtype;
  // const dashtype= payload.dashtype
  // const subfraud= payload.subfraud
  try {
    // const response = yield call(PublisherServices.ViewPublisherGet ,payload.publisherGetData);
    const url = ApiUrl + EndPoints.varation + '/' + dashtype;
    const response = yield API.get(url);
    if (['Install', 'Web_Event'].includes(dashtype))
      yield put({
        type: constant.PUB_CONFIG_VARIATION_LIST_INIT_SUCCESS,
        response,
      });
    else
      yield put({
        type: constant.PUB_CONFIG_VARIATION_LIST_EVENT_INIT_SUCCESS,
        response,
      });
  } catch (error) {
    yield put({ type: constant.PUB_CONFIG_VARIATION_LIST_INIT_ERROR, error });
  }
}
// pub config tabel
export function* ViewWebpublisherConfigTabelSaga(payload) {
  // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
  const pack = payload.pack;
  try {
    // const response = yield call(PublisherServices.ViewPublisherGet ,payload.publisherGetData);
    const url = ApiUrl + EndPoints.config_tabel + '?package_name=' + pack;
    const response = yield API.get(url);
    // console.log("sdasdad", response);
    yield put({
      type: constant.CONFIG_REPORT_LIST_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.CONFIG_REPORT_LIST_INIT_ERROR,
      error,
    });
  }
}
export function* ViewWebPublisherConfigUpdateSaga(payload) {
  try {
    const { id, formData } = payload;
    const response = yield API.put(
      `${ApiUrl}${EndPoints.pubconfig_edit}/${id}`,
      formData
    );
    // const response = yield call(PublisherServices.ViewPublisherUpdate, payload);
    yield put({ type: constant.CONFIG_EDIT_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CONFIG_EDIT_LIST_INIT_ERROR, error });
  }
}
export function* ViewWebPublisherConfigDeleteSaga(payload) {
  const pack = payload.packageName;
  const dashtype = payload.dashtype;
  const id = payload.id;
  const pub = payload.pub;
  const params = {
    package_name: pack,
    publisher: pub,
    dashboard_type: dashtype,
    configuration_name: id,
    // ?.join(",") ?? [],
  };
  try {
    const { id } = payload;
    // const url = ApiUrl + EndPoints.publisher
    const url = ApiUrl + EndPoints.pubDelete;
    const response = yield API.delete(url, { params });
    if (response?.data?.acknowledged === true) {
      swal('', 'Deleted  successfully !', 'success');
    } else {
      swal('', 'Something Went wrong!', 'error');
    }
    yield put({ type: constant.PUBLISHER_CONFIG_DELETE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PUBLISHER_CONFIG_DELETE_ERROR, error });
  }
}
export default function* PublisherSaga() {
  yield takeLatest(constant.PUBLISHER_ADD, ViewPublisherSaga);
  yield takeLatest(constant.PUBLISHER_GET_MTRACKIT, ViewGetPublisherSaga);
  yield takeLatest(constant.PUBLISHER_UPDATE, ViewPublisherUpdateSaga);
  yield takeLatest(constant.PUBLISHER_DELETE, ViewPublisherDeleteSaga);
  yield takeLatest(constant.PUBLISHER_ADD, ViewWebPublisherSaga);
  yield takeLatest(
    constant.PUBLISHER_CONFIG_DELETE,
    ViewWebPublisherConfigDeleteSaga
  );
  yield takeLatest(constant.PUB_CONFIG_SAVE_INIT, ViewWebPublisherSaveSaga);
  yield takeLatest(constant.PUBLISHER_GET, ViewWebGetPublisherSaga);
  yield takeLatest(constant.PUBLISHER_UPDATE, ViewWebPublisherUpdateSaga);
  yield takeLatest(constant.CONFIG_EDIT_LIST_INIT, ViewWebPublisherUpdateSaga);
  yield takeLatest(constant.PUBLISHER_DELETE, ViewWebPublisherDeleteSaga);
  yield takeLatest(
    constant.CONFIG_REPORT_LIST_INIT,
    ViewWebpublisherConfigTabelSaga
  );
  yield takeLatest(
    constant.PUB_CONFIG_DASHBOARD_INIT,
    ViewWebPublisherDashtypeSaga
  );
  yield takeLatest(constant.PUB_CONFIG_LIST_INIT, ViewWebpublisherListSaga);
  yield takeLatest(
    constant.PUB_CONFIG_FRAUD_CATEGORIES_LIST_INIT,
    ViewWebpublisherFraudCategoryListSaga
  );
  yield takeLatest(
    constant.PUB_CONFIG_SUB_FRAUD_CATEGORY_LIST_INIT,
    ViewWebpublisherFraudSubCategoryListSaga
  );
  yield takeLatest(
    constant.PUB_CONFIG_VARIATION_LIST_INIT,
    ViewWebPublisherConfigVariation
  );
}