import { takeLatest, put, call } from 'redux-saga/effects';
import * as constant from '../constants/EventConstant';
// import DashboardServices from "../services/DashboardServices";

import { API, API_DOWNLOAD_CALL } from '../setupAxios';
import {
  EndPoints,
  ApiUrl,
  AppInstall,
  AppFilterDomain,
} from '../helpers/Endpoints';

export function* ViewEventCount(payload) {
  try {
    // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
    const params = { ...payload.dashboarddata };
    const url = ApiUrl + EndPoints.events_count;
    const response = yield API.get(url, { params });

    yield put({ type: constant.EVENT_COUNT_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.EVENT_COUNT_LIST_INIT_ERROR, error });
  }
}

export function* ViewEventFraudCategory(payload) {
  try {
    // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
    const params = { ...payload.dashboarddata };
    const url = ApiUrl + EndPoints.event_fraud_category;
    const response = yield API.get(url, { params });

    yield put({ type: constant.EVENT_FRAUD_CATEGORY_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.EVENT_FRAUD_CATEGORY_INIT_ERROR, error });
  }
}

export function* ViewEventCleanPublisherName(payload) {
  try {
    // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
    const params = { ...payload.dashboarddata };
    const url = ApiUrl + EndPoints.event_clean_publisher_name;
    const response = yield API.get(url, { params });

    yield put({
      type: constant.EVENT_CLEAN_PUBLISHER_NAME_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.EVENT_CLEAN_PUBLISHER_NAME_INIT_ERROR, error });
  }
}

export function* ViewEventFraudPublisherName(payload) {
  try {
    // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
    const params = { ...payload.dashboarddata };
    const url = ApiUrl + EndPoints.event_fraud_publisher_name;
    const response = yield API.get(url, { params });

    yield put({
      type: constant.EVENT_FRAUD_PUBLISHER_NAME_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.EVENT_FRAUD_PUBLISHER_NAME_INIT_ERROR, error });
  }
}

export function* ViewFraudEventByMonth(payload) {
  try {
    // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
    const params = { ...payload.dashboarddata };
    const url = ApiUrl + EndPoints.event_fraud_month;
    const response = yield API.get(url, { params });

    yield put({ type: constant.FRAUD_EVENT_BY_MONTH_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FRAUD_EVENT_BY_MONTH_INIT_ERROR, error });
  }
}

export function* ViewFraudEventByDate(payload) {
  try {
    // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
    const params = { ...payload.dashboarddata };
    const url = ApiUrl + EndPoints.event_fraud_date;
    const response = yield API.get(url, { params });

    yield put({ type: constant.FRAUD_EVENT_BY_DATE_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FRAUD_EVENT_BY_DATE_INIT_ERROR, error });
  }
}

export function* ViewFraudEventPublisher(payload) {
  try {
    // const response = yield call(DashboardServices.ViewTotalincidents, payload.dashboarddata);
    const params = { ...payload.dashboarddata };
    const url = ApiUrl + EndPoints.event_fraud_publisher;
    const response = yield API.get(url, { params });

    yield put({ type: constant.FRAUD_PUBLISHER_WISE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FRAUD_PUBLISHER_WISE_ERROR, error });
  }
}

export default function* EventSaga() {
  yield takeLatest(constant.EVENT_COUNT_LIST_INIT, ViewEventCount);
  yield takeLatest(constant.EVENT_FRAUD_CATEGORY_INIT, ViewEventFraudCategory);
  yield takeLatest(
    constant.EVENT_CLEAN_PUBLISHER_NAME_INIT,
    ViewEventCleanPublisherName
  );
  yield takeLatest(
    constant.EVENT_FRAUD_PUBLISHER_NAME_INIT,
    ViewEventFraudPublisherName
  );
  yield takeLatest(constant.FRAUD_EVENT_BY_MONTH_INIT, ViewFraudEventByMonth);
  yield takeLatest(constant.FRAUD_EVENT_BY_DATE_INIT, ViewFraudEventByDate);
  yield takeLatest(constant.FRAUD_PUBLISHER_WISE, ViewFraudEventPublisher);
}
