import { actions } from "../../app/modules/Auth";
import * as constant from "../constants/Reconcilation";

const initialState = {
  reconcilationPaymentmodal_data: "",
  reconcilationPaymentmodal_loading: true,
  reconcilationPaymentmodal_error: null,
  total_count: 0,

  reconcilationPaymentmodalPost_data: "",
  reconcilationPaymentmodalPost_loading: true,
  reconcilationPaymentmodalPost_error: null,

  reconcilation_payment_delete_data: "",
  reconcilation_payment_delete_loading: true,
  reconcilation_payment_delete_error: null,

  reconcilation_payment_update_data: "",
  reconcilation_payment_update_loading: true,
  reconcilation_payment_update_error: null,

  reconcilationPublisherPaymentmodal_data: "",
  reconcilationPublisherPaymentmodal_loading: true,
  reconcilationPublisherPaymentmodal_error: null,

  reconcilationPublisherPaymentmodalPost_data: "",
  reconcilationPublisherPaymentmodalPost_loading: true,
  reconcilationPublisherPaymentmodalPost_error: null,

  reconcilation_Publisherpayment_delete_data: "",
  reconcilation_Publisherpayment_delete_loading: true,
  reconcilation_Publisherpayment_delete_error: null,

  reconcilation_Publisherpayment_update_data: "",
  reconcilation_Publisherpayment_update_loading: true,
  reconcilation_Publisherpayment_update_error: null,

  reconcilationSettlementSettingmodal_data: "",
  reconcilationSettlementSettingmodal_loading: true,
  reconcilationSettlementSettingmodal_error: null,

  reconcilationSettlementSettingmodalPost_data: "",
  reconcilationSettlementSettingmodalPost_loading: true,
  reconcilationSettlementSettingmodalPost_error: null,

  reconcilation_SettlementSetting_delete_data: "",
  reconcilation_SettlementSetting_delete_loading: true,
  reconcilation_SettlementSetting_delete_error: null,

  reconcilation_SettlementSetting_update_data: "",
  reconcilation_SettlementSetting_update_loading: true,
  reconcilation_SettlementSetting_update_error: null,

  reconcilation_currency_data: "",
  reconcilation_currency_loading: true,
  reconcilation_currency_error: null,

  reconcilation_payment_data: "",
  reconcilation_payment_loading: true,
  reconcilation_payment_error: null,

  reconcilation_order_Status_data: "",
  reconcilation_order_Status_loading: true,
  reconcilation_order_Status_error: null,

  alert_page: "",
  alert_page_loading: true,
  alert_page_error: null,

  alert_page_post: "",
  alert_page_post_loading: true,
  alert_page_post_error: null,

  alert_page_update_data: "",
  alert_page_update_loading: true,
  alert_page_update_error: null,

  report_page: "",
  report_page_loading: true,
  report_page_error: null,

  invoice_data: "",
  invoice_data_loading: true,
  invoice_data_error: null,
};

const ReconcilationReducer = (
  state = initialState,
  { type, response, error }
) => {
  switch (type) {
    //event config
    // case constant.REPORT_PAGE_INVOICE_ERROR:
    case constant.RECONCILATION_PAYMENT_MODAL_ERROR:
    case constant.RECONCILATION_PAYMENT_MODAL_POST:
    case constant.RECONCILATION_PAYMENT_MODAL_POST_ERROR:
    case constant.DELETE_RECONCILATION_INIT:
    case constant.DELETE_RECONCILATION_INIT_ERROR:
    case constant.UPDATE_RECONCILATION_INIT:
    case constant.UPDATE_RECONCILATION_INIT_ERROR:
    case constant.RECONCILATION_PUBLISHER_PAYMENT_MODAL:
    case constant.RECONCILATION_PUBLISHER_PAYMENT_MODAL_ERROR:
    case constant.RECONCILATION_PUBLISHER_PAYMENT_MODAL_POST:
    case constant.RECONCILATION_PUBLISHER_PAYMENT_MODAL_POST_ERROR:
    case constant.DELETE_PUBLISHER_RECONCILATION_INIT:
    case constant.DELETE_PUBLISHER_RECONCILATION_INIT_ERROR:
    case constant.RECONCILATION_SETTLEMENT_SETTING_MODAL:
    case constant.RECONCILATION_SETTLEMENT_SETTING_MODAL_ERROR:
    case constant.RECONCILATION_SETTLEMENT_SETTING_MODAL_POST:
    case constant.RECONCILATION_SETTLEMENT_SETTING_MODAL_POST_ERROR:
    case constant.DELETE_SETTLEMENT_SETTING_INIT:
    case constant.DELETE_SETTLEMENT_SETTING_INIT_ERROR:
    case constant.RECONCILATION_CURRENCY_MODAL:
    case constant.RECONCILATION_CURRENCY_INIT_ERROR:
    case constant.RECONCILATION_PAYMENT_MODAL_ID:
    case constant.RECONCILATION_PAYMENT_ID_INIT_ERROR:
    case constant.UPDATE_PUBLISHER_RECONCILATION_INIT:
    case constant.UPDATE_PUBLISHER_RECONCILATION_INIT_ERROR:
    case constant.UPDATE_SETTLEMENT_SETTING_INIT:
    case constant.UPDATE_SETTLEMENT_SETTING_INIT_ERROR:
    case constant.RECONCILATION_ORDER_STATUS_MODAL:
    case constant.RECONCILATION_ORDER_STATUS_INIT_ERROR:
    case constant.ALERT_PAGE:
    case constant.ALERT_PAGE_ERROR:
    case constant.ALERT_PAGE_POST:
    case constant.ALERT_PAGE_POST_ERROR:
    case constant.UPDATE_ALERT_PAGE_INIT:
    case constant.UPDATE_ALERT_PAGE_INIT_ERROR:
    case constant.REPORT_PAGE_ERROR:

    //invoice

    case constant.REPORT_PAGE_INVOICE_INIT:
      return {
        ...state,
        invoice_data_loading: true,
      };

    case constant.REPORT_PAGE_INVOICE_SUCCESS:
      return {
        ...state,
        invoice_data_loading: false,
        invoice_data: response,
      };

    case constant.RECONCILATION_PAYMENT_MODAL:
      return {
        ...state,
        reconcilationPaymentmodal_loading: true,
      };

    case constant.RECONCILATION_PAYMENT_MODAL_SUCCESS:
      return {
        ...state,
        reconcilationPaymentmodal_loading: false,
        reconcilationPaymentmodal_data: response.data,
        total_count: response.Total_pages,
      };

    case constant.RECONCILATION_PAYMENT_MODAL_ERROR:
      return {
        ...state,
        reconcilationPaymentmodal_loading: false,
        reconcilationPaymentmodal_error: error,
      };

    case constant.RECONCILATION_PAYMENT_MODAL_POST:
      return {
        ...state,
        reconcilationPaymentmodalPost_loading: true,
      };

    case constant.RECONCILATION_PAYMENT_MODAL_POST_SUCCESS:
      return {
        ...state,
        reconcilationPaymentmodalPost_loading: false,
        reconcilationPaymentmodalPost_data: response.data,
      };

    case constant.RECONCILATION_PAYMENT_MODAL_POST_ERROR:
      return {
        ...state,
        reconcilationPaymentmodalPost_loading: false,
        reconcilationPaymentmodalPost_error: error,
      };

    case constant.DELETE_RECONCILATION_INIT:
      return {
        ...state,
        reconcilation_payment_delete_loading: true,
      };
    case constant.DELETE_RECONCILATION_INIT_SUCCESS:
      return {
        ...state,
        reconcilation_payment_delete_loading: false,
        reconcilation_payment_delete_data: response,
      };
    case constant.DELETE_RECONCILATION_INIT_ERROR:
      return {
        ...state,
        reconcilation_payment_delete_loading: false,
        reconcilation_payment_delete_error: error,
      };

    case constant.REPORT_PAGE_INVOICE_ERROR:
      return {
        ...state,
        invoice_data_loading: false,
        invoice_data_error: error,
      };

    case constant.UPDATE_RECONCILATION_INIT:
      return {
        ...state,
        reconcilation_payment_update_loading: true,
      };
    case constant.UPDATE_RECONCILATION_INIT_SUCCESS:
      return {
        ...state,
        reconcilation_payment_update_loading: false,
        reconcilation_payment_update_data: response,
      };
    case constant.UPDATE_RECONCILATION_INIT_ERROR:
      return {
        ...state,
        reconcilation_payment_update_loading: false,
        reconcilation_payment_update_error: error,
      };

    case constant.UPDATE_RECONCILATION_INIT_CLEAR:
      return {
        ...state,
        reconcilation_payment_update_loading: false,
        reconcilation_payment_update_error: null,
        reconcilation_payment_update_data: null,
      };

    //Publisher Payment

    case constant.RECONCILATION_PUBLISHER_PAYMENT_MODAL:
      return {
        ...state,
        reconcilationPublisherPaymentmodal_loading: true,
      };

    case constant.RECONCILATION_PUBLISHER_PAYMENT_MODAL_SUCCESS:
      return {
        ...state,
        reconcilationPublisherPaymentmodal_loading: false,
        reconcilationPublisherPaymentmodal_data: response.data,
        total_count: response.Total_pages,
      };

    case constant.RECONCILATION_PUBLISHER_PAYMENT_MODAL_ERROR:
      return {
        ...state,
        reconcilationPublisherPaymentmodal_loading: false,
        reconcilationPublisherPaymentmodal_error: error,
      };

    case constant.RECONCILATION_PUBLISHER_PAYMENT_MODAL_POST:
      return {
        ...state,
        reconcilationPublisherPaymentmodalPost_loading: true,
      };

    case constant.RECONCILATION_PUBLISHER_PAYMENT_MODAL_POST_SUCCESS:
      return {
        ...state,
        reconcilationPublisherPaymentmodalPost_loading: false,
        reconcilationPublisherPaymentmodalPost_data: response.data,
      };

    case constant.RECONCILATION_PUBLISHER_PAYMENT_MODAL_POST_ERROR:
      return {
        ...state,
        reconcilationPublisherPaymentmodalPost_loading: false,
        reconcilationPublisherPaymentmodalPost_error: error,
      };

    case constant.DELETE_PUBLISHER_RECONCILATION_INIT:
      return {
        ...state,
        reconcilation_Publisherpayment_delete_loading: true,
      };
    case constant.DELETE_PUBLISHER_RECONCILATION_INIT_SUCCESS:
      return {
        ...state,
        reconcilation_Publisherpayment_delete_loading: false,
        reconcilation_Publisherpayment_delete_data: response,
      };
    case constant.DELETE_PUBLISHER_RECONCILATION_INIT_ERROR:
      return {
        ...state,
        reconcilation_Publisherpayment_delete_loading: false,
        reconcilation_Publisherpayment_delete_error: error,
      };

    case constant.UPDATE_PUBLISHER_RECONCILATION_INIT:
      return {
        ...state,
        reconcilation_Publisherpayment_update_loading: true,
      };
    case constant.UPDATE_PUBLISHER_RECONCILATION_INIT_SUCCESS:
      return {
        ...state,
        reconcilation_Publisherpayment_update_loading: false,
        reconcilation_Publisherpayment_update_data: response,
      };
    case constant.UPDATE_PUBLISHER_RECONCILATION_INIT_ERROR:
      return {
        ...state,
        reconcilation_Publisherpayment_update_loading: false,
        reconcilation_Publisherpayment_update_error: error,
      };

    case constant.UPDATE_PUBLISHER_RECONCILATION_INIT_CLEAR:
      return {
        ...state,
        reconcilation_Publisherpayment_update_loading: false,
        reconcilation_Publisherpayment_update_error: null,
        reconcilation_Publisherpayment_update_data: null,
      };

    //settlement setting

    case constant.RECONCILATION_SETTLEMENT_SETTING_MODAL:
      return {
        ...state,
        reconcilationSettlementSettingmodal_loading: true,
      };

    case constant.RECONCILATION_SETTLEMENT_SETTING_MODAL_SUCCESS:
      return {
        ...state,
        reconcilationSettlementSettingmodal_loading: false,
        reconcilationSettlementSettingmodal_data: response.data,
        total_count: response.Total_pages,
      };

    case constant.RECONCILATION_SETTLEMENT_SETTING_MODAL_ERROR:
      return {
        ...state,
        reconcilationSettlementSettingmodal_loading: false,
        reconcilationSettlementSettingmodal_error: error,
      };

    case constant.RECONCILATION_SETTLEMENT_SETTING_MODAL_POST:
      return {
        ...state,
        reconcilationSettlementSettingmodalPost_loading: true,
      };

    case constant.RECONCILATION_SETTLEMENT_SETTING_MODAL_POST_SUCCESS:
      return {
        ...state,
        reconcilationSettlementSettingmodalPost_loading: false,
        reconcilationSettlementSettingmodalPost_data: response.data,
      };

    case constant.RECONCILATION_SETTLEMENT_SETTING_MODAL_POST_ERROR:
      return {
        ...state,
        reconcilationSettlementSettingmodalPost_loading: false,
        reconcilationSettlementSettingmodalPost_error: error,
      };

    case constant.DELETE_SETTLEMENT_SETTING_INIT:
      return {
        ...state,
        reconcilation_SettlementSetting_delete_loading: true,
      };
    case constant.DELETE_SETTLEMENT_SETTING_INIT_SUCCESS:
      return {
        ...state,
        reconcilation_SettlementSetting_delete_loading: false,
        reconcilation_SettlementSetting_delete_data: response,
      };
    case constant.DELETE_SETTLEMENT_SETTING_INIT_ERROR:
      return {
        ...state,
        reconcilation_SettlementSetting_delete_loading: false,
        reconcilation_SettlementSetting_delete_error: error,
      };

    case constant.UPDATE_SETTLEMENT_SETTING_INIT:
      return {
        ...state,
        reconcilation_SettlementSetting_update_loading: true,
      };
    case constant.UPDATE_SETTLEMENT_SETTING_INIT_SUCCESS:
      return {
        ...state,
        reconcilation_SettlementSetting_update_loading: false,
        reconcilation_SettlementSetting_update_data: response,
      };
    case constant.UPDATE_SETTLEMENT_SETTING_INIT_ERROR:
      return {
        ...state,
        reconcilation_SettlementSetting_update_loading: false,
        reconcilation_SettlementSetting_update_error: error,
      };

    case constant.UPDATE_SETTLEMENT_SETTING_INIT_CLEAR:
      return {
        ...state,
        reconcilation_SettlementSetting_update_loading: false,
        reconcilation_SettlementSetting_update_error: null,
        reconcilation_SettlementSetting_update_data: null,
      };

    case constant.RECONCILATION_CURRENCY_MODAL:
      return {
        ...state,
        reconcilation_currency_loading: true,
      };

    case constant.RECONCILATION_CURRENCY_INIT_SUCCESS:
      return {
        ...state,
        reconcilation_currency_loading: false,
        reconcilation_currency_data: response.data,
        total_count: response.Total_pages,
      };

    case constant.RECONCILATION_CURRENCY_INIT_ERROR:
      return {
        ...state,
        reconcilation_currency_loading: false,
        reconcilation_currency_error: error,
      };

    case constant.RECONCILATION_PAYMENT_MODAL_ID:
      return {
        ...state,
        reconcilation_payment_loading: true,
      };

    case constant.RECONCILATION_PAYMENT_ID_INIT_SUCCESS:
      return {
        ...state,
        reconcilation_payment_loading: false,
        reconcilation_payment_data: response.data,
      };

    case constant.RECONCILATION_PAYMENT_ID_INIT_ERROR:
      return {
        ...state,
        reconcilation_payment_loading: false,
        reconcilation_payment_error: error,
      };

    case constant.RECONCILATION_ORDER_STATUS_MODAL:
      return {
        ...state,
        reconcilation_order_Status_loading: true,
      };

    case constant.RECONCILATION_ORDER_STATUS_INIT_SUCCESS:
      return {
        ...state,
        reconcilation_order_Status_loading: false,
        reconcilation_order_Status_data: response.data,
      };

    case constant.RECONCILATION_ORDER_STATUS_INIT_ERROR:
      return {
        ...state,
        reconcilation_order_Status_loading: false,
        reconcilation_order_Status_error: error,
      };

    case constant.ALERT_PAGE:
      return {
        ...state,
        alert_page_loading: true,
      };

    case constant.ALERT_PAGE_SUCCESS:
      return {
        ...state,
        alert_page_loading: false,
        alert_page: response.data,
      };

    case constant.ALERT_PAGE_ERROR:
      return {
        ...state,
        alert_page_loading: false,
        alert_page_error: error,
      };

    case constant.ALERT_PAGE_POST:
      return {
        ...state,
        alert_page_post_loading: true,
      };

    case constant.ALERT_PAGE_POST_SUCCESS:
      return {
        ...state,
        alert_page_post_loading: false,
        alert_page_post: response.data,
      };

    case constant.ALERT_PAGE_POST_ERROR:
      return {
        ...state,
        alert_page_post_loading: false,
        alert_page_post_error: error,
      };

    case constant.UPDATE_ALERT_PAGE_INIT:
      return {
        ...state,
        alert_page_update_loading: true,
      };
    case constant.UPDATE_ALERT_PAGE_INIT_SUCCESS:
      return {
        ...state,
        alert_page_update_loading: false,
        alert_page_update_data: response,
      };
    case constant.UPDATE_ALERT_PAGE_INIT_ERROR:
      return {
        ...state,
        alert_page_update_loading: false,
        alert_page_update_error: error,
      };

    case constant.REPORT_PAGE_INIT:
      return {
        ...state,
        report_page_loading: true,
      };
    case constant.CLEAR_REPORT_PAGE:
      return {
        ...state,
        report_page: [],
        report_page_loading: false,
      };

    case constant.REPORT_PAGE_SUCCESS:
      return {
        ...state,
        report_page_loading: false,
        report_page: response.data,
        total_count: response.Total_pages,
      };

    case constant.REPORT_PAGE_ERROR:
      return {
        ...state,
        report_page_loading: false,
        report_page_error: error,
      };

    default:
      return state;
  }
};

export default ReconcilationReducer;
