import { combineReducers } from 'redux';
import Reducer from '../../Utils/Reducer';
import CONST from './const';

export default function ClickIntegrityReducer(state = {}, action) {
  return Reducer(CONST.DASHBOARD, state, action);
}
// export default combineReducers({
//   Dashboard: ClickIntegrityReducer,
// });
