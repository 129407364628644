import { takeLatest, put, call } from "redux-saga/effects";
import * as constant from "../constants/Reconcilation";
// import PublisherServices from '../services/PublisherServices';
import { API, API_DOWNLOAD_CALL } from "../setupAxios";
import toast, { Toaster } from "react-hot-toast";

import {
  EndPoints,
  ApiUrl,
  AppFilterDomain,
  PythonApiUrl,
  MtrackitApiUrl,
} from "../helpers/Endpoints";
import { ContactsOutlined } from "@material-ui/icons";
import swal from "sweetalert";

export function* ViewGetReconcilationPaymentModalSaga(payload) {
  try {
    let url = ApiUrl + EndPoints.payment_model_table;
    const params = payload.dashboarddata;
    const response = yield API.get(url, { params });
    yield put({ type: constant.RECONCILATION_PAYMENT_MODAL_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.RECONCILATION_PAYMENT_MODAL_ERROR, error });
  }
}

export function* ViewGetReconcilationPaymentPostModalSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.payment_model_table;
    const response = yield API.post(url, payload.dashboarddata);

    if (response?.status === true) {
      swal("", "Data Saved  successfully !", "success");
    } else {
      swal("", "Something Went wrong!", "error");
    }

    // const response = yield call(PublisherServices.ViewPublisher ,payload.publisherData);
    yield put({
      type: constant.RECONCILATION_PAYMENT_MODAL_POST_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.RECONCILATION_PAYMENT_MODAL_POST_ERROR,
      error: "400",
    });
  }
}

export function* DeleteUserSaga(payload) {
  const id = payload.id;
  const params = {
    id: id,
  };
  try {
    let url = ApiUrl + EndPoints.payment_model_table + "/" + id;
    // const response = yield API.delete(url , {id});
    const response = yield API.delete(url, { id });
    if (response?.data?.acknowledged === true) {
      swal("", "Deleted  successfully !", "success");
    } else {
      swal("", "Something Went wrong!", "error");
    }
    yield put({
      type: constant.DELETE_RECONCILATION_INIT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({ type: constant.DELETE_RECONCILATION_INIT_ERROR, error: "400" });
  }
}

export function* ViewReconcilationUpdateSaga(payload) {
  try {
    // const url = ApiUrl + EndPoints.publisher
    // const response = yield API.put(url,payload);
    const id = payload.id;
    let url = ApiUrl + EndPoints.payment_model_table + "/" + id;
    const response = yield API.put(url, payload.body);

    if (response?.data?.lastErrorObject.updatedExisting === true) {
      swal("", "Updated  successfully !", "success");
    } else {
      swal("", "Something Went wrong!", "error");
    }

    // const response = yield call(PublisherServices.ViewPublisherUpdate, payload);
    yield put({ type: constant.UPDATE_RECONCILATION_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.UPDATE_RECONCILATION_INIT_ERROR, error: "400" });
  }
}

export function* ViewGetReconcilationPublisherPaymentModalSaga(payload) {
  try {
    let url = ApiUrl + EndPoints.publisher_payment_model_table;
    const params = payload.dashboarddata;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.RECONCILATION_PUBLISHER_PAYMENT_MODAL_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.RECONCILATION_PUBLISHER_PAYMENT_MODAL_ERROR,
      error,
    });
  }
}

export function* ViewGetReconcilationPublisherPaymentPostModalSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.publisher_payment_model_table;
    const response = yield API.post(url, payload.dashboarddata);
    if (response?.status === true) {
      swal("", "Data Saved  successfully !", "success");
    } else {
      swal("", "Something Went wrong!", "error");
    }
    // const response = yield call(PublisherServices.ViewPublisher ,payload.publisherData);
    yield put({
      type: constant.RECONCILATION_PUBLISHER_PAYMENT_MODAL_POST_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.RECONCILATION_PUBLISHER_PAYMENT_MODAL_POST_ERROR,
      error: "400",
    });
  }
}

export function* DeletePaymentModalUserSaga(payload) {
  const id = payload.id;
  const params = {
    id: id,
  };
  try {
    let url = ApiUrl + EndPoints.publisher_payment_model_table + "/" + id;
    // const response = yield API.delete(url , {id});
    const response = yield API.delete(url, { id });
    if (response?.data?.acknowledged === true) {
      swal("", "Deleted  successfully !", "success");
    } else {
      swal("", "Something Went wrong!", "error");
    }
    yield put({
      type: constant.DELETE_PUBLISHER_RECONCILATION_INIT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: constant.DELETE_PUBLISHER_RECONCILATION_INIT_ERROR,
      error: "400",
    });
  }
}

export function* ViewReconcilationPublisherPaymentUpdateSaga(payload) {
  try {
    // const url = ApiUrl + EndPoints.publisher
    // const response = yield API.put(url,payload);
    const id = payload.id;
    let url = ApiUrl + EndPoints.publisher_payment_model_table + "/" + id;
    const response = yield API.put(url, payload.body);
    if (response?.data?.lastErrorObject.updatedExisting === true) {
      swal("", "Updated  successfully !", "success");
    } else {
      swal("", "Something Went wrong!", "error");
    }
    // const response = yield call(PublisherServices.ViewPublisherUpdate, payload);
    yield put({
      type: constant.UPDATE_PUBLISHER_RECONCILATION_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.UPDATE_PUBLISHER_RECONCILATION_INIT_ERROR,
      error: "400",
    });
  }
}

export function* ViewGetReconcilationSettlementSettingModalSaga(payload) {
  try {
    let url = ApiUrl + EndPoints.settlement_setting;
    const params = payload.dashboarddata;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.RECONCILATION_SETTLEMENT_SETTING_MODAL_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.RECONCILATION_SETTLEMENT_SETTING_MODAL_ERROR,
      error,
    });
  }
}

export function* ViewGetReconcilationSettlementSettingModalPostSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.settlement_setting;
    const response = yield API.post(url, payload.dashboarddata);
    // const response = yield call(PublisherServices.ViewPublisher ,payload.publisherData);
    if (response?.status === true) {
      swal("", "Data Saved  successfully !", "success");
    } else {
      swal("", "Something Went wrong!", "error");
    }
    yield put({
      type: constant.RECONCILATION_SETTLEMENT_SETTING_MODAL_POST_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.RECONCILATION_SETTLEMENT_SETTING_MODAL_POST_ERROR,
      error: "400",
    });
  }
}

export function* DeleteSettlementSettingUserSaga(payload) {
  const id = payload.id;
  const params = {
    id: id,
  };
  try {
    let url = ApiUrl + EndPoints.settlement_setting + "/" + id;
    // const response = yield API.delete(url , {id});
    const response = yield API.delete(url, { id });
    if (response?.data?.acknowledged === true) {
      swal("", "Deleted  successfully !", "success");
    } else {
      swal("", "Something Went wrong!", "error");
    }
    yield put({
      type: constant.DELETE_SETTLEMENT_SETTING_INIT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: constant.DELETE_SETTLEMENT_SETTING_INIT_ERROR,
      error: "400",
    });
  }
}

export function* ViewReconcilationSettlementSettingUpdateSaga(payload) {
  try {
    const id = payload.id;
    let url = ApiUrl + EndPoints.settlement_setting + "/" + id;
    console.log(payload);
    const response = yield API.put(url, payload.body);
    if (response?.data?.lastErrorObject.updatedExisting === true) {
      swal("", "Updated  successfully !", "success");
    } else {
      swal("", "Something Went wrong!", "error");
    }
    // const response = yield call(PublisherServices.ViewPublisherUpdate, payload);
    yield put({
      type: constant.UPDATE_SETTLEMENT_SETTING_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.UPDATE_SETTLEMENT_SETTING_INIT_ERROR,
      error: "400",
    });
  }
}

export function* ViewGetReconcilationCurrencyModalSaga(payload) {
  try {
    let url = ApiUrl + EndPoints.currency;
    const params = payload.dashboarddata;
    const response = yield API.get(url, { params });
    yield put({ type: constant.RECONCILATION_CURRENCY_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.RECONCILATION_CURRENCY_INIT_ERROR, error });
  }
}

export function* ViewGetReconcilationEventModalSaga(payload) {
  try {
    let url = AppFilterDomain + EndPoints.order_status;
    const params = payload.dashboarddata;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.RECONCILATION_ORDER_STATUS_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.RECONCILATION_ORDER_STATUS_INIT_ERROR, error });
  }
}

export function* ViewGetReconcilationPaymentIdModalSaga(payload) {
  try {
    let url = ApiUrl + EndPoints.payment_model;
    const params = { package_name: payload.dashboarddata };
    const response = yield API.get(url, { params });
    yield put({
      type: constant.RECONCILATION_PAYMENT_ID_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    console.warn({ error });
    yield put({ type: constant.RECONCILATION_PAYMENT_ID_INIT_ERROR, error });
  }
}

export function* ViewGetAlertPageModalSaga(payload) {
  try {
    let url = ApiUrl + EndPoints.alert_page;
    const params = payload.dashboarddata;
    const response = yield API.get(url, { params });
    yield put({ type: constant.ALERT_PAGE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.ALERT_PAGE_ERROR, error });
  }
}

export function* ViewGetAlertPagePostModalSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.alert_page;
    const response = yield API.post(url, payload.dashboarddata);
    if (response?.status === true) {
      swal("", "Data Saved  successfully !", "success");
    } else {
      swal("", "Something Went wrong!", "error");
    }

    // const response = yield call(PublisherServices.ViewPublisher ,payload.publisherData);
    yield put({
      type: constant.ALERT_PAGE_POST_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.ALERT_PAGE_POST_ERROR,
      error: "400",
    });
  }
}

export function* ViewAlertUpdateSaga(payload) {
  try {
    // const url = ApiUrl + EndPoints.publisher
    // const response = yield API.put(url,payload);
    const id = payload.id;
    let url = ApiUrl + EndPoints.alert_page;
    const response = yield API.put(url, payload.body);

    if (response?.data?.lastErrorObject.updatedExisting === true) {
      swal("", "Updated  successfully !", "success");
    } else {
      swal("", "Something Went wrong!", "error");
    }

    // const response = yield call(PublisherServices.ViewPublisherUpdate, payload);
    yield put({ type: constant.UPDATE_ALERT_PAGE_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.UPDATE_ALERT_PAGE_INIT_ERROR, error: "400" });
  }
}

export function* ViewGetReportPageModalSaga(payload) {
  try {
    let url = ApiUrl + EndPoints.payout_report;
    const params = payload.dashboarddata;
    const response = yield API.get(url, { params });
    yield put({ type: constant.REPORT_PAGE_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.REPORT_PAGE_ERROR, error });
  }
}

export function* ViewInvoiceGenration(payload) {
  try {
    let url = ApiUrl + EndPoints.invoice_genration;
    const params = payload.dashboarddata;
    const response = yield API_DOWNLOAD_CALL.get(url, { params });
    yield put({ type: constant.REPORT_PAGE_INVOICE_SUCCESS, response });
  } catch (error) {
    if (error?.response) {
      swal(error?.response?.data?.message);
    }
    yield put({ type: constant.REPORT_PAGE_INVOICE_ERROR, error });
  }
}

export default function* ReconcilationSaga() {
  yield takeLatest(
    constant.RECONCILATION_PAYMENT_MODAL,
    ViewGetReconcilationPaymentModalSaga
  );
  yield takeLatest(
    constant.RECONCILATION_PAYMENT_MODAL_POST,
    ViewGetReconcilationPaymentPostModalSaga
  );
  yield takeLatest(constant.DELETE_RECONCILATION_INIT, DeleteUserSaga);
  yield takeLatest(
    constant.UPDATE_RECONCILATION_INIT,
    ViewReconcilationUpdateSaga
  );
  yield takeLatest(
    constant.RECONCILATION_PUBLISHER_PAYMENT_MODAL,
    ViewGetReconcilationPublisherPaymentModalSaga
  );
  yield takeLatest(
    constant.RECONCILATION_PUBLISHER_PAYMENT_MODAL_POST,
    ViewGetReconcilationPublisherPaymentPostModalSaga
  );
  yield takeLatest(
    constant.DELETE_PUBLISHER_RECONCILATION_INIT,
    DeletePaymentModalUserSaga
  );
  yield takeLatest(
    constant.UPDATE_PUBLISHER_RECONCILATION_INIT,
    ViewReconcilationPublisherPaymentUpdateSaga
  );
  yield takeLatest(
    constant.RECONCILATION_SETTLEMENT_SETTING_MODAL,
    ViewGetReconcilationSettlementSettingModalSaga
  );
  yield takeLatest(
    constant.RECONCILATION_SETTLEMENT_SETTING_MODAL_POST,
    ViewGetReconcilationSettlementSettingModalPostSaga
  );
  yield takeLatest(
    constant.DELETE_SETTLEMENT_SETTING_INIT,
    DeleteSettlementSettingUserSaga
  );
  yield takeLatest(
    constant.RECONCILATION_CURRENCY_MODAL,
    ViewGetReconcilationCurrencyModalSaga
  );
  yield takeLatest(
    constant.RECONCILATION_PAYMENT_MODAL_ID,
    ViewGetReconcilationPaymentIdModalSaga
  );
  yield takeLatest(
    constant.UPDATE_SETTLEMENT_SETTING_INIT,
    ViewReconcilationSettlementSettingUpdateSaga
  );
  yield takeLatest(
    constant.RECONCILATION_ORDER_STATUS_MODAL,
    ViewGetReconcilationEventModalSaga
  );

  yield takeLatest(constant.ALERT_PAGE, ViewGetAlertPageModalSaga);

  yield takeLatest(constant.ALERT_PAGE_POST, ViewGetAlertPagePostModalSaga);

  yield takeLatest(constant.UPDATE_ALERT_PAGE_INIT, ViewAlertUpdateSaga);
  yield takeLatest(constant.REPORT_PAGE_INIT, ViewGetReportPageModalSaga);
  yield takeLatest(constant.REPORT_PAGE_INVOICE_INIT, ViewInvoiceGenration);
}
