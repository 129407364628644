/* eslint-disable no-unreachable */
// export default  PublisherServices 
import { id } from 'date-fns/locale';
import { EndPoints , PythonApiUrl } from '../helpers/Endpoints';
import { API } from '../setupAxios'
import { TOKEN } from '../../app/utils/const';

const PublisherServices = {}

PublisherServices.ViewPublisher = function async(body,packageName){
    const formdata = body
    console.log(formdata,"publisherdata")
    let url = PythonApiUrl + EndPoints.publisher_add + '?package_name=' + packageName
    //  return fetch(url, requestOptions)
     API.post(`${url}`, formdata)
    .then(async response => {
        const data = await response.json()

        if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        return data
    })
    .catch(error => {
        console.error('There was an error!', error);
    });
}

PublisherServices.ViewPublisherGet = function(queryParams) {
    let searchParams = new URLSearchParams();
      Object.keys(queryParams).forEach(key =>
      searchParams.append(key, queryParams[key])
    );
    let url = PythonApiUrl + EndPoints.publisher + '?' + searchParams;
    
    return fetch(url, {
      mode: 'cors',
      headers: { 'Access-Control-Allow-Origin': '*', authorization: TOKEN.token },
    })
      .then(async response => {
        const data = await response.json();
        return data;
        if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };



export default  PublisherServices 