import { takeLatest, put } from 'redux-saga/effects';
import * as constant from '../constants/LandingReportConstants';
import { ApiUrl, EndPoints, PythonApiUrl } from '../helpers/Endpoints';
import { API } from '../setupAxios';


export function* ViewLandingPageSaga(payload) {
    try {
      let url = PythonApiUrl + EndPoints.landing_report;
      const params = payload.landingreportData;
      const response = yield API.get(url, { params });
      yield put({ type: constant.LANDINGREPORT_INIT_SUCCESS, response });
    } catch (error) {
      yield put({ type: constant.LANDINGREPORT_INIT_ERROR, error });
    }
  }

  export function* ViewLandingurlSaga(payload, packageName) {
    try {
      const url = PythonApiUrl + EndPoints.landing_page_add_details;
      const response = yield API.post(url, payload.landingurlData );
      // const response = yield call(CampaignServices.ViewCampaign ,payload.campaignData);
      yield put({ type: constant.LANDINGURL_ADD_SUCCESS, response });
    } catch (error) {
      yield put({ type: constant.LANDINGURL_ADD_ERROR, error: '400' });
    }
  }

  export default function* LandingReportSaga() {
    yield takeLatest(constant.LANDINGREPORT_INIT, ViewLandingPageSaga);
    yield takeLatest(constant.LANDINGURL_ADD , ViewLandingurlSaga );



  }