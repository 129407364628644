import * as constant from '../constants/CommonConstants';
import { setLocalStorage } from '../../app/utils/helpers';
import { MENU_LIST_VALUE } from '../../app/utils/const';
//   } from "../../../utils/helpers"

const initialState = {
  package_name: [],
  country_name: [],
  category_list: [],
  brand_list: [],
  priority_list: [],
  publisher_list: [],
  status_list: [],
  menu_list: [],
  filter_list: [],
  channel_list: [],
  loading: false,
  error: null,
  setGlobalPackageName: [],
  setGlobalRefreshRendering: true,

  // web_package_name: [],
  web_campaign_list: [],
  web_creative_list: [],
  web_systemdomain_list: [],
  web_placementid_list: [],
  web_fraudsubcategory_list: [],
  web_fraudcategory_list: [],
  web_placementtype_list: [],
  web_publisherid_list: [],
  web_channel_list: [],
  dashboard_list: [],
  selectedDashboardType: '',
  web_publisher_list: [],
  web_sub_publisher_list: [],
  web_page_id_list: [],

  click_fraudcategory_list: [],
  click_fraudsubcategory_list: [],
  click_publisher_list: [],
  click_campaign_list: [],
  click_device_type_list: [],

  //
  campaign_list: [],
  event_list: [],
  fraud_list: [],
  mtrackit_publisher_list: [],

  ///
  event_campaign_list: [],
  event_page_list: [],
  event_publisher_list: [],
  event_channel_list: [],
  event_event_list: [],

  // event report list

  report_publisher_list: [],
  report_feilds_list: [],
  report_fruad_category_list: [],

  // event report pages

  web_event_report: [],
  web_event_report_loading: true,
  web_event_report_error: null,
  download: { loading: false, data: null, error: null },

  //app evnet reports

  app_event_report: [],
  app_event_report_loading: true,
  app_event_report_error: null,
  download: { loading: false, data: null, error: null },

  //install event reports

  app_install_report: [],
  app_install_report_loading: true,
  app_install_report_error: null,
  download: { loading: false, data: null, error: null },

  generate_data: '',
  generate_data_loading: false,
  generate_data_error: null,

  event_app_generate_data: '',
  event_app_generate_data_loading: false,
  event_app_generate_data_error: null,

  install_app_generate_data: '',
  install_app_generate_data_loading: false,
  install_app_generate_data_error: null,

  app_f_publisher: {
    loading: true,
    response: null,
    data: [],
    error: null,
  },
  app_f_publisher_test: {
    loading: true,
    response: null,
    data: [],
    error: null,
  },
  app_f_country: {
    loading: true,
    response: null,
    data: [],
    error: null,
  },
  app_f_campaign: {
    loading: true,
    response: null,
    data: [],
    error: null,
  },
};
const CommonReducer = (
  state = initialState,
  {
    type,
    response,
    error,
    setGlobalPackageName,
    setGlobalRefreshRendering,
    end = 500,
  }
) => {
  switch (type) {
    case constant.PACKAGENAME_INIT:
    case constant.MENULIST_INIT:
    case constant.FILTERLIST_INIT:
    case constant.CHANNEL_LIST_INIT:
      return {
        ...state,
        channel_loading: true,
      };
    case constant.COUNTRY_LIST_INIT:
      return {
        ...state,
        country_loading: true,
      };
    case constant.CATEGORY_LIST_INIT:
      return {
        ...state,
        category_loading: true,
      };
    case constant.BRAND_LIST_INIT:
      return {
        ...state,
        brand_loading: true,
      };
    case constant.PRIORITY_LIST_INIT:
      return {
        ...state,
        priority_loading: true,
      };
    case constant.PUBLISHER_LIST_INIT:
      return {
        ...state,
        publisher_loading: true,
      };
    case constant.STATUS_LIST_INIT:
      return {
        ...state,
        status_loading: true,
      };
    case constant.WEB_CHANNEL_LIST_INIT:
      return {
        ...state,
        web_channel_loading: true,
      };
    case constant.WEB_CAMPAIGN_LIST_INIT:
      return {
        ...state,
        web_campaign_loading: true,
      };

    case constant.WEB_CREATIVE_LIST_INIT:
      return {
        ...state,
        web_creative_loading: true,
      };
    case constant.WEB_SYSTEM_DOMAIN_LIST_INIT:
      return {
        ...state,
        web_system_loading: true,
      };
    case constant.WEB_PLACEMENT_ID_LIST_INIT:
      return {
        ...state,
        web_placementid_loading: true,
      };
    case constant.WEB_FRAUD_CATEGORY_LIST_INIT:
      return {
        ...state,
        web_fraudcategory_loading: true,
      };
    case constant.WEB_FRAUD_SUB_CATEGORY_LIST_INIT:
      return {
        ...state,
        web_fraudsubcategory_loading: true,
      };
    case constant.WEB_PLACEMENT_TYPE_LIST_INIT:
      return {
        ...state,
        web_placementtype_loading: true,
      };
    case constant.WEB_DASHBOARD_TYPE_LIST_INIT:
      return {
        ...state,
        web_dashboard_loading: true,
      };
    case constant.WEB_SET_DASHBOARD_TYPE_LIST_INIT:
    case constant.WEB_PUBLISHER_ID_LIST_INIT:
      return {
        ...state,
        web_publisherid_loading: true,
      };
    case constant.WEB_PUBLISHER_LIST_INIT:
      return {
        ...state,
        web_publishername_loading: true,
      };
    case constant.WEB_SUB_PUBLISHER_LIST_INIT:
      return {
        ...state,
        web_subpublishername_loading: true,
      };
    case constant.WEB_PAGE_ID_LIST_INIT:
      return {
        ...state,
        web_pageid_loading: true,
      };
    //click
    case constant.CLICK_FRAUD_CATEGORY_INIT:
      return {
        ...state,
        click_fraudcategory_loading: true,
      };
    case constant.CLICK_FRAUD_SUB_CATEGORY_INIT:
      return {
        ...state,
        click_fraudsubcategory_loading: true,
      };
    case constant.CLICK_PUBLISHER_NAME_INIT:
      return {
        ...state,
        click_publishername_loading: true,
      };
    case constant.CLICK_CAMPAIGN_ID_INIT:
      return {
        ...state,
        click_campaignid_loading: true,
      };
    case constant.CLICK_DEVICE_TYPE_INIT:
      return {
        ...state,
        click_devicetype_loading: true,
      };
    case constant.MTRACKIT_REPORT_PUBLISHER_NAME_INIT:
      return {
        ...state,
        report_publishername_loading: true,
      };
    case constant.MTRACKIT_REPORT_CAMPAIGN_LIST_INIT:
      return {
        ...state,
        report_campaign_loading: true,
      };
    case constant.MTRACKIT_REPORT_EVENT_LIST_INIT:
      return {
        ...state,
        report_event_loading: true,
      };

    case constant.MTRACKIT_REPORT_FRAUD_LIST_INIT:
      return {
        ...state,
        report_fraud_loading: true,
      };

    case constant.MTRACKIT_CAMPAIGN_REPORT_CAMPAIGN_LIST_INIT:
      return {
        ...state,
        campaign_report_campaign_loading: true,
      };

    case constant.MTRACKIT_CAMPAIGN_REPORT_PUBLISHER_LIST_INIT:
      return {
        ...state,
        campaign_report_publisher_loading: true,
      };

    case constant.MTRACKIT_POSTBACK_REPORT_EVENT_LIST_INIT:
      return {
        ...state,
        postback_report_event_loading: true,
      };

    case constant.MTRACKIT_POSTBACK_REPORT_PB_LIST_INIT:
      return {
        ...state,
        postback_report_pb_status_loading: true,
      };

    case constant.MTRACKIT_POSTBACK_REPORT_PUBLISHER_LIST_INIT:
      return {
        ...state,
        postback_report_publisher_status_loading: true,
      };

    case constant.PUBLISHER_NAME_INIT:
      return {
        ...state,
        publishername_loading: true,
      };
    case constant.CAMPAIGN_LIST_INIT:
      return {
        ...state,
        campaign_loading: true,
      };
    case constant.EVENT_LIST_INIT:
      return {
        ...state,
        event_loading: true,
      };

    case constant.FRAUD_LIST_INIT:
      return {
        ...state,
        fraud_loading: true,
      };

    case constant.EVENT_CAMPAIGN_LIST_INIT:
      return {
        ...state,
        campaignlist_loading: true,
      };
    case constant.EVENT_PAGE_LIST_INIT:
      return {
        ...state,
        pagelist_loading: true,
      };
    case constant.EVENT_PUBLISHER_LIST_INIT:
      return {
        ...state,
        publisherlist_loading: true,
      };
    case constant.EVENT_CHANNEL_LIST_INIT:
      return {
        ...state,
        channellist_loading: true,
      };
    case constant.EVENT_EVENT_LIST_INIT:
      return {
        ...state,
        eventlist_loading: true,
      };

    //web event dash //change from here

    //Genrate report reducers

    case constant.EVENT_GENERATE_REPORT_INIT:
      return {
        ...state,
        generate_data_loading: true,
      };
    case constant.EVENT_GENERATE_REPORT_INIT_SUCCESS:
      return {
        ...state,
        generate_data_loading: false,
        generate_data: response,
      };
    case constant.EVENT_GENERATE_REPORT_INIT_ERROR:
      return {
        ...state,
        generate_data_loading: false,
        generate_data_error: error,
      };

    case constant.EVENT_GENERATE_REPORT_INIT_CLEAR:
      return {
        ...state,
        generate_data_error: response,
        generate_data: [],
      };

    //event app generate report

    case constant.APP_EVENT_GENERATE_REPORT_INIT:
      return {
        ...state,
        event_app_generate_data_loading: true,
      };
    case constant.APP_EVENT_GENERATE_REPORT_INIT_SUCCESS:
      return {
        ...state,
        event_app_generate_data_loading: false,
        event_app_generate_data: response,
      };
    case constant.APP_EVENT_GENERATE_REPORT_INIT_ERROR:
      return {
        ...state,
        event_app_generate_data_loading: false,
        event_app_generate_data_error: error,
      };

    case constant.APP_EVENT_GENERATE_REPORT_INIT_CLEAR:
      return {
        ...state,
        event_app_generate_data_error: response,
        event_app_generate_data: [],
      };

    // install app generate report

    case constant.INSTALL_GENERATE_REPORT_INIT:
      return {
        ...state,
        install_app_generate_data_loading: true,
      };
    case constant.INSTALL_GENERATE_REPORT_INIT_SUCCESS:
      return {
        ...state,
        install_app_generate_data_loading: false,
        install_app_generate_data: response,
      };
    case constant.INSTALL_GENERATE_REPORT_INIT_ERROR:
      return {
        ...state,
        install_app_generate_data_loading: false,
        install_app_generate_data_error: error,
      };

    case constant.INSTALL_GENERATE_REPORT_INIT_CLEAR:
      return {
        ...state,
        install_app_generate_data_error: response,
        install_app_generate_data: [],
      };

    // case constant.EVENT_CAMPAIGN_LIST_INIT:
    //   return {
    //     ...state,
    //     campaignlist_loading: true,
    //   };
    // case constant.EVENT_PAGE_LIST_INIT:
    //   return {
    //     ...state,
    //     pagelist_loading: true,
    //   };
    // case constant.EVENT_PUBLISHER_LIST_INIT:
    //   return {
    //     ...state,
    //     publisherlist_loading: true,
    //   };
    // case constant.EVENT_CHANNEL_LIST_INIT:
    //   return {
    //     ...state,
    //     channellist_loading: true,
    //   };
    // case constant.EVENT_EVENT_LIST_INIT:
    //   return {
    //     ...state,
    //     eventlist_loading: true,
    //   };

    // web event report

    case constant.EVENT_REPORT_PUBLISHER_lIST_INIT:
      return {
        ...state,
        reportpublisherlist_loading: true,
      };

    case constant.EVENT_REPORT_FEILDS_lIST_INIT:
      return {
        ...state,
        report_feilds_list_loading: true,
      };
    case constant.EVENT_REPORT_FRAUD_CATEGORY_lIST_INIT:
      return {
        ...state,
        report_fruad_category_list_loading: true,
      };

    // case constant.WEB_SET_DASHBOARD_TYPE_LIST_INIT_SUCCESS:
    //     return{
    //         ...state,
    //         selectedDashboardType:response.selectedDashboardType
    //     }

    case constant.PACKAGENAME_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        package_name: response.data,
      };

    case constant.MENULIST_INIT_SUCCESS:
      setLocalStorage(MENU_LIST_VALUE.MENU, JSON.stringify(response.menus));
      return {
        ...state,
        loading: false,
        menu_list: response,
      };

    case constant.FILTERLIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        filter_list: response.filter,
      };
    case constant.CHANNEL_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        channel_loading: false,
        channel_list: response,
      };
    case constant.COUNTRY_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        country_loading: false,
        country_name: response,
      };
    case constant.CATEGORY_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        category_loading: false,
        category_list: response,
      };
    case constant.BRAND_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        brand_loading: false,
        brand_list: response,
      };
    case constant.PRIORITY_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        priority_loading: false,
        priority_list: response,
      };
    case constant.PUBLISHER_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        publisher_loading: false,
        publisher_list: response,
      };
    case constant.STATUS_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        status_loading: false,
        status_list: response,
      };

    //web
    case constant.WEB_PUBLISHER_ID_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        web_publisherid_loading: false,
        web_publisherid_list: response,
      };
    case constant.WEB_PUBLISHER_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        web_publishername_loading: false,
        web_publisher_list: response,
      };

    case constant.WEB_SUB_PUBLISHER_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        web_subpublishername_loading: false,
        web_sub_publisher_list: response,
      };

    case constant.WEB_PAGE_ID_LIST_INIT_SUCCESS:
      if (!response.previous) {
        return {
          ...state,
          loading: false,
          web_pageid_loading: false,
          web_page_id_list: response.result,
        };
      }
      return {
        ...state,
        loading: false,
        web_pageid_loading: false,
        web_page_id_list: state.web_page_id_list.concat(response.result),
      };

    // case constant.FILTERLIST_INIT_SUCCESS:
    //     return {
    //         ...state,
    //         loading: false,
    //         filter_list: response.filter,
    //     }
    // -----------------------------web event report
    case constant.WEB_EVENT_REPORT_LIST_INIT:
      return {
        ...state,
        web_event_report_loading: true,
      };

    case constant.WEB_EVENT_REPORT_LIST_INIT_SUCCESS:
      return {
        ...state,
        web_event_report_loading: false,
        web_event_report_refetch: false,
        web_event_report: response,
      };
    case constant.WEB_EVENT_REPORT_REFETCH:
      return {
        ...state,
        web_event_report_loading: false,
        web_event_report_refetch: true,
      };
    case constant.WEB_EVENT_REPORT_LIST_INIT_ERROR:
      return {
        ...state,
        web_event_report_loading: false,
        web_event_report_error: response,
      };

    //-------------app event report ---------------------------

    case constant.APP_EVENT_REPORT_LIST_INIT:
      return {
        ...state,
        app_event_report_loading: true,
      };

    case constant.APP_EVENT_REPORT_LIST_INIT_SUCCESS:
      return {
        ...state,
        app_event_report_loading: false,
        app_event_report_refetch: false,
        app_event_report: response,
      };
    case constant.APP_EVENT_REPORT_REFETCH:
      return {
        ...state,
        app_event_report_loading: false,
        app_event_report_refetch: true,
      };
    case constant.APP_EVENT_REPORT_LIST_INIT_ERROR:
      return {
        ...state,
        app_event_report_loading: false,
        app_event_report_error: response,
      };

    // ------install report ------------------

    case constant.APP_INSTALL_REPORT_LIST_INIT:
      return {
        ...state,
        app_install_report_loading: true,
      };

    case constant.APP_INSTALL_REPORT_LIST_INIT_SUCCESS:
      return {
        ...state,
        app_install_report_loading: false,
        app_install_report_refetch: false,
        app_install_report: response,
      };
    case constant.APP_INSTALL_REPORT_REFETCH:
      return {
        ...state,
        app_install_report_loading: false,
        app_install_report_refetch: true,
      };
    case constant.APP_INSTALL_REPORT_LIST_INIT_ERROR:
      return {
        ...state,
        app_install_report_loading: false,
        app_install_report_error: response,
      };

    case constant.WEB_CHANNEL_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        web_channel_loading: false,
        web_channel_list: response,
      };
    case constant.WEB_CAMPAIGN_LIST_INIT_SUCCESS:
      if (!response.previous) {
        return {
          ...state,
          loading: false,
          web_campaign_loading: false,
          web_campaign_list: response.result,
        };
      }
      return {
        ...state,
        loading: false,
        web_campaign_loading: false,
        web_campaign_list: state.web_campaign_list.concat(response.result),
      };
    case constant.WEB_CREATIVE_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        web_creative_loading: false,
        web_creative_list: response,
      };
    case constant.WEB_SYSTEM_DOMAIN_LIST_INIT_SUCCESS:
      if (!response.previous) {
        return {
          ...state,
          loading: false,
          web_system_loading: false,
          web_systemdomain_list: response.result,
        };
      }
      return {
        ...state,
        loading: false,
        web_system_loading: false,
        web_systemdomain_list: state.web_systemdomain_list.concat(
          response.result
        ),
      };
    case constant.WEB_PLACEMENT_ID_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        web_placementid_loading: false,
        web_placementid_list: response,
      };
    case constant.WEB_FRAUD_CATEGORY_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        web_fraudcategory_loading: false,
        web_fraudcategory_list: response,
      };
    case constant.WEB_FRAUD_SUB_CATEGORY_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        web_fraudsubcategory_loading: false,
        web_fraudsubcategory_list: response,
      };
    case constant.WEB_PLACEMENT_TYPE_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        web_placementtype_loading: false,
        web_placementtype_list: response,
      };
    case constant.WEB_DASHBOARD_TYPE_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        web_dashboard_loading: false,
        dashboard_list: response.dashboardType,
      };
    case constant.CAMPAIGN_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        campaign_loading: false,
        campaign_list: response.data,
      };
    case constant.PUBLISHER_NAME_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        publishername_loading: false,
        mtrackit_publisher_list: response.data,
      };
    case constant.EVENT_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        event_loading: false,
        event_list: response.data,
      };

    case constant.FRAUD_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        fraud_loading: false,
        fraud_list: response.data,
      };

    case constant.CLICK_FRAUD_CATEGORY_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        click_fraudcategory_loading: false,
        click_fraudcategory_list: response.data,
      };

    case constant.CLICK_FRAUD_SUB_CATEGORY_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        click_fraudsubcategory_loading: false,
        click_fraudsubcategory_list: response.data,
      };

    case constant.CLICK_PUBLISHER_NAME_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        click_publishername_loading: false,
        click_publisher_list: response.data,
      };

    case constant.CLICK_CAMPAIGN_ID_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        click_campaignid_loading: false,
        click_campaign_list: response.data,
      };

    case constant.CLICK_DEVICE_TYPE_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        click_devicetype_loading: false,
        click_device_type_list: response.data,
      };

    case constant.EVENT_CAMPAIGN_LIST_INIT_SUCCESS:
      if (!response.previous) {
        return {
          ...state,
          loading: false,
          campaignlist_loading: false,
          event_campaign_list: response.result,
        };
      }
      return {
        ...state,
        loading: false,
        campaignlist_loading: false,
        event_campaign_list: state.event_campaign_list.concat(response.result),
      };

    case constant.EVENT_PAGE_LIST_INIT_SUCCESS:
      if (!response.previous) {
        return {
          ...state,
          loading: false,
          pagelist_loading: false,
          event_page_list: response.result,
        };
      }
      return {
        ...state,
        loading: false,
        pagelist_loading: false,
        event_page_list: state.event_page_list.concat(response.result),
      };

    case constant.EVENT_PUBLISHER_LIST_INIT_SUCCESS:
      if (!response.previous) {
        return {
          ...state,
          loading: false,
          publisherlist_loading: false,
          event_publisher_list: response.result,
        };
      }
      return {
        ...state,
        loading: false,
        publisherlist_loading: false,
        event_publisher_list: state.event_publisher_list.concat(
          response.result
        ),
      };

    case constant.EVENT_CHANNEL_LIST_INIT_SUCCESS:
      if (!response.previous) {
        return {
          ...state,
          loading: false,
          channellist_loading: false,
          event_channel_list: response.result,
        };
      }
      return {
        ...state,
        loading: false,
        channellist_loading: false,
        event_channel_list: state.event_channel_list.concat(response.result),
      };

    case constant.EVENT_EVENT_LIST_INIT_SUCCESS:
      if (!response.previous) {
        return {
          ...state,
          loading: false,
          eventlist_loading: false,
          event_event_list: response.result,
        };
      }
      return {
        ...state,
        loading: false,
        eventlist_loading: false,
        event_event_list: state.event_event_list.concat(response.result),
      };

    case constant.MTRACKIT_REPORT_CAMPAIGN_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        report_campaign_loading: false,
        report_campaign_list: response.data,
      };
    case constant.MTRACKIT_REPORT_PUBLISHER_NAME_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        report_publishername_loading: false,
        report_publisher_list: response.data,
      };
    case constant.MTRACKIT_REPORT_EVENT_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        report_event_loading: false,
        report_event_list: response.data,
      };

    case constant.MTRACKIT_REPORT_FRAUD_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        report_fraud_loading: false,
        report_fraud_list: response.data,
      };

    case constant.MTRACKIT_CAMPAIGN_REPORT_CAMPAIGN_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        campaign_report_campaign_loading: false,
        campaign_report_campaign_list: response.data.data,
      };

    case constant.MTRACKIT_CAMPAIGN_REPORT_PUBLISHER_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        campaign_report_publisher_loading: false,
        campaign_report_publisher_list: response.data.data,
      };

    case constant.MTRACKIT_POSTBACK_REPORT_EVENT_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        postback_report_event_loading: false,
        postback_report_event_list: response.data.data,
      };

    case constant.MTRACKIT_POSTBACK_REPORT_PB_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        postback_report_pb_status_loading: false,
        postback_report_pb_status_list: response.data.data,
      };

    case constant.MTRACKIT_POSTBACK_REPORT_PUBLISHER_LIST_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        postback_report_publisher_status_loading: false,
        postback_report_publisher_list: response.data.data,
      };

    // event report filter

    case constant.EVENT_REPORT_FEILDS_lIST_INIT_SUCCESS:
      if (!response.previous) {
        var feild = [];
        response.data.map((item, index) => {
          feild.push({ feilds: item });
        });
        return {
          ...state,
          loading: false,
          report_feilds_list_loading: false,
          report_feilds_list: feild,
        };
      }
      // console.log(feild,"asdasdasdasdasdasd");
      return {
        ...state,
        loading: false,
        report_feilds_list_loading: false,
        report_feilds_list: state.report_feilds_list.concat(response.data),
      };


      
    case constant.IMPRESSION_INTEGRITY_REPORT_SUCCESS:
      if (!response.previous) {
        var feild = [];
        response.data.map((item, index) => {
          feild.push({ feilds: item });
        });
        return {
          ...state,
          loading: false,
          report_feilds_list_loading: false,
          report_feilds_list: feild,
        };
      }
      // console.log(feild,"asdasdasdasdasdasd");
      return {
        ...state,
        loading: false,
        report_feilds_list_loading: false,
        report_feilds_list: state.report_feilds_list.concat(response.data),
      };

    case constant.EVENT_REPORT_PUBLISHER_lIST_INIT_SUCCESS:
      if (!response.previous) {
        return {
          ...state,
          loading: false,
          report_publisher_list_loading: false,
          report_publisher_list: response.data,
        };
      }
      return {
        ...state,
        loading: false,
        report_publisher_list_loading: false,
        report_publisher_list: state.report_publisher_list.concat(
          response.data
        ),
      };

    case constant.EVENT_REPORT_FRAUD_CATEGORY_lIST_SUCCESS:
      // console.log('asasasasasas', response);
      if (!response.previous) {
        return {
          ...state,
          loading: false,
          report_fruad_category_list_loading: false,
          report_fruad_category_list: response.result,
        };
      }

      return {
        ...state,
        loading: false,
        report_fruad_category_list_loading: false,
        report_fruad_category_list: state.report_fruad_category_list.concat(
          response.result
        ),
      };

    case constant.SET_GLOBAL_PACKAGE_NAME:
      return {
        ...state,
        setGlobalPackageName: setGlobalPackageName,
      };
    // REFREH
    case constant.SET_GLOBAL_REFRESH_RENDERING:
      return {
        ...state,
        setGlobalRefreshRendering: setGlobalRefreshRendering,
      };

    // APP Filter
    // 1. Publisher
    case constant.APP_F_PUBLISHER_TEST_INIT: {
      const app_f_publisher_test = {
        loading: true,
        response: null,
        data: [],
        error: null,
      };
      return { ...state, app_f_publisher_test };
    }
    case constant.APP_F_PUBLISHER_TEST_SUCCESS: {
      const app_f_publisher_test = {
        loading: false,
        response: response,
        data: response ?? [],
        error: null,
      };
      return { ...state, app_f_publisher_test };
    }
    case constant.APP_F_PUBLISHER_TEST_ERROR: {
      const app_f_publisher_test = {
        loading: false,
        response: null,
        data: [],
        error: error,
      };
      return { ...state, app_f_publisher_test };
    }
    // 1. Publisher
    case constant.APP_F_PUBLISHER_INIT: {
      const app_f_publisher = {
        loading: true,
        response: null,
        data: [],
        error: null,
      };
      return { ...state, app_f_publisher };
    }
    case constant.APP_F_PUBLISHER_SUCCESS: {
      const app_f_publisher = {
        loading: false,
        data: response,
        // data: response?.slice(0, end) ?? [],
        error: null,
      };
      return { ...state, app_f_publisher };
    }
    case constant.APP_F_PUBLISHER_ERROR: {
      const app_f_publisher = {
        loading: false,
        response: null,
        data: [],
        error: error,
      };
      return { ...state, app_f_publisher };
    }
    // case constant.APP_F_PUBLISHER_NEXT: {
    //   const app_f_publisher = {
    //     ...state.app_f_publisher,
    //     data: state.app_f_publisher.response?.slice(0, end) ?? [],
    //   };
    //   return { ...state, app_f_publisher };
    // }
    // 2. COUNTRY
    case constant.APP_F_COUNTRY_INIT: {
      const app_f_country = {
        loading: true,
        response: null,
        data: [],
        error: null,
      };
      return { ...state, app_f_country };
    }
    case constant.APP_F_COUNTRY_SUCCESS: {
      const app_f_country = {
        loading: false,
        response: response,
        data: response?.slice(0, end) ?? [],
        error: null,
      };
      return { ...state, app_f_country };
    }
    case constant.APP_F_COUNTRY_ERROR: {
      const app_f_country = {
        loading: false,
        response: null,
        data: [],
        error: error,
      };
      return { ...state, app_f_country };
    }
    case constant.APP_F_COUNTRY_NEXT: {
      const app_f_country = {
        ...state.app_f_country,
        data: state.app_f_country.response?.slice(0, end) ?? [],
      };
      return { ...state, app_f_country };
    }
    // 3. CAMPAIGN
    case constant.APP_F_CAMPAIGN_INIT: {
      const app_f_campaign = {
        loading: true,
        response: null,
        data: [],
        error: null,
      };
      return { ...state, app_f_campaign };
    }
    case constant.APP_F_CAMPAIGN_SUCCESS: {
      const app_f_campaign = {
        loading: false,
        response: response,
        data: response?.slice(0, end) ?? [],
        error: null,
      };
      return { ...state, app_f_campaign };
    }
    case constant.APP_F_CAMPAIGN_ERROR: {
      const app_f_campaign = {
        loading: false,
        response: null,
        data: [],
        error: error,
      };
      return { ...state, app_f_campaign };
    }
    case constant.APP_F_CAMPAIGN_NEXT: {
      const app_f_campaign = {
        ...state.app_f_campaign,
        data: state.app_f_campaign.response?.slice(0, end) ?? [],
      };
      return { ...state, app_f_campaign };
    }
    // 4. CHANNEL
    case constant.APP_F_VENDOR_INIT: {
      return {
        ...state,
        app_f_vendor_loading: true,
        app_f_vendor_response: null,
        app_f_vendor_error: null,
      };
    }
    case constant.APP_F_VENDOR_SUCCESS: {
      return {
        ...state,
        app_f_vendor_loading: false,
        app_f_vendor_response: response,
        app_f_vendor_error: null,
      };
    }
    case constant.APP_F_VENDOR_ERROR: {
      return {
        ...state,
        app_f_vendor_loading: false,
        app_f_vendor_response: null,
        app_f_vendor_error: error,
      };
    }
    // 5. EVENT
    case constant.APP_F_EVENT_INIT: {
      return {
        ...state,
        app_f_event_loading: true,
        app_f_event_response: null,
        app_f_event_error: null,
      };
    }
    case constant.APP_F_EVENT_NEXT: {
      const app_f_event = {
        ...state.app_f_event,
        data: state.app_f_event.response?.slice(0, end) ?? [],
      };
      return { ...state, app_f_event };
    }
    case constant.APP_F_EVENT_SUCCESS: {
      const app_f_event = {
        loading: false,
        response: response,
        data: response?.slice(0, end) ?? [],
        error: null,
      };
      return { ...state, app_f_event };
    }
    case constant.APP_F_EVENT_ERROR: {
      return {
        ...state,
        app_f_event_loading: false,
        app_f_event_response: null,
        app_f_event_error: error,
      };
    }
    // 6. SUBPUBLISHER
    case constant.APP_F_SUBPUBLISHER_INIT: {
      return {
        ...state,
        app_f_subpublisher_loading: true,
        app_f_subpublisher_response: null,
        app_f_subpublisher_error: null,
      };
    }
    case constant.APP_F_SUBPUBLISHER_SUCCESS: {
      return {
        ...state,
        app_f_subpublisher_loading: false,
        app_f_subpublisher_response: response,
        app_f_subpublisher_error: null,
      };
    }
    case constant.APP_F_SUBPUBLISHER_ERROR: {
      return {
        ...state,
        app_f_subpublisher_loading: false,
        app_f_subpublisher_response: null,
        app_f_subpublisher_error: error,
      };
    }
    // 7. FIELDS
    case constant.APP_F_FIELDS_INIT: {
      return {
        ...state,
        app_f_fields_loading: true,
        app_f_fields_response: null,
        app_f_fields_error: null,
      };
    }
    case constant.APP_F_FIELDS_SUCCESS: {
      return {
        ...state,
        app_f_fields_loading: false,
        app_f_fields_response: response,
        app_f_fields_error: null,
      };
    }
    case constant.APP_F_FIELDS_ERROR: {
      return {
        ...state,
        app_f_fields_loading: false,
        app_f_fields_response: null,
        app_f_fields_error: error,
      };
    }
    // 7. FIELDS
    case constant.APP_F_FRAUD_INIT: {
      return {
        ...state,
        app_f_fraud_loading: true,
        app_f_fraud_response: null,
        app_f_fraud_error: null,
      };
    }
    case constant.APP_F_FRAUD_SUCCESS: {
      return {
        ...state,
        app_f_fraud_loading: false,
        app_f_fraud_response: response?.resultset ?? [],
        app_f_fraud_error: null,
      };
    }
    case constant.APP_F_FRAUD_ERROR: {
      return {
        ...state,
        app_f_fraud_loading: false,
        app_f_fraud_response: null,
        app_f_fraud_error: error,
      };
    }

    case constant.PACKAGENAME_INIT_ERROR:
    case constant.MENULIST_INIT_ERROR:
    case constant.FILTERLIST_INIT_ERROR:
    case constant.CHANNEL_LIST_INIT_ERROR:
    case constant.COUNTRY_LIST_INIT_ERROR:
    case constant.CATEGORY_LIST_INIT_ERROR:
    case constant.BRAND_LIST_INIT_ERROR:
    case constant.PRIORITY_LIST_INIT_ERROR:
    case constant.PUBLISHER_LIST_INIT_ERROR:
    case constant.STATUS_LIST_INIT_ERROR:
    case constant.WEB_CHANNEL_LIST_INIT_ERROR:
    case constant.WEB_CAMPAIGN_LIST_INIT_ERROR:
    case constant.WEB_CREATIVE_LIST_INIT_ERROR:
    case constant.WEB_SYSTEM_DOMAIN_LIST_INIT_ERROR:
    case constant.WEB_PLACEMENT_ID_LIST_INIT_ERROR:
    case constant.WEB_FRAUD_CATEGORY_LIST_INIT_ERROR:
    case constant.WEB_FRAUD_SUB_CATEGORY_LIST_INIT_ERROR:
    case constant.WEB_PLACEMENT_TYPE_LIST_INIT_ERROR:
    case constant.WEB_DASHBOARD_TYPE_LIST_INIT_ERROR:
    case constant.WEB_PUBLISHER_ID_LIST_INIT_ERROR:
    case constant.WEB_PUBLISHER_LIST_INIT_ERROR:
    case constant.WEB_SUB_PUBLISHER_LIST_INIT_ERROR:
    case constant.WEB_PAGE_ID_LIST_INIT_ERROR:
    case constant.CLICK_FRAUD_CATEGORY_INIT_ERROR:
    case constant.CLICK_FRAUD_SUB_CATEGORY_INIT_ERROR:
    case constant.CLICK_PUBLISHER_NAME_INIT_ERROR:
    case constant.CLICK_CAMPAIGN_ID_INIT_ERROR:
    case constant.CLICK_DEVICE_TYPE_INIT_ERROR:
    case constant.EVENT_LIST_INIT_ERROR:
    case constant.CAMPAIGN_LIST_INIT_ERROR:
    case constant.FRAUD_LIST_INIT_ERROR:
    case constant.PUBLISHER_NAME_INIT_ERROR:
    case constant.EVENT_CAMPAIGN_LIST_INIT_ERROR:
    case constant.EVENT_PAGE_LIST_INIT_ERROR:
    case constant.EVENT_PUBLISHER_LIST_INIT_ERROR:
    case constant.EVENT_CHANNEL_LIST_INIT_ERROR:
    case constant.EVENT_EVENT_LIST_INIT_ERROR:
    case constant.EVENT_REPORT_FEILDS_lIST_INIT_ERROR:
    case constant.EVENT_REPORT_PUBLISHER_lIST_INIT_ERROR:
    case constant.EVENT_REPORT_FRAUD_CATEGORY_lIST_ERROR:
    case constant.MTRACKIT_REPORT_EVENT_LIST_INIT_ERROR:
    case constant.MTRACKIT_REPORT_CAMPAIGN_LIST_INIT_ERROR:
    case constant.MTRACKIT_REPORT_FRAUD_LIST_INIT_ERROR:
    case constant.MTRACKIT_REPORT_PUBLISHER_NAME_INIT_ERROR:
    case constant.MTRACKIT_CAMPAIGN_REPORT_CAMPAIGN_LIST_INIT_ERROR:
    case constant.MTRACKIT_CAMPAIGN_REPORT_PUBLISHER_LIST_INIT_ERROR:
    case constant.MTRACKIT_POSTBACK_REPORT_EVENT_LIST_INIT_ERROR:
    case constant.MTRACKIT_POSTBACK_REPORT_PB_LIST_INIT_ERROR:
    case constant.MTRACKIT_POSTBACK_REPORT_PUBLISHER_LIST_INIT_ERROR:
      return {
        ...state,
        loading: false,
        error: response,
      };
    default:
      return state;
  }
};
export default CommonReducer;
