import { takeLatest, put } from 'redux-saga/effects';
import { API } from '../setupAxios';
import { EndPoints, ApiUrl } from '../helpers/Endpoints';
import USERMANAGEMENT from '../constants/userManagementConst';
import toast, { Toaster } from 'react-hot-toast';


const notifysuccess = () => {
  toast.success('User added successfully !');
};
const notify = () => {
  toast.error('Something went Wrong');
};



export function* GetAllUserSaga({ payload }) {
  try {
    let url = ApiUrl + EndPoints.ALL_USER;
    const response = yield API.get(url, { params: payload });
    yield put({ type: USERMANAGEMENT.GET_USER_SUCCESS, data: response.result });
  } catch (error) {
    yield put({ type: USERMANAGEMENT.GET_USER_ERROR, error });
  }
}

export function* AddUserSaga({ payload }) {
  try {
    const data = {
      email: payload.email,
      name: payload.name,
      password: payload.password,
      role: payload.role,
    };
    if (payload.fraudType) data.fraud_type = payload?.fraudType?.split(',');
    if (payload.vendor) data.vendor = payload?.vendor?.split(',');
    if (payload.phone) data.phone = payload?.phone;
    if (payload.role === 'publisher')
      data.publisher = payload?.publisher?.split(',') ?? [];
    let url = ApiUrl + EndPoints.ADD_USER;
    const response = yield API.post(url, data);
    if (response.status === true) {
       notifysuccess();
    } else {
      notify(); 
    }
    yield put({ type: USERMANAGEMENT.ADD_USER_SUCCESS, data: response.result });
  } catch (error) {
    yield put({ type: USERMANAGEMENT.ADD_USER_ERROR, error });
  }
}

export function* EditUserSaga({ payload }) {
  try {
    const id = payload.id;
    const data = payload.data;
    const body = {
      // email: data.email,
      // name: data.name,
      role: data.role,
    };
    if (data.fraudType) body.fraud_type = data?.fraudType?.split(',');
    if (data.vendor) body.vendor = data?.vendor?.split(',');
    if (data.phone) body.phone = data.phone;
    if (data.role === 'publisher')
      body.publisher = data?.publisher?.split(',') ?? [];
    let url = ApiUrl + EndPoints.EDIT_USER + id;
    // console.warn('EDIT fn is disabled');
    const response = yield API.put(url, body);
    // const response = {};
    yield put({ type: USERMANAGEMENT.ADD_USER_SUCCESS, data: response.result });
  } catch (error) {
    yield put({ type: USERMANAGEMENT.ADD_USER_ERROR, error });
  }
}
export function* GetOneUserSaga({ payload }) {
  try {
    const id = payload.id;
    let url = ApiUrl + EndPoints.GET_ONE_USER + id;
    const response = yield API.get(url);
    yield put({
      type: USERMANAGEMENT.GET_ONE_USER_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({ type: USERMANAGEMENT.GET_ONE_USER_ERROR, error });
  }
}

export function* DeleteUserSaga({ payload }) {
  try {
    const id = payload.id;
    let url = ApiUrl + EndPoints.DELETE_USER + id;
    const response = yield API.delete(url);
    yield put({
      type: USERMANAGEMENT.DELETE_USER_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({ type: USERMANAGEMENT.DELETE_USER_ERROR, error });
  }
}

//................user mapping
export function* ViewUserSaga() {
  try {
    let url = ApiUrl + EndPoints.getuser;
    // console.log(url, "getuseri22222222");
    const response = yield API.get(url);

    yield put({ type: USERMANAGEMENT.GET_ALL_USER_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: USERMANAGEMENT.GET_ALL_USER_INIT_ERROR, error });
  }
}

export function* ViewUserMappingSaga(payload) {
  try {
    let url =
      ApiUrl +
      EndPoints.getusermapping +
      '?page=' +
      payload.payload.page +
      '&limit=' +
      payload.payload.limit;
    const response = yield API.get(url);
    yield put({
      type: USERMANAGEMENT.USERMAPPING_DETAILS_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: USERMANAGEMENT.USERMAPPING_DETAILS_INIT_ERROR, error });
  }
}
export function* ViewUserMappingsaveSaga(payload) {
  try {
    let url = ApiUrl + EndPoints.postusermapping;
    const response = yield API.post(url, payload.values);
    yield put({ type: USERMANAGEMENT.USERMAPPING_SAVE_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: USERMANAGEMENT.USERMAPPING_SAVE_INIT_ERROR, error });
  }
}
export function* ViewUserMappingUpdateSaga(payload) {
  try {
    let url = ApiUrl + EndPoints.updateusermapping + '/' + payload.values.id_;
    const response = yield API.put(url, payload.values.body);
    yield put({
      type: USERMANAGEMENT.USERMAPPING_UPDATE_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: USERMANAGEMENT.USERMAPPING_UPDATE_INIT_ERROR, error });
  }
}
export function* ViewUserMappingDeleteSaga(payload) {
  try {
    let url = ApiUrl + EndPoints.deleteusermapping + '/' + payload.values;
    const response = yield API.delete(url);
    yield put({
      type: USERMANAGEMENT.USERMAPPING_DELETE_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: USERMANAGEMENT.USERMAPPING_DELETE_INIT_ERROR, error });
  }
}
export function* ViewMenuSelectionSaga() {
  try {
    let url = ApiUrl + EndPoints.menuselection;
    const response = yield API.get(url);
    yield put({
      type: USERMANAGEMENT.GET_MENU_SELECTION_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: USERMANAGEMENT.GET_MENU_SELECTION_INIT_ERROR, error });
  }
}
export function* ViewFilterSelectionSaga() {
  try {
    let url = ApiUrl + EndPoints.filterselection;
    const response = yield API.get(url);
    yield put({
      type: USERMANAGEMENT.GET_FILTER_SELECTION_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: USERMANAGEMENT.GET_FILTER_SELECTION_INIT_ERROR, error });
  }
}
export function* ViewUsergetoneapiSaga(payload) {
  try {
    let url = ApiUrl + EndPoints.usergetoneapi + '/' + payload.payload;

    const response = yield API.get(url);
    yield put({ type: USERMANAGEMENT.USER_GETONEAPI_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: USERMANAGEMENT.USER_GETONEAPI_INIT_ERROR, error });
  }
}

export function* ViewPackageDropdownSaga() {
  try {
    let url = ApiUrl + EndPoints.userpackagemapping;
    const response = yield API.get(url);
    yield put({
      type: USERMANAGEMENT.USER_PACKAGE_DROPDOWN_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: USERMANAGEMENT.USER_PACKAGE_DROPDOWN_INIT_ERROR, error });
  }
}

export default function* userManagementSaga() {
  yield takeLatest(USERMANAGEMENT.GET_USER_INIT, GetAllUserSaga);
  yield takeLatest(USERMANAGEMENT.ADD_USER_INIT, AddUserSaga);
  yield takeLatest(USERMANAGEMENT.EDIT_USER_INIT, EditUserSaga);
  yield takeLatest(USERMANAGEMENT.DELETE_USER_INIT, DeleteUserSaga);
  //............user mapping
  yield takeLatest(
    USERMANAGEMENT.USERMAPPING_DETAILS_INIT,
    ViewUserMappingSaga
  );
  yield takeLatest(
    USERMANAGEMENT.USERMAPPING_SAVE_INIT,
    ViewUserMappingsaveSaga
  );
  yield takeLatest(
    USERMANAGEMENT.USERMAPPING_UPDATE_INIT,
    ViewUserMappingUpdateSaga
  );
  yield takeLatest(
    USERMANAGEMENT.USERMAPPING_DELETE_INIT,
    ViewUserMappingDeleteSaga
  );
  yield takeLatest(
    USERMANAGEMENT.GET_MENU_SELECTION_INIT,
    ViewMenuSelectionSaga
  );
  yield takeLatest(
    USERMANAGEMENT.GET_FILTER_SELECTION_INIT,
    ViewFilterSelectionSaga
  );
  yield takeLatest(
    USERMANAGEMENT.USER_PACKAGE_DROPDOWN_INIT,
    ViewPackageDropdownSaga
  );
  yield takeLatest(USERMANAGEMENT.USER_GETONEAPI_INIT, ViewUsergetoneapiSaga);
  yield takeLatest(USERMANAGEMENT.GET_ALL_USER_INIT, ViewUserSaga);
  yield takeLatest(USERMANAGEMENT.GET_ONE_USER_INIT, GetOneUserSaga);
}
