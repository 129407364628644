export default {
  DASHBOARD: {
    INSTALL: {
      
      INSERT_360_OUTPUT: {
        INIT: 'INIT_BRAND_RELEVANCY_INSERT_360_OUTPUT',
        SUCCESS: 'SUCCESS_BRAND_RELEVANCY_INSERT_360_OUTPUT',
        ERROR: 'INIT_BRAND_RELEVANCY_INSERT_360_OUTPUT',
        CLEAR: 'CLEAR_BRAND_RELEVANCY_INSERT_360_OUTPUT',
      },
      TOTAL_COUNT: {
        INIT: 'APP_DASHBOARD_INSTALL_TOTAL_COUNT_INIT',
        SUCCESS: 'APP_DASHBOARD_INSTALL_TOTAL_COUNT_SUCCESS',
        ERROR: 'APP_DASHBOARD_INSTALL_TOTAL_COUNT_ERROR',
      },
      TOTAL_BY_DATE: {
        INIT: 'APP_DASHBOARD_INSTALL_TOTAL_INIT',
        SUCCESS: 'APP_DASHBOARD_INSTALL_TOTAL_SUCCESS',
        ERROR: 'APP_DASHBOARD_INSTALL_TOTAL_ERROR',
      },
      TOP_CLEAN_PUBLISHER: {
        INIT: 'APP_DASHBOARD_INSTALL_TOP_CLEAN_INIT',
        SUCCESS: 'APP_DASHBOARD_INSTALL_TOP_CLEAN_SUCCESS',
        ERROR: 'APP_DASHBOARD_INSTALL_TOP_CLEAN_ERROR',
      },
      TOP_FRAUD_PUBLISHER: {
        INIT: 'APP_DASHBOARD_INSTALL_TOP_FRAUD_INIT',
        SUCCESS: 'APP_DASHBOARD_INSTALL_TOP_FRAUD_SUCCESS',
        ERROR: 'APP_DASHBOARD_INSTALL_TOP_FRAUD_ERROR',
      },
      FRAUD_CATEGORY: {
        INIT: 'APP_DASHBOARD_INSTALL_FRAUD_CATEGORY_INIT',
        SUCCESS: 'APP_DASHBOARD_INSTALL_FRAUD_CATEGORY_SUCCESS_',
        ERROR: 'APP_DASHBOARD_INSTALL_FRAUD_CATEGORY_ERROR_',
      },
      EVENTS_FRAUD_BY_DATE: {
        INIT: 'APP_DASHBOARD_INSTALL_EVENTS_FRAUD_BY_DATE_INIT',
        SUCCESS: 'APP_DASHBOARD_INSTALL_EVENTS_FRAUD_BY_DATE_SUCCESS',
        ERROR: 'APP_DASHBOARD_INSTALL_EVENTS_FRAUD_BY_DATE_ERROR',
      },
      EVENTS_FRAUD_BY_PUBLISHER: {
        INIT: 'APP_DASHBOARD_INSTALL_EVENTS_FRAUD_BY_PUBLISHER_INIT',
        SUCCESS: 'APP_DASHBOARD_INSTALL_EVENTS_FRAUD_BY_PUBLISHER_SUCCESS',
        ERROR: 'APP_DASHBOARD_INSTALL_EVENTS_FRAUD_BY_PUBLISHER_ERROR',
      },
      EVENTS_FRAUD_BY_WEEK: {
        SUCCESS: 'APP_DASHBOARD_INSTALL_EVENTS_FRAUD_BY_WEEK_SUCCESS',
        INIT: 'APP_DASHBOARD_INSTALL_EVENTS_FRAUD_BY_WEEK_INIT',
        ERROR: 'APP_DASHBOARD_INSTALL_EVENTS_FRAUD_BY_WEEK_ERROR',
      },
      EVENTS_FRAUD_BY_MONTH: {
        INIT: 'APP_DASHBOARD_INSTALL_EVENTS_FRAUD_BY_MONTH_INIT',
        SUCCESS: 'APP_DASHBOARD_INSTALL_EVENTS_FRAUD_BY_MONTH_SUCCESS',
        ERROR: 'APP_DASHBOARD_INSTALL_EVENTS_FRAUD_BY_MONTH_ERROR',
      },
      EVENTS_FRAUD_BY_INSTALL_TYPE: {
        INIT: 'APP_DASHBOARD_INSTALL_EVENTS_FRAUD_BY_INSTALL_TYPE_INIT',
        SUCCESS: 'APP_DASHBOARD_INSTALL_EVENTS_FRAUD_BY_INSTALL_TYPE_SUCCESS',
        ERROR: 'APP_DASHBOARD_INSTALL_EVENTS_FRAUD_BY_INSTALL_TYPE_ERROR',
      },
      FRAUD_SUB_CATEGORY: {
        INIT: 'APP_DASHBOARD_INSTALL_FRAUD_SUB_CATEGORY_INIT',
        SUCCESS: 'APP_DASHBOARD_INSTALL_FRAUD_SUB_CATEGORY_SUCCESS',
        ERROR: 'APP_DASHBOARD_INSTALL_FRAUD_SUB_CATEGORY_ERROR',
      },
      FRAUD_SUB_CATEGORY_PERCENT: {
        INIT: 'APP_DASHBOARD_INSTALL_FRAUD_SUB_CATEGORY_PERCENT_INIT',
        SUCCESS: 'APP_DASHBOARD_INSTALL_FRAUD_SUB_CATEGORY_PERCENT_SUCCESS',
        ERROR: 'APP_DASHBOARD_INSTALL_FRAUD_SUB_CATEGORY_PERCENT_ERROR',
      },
      SUB_PUBLISHER: {
        INIT: 'APP_DASHBOARD_INSTALL_SUB_PUBLISHER_INIT',
        SUCCESS: 'APP_DASHBOARD_INSTALL_SUB_PUBLISHER_SUCCESS',
        ERROR: 'APP_DASHBOARD_INSTALL_SUB_PUBLISHER_ERROR',
      },
      SUB_FRAUD_BY_DATE: {
        INIT: 'APP_DASHBOARD_INSTALL_SUB_FRAUD_BY_DATE_INIT',
        SUCCESS: 'APP_DASHBOARD_INSTALL_SUB_FRAUD_BY_DATE_SUCCESS',
        ERROR: 'APP_DASHBOARD_INSTALL_SUB_FRAUD_BY_DATE_ERROR',
      },
      SUB_FRAUD_BY_PUBLISHER: {
        INIT: 'APP_DASHBOARD_INSTALL_SUB_FRAUD_BY_PUBLISHER_INIT',
        SUCCESS: 'APP_DASHBOARD_INSTALL_SUB_FRAUD_BY_PUBLISHER_SUCCESS',
        ERROR: 'APP_DASHBOARD_INSTALL_SUB_FRAUD_BY_PUBLISHER_ERROR',
      },
    },
    EVENT: {
      TOTAL_COUNT: {
        INIT: 'APP_DASHBOARD_EVENT_TOTAL_COUNT_INIT',
        SUCCESS: 'APP_DASHBOARD_EVENT_TOTAL_COUNT_SUCCESS',
        ERROR: 'APP_DASHBOARD_EVENT_TOTAL_COUNT_ERROR',
      },
      TOTAL_BY_DATE: {
        INIT: 'APP_DASHBOARD_EVENT_TOTAL_INIT',
        SUCCESS: 'APP_DASHBOARD_EVENT_TOTAL_SUCCESS',
        ERROR: 'APP_DASHBOARD_EVENT_TOTAL_ERROR',
      },
      TOP_CLEAN_PUBLISHER: {
        INIT: 'APP_DASHBOARD_EVENT_TOP_CLEAN_INIT',
        SUCCESS: 'APP_DASHBOARD_EVENT_TOP_CLEAN_SUCCESS',
        ERROR: 'APP_DASHBOARD_EVENT_TOP_CLEAN_ERROR',
      },
      TOP_FRAUD_PUBLISHER: {
        INIT: 'APP_DASHBOARD_EVENT_TOP_FRAUD_INIT',
        SUCCESS: 'APP_DASHBOARD_EVENT_TOP_FRAUD_SUCCESS',
        ERROR: 'APP_DASHBOARD_EVENT_TOP_FRAUD_ERROR',
      },
      FRAUD_CATEGORY: {
        INIT: 'APP_DASHBOARD_EVENT_FRAUD_CATEGORY_INIT_',
        SUCCESS: 'APP_DASHBOARD_EVENT_FRAUD_CATEGORY_SUCCESS_',
        ERROR: 'APP_DASHBOARD_EVENT_FRAUD_CATEGORY_ERROR_',
      },
      EVENTS_FRAUD_BY_DATE: {
        INIT: 'APP_DASHBOARD_EVENT_EVENTS_FRAUD_BY_DATE_INIT',
        SUCCESS: 'APP_DASHBOARD_EVENT_EVENTS_FRAUD_BY_DATE_SUCCESS',
        ERROR: 'APP_DASHBOARD_EVENT_EVENTS_FRAUD_BY_DATE_ERROR',
      },
      EVENTS_FRAUD_BY_WEEK: {
        SUCCESS: 'APP_DASHBOARD_EVENTS_EVENTS_FRAUD_BY_WEEK_SUCCESS',
        INIT: 'APP_DASHBOARD_EVENTS_EVENTS_FRAUD_BY_WEEK_INIT',
        ERROR: 'APP_DASHBOARD_EVENTS_EVENTS_FRAUD_BY_WEEK_ERROR',
      },
      EVENTS_FRAUD_BY_MONTH: {
        INIT: 'APP_DASHBOARD_EVENTS_EVENTS_FRAUD_BY_MONTH_INIT',
        SUCCESS: 'APP_DASHBOARD_EVENTS_EVENTS_FRAUD_BY_MONTH_SUCCESS',
        ERROR: 'APP_DASHBOARD_EVENTS_EVENTS_FRAUD_BY_MONTH_ERROR',
      },
      EVENTS_FRAUD_BY_INSTALL_TYPE: {
        INIT: 'APP_DASHBOARD_EVENTS_EVENTS_FRAUD_BY_INSTALL_TYPE_INIT',
        SUCCESS: 'APP_DASHBOARD_EVENTS_EVENTS_FRAUD_BY_INSTALL_TYPE_SUCCESS',
        ERROR: 'APP_DASHBOARD_EVENTS_EVENTS_FRAUD_BY_INSTALL_TYPE_ERROR',
      },
      EVENTS_FRAUD_BY_PUBLISHER: {
        INIT: 'APP_DASHBOARD_EVENT_EVENTS_FRAUD_BY_PUBLISHER_INIT',
        SUCCESS: 'APP_DASHBOARD_EVENT_EVENTS_FRAUD_BY_PUBLISHER_SUCCESS',
        ERROR: 'APP_DASHBOARD_EVENT_EVENTS_FRAUD_BY_PUBLISHER_ERROR',
      },
      FRAUD_SUB_CATEGORY: {
        INIT: 'APP_DASHBOARD_EVENT_FRAUD_SUB_CATEGORY_INIT',
        SUCCESS: 'APP_DASHBOARD_EVENT_FRAUD_SUB_CATEGORY_SUCCESS',
        ERROR: 'APP_DASHBOARD_EVENT_FRAUD_SUB_CATEGORY_ERROR',
      },
      FRAUD_SUB_CATEGORY_PERCENT: {
        INIT: 'APP_DASHBOARD_EVENT_FRAUD_SUB_CATEGORY_PERCENT_INIT',
        SUCCESS: 'APP_DASHBOARD_EVENT_FRAUD_SUB_CATEGORY_PERCENT_SUCCESS',
        ERROR: 'APP_DASHBOARD_EVENT_FRAUD_SUB_CATEGORY_PERCENT_ERROR',
      },
      SUB_PUBLISHER: {
        INIT: 'APP_DASHBOARD_EVENT_SUB_PUBLISHER_INIT',
        SUCCESS: 'APP_DASHBOARD_EVENT_SUB_PUBLISHER_SUCCESS',
        ERROR: 'APP_DASHBOARD_EVENT_SUB_PUBLISHER_ERROR',
      },
      SUB_FRAUD_BY_DATE: {
        INIT: 'APP_DASHBOARD_EVENT_SUB_FRAUD_BY_DATE_INIT',
        SUCCESS: 'APP_DASHBOARD_EVENT_SUB_FRAUD_BY_DATE_SUCCESS',
        ERROR: 'APP_DASHBOARD_EVENT_SUB_FRAUD_BY_DATE_ERROR',
      },
      SUB_FRAUD_BY_PUBLISHER: {
        INIT: 'APP_DASHBOARD_EVENT_SUB_FRAUD_BY_PUBLISHER_INIT',
        SUCCESS: 'APP_DASHBOARD_EVENT_SUB_FRAUD_BY_PUBLISHER_SUCCESS',
        ERROR: 'APP_DASHBOARD_EVENT_SUB_FRAUD_BY_PUBLISHER_ERROR',
      },
      UNIQUE_EVENT_BY_DATE: {
        INIT: 'APP_DASHBOARD_EVENT_UNIQUE_DATE_COUNT_INIT',
        SUCCESS: 'APP_DASHBOARD_EVENT_UNIQUE _DATE_COUNT_SUCCESS',
        ERROR: 'APP_DASHBOARD_EVENT_UNIQUE _DATE_COUNT_ERROR',
      },
      UNIQUE_EVENT_BY_PUBLISHER: {
        INIT: 'APP_DASHBOARD_EVENT_UNIQUE_PUBLISHER_COUNT_INIT',
        SUCCESS: 'APP_DASHBOARD_EVENT_UNIQUE _PUBLISHER_COUNT_SUCCESS',
        ERROR: 'APP_DASHBOARD_EVENT_UNIQUE _PUBLISHER_COUNT_ERROR',
      },
      PRIMARY_ATTRIBUTION_BY_DATE: {
        INIT: 'APP_DASHBOARD_PRIMARY_ATTRIBUTION_DATE_COUNT_INIT',
        SUCCESS: 'APP_DASHBOARD_PRIMARY_ATTRIBUTION_DATE_COUNT_SUCCESS',
        ERROR: 'APP_DASHBOARD_PRIMARY_ATTRIBUTION_DATE_COUNT_ERROR',
      },
      PRIMARY_ATTRIBUTION_BY_PUBLISHER: {
        INIT: 'APP_DASHBOARD_PRIMARY_ATTRIBUTION_PUBLISHER_COUNT_INIT',
        SUCCESS: 'APP_DASHBOARD_PRIMARY_ATTRIBUTION_PUBLISHER_COUNT_SUCCESS',
        ERROR: 'APP_DASHBOARD_PRIMARY_ATTRIBUTION_PUBLISHER_COUNT_ERROR',
      },
      NON_PRIMARY_ATTRIBUTION_BY_DATE: {
        INIT: 'APP_DASHBOARD_NON_PRIMARY_ATTRIBUTION_DATE_COUNT_INIT',
        SUCCESS: 'APP_DASHBOARD_NON_PRIMARY_ATTRIBUTION_DATE_COUNT_SUCCESS',
        ERROR: 'APP_DASHBOARD_NON_PRIMARY_ATTRIBUTION_DATE_COUNT_ERROR',
      },
      NON_PRIMARY_ATTRIBUTION_BY_PUBLISHER: {
        INIT: 'APP_DASHBOARD_NON_PRIMARY_ATTRIBUTION_PUBLISHER_COUNT_INIT',
        SUCCESS:
          'APP_DASHBOARD_NON_PRIMARY_ATTRIBUTION_PUBLISHER_COUNT_SUCCESS',
        ERROR: 'APP_DASHBOARD_NON_PRIMARY_ATTRIBUTION_PUBLISHER_COUNT_ERROR',
      },
      CURRENT_MONTH_AFFILIATION_BY_DATE: {
        INIT: 'APP_DASHBOARD_CURRENT_MONTH_AFFILIATION_DATE_COUNT_INIT',
        SUCCESS: 'APP_DASHBOARD_CURRENT_MONTH_AFFILIATION_DATE_COUNT_SUCCESS',
        ERROR: 'APP_DASHBOARD_CURRENT_MONTH_AFFILIATION_DATE_COUNT_ERROR',
      },
      CURRENT_MONTH_AFFILIATION_BY_PUBLISHER: {
        INIT: 'APP_DASHBOARD_CURRENT_MONTH_AFFILIATION_PUBLISHER_COUNT_INIT',
        SUCCESS:
          'APP_DASHBOARD_CURRENT_MONTH_AFFILIATION_PUBLISHER_COUNT_SUCCESS',
        ERROR: 'APP_DASHBOARD_CURRENT_MONTH_AFFILIATION_PUBLISHER_COUNT_ERROR',
      },
    },
    RE_CLICK: {
      TOTAL_COUNT: {
        INIT: 'APP_DASHBOARD_RE_CLICKS_TOTAL_COUNT_INIT',
        SUCCESS: 'APP_DASHBOARD_RE_CLICKS_TOTAL_COUNT_SUCCESS',
        ERROR: 'APP_DASHBOARD_RE_CLICKS_TOTAL_COUNT_ERROR',
      },
      TOTAL_BY_DATE: {
        INIT: 'APP_DASHBOARD_RE_CLICKS_TOTAL_INIT',
        SUCCESS: 'APP_DASHBOARD_RE_CLICKS_TOTAL_SUCCESS',
        ERROR: 'APP_DASHBOARD_RE_CLICKS_TOTAL_ERROR',
      },
      TOP_CLEAN_PUBLISHER: {
        INIT: 'APP_DASHBOARD_RE_CLICKS_TOP_CLEAN_INIT',
        SUCCESS: 'APP_DASHBOARD_RE_CLICKS_TOP_CLEAN_SUCCESS',
        ERROR: 'APP_DASHBOARD_RE_CLICKS_TOP_CLEAN_ERROR',
      },
      TOP_FRAUD_PUBLISHER: {
        INIT: 'APP_DASHBOARD_RE_CLICKS_TOP_FRAUD_INIT',
        SUCCESS: 'APP_DASHBOARD_RE_CLICKS_TOP_FRAUD_SUCCESS',
        ERROR: 'APP_DASHBOARD_RE_CLICKS_TOP_FRAUD_ERROR',
      },
      FRAUD_CATEGORY: {
        INIT: 'APP_DASHBOARD_RE_CLICKS_FRAUD_CATEGORY_INIT_',
        SUCCESS: 'APP_DASHBOARD_RE_CLICKS_FRAUD_CATEGORY_SUCCESS_',
        ERROR: 'APP_DASHBOARD_RE_CLICKS_FRAUD_CATEGORY_ERROR_',
      },
      EVENTS_FRAUD_BY_DATE: {
        INIT: 'APP_DASHBOARD_RE_CLICKS_EVENTS_FRAUD_BY_DATE_INIT',
        SUCCESS: 'APP_DASHBOARD_RE_CLICKS_EVENTS_FRAUD_BY_DATE_SUCCESS',
        ERROR: 'APP_DASHBOARD_RE_CLICKS_EVENTS_FRAUD_BY_DATE_ERROR',
      },
      EVENTS_FRAUD_BY_WEEK: {
        SUCCESS: 'APP_DASHBOARD_RE_CLICKS_EVENTS_FRAUD_BY_WEEK_SUCCESS',
        INIT: 'APP_DASHBOARD_RE_CLICKS_EVENTS_FRAUD_BY_WEEK_INIT',
        ERROR: 'APP_DASHBOARD_RE_CLICKS_EVENTS_FRAUD_BY_WEEK_ERROR',
      },
      EVENTS_FRAUD_BY_MONTH: {
        INIT: 'APP_DASHBOARD_RE_CLICKS_EVENTS_FRAUD_BY_MONTH_INIT',
        SUCCESS: 'APP_DASHBOARD_RE_CLICKS_EVENTS_FRAUD_BY_MONTH_SUCCESS',
        ERROR: 'APP_DASHBOARD_RE_CLICKS_EVENTS_FRAUD_BY_MONTH_ERROR',
      },
      EVENTS_FRAUD_BY_INSTALL_TYPE: {
        INIT: 'APP_DASHBOARD_RE_CLICKS_EVENTS_FRAUD_BY_INSTALL_TYPE_INIT',
        SUCCESS: 'APP_DASHBOARD_RE_CLICKS_EVENTS_FRAUD_BY_INSTALL_TYPE_SUCCESS',
        ERROR: 'APP_DASHBOARD_RE_CLICKS_EVENTS_FRAUD_BY_INSTALL_TYPE_ERROR',
      },
      EVENTS_FRAUD_BY_PUBLISHER: {
        INIT: 'APP_DASHBOARD_RE_CLICKS_EVENTS_FRAUD_BY_PUBLISHER_INIT',
        SUCCESS: 'APP_DASHBOARD_RE_CLICKS_EVENTS_FRAUD_BY_PUBLISHER_SUCCESS',
        ERROR: 'APP_DASHBOARD_RE_CLICKS_EVENTS_FRAUD_BY_PUBLISHER_ERROR',
      },
      FRAUD_SUB_CATEGORY: {
        INIT: 'APP_DASHBOARD_RE_CLICKS_FRAUD_SUB_CATEGORY_INIT',
        SUCCESS: 'APP_DASHBOARD_RE_CLICKS_FRAUD_SUB_CATEGORY_SUCCESS',
        ERROR: 'APP_DASHBOARD_RE_CLICKS_FRAUD_SUB_CATEGORY_ERROR',
      },
      FRAUD_SUB_CATEGORY_PERCENT: {
        INIT: 'APP_DASHBOARD_RE_CLICKS_FRAUD_SUB_CATEGORY_PERCENT_INIT',
        SUCCESS: 'APP_DASHBOARD_RE_CLICKS_FRAUD_SUB_CATEGORY_PERCENT_SUCCESS',
        ERROR: 'APP_DASHBOARD_RE_CLICKS_FRAUD_SUB_CATEGORY_PERCENT_ERROR',
      },
      SUB_PUBLISHER: {
        INIT: 'APP_DASHBOARD_RE_CLICKS_SUB_PUBLISHER_INIT',
        SUCCESS: 'APP_DASHBOARD_RE_CLICKS_SUB_PUBLISHER_SUCCESS',
        ERROR: 'APP_DASHBOARD_RE_CLICKS_SUB_PUBLISHER_ERROR',
      },
      SUB_FRAUD_BY_DATE: {
        INIT: 'APP_DASHBOARD_RE_CLICKS_SUB_FRAUD_BY_DATE_INIT',
        SUCCESS: 'APP_DASHBOARD_RE_CLICKS_SUB_FRAUD_BY_DATE_SUCCESS',
        ERROR: 'APP_DASHBOARD_RE_CLICKS_SUB_FRAUD_BY_DATE_ERROR',
      },
      SUB_FRAUD_BY_PUBLISHER: {
        INIT: 'APP_DASHBOARD_RE_CLICKS_SUB_FRAUD_BY_PUBLISHER_INIT',
        SUCCESS: 'APP_DASHBOARD_RE_CLICKS_SUB_FRAUD_BY_PUBLISHER_SUCCESS',
        ERROR: 'APP_DASHBOARD_RE_CLICKS_SUB_FRAUD_BY_PUBLISHER_ERROR',
      },
    },
    RE_EVENT: {
      TOTAL_COUNT: {
        INIT: 'APP_DASHBOARDS_RE_EVENTSTOTAL_COUNT_INIT',
        SUCCESS: 'APP_DASHBOARDS_RE_EVENTS_TOTAL_COUNT_SUCCESS',
        ERROR: 'APP_DASHBOARDS_RE_EVENTS_TOTAL_COUNT_ERROR',
      },
      TOTAL_BY_DATE: {
        INIT: 'APP_DASHBOARDS_RE_EVENTS_TOTAL_INIT',
        SUCCESS: 'APP_DASHBOARDS_RE_EVENTS_TOTAL_SUCCESS',
        ERROR: 'APP_DASHBOARDS_RE_EVENTS_TOTAL_ERROR',
      },
      EVENTS_FRAUD_BY_WEEK: {
        SUCCESS: 'APP_DASHBOARD_RE_EVENTS_EVENTS_FRAUD_BY_WEEK_SUCCESS',
        INIT: 'APP_DASHBOARD_RE_EVENTS_EVENTS_FRAUD_BY_WEEK_INIT',
        ERROR: 'APP_DASHBOARD_RE_EVENTS_EVENTS_FRAUD_BY_WEEK_ERROR',
      },
      EVENTS_FRAUD_BY_MONTH: {
        INIT: 'APP_DASHBOARD_RE_EVENTS_EVENTS_FRAUD_BY_MONTH_INIT',
        SUCCESS: 'APP_DASHBOARD_RE_EVENTS_EVENTS_FRAUD_BY_MONTH_SUCCESS',
        ERROR: 'APP_DASHBOARD_RE_EVENTS_EVENTS_FRAUD_BY_MONTH_ERROR',
      },
      EVENTS_FRAUD_BY_INSTALL_TYPE: {
        INIT: 'APP_DASHBOARD_RE_EVENTS_EVENTS_FRAUD_BY_INSTALL_TYPE_INIT',
        SUCCESS: 'APP_DASHBOARD_RE_EVENTS_EVENTS_FRAUD_BY_INSTALL_TYPE_SUCCESS',
        ERROR: 'APP_DASHBOARD_RE_EVENTS_EVENTS_FRAUD_BY_INSTALL_TYPE_ERROR',
      },
      TOP_CLEAN_PUBLISHER: {
        INIT: 'APP_DASHBOARDS_RE_EVENTS_TOP_CLEAN_INIT',
        SUCCESS: 'APP_DASHBOARDS_RE_EVENTS_TOP_CLEAN_SUCCESS',
        ERROR: 'APP_DASHBOARDS_RE_EVENTS_TOP_CLEAN_ERROR',
      },
      TOP_FRAUD_PUBLISHER: {
        INIT: 'APP_DASHBOARDS_RE_EVENTS_TOP_FRAUD_INIT',
        SUCCESS: 'APP_DASHBOARDS_RE_EVENTS_TOP_FRAUD_SUCCESS',
        ERROR: 'APP_DASHBOARDS_RE_EVENTS_TOP_FRAUD_ERROR',
      },
      FRAUD_CATEGORY: {
        INIT: 'APP_DASHBOARDS_RE_EVENTS_FRAUD_CATEGORY_INIT_',
        SUCCESS: 'APP_DASHBOARDS_RE_EVENTS_FRAUD_CATEGORY_SUCCESS_',
        ERROR: 'APP_DASHBOARDS_RE_EVENTS_FRAUD_CATEGORY_ERROR_',
      },
      EVENTS_FRAUD_BY_DATE: {
        INIT: 'APP_DASHBOARDS_RE_EVENTS_EVENTS_FRAUD_BY_DATE_INIT',
        SUCCESS: 'APP_DASHBOARDS_RE_EVENTS_EVENTS_FRAUD_BY_DATE_SUCCESS',
        ERROR: 'APP_DASHBOARDS_RE_EVENTS_EVENTS_FRAUD_BY_DATE_ERROR',
      },
      EVENTS_FRAUD_BY_PUBLISHER: {
        INIT: 'APP_DASHBOARDS_RE_EVENTS_EVENTS_FRAUD_BY_PUBLISHER_INIT',
        SUCCESS: 'APP_DASHBOARDS_RE_EVENTS_EVENTS_FRAUD_BY_PUBLISHER_SUCCESS',
        ERROR: 'APP_DASHBOARDS_RE_EVENTS_EVENTS_FRAUD_BY_PUBLISHER_ERROR',
      },
      FRAUD_SUB_CATEGORY: {
        INIT: 'APP_DASHBOARDS_RE_EVENTS_FRAUD_SUB_CATEGORY_INIT',
        SUCCESS: 'APP_DASHBOARDS_RE_EVENTS_FRAUD_SUB_CATEGORY_SUCCESS',
        ERROR: 'APP_DASHBOARDS_RE_EVENTS_FRAUD_SUB_CATEGORY_ERROR',
      },
      FRAUD_SUB_CATEGORY_PERCENT: {
        INIT: 'APP_DASHBOARDS_RE_EVENTS_FRAUD_SUB_CATEGORY_PERCENT_INIT',
        SUCCESS: 'APP_DASHBOARDS_RE_EVENTS_FRAUD_SUB_CATEGORY_PERCENT_SUCCESS',
        ERROR: 'APP_DASHBOARDS_RE_EVENTS_FRAUD_SUB_CATEGORY_PERCENT_ERROR',
      },
      SUB_PUBLISHER: {
        INIT: 'APP_DASHBOARDS_RE_EVENTS_SUB_PUBLISHER_INIT',
        SUCCESS: 'APP_DASHBOARDS_RE_EVENTS_SUB_PUBLISHER_SUCCESS',
        ERROR: 'APP_DASHBOARDS_RE_EVENTS_SUB_PUBLISHER_ERROR',
      },
      SUB_FRAUD_BY_DATE: {
        INIT: 'APP_DASHBOARDS_RE_EVENTS_SUB_FRAUD_BY_DATE_INIT',
        SUCCESS: 'APP_DASHBOARDS_RE_EVENTS_SUB_FRAUD_BY_DATE_SUCCESS',
        ERROR: 'APP_DASHBOARDS_RE_EVENTS_SUB_FRAUD_BY_DATE_ERROR',
      },
      SUB_FRAUD_BY_PUBLISHER: {
        INIT: 'APP_DASHBOARDS_RE_EVENTS_SUB_FRAUD_BY_PUBLISHER_INIT',
        SUCCESS: 'APP_DASHBOARDS_RE_EVENTS_SUB_FRAUD_BY_PUBLISHER_SUCCESS',
        ERROR: 'APP_DASHBOARDS_RE_EVENTS_SUB_FRAUD_BY_PUBLISHER_ERROR',
      },
    }, 
    CLICK_IMPRESSION: {
      TOTAL_COUNT: {
        INIT: 'APP_DASHBOARD_CLICK_IMPRESSION_TOTAL_COUNT_INIT',
        SUCCESS: 'APP_DASHBOARD_CLICK_IMPRESSION_TOTAL_COUNT_SUCCESS',
        ERROR: 'APP_DASHBOARD_CLICK_IMPRESSION_TOTAL_COUNT_ERROR',
      },
      TOTAL_BY_DATE: {
        INIT: 'APP_DASHBOARD_CLICK_IMPRESSION_TOTAL_INIT',
        SUCCESS: 'APP_DASHBOARD_CLICK_IMPRESSION_TOTAL_SUCCESS',
        ERROR: 'APP_DASHBOARD_CLICK_IMPRESSION_TOTAL_ERROR',
      },
      TOP_CLEAN_PUBLISHER: {
        INIT: 'APP_DASHBOARD_CLICK_IMPRESSION_TOP_CLEAN_INIT',
        SUCCESS: 'APP_DASHBOARD_CLICK_IMPRESSION_TOP_CLEAN_SUCCESS',
        ERROR: 'APP_DASHBOARD_CLICK_IMPRESSION_TOP_CLEAN_ERROR',
      },
      TOP_FRAUD_PUBLISHER: {
        INIT: 'APP_DASHBOARD_CLICK_IMPRESSION_TOP_FRAUD_INIT',
        SUCCESS: 'APP_DASHBOARD_CLICK_IMPRESSION_TOP_FRAUD_SUCCESS',
        ERROR: 'APP_DASHBOARD_CLICK_IMPRESSION_TOP_FRAUD_ERROR',
      },
      FRAUD_CATEGORY: {
        INIT: 'APP_DASHBOARD_CLICK_IMPRESSION_FRAUD_CATEGORY_INIT_',
        SUCCESS: 'APP_DASHBOARD_CLICK_IMPRESSION_FRAUD_CATEGORY_SUCCESS_',
        ERROR: 'APP_DASHBOARD_CLICK_IMPRESSION_FRAUD_CATEGORY_ERROR_',
      },
      EVENTS_FRAUD_BY_DATE: {
        INIT: 'APP_DASHBOARD_CLICK_IMPRESSION_EVENTS_FRAUD_BY_DATE_INIT',
        SUCCESS: 'APP_DASHBOARD_CLICK_IMPRESSION_EVENTS_FRAUD_BY_DATE_SUCCESS',
        ERROR: 'APP_DASHBOARD_CLICK_IMPRESSION_EVENTS_FRAUD_BY_DATE_ERROR',
      },
      EVENTS_FRAUD_BY_PUBLISHER: {
        INIT: 'APP_DASHBOARD_CLICK_IMPRESSION_EVENTS_FRAUD_BY_PUBLISHER_INIT',
        SUCCESS: 'APP_DASHBOARD_CLICK_IMPRESSION_EVENTS_FRAUD_BY_PUBLISHER_SUCCESS',
        ERROR: 'APP_DASHBOARD_CLICK_IMPRESSION_EVENTS_FRAUD_BY_PUBLISHER_ERROR',
      },
      EVENTS_FRAUD_BY_WEEK: {
        SUCCESS: 'APP_DASHBOARD_CLICK_IMPRESSION_EVENTS_FRAUD_BY_WEEK_SUCCESS',
        INIT: 'APP_DASHBOARD_CLICK_IMPRESSION_EVENTS_FRAUD_BY_WEEK_INIT',
        ERROR: 'APP_DASHBOARD_CLICK_IMPRESSION_EVENTS_FRAUD_BY_WEEK_ERROR',
      },
      EVENTS_FRAUD_BY_MONTH: {
        INIT: 'APP_DASHBOARD_CLICK_IMPRESSION_EVENTS_FRAUD_BY_MONTH_INIT',
        SUCCESS: 'APP_DASHBOARD_CLICK_IMPRESSION_EVENTS_FRAUD_BY_MONTH_SUCCESS',
        ERROR: 'APP_DASHBOARD_CLICK_IMPRESSION_EVENTS_FRAUD_BY_MONTH_ERROR',
      },
      EVENTS_FRAUD_BY_CLICK_IMPRESSION_TYPE: {
        INIT: 'APP_DASHBOARD_CLICK_IMPRESSION_EVENTS_FRAUD_BY_CLICK_IMPRESSION_TYPE_INIT',
        SUCCESS: 'APP_DASHBOARD_CLICK_IMPRESSION_EVENTS_FRAUD_BY_CLICK_IMPRESSION_TYPE_SUCCESS',
        ERROR: 'APP_DASHBOARD_CLICK_IMPRESSION_EVENTS_FRAUD_BY_CLICK_IMPRESSION_TYPE_ERROR',
      },
      FRAUD_SUB_CATEGORY: {
        INIT: 'APP_DASHBOARD_CLICK_IMPRESSION_FRAUD_CATEGORY_INIT',
        SUCCESS: 'APP_DASHBOARD_CLICK_IMPRESSION_FRAUD_CATEGORY_SUCCESS',
        ERROR: 'APP_DASHBOARD_CLICK_IMPRESSION_FRAUD_CATEGORY_ERROR',
      },
      FRAUD_SUB_CATEGORY_PERCENT: {
        INIT: 'APP_DASHBOARD_CLICK_IMPRESSION_FRAUD_SUB_CATEGORY_PERCENT_INIT',
        SUCCESS: 'APP_DASHBOARD_CLICK_IMPRESSION_FRAUD_SUB_CATEGORY_PERCENT_SUCCESS',
        ERROR: 'APP_DASHBOARD_CLICK_IMPRESSION_FRAUD_SUB_CATEGORY_PERCENT_ERROR',
      },
      SUB_PUBLISHER: {
        INIT: 'APP_DASHBOARD_CLICK_IMPRESSION_SUB_PUBLISHER_INIT',
        SUCCESS: 'APP_DASHBOARD_CLICK_IMPRESSION_SUB_PUBLISHER_SUCCESS',
        ERROR: 'APP_DASHBOARD_CLICK_IMPRESSION_SUB_PUBLISHER_ERROR',
      },
      SUB_FRAUD_BY_DATE: {
        INIT: 'APP_DASHBOARD_CLICK_IMPRESSION_SUB_FRAUD_BY_DATE_INIT',
        SUCCESS: 'APP_DASHBOARD_CLICK_IMPRESSION_SUB_FRAUD_BY_DATE_SUCCESS',
        ERROR: 'APP_DASHBOARD_CLICK_IMPRESSION_SUB_FRAUD_BY_DATE_ERROR',
      },
      SUB_FRAUD_BY_PUBLISHER: {
        INIT: 'APP_DASHBOARD_CLICK_IMPRESSION_SUB_FRAUD_BY_PUBLISHER_INIT',
        SUCCESS: 'APP_DASHBOARD_CLICK_IMPRESSION_SUB_FRAUD_BY_PUBLISHER_SUCCESS',
        ERROR: 'APP_DASHBOARD_CLICK_IMPRESSION_SUB_FRAUD_BY_PUBLISHER_ERROR',
      },
    }
  },

  REPORT: {
    EVENT: {
      GET_TABLE: {
        INIT: 'APP_EVENT_REPORT_GET_TABLE_INIT',
        SUCCESS: 'APP_EVENT_REPORT_GET_TABLE_SUCCESS',
        ERROR: 'APP_EVENT_REPORT_GET_TABLE_ERROR',
        CLEAR: 'APP_EVENT_REPORT_GET_TABLE_CLEAR',
        REFETCH: 'APP_EVENT_REPORT_GET_TABLE_REFETCH',
      },
      GENERATE_REPORT: {
        INIT: 'APP_EVENT_REPORT_DOWNLOAD_INIT',
        SUCCESS: 'APP_EVENT_REPORT_DOWNLOAD_SUCCESS',
        ERROR: 'APP_EVENT_REPORT_DOWNLOAD_ERROR',
        CLEAR: 'APP_EVENT_REPORT_DOWNLOAD_CLEAR',
      },
    },
    INSTALL: {
      GET_TABLE: {
        INIT: 'APP_INSTALL_REPORT_GET_TABLE_INIT',
        SUCCESS: 'APP_INSTALL_REPORT_GET_TABLE_SUCCESS',
        ERROR: 'APP_INSTALL_REPORT_GET_TABLE_ERROR',
        CLEAR: 'APP_INSTALL_REPORT_GET_TABLE_CLEAR',
        REFETCH: 'APP_INSTALL_REPORT_GET_TABLE_REFETCH',
      },
      GENERATE_REPORT: {
        INIT: 'APP_INSTALL_REPORT_DOWNLOAD_INIT',
        SUCCESS: 'APP_INSTALL_REPORT_DOWNLOAD_SUCCESS',
        ERROR: 'APP_INSTALL_REPORT_DOWNLOAD_ERROR',
        CLEAR: 'APP_INSTALL_REPORT_DOWNLOAD_CLEAR',
      },
    },
  },

  FILTER: {
    PUBLISHER: {
      INIT: '51961_APP_FILTER_PUBLISHER_INIT',
      SUCCESS: '51961_APP_FILTER_SUB_PUBLISHER_SUCCESS',
      ERROR: '51961_APP_FILTER_SUB_PUBLISHER_ERROR',
    },
    AGENCY: {
      INIT: '51961_APP_FILTER_AGENCY_INIT',
      SUCCESS: '51961_APP_FILTER_SUB_AGENCY_SUCCESS',
      ERROR: '51961_APP_FILTER_SUB_AGENCY_ERROR',
    },
    EVENT_TYPE: {
      INIT: '3403_EVENT_TYPE_INIT',
      SUCCESS: '3403_EVENT_TYPE_SUCCESS',
      ERROR: '3403_EVENT_TYPE_ERROR',
    },
  },
  COLOR: {
    INIT: 'COLORS_INIT',
    SUCCESS: 'COLORS_SUCCESS',
    ERROR: 'COLORS_ERROR',
  },
  PUBLISHER_REPORT: {
    GET_TABLE: {
      INIT: '3403_GET_TABLE_INIT',
      SUCCESS: '3403_GET_TABLE_SUCCESS',
      ERROR: '3403_GET_TABLE_ERROR',
    },
    GET_ONE: {
      INIT: '3403_GET_ONE_INIT',
      SUCCESS: '3403_GET_ONE_SUCCESS',
      ERROR: '3403_GET_ONE_ERROR',
    },
    DEL: {
      INIT: '3403_DEL_INIT',
      SUCCESS: '3403_DEL_SUCCESS',
      ERROR: '3403_DEL_ERROR',
      CLEAR: '3403_DEL_CLEAR',
    },
    ADD: {
      INIT: '3403_ADD_INIT',
      SUCCESS: '3403_ADD_SUCCESS',
      ERROR: '3403_ADD_ERROR',
      CLEAR: '3403_ADD_CLEAR',
    },
    DROPDOWN: {
      VARIATION_INSTALL: {
        INIT: '3403_VARIATION_INSTALL_INIT',
        SUCCESS: '3403_VARIATION_INSTALL_SUCCESS',
        ERROR: '3403_VARIATION_INSTALL_ERROR',
      },
      VARIATION_EVENT: {
        INIT: '3403_VARIATION_EVENT_INIT',
        SUCCESS: '3403_VARIATION_EVENT_SUCCESS',
        ERROR: '3403_VARIATION_EVENT_ERROR',
      },
      FRAUD_SUB_CATEGORY_INSTALL: {
        INIT: '3403_FSC_INSTALL_INIT',
        SUCCESS: '3403_FSC_INSTALL_SUCCESS',
        ERROR: '3403_FSC_INSTALL_ERROR',
      },
      FRAUD_SUB_CATEGORY_EVENT: {
        INIT: '3403_FSC_EVENT_INIT',
        SUCCESS: '3403_FSC_EVENT_SUCCESS',
        ERROR: '3403_FSC_EVENT_ERROR',
      },
    },
  },

  INCENT_DETAILS: {
    GET_TABLE: {
      INIT: 'APP_INCENT_DETAIL_GET_TABLE_INIT',
      SUCCESS: 'APP_INCENT_DETAIL_GET_TABLE_SUCCESS',
      ERROR: 'APP_INCENT_DETAIL_GET_TABLE_ERROR',
      CLEAR: 'APP_INCENT_CLEAR',
    },
    DOWNLOAD_TABLE: {
      INIT: 'APP_INCENT_DETAIL_DOWNLOAD_INIT',
      SUCCESS: 'APP_INCENT_DETAIL_DOWNLOAD_SUCCESS',
      ERROR: 'APP_INCENT_DETAIL_DOWNLOAD_ERROR',
      CLEAR: 'APP_INCENT_DETAIL_DOWNLOAD_CLEAR',
    },
  },

  PUBLISHER_SUMMARY: {
    GET_TABLE: {
      INIT: 'APP_PUBLISHER_SUMMARY_GET_TABLE_INIT',
      SUCCESS: 'APP_PUBLISHER_SUMMARY_GET_TABLE_SUCCESS',
      ERROR: 'APP_PUBLISHER_SUMMARY_GET_TABLE_ERROR',
      CLEAR: 'APP_PUBLISHER_SUMMARY_CLEAR',
    },
    DOWNLOAD_TABLE: {
      INIT: 'APP_PUBLISHER_SUMMARY_DOWNLOAD_INIT',
      SUCCESS: 'APP_PUBLISHER_SUMMARY_DOWNLOAD_SUCCESS',
      ERROR: 'APP_PUBLISHER_SUMMARY_DOWNLOAD_ERROR',
      CLEAR: 'APP_PUBLISHER_SUMMARY_DOWNLOAD_CLEAR',
    },
  },

  ALERT_PAGE: {
    GET_TABLE: {
      INIT: 'APP_ALERT_PAGE_GET_TABLE_INIT',
      SUCCESS: 'APP_ALERT_PAGE_GET_TABLE_SUCCESS',
      ERROR: 'APP_ALERT_PAGE_GET_TABLE_ERROR',
      CLEAR: 'APP_ALERT_PAGE_GET_TABLE_CLEAR',
    },
    POST_TABLE: {
      INIT: 'APP_ALERT_PAGE_POST_TABLE_INIT',
      SUCCESS: 'APP_ALERT_PAGE_POST_TABLE_SUCCESS',
      ERROR: 'APP_ALERT_PAGE_POST_TABLE_ERROR',
      CLEAR: 'APP_ALERT_PAGE_POST_TABLE_CLEAR',
    },
  },
  WHITELIST: {
    GET_TABLE: {
      INIT: '6541_APP_WHITELIST_GET_TABLE_INIT',
      SUCCESS: '6541_APP_WHITELIST_GET_TABLE_SUCCESS',
      ERROR: '6541_APP_WHITELIST_GET_TABLE_ERROR',
    },
    ADD: {
      INIT: '6541_APP_WHITELIST_ADD_INIT',
      SUCCESS: '6541_APP_WHITELIST_ADD_SUCCESS',
      ERROR: '6541_APP_WHITELIST_ADD_ERROR',
      CLEAR: '6541_APP_WHITELIST_ADD_CLEAR',
    },
    EDIT: {
      INIT: '6541_APP_WHITELIST_EDIT_INIT',
      SUCCESS: '6541_APP_WHITELIST_EDIT_SUCCESS',
      ERROR: '6541_APP_WHITELIST_EDIT_ERROR',
      CLEAR: '6541_APP_WHITELIST_EDIT_CLEAR',
    },
    DELETE: {
      INIT: '6541_APP_WHITELIST_DELETE_INIT',
      SUCCESS: '6541_APP_WHITELIST_DELETE_SUCCESS',
      ERROR: '6541_APP_WHITELIST_DELETE_ERROR',
      CLEAR: '6541_APP_WHITELIST_DELETE_CLEAR',
    },
    CAMPAIGN: {
      INIT: '6541_APP_WHITELIST_DROPDOWN_CAMPAIGN_INIT',
      SUCCESS: '6541_APP_WHITELIST_DROPDOWN_CAMPAIGN_SUCCESS',
      ERROR: '6541_APP_WHITELIST_DROPDOWN_CAMPAIGN_ERROR',
    },
    PUBLISHER: {
      INIT: '6541_APP_WHITELIST_DROPDOWN_PUBLISHER_INIT',
      SUCCESS: '6541_APP_WHITELIST_DROPDOWN_PUBLISHER_SUCCESS',
      ERROR: '6541_APP_WHITELIST_DROPDOWN_PUBLISHER_ERROR',
    },
    SUBPUBLISHER: {
      INIT: '6541_APP_WHITELIST_DROPDOWN_SUBPUBLISHER_INIT',
      SUCCESS: '6541_APP_WHITELIST_DROPDOWN_SUBPUBLISHER_SUCCESS',
      ERROR: '6541_APP_WHITELIST_DROPDOWN_SUBPUBLISHER_ERROR',
    },
    FRAUD_CATEGORY: {
      INIT: '6541_APP_WHITELIST_DROPDOWN_FRAUD_INIT',
      SUCCESS: '6541_APP_WHITELIST_DROPDOWN_FRAUD_SUCCESS',
      ERROR: '6541_APP_WHITELIST_DROPDOWN_FRAUD_ERROR',
    },
    FIELDS: {
      INIT: '6541_APP_WHITELIST_DROPDOWN_FIELDS_INIT',
      SUCCESS: '6541_APP_WHITELIST_DROPDOWN_FIELDS_SUCCESS',
      ERROR: '6541_APP_WHITELIST_DROPDOWN_FIELDS_ERROR',
    },
  },
  DASHBOARD_DOWNLOAD: {
    TOP_CLEAN_PUBLISHER: {
      INIT: 'APP_DASHBOARD_DOWNLOAD_TOP_CLEAN_INIT',
      SUCCESS: 'APP_DASHBOARD_DOWNLOAD_TOP_CLEAN_SUCCESS',
      ERROR: 'APP_DASHBOARD_DOWNLOAD_TOP_CLEAN_ERROR',
      CLEAR: 'APP_DASHBOARD_DOWNLOAD_TOP_CLEAN_CLEAR',
    },
    TOP_FRAUD_PUBLISHER: {
      INIT: 'APP_DASHBOARD_DOWNLOAD_TOP_FRAUD_INIT',
      SUCCESS: 'APP_DASHBOARD_DOWNLOAD_TOP_FRAUD_SUCCESS',
      ERROR: 'APP_DASHBOARD_DOWNLOAD_TOP_FRAUD_ERROR',
      CLEAR: 'APP_DASHBOARD_DOWNLOAD_TOP_FRAUD_CLEAR',
    },
    FRAUD_DATE: {
      INIT: 'APP_DASHBOARD_DOWNLOAD_FRAUD_DATE_INIT',
      SUCCESS: 'APP_DASHBOARD_DOWNLOAD_FRAUD_DATE_SUCCESS',
      ERROR: 'APP_DASHBOARD_DOWNLOAD_FRAUD_DATE_ERROR',
      CLEAR: 'APP_DASHBOARD_DOWNLOAD_FRAUD_DATE_CLEAR',
    },
    FRAUD_PUBLISHER: {
      INIT: 'APP_DASHBOARD_DOWNLOAD_FRAUD_PUBLISHER_INIT',
      SUCCESS: 'APP_DASHBOARD_DOWNLOAD_FRAUD_PUBLISHER_SUCCESS',
      ERROR: 'APP_DASHBOARD_DOWNLOAD_FRAUD_PUBLISHER_ERROR',
      CLEAR: 'APP_DASHBOARD_DOWNLOAD_FRAUD_PUBLISHER_CLEAR',
    },
    FRAUD_TOTAL_DATE: {
      INIT: 'APP_DASHBOARD_DOWNLOAD_FRAUD_TOTAL_DATE_INIT',
      SUCCESS: 'APP_DASHBOARD_DOWNLOAD_FRAUD_TOTAL_DATE_SUCCESS',
      ERROR: 'APP_DASHBOARD_DOWNLOAD_FRAUD_TOTAL_DATE_ERROR',
      CLEAR: 'APP_DASHBOARD_DOWNLOAD_FRAUD_TOTAL_DATE_CLEAR',
    },
    FRAUD_TOTAL_PUBLISHER: {
      INIT: 'APP_DASHBOARD_DOWNLOAD_FRAUD_TOTAL_PUBLISHER_INIT',
      SUCCESS: 'APP_DASHBOARD_DOWNLOAD_FRAUD_TOTAL_PUBLISHER_SUCCESS',
      ERROR: 'APP_DASHBOARD_DOWNLOAD_FRAUD_TOTAL_PUBLISHER_ERROR',
      CLEAR: 'APP_DASHBOARD_DOWNLOAD_FRAUD_TOTAL_PUBLISHER_CLEAR',
    },
    FRAUD_TOTAL_SUB_PUBLISHER: {
      INIT: 'APP_DASHBOARD_DOWNLOAD_FRAUD_TOTAL_SUB_PUBLISHER_INIT',
      SUCCESS: 'APP_DASHBOARD_DOWNLOAD_FRAUD_TOTAL_SUB_PUBLISHER_SUCCESS',
      ERROR: 'APP_DASHBOARD_DOWNLOAD_FRAUD_TOTAL_SUB_PUBLISHER_ERROR',
      CLEAR: 'APP_DASHBOARD_DOWNLOAD_FRAUD_TOTAL_SUB_PUBLISHER_CLEAR',
    },
    FRAUD_SUB_DATE: {
      INIT: 'APP_DASHBOARD_DOWNLOAD_FRAUD_SUB_DATE_INIT',
      SUCCESS: 'APP_DASHBOARD_DOWNLOAD_FRAUD_SUB_DATE_SUCCESS',
      ERROR: 'APP_DASHBOARD_DOWNLOAD_FRAUD_SUB_DATE_ERROR',
      CLEAR: 'APP_DASHBOARD_DOWNLOAD_FRAUD_SUB_DATE_CLEAR',
    },
    FRAUD_SUB_PUBLISHER: {
      INIT: 'APP_DASHBOARD_DOWNLOAD_FRAUD_SUB_PUBLISHER_INIT',
      SUCCESS: 'APP_DASHBOARD_DOWNLOAD_FRAUD_SUB_PUBLISHER_SUCCESS',
      ERROR: 'APP_DASHBOARD_DOWNLOAD_FRAUD_SUB_PUBLISHER_ERROR',
      CLEAR: 'APP_DASHBOARD_DOWNLOAD_FRAUD_SUB_PUBLISHER_CLEAR',
    },
    UNIQUE_EVENTS_DATE: {
      INIT: 'APP_DASHBOARD_DOWNLOAD_UNIQUE_EVENTS_DATE_INIT',
      SUCCESS: 'APP_DASHBOARD_DOWNLOAD_UNIQUE_EVENTS_DATE_SUCCESS',
      ERROR: 'APP_DASHBOARD_DOWNLOAD_UNIQUE_EVENTS_DATE_ERROR',
      CLEAR: 'APP_DASHBOARD_DOWNLOAD_UNIQUE_EVENTS_DATE_CLEAR',
    },
    UNIQUE_EVENTS_PUBLISHER: {
      INIT: 'APP_DASHBOARD_DOWNLOAD_UNIQUE_EVENTS_PUBLISHER_INIT',
      SUCCESS: 'APP_DASHBOARD_DOWNLOAD_UNIQUE_EVENTS_PUBLISHER_SUCCESS',
      ERROR: 'APP_DASHBOARD_DOWNLOAD_UNIQUE_EVENTS_PUBLISHER_ERROR',
      CLEAR: 'APP_DASHBOARD_DOWNLOAD_UNIQUE_EVENTS_PUBLISHER_CLEAR',
    },
    CURRENT_MONTH_DATE: {
      INIT: 'APP_DASHBOARD_DOWNLOAD_CURRENT_MONTH_DATE_INIT',
      SUCCESS: 'APP_DASHBOARD_DOWNLOAD_CURRENT_MONTH_DATE_SUCCESS',
      ERROR: 'APP_DASHBOARD_DOWNLOAD_CURRENT_MONTH_DATE_ERROR',
      CLEAR: 'APP_DASHBOARD_DOWNLOAD_CURRENT_MONTH_DATE_CLEAR',
    },
    CURRENT_MONTH_PUBLISHER: {
      INIT: 'APP_DASHBOARD_DOWNLOAD_CURRENT_MONTH_PUBLISHER_INIT',
      SUCCESS: 'APP_DASHBOARD_DOWNLOAD_CURRENT_MONTH_PUBLISHER_SUCCESS',
      ERROR: 'APP_DASHBOARD_DOWNLOAD_CURRENT_MONTH_PUBLISHER_ERROR',
      CLEAR: 'APP_DASHBOARD_DOWNLOAD_CURRENT_MONTH_PUBLISHER_CLEAR',
    },
    PRIMARY_ATTRIBUTION_DATE: {
      INIT: 'APP_DASHBOARD_DOWNLOAD_PRIMARY_ATTRIBUTION_DATE_INIT',
      SUCCESS: 'APP_DASHBOARD_DOWNLOAD_PRIMARY_ATTRIBUTION_DATE_SUCCESS',
      ERROR: 'APP_DASHBOARD_DOWNLOAD_PRIMARY_ATTRIBUTION_DATE_ERROR',
      CLEAR: 'APP_DASHBOARD_DOWNLOAD_PRIMARY_ATTRIBUTION_DATE_CLEAR',
    },
    PRIMARY_ATTRIBUTION_PUBLISHER: {
      INIT: 'APP_DASHBOARD_DOWNLOAD_PRIMARY_ATTRIBUTION_PUBLISHER_INIT',
      SUCCESS: 'APP_DASHBOARD_DOWNLOAD_PRIMARY_ATTRIBUTION_PUBLISHER_SUCCESS',
      ERROR: 'APP_DASHBOARD_DOWNLOAD_PRIMARY_ATTRIBUTION_PUBLISHER_ERROR',
      CLEAR: 'APP_DASHBOARD_DOWNLOAD_PRIMARY_ATTRIBUTION_PUBLISHER_CLEAR',
    },
    NON_PRIMARY_ATTRIBUTION_DATE: {
      INIT: 'APP_DASHBOARD_DOWNLOAD_NON_PRIMARY_ATTRIBUTION_INIT',
      SUCCESS: 'APP_DASHBOARD_DOWNLOAD_NON_PRIMARY_ATTRIBUTION_SUCCESS',
      ERROR: 'APP_DASHBOARD_DOWNLOAD_NON_PRIMARY_ATTRIBUTION_ERROR',
      CLEAR: 'APP_DASHBOARD_DOWNLOAD_NON_PRIMARY_ATTRIBUTION_CLEAR',
    },
    NON_PRIMARY_ATTRIBUTION_PUBLISHER: {
      INIT: 'APP_DASHBOARD_DOWNLOAD_NON_PRIMARY_ATTRIBUTION_PUBLISHER_INIT',
      SUCCESS:
        'APP_DASHBOARD_DOWNLOAD_NON_PRIMARY_ATTRIBUTION_PUBLISHER_SUCCESS',
      ERROR: 'APP_DASHBOARD_DOWNLOAD_NON_PRIMARY_ATTRIBUTION_PUBLISHER_ERROR',
      CLEAR: 'APP_DASHBOARD_DOWNLOAD_NON_PRIMARY_ATTRIBUTION_PUBLISHER_CLEAR',
    },
  },
  META_CAMPAIGN_CONFIGURATION: {
    GET_DETAILS_TABLE: {
      INIT: 'GET_META_CAMPAIGN_CONFIGURATION_INIT',
      SUCCESS: 'GET_META_CAMPAIGN_CONFIGURATION_SUCCESS',
      ERROR: 'GET_META_CAMPAIGN_CONFIGURATION_ERROR',
      CLEAR: 'GET_META_CAMPAIGN_CONFIGURATION_CLEAR',
    },
    POST_DETAILS: {
      INIT: 'POST_META_CAMPAIGN_CONFIGURATION_INIT',
      SUCCESS: 'POST_META_CAMPAIGN_CONFIGURATION_SUCCESS',
      ERROR: 'POST_META_CAMPAIGN_CONFIGURATION_ERROR',
      CLEAR: 'POST_META_CAMPAIGN_CONFIGURATION_CLEAR',
    },
  },
};
