import { takeLatest, put } from 'redux-saga/effects';

import { API } from '../setupAxios';
import { EndPoints, ApiUrl } from '../helpers/Endpoints';
import AnalyticsDashboardConst from '../constants/AnalyticsDashboardConst';

export function* getAnalyticsDashboardURL({ query }) {
  try {
    let url = ApiUrl + EndPoints.ANALYTICS_DASHBOARD;
    const response = yield API.get(url, { params: query });
    yield put({ type: AnalyticsDashboardConst.GETURL_SUCCESS, response });
  } catch (error) {
    yield put({ type: AnalyticsDashboardConst.GETURL_ERROR, error });
  }
}

export default function* AnalyticsDashboardSaga() {
  yield takeLatest(
    AnalyticsDashboardConst.GETURL_INIT,
    getAnalyticsDashboardURL
  );
}
