export const WHITE_LISTING_FRAUD_CATEGORIES_LIST_INIT =  'WHITE_LISTING_FRAUD_CATEGORIES_LIST_INIT'
export const WHITE_LISTING_FRAUD_CATEGORIES_LIST_INIT_SUCCESS = 'WHITE_LISTING_FRAUD_CATEGORIES_LIST_INIT_SUCCESS'
export const WHITE_LISTING_FRAUD_CATEGORIES_LIST_INIT_ERROR = 'WHITE_LISTING_FRAUD_CATEGORIES_LIST_INIT_ERROR'

export const WHITE_LISTING_FIELDS_LIST_INIT =  'WHITE_LISTING_FIELDS_LIST_INIT'
export const WHITE_LISTING_FIELDS_LIST_INIT_SUCCESS = 'WHITE_LISTING_FIELDS_LIST_INIT_SUCCESS'
export const WHITE_LISTING_FIELDS_LIST_INIT_ERROR = 'WHITE_LISTING_FIELDS_LIST_INIT_ERROR'

export const WHITE_LISTING_TABLE_INIT =  'WHITE_LISTING_TABLE_INIT'
export const WHITE_LISTING_TABLE_INIT_SUCCESS = 'WHITE_LISTING_TABLE_INIT_SUCCESS'
export const WHITE_LISTING_TABLE_INIT_ERROR = 'WHITE_LISTING_TABLE_INIT_ERROR'


export const WHITE_LIST_ADD_INIT= 'WHITE_LIST_ADD_INIT';
export const WHITE_LIST_ADD_INIT_SUCCESS = 'WHITE_LIST_ADD_INIT_SUCCESS';
export const WHITE_LIST_ADD_INIT_ERROR = 'WHITE_LIST_ADD_INIT_ERROR';
export const WHITE_LIST_ADD_INIT_CLEAR = 'WHITE_LIST_ADD_INIT_CLEAR'


export const WHITE_LIST_EDIT_INIT= 'WHITE_LIST_EDIT_INIT';
export const WHITE_LIST_EDIT_INIT_SUCCESS = 'WHITE_LIST_EDIT_INIT_SUCCESS';
export const WHITE_LIST_EDIT_INIT_ERROR = 'WHITE_LIST_EDIT_INIT_ERROR';
export const WHITE_LIST_EDIT_INIT_CLEAR = 'WHITE_LIST_EDIT_INIT_CLEAR';

export const DELETE_USER_INIT =  'DELETE_USER_INIT'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR'













