import * as constant from '../constants/IncentDetailsConstant';
const initialState = {
  report: { loading: true, data: null, error: null },
  download: { loading: true, data: null, error: null },
};

const IncentDetailsReducer = (
  state = initialState,
  { type, response, error }
) => {
  switch (type) {
    case constant.INCENT_DETAILS_INIT: {
      const report = { loading: true, data: null, error: null };
      return {
        ...state,
        report,
      };
    }
    case constant.INCENT_DETAILS_SUCCESS: {
      const report = { loading: false, data: response, error: null };
      return {
        ...state,
        report,
      };
    }
    case constant.INCENT_DETAILS_ERROR: {
      const report = { loading: false, data: null, error: error };
      return {
        ...state,
        report,
      };
    }
    case constant.INCENT_DETAILS_CLEAR: {
      const report = { loading: true, data: null, error: null };
      return {
        ...state,
        report,
      };
    }
    // Download
    case constant.INCENT_DOWNLOAD_INIT: {
      const download = { loading: true, data: null, error: null };
      return {
        ...state,
        download,
      };
    }
    case constant.INCENT_DOWNLOAD_SUCCESS: {
      const download = { loading: false, data: response, error: null };
      return {
        ...state,
        download,
      };
    }
    case constant.INCENT_DOWNLOAD_ERROR: {
      const download = { loading: false, data: null, error: error };
      return {
        ...state,
        download,
      };
    }
    case constant.INCENT_DOWNLOAD_CLEAR: {
      const download = { loading: true, data: null, error: null };
      return {
        ...state,
        download,
      };
    }
    default:
      return state;
  }
};
export default IncentDetailsReducer;
