export default {
  RECONCILATION: {
    PAYMENT_CONFIG: {
      GET_TABLE: {
        INIT: "RECONCILATION_PAYMENT_CONFIG_INIT",
        SUCCESS: "RECONCILATION_PAYMENT_CONFIG_SUCCESS",
        ERROR: "RECONCILATION_PAYMENT_CONFIG_ERROR",
        CLEAR: "RECONCILATION_PAYMENT_CONFIG_CLEAR",
      },
      POST_TABLE: {
        INIT: "RECONCILATION_PAYMENT_CONFIG_POST_INIT",
        SUCCESS: "RECONCILATION_PAYMENT_CONFIG_POST_SUCCESS",
        ERROR: "RECONCILATION_PAYMENT_CONFIG_POST_ERROR",
        CLEAR: "RECONCILATION_PAYMENT_CONFIG_POST_CLEAR",
      },
      DELETE: {
        INIT: "RECONCILATION_PAYMENT_CONFIG_DELETE_INIT",
        SUCCESS: "RECONCILATION_PAYMENT_CONFIG_DELETE_SUCCESS",
        ERROR: "RECONCILATION_PAYMENT_CONFIG_DELETE_ERROR",
        CLEAR: "RECONCILATION_PAYMENT_CONFIG_DELETE_CLEAR",
      },
      EDIT: {
        INIT: "RECONCILATION_PAYMENT_CONFIG_EDIT_INIT",
        SUCCESS: "RECONCILATION_PAYMENT_CONFIG_EDIT_SUCCESS",
        ERROR: "RECONCILATION_PAYMENT_CONFIG_EDIT_ERROR",
        CLEAR: "RECONCILATION_PAYMENT_CONFIG_EDIT_CLEAR",
      },
    },
    PUBLISHER_CONFIGURATION: {
      GET_TABLE: {
        INIT: "RECONCILATION_PUBLISHER_CONFIGURATION_INIT",
        SUCCESS: "RECONCILATION_PUBLISHER_CONFIGURATION_SUCCESS",
        ERROR: "RECONCILATION_PUBLISHER_CONFIGURATION_ERROR",
        CLEAR: "RECONCILATION_PUBLISHER_CONFIGURATION_CLEAR",
      },
      POST_TABLE: {
        INIT: "RECONCILATION_PUBLISHER_CONFIGURATION_POST_INIT",
        SUCCESS: "RECONCILATION_PUBLISHER_CONFIGURATION_POST_SUCCESS",
        ERROR: "RECONCILATION_PUBLISHER_CONFIGURATION_POST_ERROR",
        CLEAR: "RECONCILATION_PUBLISHER_CONFIGURATION_POST_CLEAR",
      },
      DELETE: {
        INIT: "RECONCILATION_PUBLISHER_CONFIGURATION_DELETE_INIT",
        SUCCESS: "RECONCILATION_PUBLISHER_CONFIGURATION_DELETE_SUCCESS",
        ERROR: "RECONCILATION_PUBLISHER_CONFIGURATION_DELETE_ERROR",
        CLEAR: "RECONCILATION_PUBLISHER_CONFIGURATION_DELETE_CLEAR",
      },
      EDIT: {
        INIT: "RECONCILATION_PUBLISHER_CONFIGURATION_EDIT_INIT",
        SUCCESS: "RECONCILATION_PUBLISHER_CONFIGURATION_EDIT_SUCCESS",
        ERROR: "RECONCILATION_PUBLISHER_CONFIGURATION_EDIT_ERROR",
        CLEAR: "RECONCILATION_PUBLISHER_CONFIGURATION_EDIT_CLEAR",
      },
      PAYMENT_MODAL_ID: {
        INIT: "RECONCILATION_PUBLISHER_CONFIGURATION_PAYMENT_MODAL_ID_INIT",
        SUCCESS:
          "RECONCILATION_PUBLISHER_CONFIGURATION_PAYMENT_MODAL_ID_SUCCESS",
        ERROR: "RECONCILATION_PUBLISHER_CONFIGURATION_PAYMENT_MODAL_ID_ERROR",
        CLEAR: "RECONCILATION_PUBLISHER_CONFIGURATION_PAYMENT_MODAL_ID_CLEAR",
      },
    },
    INVOICE_PERFORMA: {
      GET_TABLE: {
        INIT: "RECONCILATION_INVOICE_PERFORMA_INIT",
        SUCCESS: "RECONCILATION_INVOICE_PERFORMA_SUCCESS",
        ERROR: "RECONCILATION_INVOICE_PERFORMA_ERROR",
        CLEAR: "RECONCILATION_INVOICE_PERFORMA_CLEAR",
      },
      POST_TABLE: {
        INIT: "RECONCILATION_INVOICE_PERFORMA_POST_TABLE_INIT",
        SUCCESS: "RECONCILATION_INVOICE_PERFORMA_POST_TABLE_SUCCESS",
        ERROR: "RECONCILATION_INVOICE_PERFORMA_POST_TABLE_ERROR",
        CLEAR: "RECONCILATION_INVOICE_PERFORMA_POST_TABLE_CLEAR",
      },
      DELETE: {
        INIT: "RECONCILATION_INVOICE_PERFORMA_DELETE_INIT",
        SUCCESS: "RECONCILATION_INVOICE_PERFORMA_DELETE_SUCCESS",
        ERROR: "RECONCILATION_INVOICE_PERFORMA_DELETE_ERROR",
        CLEAR: "RECONCILATION_INVOICE_PERFORMA_DELETE_CLEAR",
      },
      EDIT: {
        INIT: "RECONCILATION_INVOICE_PERFORMA_EDIT_INIT",
        SUCCESS: "RECONCILATION_INVOICE_PERFORMA_EDIT_SUCCESS",
        ERROR: "RECONCILATION_INVOICE_PERFORMA_EDIT_ERROR",
        CLEAR: "RECONCILATION_INVOICE_PERFORMA_EDIT_CLEAR",
      },
      CURRENCY: {
        INIT: "RECONCILATION_CURRENCY_INIT",
        SUCCESS: "RECONCILATION_CURRENCY_SUCCESS",
        ERROR: "RECONCILATION_CURRENCY_ERROR",
        CLEAR: "RECONCILATION_CURRENCY_CLEAR",
      },
      PAYMENT_MODAL: {
        INIT: "RECONCILATION_PAYMENT_MODAL_INIT",
        SUCCESS: "RECONCILATION_PAYMENT_MODAL_SUCCESS",
        ERROR: "RECONCILATION_PAYMENT_MODAL_ERROR",
        CLEAR: "RECONCILATION_PAYMENT_MODAL_CLEAR",
      },
      ORDER_STATUS: {
        INIT: "RECONCILATION_ORDER_STATUS_INIT",
        SUCCESS: "RECONCILATION_ORDER_STATUS_SUCCESS",
        ERROR: "RECONCILATION_ORDER_STATUS_ERROR",
        CLEAR: "RECONCILATION_ORDER_STATUS_CLEAR",
      },
    },
    REPORT: {
      GET_TABLE: {
        INIT: "RECONCILATION_REPORT_INIT",
        SUCCESS: "RECONCILATION_REPORT_SUCCESS",
        ERROR: "RECONCILATION_REPORT_ERROR",
        CLEAR: "RECONCILATION_REPORT_CLEAR",
      },
      INVOICE_GENRATE: {
        INIT: "RECONCILATION_REPORT_UPDATE_INIT",
        SUCCESS: "RECONCILATION_REPORT_UPDATE_SUCCESS",
        ERROR: "RECONCILATION_REPORT_UPDATE_ERROR",
      },
    },
    REATTRIBUTION_SUMMARY: {
      EVENT: {
        GET_TABLE: {
          INIT: "RECONCILATION_REATTRIBUTION_SUMMARY_EVENT_INIT",
          SUCCESS: "RECONCILATION_REATTRIBUTION_SUMMARY_EVENT_SUCCESS",
          ERROR: "RECONCILATION_REATTRIBUTION_SUMMARY_EVENT_ERROR",
          CLEAR: "RECONCILATION_REATTRIBUTION_SUMMARY_EVENT_CLEAR",
          REFETCH: "RECONCILATION_REATTRIBUTION_SUMMARY_EVENT_REFETCH",
        },
        GENERATE_REPORT: {
          INIT:
            "RECONCILATION_REATTRIBUTION_SUMMARY_EVENT_GENERATE_REPORT_INIT",
          SUCCESS:
            "RECONCILATION_REATTRIBUTION_SUMMARY_EVENT_GENERATE_REPORT_SUCCESS",
          ERROR:
            "RECONCILATION_REATTRIBUTION_SUMMARY_EVENT_GENERATE_REPORT_ERROR",
          CLEAR:
            "RECONCILATION_REATTRIBUTION_SUMMARY_EVENT_GENERATE_REPORT_CLEAR",
        },
      },
      INSTALL: {
        GET_TABLE: {
          INIT: "RECONCILATION_REATTRIBUTION_SUMMARY_INSTALL_INIT",
          SUCCESS: "RECONCILATION_REATTRIBUTION_SUMMARY_INSTALL_SUCCESS",
          ERROR: "RECONCILATION_REATTRIBUTION_SUMMARY_INSTALL_ERROR",
          CLEAR: "RECONCILATION_REATTRIBUTION_SUMMARY_INSTALL_CLEAR",
          REFETCH: "RECONCILATION_REATTRIBUTION_SUMMARY_INSTALL_REFETCH",
        },
        GENERATE_REPORT: {
          INIT:
            "RECONCILATION_REATTRIBUTION_SUMMARY_INSTALL_GENERATE_REPORT_INIT",
          SUCCESS:
            "RECONCILATION_REATTRIBUTION_SUMMARY_INSTALL_GENERATE_REPORT_SUCCESS",
          ERROR:
            "RECONCILATION_REATTRIBUTION_SUMMARY_INSTALL_GENERATE_REPORT_ERROR",
          CLEAR:
            "RECONCILATION_REATTRIBUTION_SUMMARY_INSTALL_GENERATE_REPORT_CLEAR",
        },
      },
    },
    INVOICE_CONFIG : {
      
      POST_FORM:{

        INIT: "RECONCILATION_INVOICE_CONFIG_INIT",
        SUCCESS: "RECONCILATION_INVOICE_CONFIG_SUCCESS",
        ERROR: "RECONCILATION_INVOICE_CONFIG_ERROR",
        CLEAR: "RECONCILATION_INVOICE_CONFIG_CLEAR",
        REFETCH: "RECONCILATION_INVOICE_CONFIG_REFETCH",

      },

      GET_FORM:{

        INIT: "RECONCILATION_INVOICE_CONFIG_DATAFORM_INIT",
        SUCCESS: "RECONCILATION_INVOICE_CONFIG_DATAFORM_SUCCESS",
        ERROR: "RECONCILATION_INVOICE_CONFIG_DATAFORM_ERROR",
        CLEAR: "RECONCILATION_INVOICE_CONFIG_DATAFORM_CLEAR",
        REFETCH: "RECONCILATION_INVOICE_CONFIG_DATAFORM_REFETCH",

      }

    }
  },
};
