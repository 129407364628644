import * as constant from "../constants/DashboardConstants";
const initialState = {
    incident_data: [],
    incidentloading: true,
    incidentdata_error: null,

    incidentvolumne_data: [],
    incidentvolumneloading: true,
    incidentvolumne_error: null,

    activecases_data: [],
    activecaseloading: true,
    activecases_error: null,

    subchannel_data: [],
    subchannelloading: true,
    subchannel_error: null,

    toptenlocation_data: [],
    toptenlocation_loading: true,
    toptenlocation_error: null,

    categorylevelcount_data: [],
    categorylevelcount_loading: true,
    categorylevelcount_error: null,

    publisherlevelcount_data: [],
    publisherlevelcount_loading: true,
    publisherlevelcount_error: null,

    dashboard_data: {},
    dashboard_data_loading: true,
    dashboard_data_error: null,

    web_incident_data: [],
    web_incidentloading: true,
    web_incidentdata_error: null,

    fraudreasontrend_data: [],
    fraudreasontrendloading: true,
    fraudreasontrend_error: null,

    fraudvisit_data: [],
    fraudvisitloading: true,
    fraudvisit_error: null,

    fraudtrend_data: [],
    fraudtrendloading: true,
    fraudtrend_error: null,

    fraudtrendmonthly_data:[],
    fraudtrendmonthlyloading:true,
    fraudtrendmonthly_error:null,

    fraudpublisher_data: [],
    fraudpublisherloading: true,
    fraudpublisher_error: null,

    cleanpublisher_data: [],
    cleanpublisherloading: true,
    cleanpublisher_error: null,

    cumulativevisittrend_data: [],
    cumulativevisittrendloading: true,
    cumulativevisittrend_error: null,

    topexchangetraffic_data: [],
    topexchangetraffic_loading: true,
    topexchangetraffic_error: null,

    fraud_trend_publisher_data: [],
    fraud_trend_publisher_loading: true,
    fraud_trend_publisher_error: null,


    //YEAH HAI HIGH TRAFFIC
    highfraudtrafficcount_data: [],
    highfraudtrafficcount_loading: true,
    highfraudtrafficcount_error: null,
    //top ten
    toptenplacement_data: [],
    toptenplacement_loading: true,
    toptenplacement_error: null,

    //Click Dashboard

    click_fraudtrend_data: [],
    click_fraudtrendloading: true,
    click_fraudtrend_error: null,

    click_fraudtrendmonthly_data:[],
    click_fraudtrendmonthlyloading:true,
    click_fraudtrendmonthly_error:null,

    click_fraudpublisher_data: [],
    click_fraudpublisherloading: true,
    click_fraudpublisher_error: null,

    click_incident_data: [],
    click_incidentloading: true,
    click_incidentdata_error: null,

    click_cleanpublisher_data: [],
    click_cleanpublisherloading: true,
    click_cleanpublisher_error: null,

    click_fraudvisit_data: [],
    click_fraudvisitloading: true,
    click_fraudvisit_error: null,

    
    //EVENT DASHBOARD
    // monthwise_fraud_trend: [],
    // monthwise_fraud_trend_loading: true,
    // monthwise_fraud_trend_error: null,

    // totalevent: [],
    // totalevent_loading: true,
    // totalevent_error: null,

    // fraudpercent: [],
    // fraudpercent_loading: true,
    // fraudpercent_error: null,


    // fraudevent: [],
    // fraudevent_loading: true,
    // fraudevent_error: null,

    // eventfraudcategory: [],
    // eventfraudcategory_loading: true,
    // eventfraudcategory_error: null,

    // eventcleanpublishername: [],
    // eventcleanpublishername_loading: true,
    // eventcleanpublishername_error: null,

    // eventfraudpublishername: [],
    // eventfraudpublishername_loading: true,
    // eventfraudpublishername_error: null,

    // eventnotfraudpublishername: [],
    // eventnotfraudpublishername_loading: true,
    // eventnotfraudpublishername_error: null,

    // totaleventfraudevent: [],
    // totaleventfraudevent_loading: true,
    // totaleventfraudevent_error: null,

    // fraudeventbydate: [],
    // fraudeventbydate_loading: true,
    // fraudeventbydate_error: null,

    // eventtabel: [],
    // eventtabel_loading: true,
    // eventtabel_error: null,

    //app install

    app_total_install:[],
    app_total_install_loading: true,
    app_total_install_error: null,
    
    app_total_fraud_install:[],
    app_total_fraud_install_loading: true,
    app_total_fraud_install_error: null,

    app_clean_install:[],
    app_clean_install_loading: true,
    app_clean_install_error: null,
    
    app_fraudelent_install:[],
    app_fraudelent_install_loading: true,
    app_fraudelent_install_error: null,

    app_fraudcategory:[],
    app_fraudcategory_loading: true,
    app_fraudcategory_error: null,

    app_fraudsubcategory:[],
    app_fraudsubcategory_loading: true,
    app_fraudsubcategory_error: null,

    app_clean_device_count:[],
    app_clean_device_count_loading: true,
    app_clean_device_count_error: null,

    app_click_fraud_date:[],
    app_click_fraud_date_loading: true,
    app_click_fraud_date_error: null,

    app_click_fraud_publisher:[],
    app_click_fraud_publisher_loading: true,
    app_click_fraud_publisher_error: null,
    
    app_install_fraud_date:[],
    app_install_fraud_date_loading: true,
    app_install_fraud_date_error: null,
    
    app_install_fraud_publisher:[],
    app_install_fraud_publisher_loading: true,
    app_install_fraud_publisher_error: null,

    app_install_sub_fraud_category_date:[],
    app_install_sub_fraud_category_date_loading: true,
    app_install_sub_fraud_category_date_error: null,

    app_install_sub_fraud_category_publisher:[],
    app_install_sub_fraud_category_publisher_loading: true,
    app_install_sub_fraud_category_publisher_error: null,

    app_install_sub_publisher_date:[],
    app_install_sub_publisher_date_loading: true,
    app_install_sub_publisher_date_error: null,

    app_install_sub_publisher:[],
    app_install_sub_publisher_loading: true,
    app_install_sub_publisher_error: null,

    //app event dashboard
    
    app_event_total_event:[],
    app_event_total_event_loading: true,
    app_event_total_event_error: null,
    
    app_event_total_fraud_event:[],
    app_event_total_fraud_event_loading: true,
    app_event_total_fraud_event_error: null,

    app_event_clean_event:[],
    app_event_clean_event_loading: true,
    app_event_clean_event_error: null,
    
    app_event_fraudelent_event:[],
    app_event_fraudelent_event_loading: true,
    app_event_fraudelent_event_error: null,

    app_event_fraudcategory:[],
    app_event_fraudcategory_loading: true,
    app_event_fraudcategory_error: null,

    app_event_fraudsubcategory:[],
    app_event_fraudsubcategory_loading: true,
    app_event_fraudsubcategory_error: null,

    app_event_clean_device_count:[],
    app_event_clean_device_count_loading: true,
    app_event_clean_device_count_error: null,

    app_event_click_fraud_date:[],
    app_event_click_fraud_date_loading: true,
    app_event_click_fraud_date_error: null,

    app_event_click_fraud_publisher:[],
    app_event_click_fraud_publisher_loading: true,
    app_event_click_fraud_publisher_error: null,
    
    app_event_fraud_date:[],
    app_event_fraud_date_loading: true,
    app_event_fraud_date_error: null,
    
    app_event_fraud_publisher:[],
    app_event_fraud_publisher_loading: true,
    app_event_fraud_publisher_error: null,

    app_event_sub_publisher:[],
    app_event_sub_publisher_loading: true,
    app_event_sub_publisher_error: null,

    app_event_sub_fraud_category_date:[],
    app_event_sub_fraud_category_date_loading: true,
    app_event_sub_fraud_category_date_error: null,

    app_event_sub_fraud_category_publisher:[],
    app_event_sub_fraud_category_publisher_loading: true,
    app_event_sub_fraud_category_publisher_error: null,

    //app reengagement-event dashboard
    
    app_reengagement_event_total_event:[],
    app_reengagement_event_total_event_loading: true,
    app_reengagement_event_total_event_error: null,
    
    app_reengagement_event_total_fraud_event:[],
    app_reengagement_event_total_fraud_event_loading: true,
    app_reengagement_event_total_fraud_event_error: null,

    app_reengagement_event_clean_event:[],
    app_reengagement_event_clean_event_loading: true,
    app_reengagement_event_clean_event_error: null,

    app_reengagement_event_not_fraud_event:[],
    app_reengagement_event_not_fraud_event_loading: true,
    app_reengagement_event_not_fraud_event_error: null,
    
    app_reengagement_event_fraudelent_event:[],
    app_reengagement_event_fraudelent_event_loading: true,
    app_reengagement_event_fraudelent_event_error: null,

    app_reengagement_event_fraudcategory:[],
    app_reengagement_event_fraudcategory_loading: true,
    app_reengagement_event_fraudcategory_error: null,

    app_reengagement_event_fraudsubcategory:[],
    app_reengagement_event_fraudsubcategory_loading: true,
    app_reengagement_event_fraudsubcategory_error: null,

    app_reengagement_event_clean_device_count:[],
    app_reengagement_event_clean_device_count_loading: true,
    app_reengagement_event_clean_device_count_error: null,

    app_reengagement_event_click_fraud_date:[],
    app_reengagement_event_click_fraud_date_loading: true,
    app_reengagement_event_click_fraud_date_error: null,

    app_reengagement_event_click_fraud_publisher:[],
    app_reengagement_event_click_fraud_publisher_loading: true,
    app_reengagement_event_click_fraud_publisher_error: null,
    
    app_reengagement_event_fraud_date:[],
    app_reengagement_event_fraud_date_loading: true,
    app_reengagement_event_fraud_date_error: null,
    
    app_reengagement_event_fraud_publisher:[],
    app_reengagement_event_fraud_publisher_loading: true,
    app_reengagement_event_fraud_publisher_error: null,

    app_reengagement_event_sub_publisher:[],
    app_reengagement_event_sub_publisher_loading: true,
    app_reengagement_event_sub_publisher_error: null,


    app_reengagement_event_sub_fraud_category_date:[],
    app_reengagement_event_sub_fraud_category_date_loading: true,
    app_reengagement_event_sub_fraud_category_date_error: null,

    app_reengagement_event_sub_fraud_category_publisher:[],
    app_reengagement_event_sub_fraud_category_publisher_loading: true,
    app_reengagement_event_sub_fraud_category_publisher_error: null,

  // app re-engagement click

  app_reengagement_click_total_click: [],
  app_reengagement_click_total_click_loading: true,
  app_reengagement_click_total_click_error: null,

  app_reengagement_click_total_fraud_click: [],
  app_reengagement_click_total_fraud_click_loading: true,
  app_reengagement_click_total_fraud_click_error: null,

  app_reengagement_click_clean_click: [],
  app_reengagement_click_clean_click_loading: true,
  app_reengagement_click_clean_click_error: null,

  app_reengagement_click_fraudelent_click: [],
  app_reengagement_click_fraudelent_click_loading: true,
  app_reengagement_click_fraudelent_click_error: null,

  app_reengagement_click_fraudcategory: [],
  app_reengagement_click_fraudcategory_loading: true,
  app_reengagement_click_fraudcategory_error: null,

  app_reengagement_click_fraudsubcategory: [],
  app_reengagement_click_fraudsubcategory_loading: true,
  app_reengagement_click_fraudsubcategory_error: null,

  app_reengagement_click_clean_device_count: [],
  app_reengagement_click_clean_device_count_loading: true,
  app_reengagement_click_clean_device_count_error: null,

  app_reengagement_click_click_fraud_date: [],
  app_reengagement_click_click_fraud_date_loading: true,
  app_reengagement_click_click_fraud_date_error: null,

  app_reengagement_click_click_fraud_publisher: [],
  app_reengagement_click_click_fraud_publisher_loading: true,
  app_reengagement_click_click_fraud_publisher_error: null,

  app_reengagement_click_fraud_date: [],
  app_reengagement_click_fraud_date_loading: true,
  app_reengagement_click_fraud_date_error: null,

  app_reengagement_click_fraud_publisher: [],
  app_reengagement_click_fraud_publisher_loading: true,
  app_reengagement_click_fraud_publisher_error: null,


  //not used by now

  app_reengagement_click_sub_fraud_category_date: [],
  app_reengagement_click_sub_fraud_category_date_loading: true,
  app_reengagement_click_sub_fraud_category_date_error: null,

  app_reengagement_click_sub_fraud_category_publisher: [],
  app_reengagement_click_sub_fraud_category_publisher_loading: true,
  app_reengagement_click_sub_fraud_category_publisher_error: null,


  //sub publisher 
  app_reengagement_click_sub_publisher : [],
  app_reengagement_click_sub_publisher_loading: true ,
  app_reengagement_click_sub_publisher_error : null ,


  app_publisher_data: [],
  app_publisher_loading: true,
  app_publisher_error: null,
  total_count: 0,

  app_publisher_download_data: null,
  app_publisher_download_loading: false,
  app_publisher_download_error: null,

  colors: [],
  colors_loading: true,
  colors_error: null,
};

const DashboardReducer = (
  state = initialState,
  { type, response, setSideDrawer,error }
) => {
  switch (type) {
    case constant.TOTAL_INCIDENTS:
      return {
        ...state,
        incidentloading: true,
      };

        case constant.INCIDENT_VOLUMES:
            return {
                ...state,
                incidentvolumneloading: true,
            };

    case constant.DASHBOARD_DATA:
      return {
        ...state,
        dashboard_data_loading: true,
        dashboard_data: response,
      };

        case constant.ACTIVECASE_CHANNEL:
            return {
                ...state,
                activecaseloading: true,
            };

        case constant.SUBCHANNEL_INIT:
            return {
                ...state,
                subchannelloading: true,
            };

        case constant.TOPTEN_LOCATION_INIT:
            return {
                ...state,
                toptenlocation_loading: true,
            };

    case constant.SET_SIDE_DRAWER:
        return {
            ...state,
            setSideDrawer: setSideDrawer,
        };
        case constant.CATEGORY_LEVEL_COUNT:
            return {
                ...state,
                categorylevelcount_loading: true,
            };

        case constant.PUBLISHER_LEVEL_COUNT:
            return {
                ...state,
                publisherlevelcount_loading: true,
            };

    // case constant.TOTAL_EVENT_LIST_INIT:
    //   return {
    //     ...state,
    //     totalevent_loading: true,
    //   };

      // case constant.FRAUD_PERCENT_EVENT_LIST_INIT:
      //   return {
      //     ...state,
      //     fraudpercent_loading: true,
      //   };

    // case constant.FRAUD_EVENT_LIST_INIT:
    //   return {
    //     ...state,
    //     fraudevent_loading: true,
    //   };
    // case constant.EVENT_FRAUD_CATEGORY_INIT:
    //   return {
    //     ...state,
    //     eventfraudcategory_loading: true,
    //   };

    // case constant.EVENT_CLEAN_PUBLISHER_NAME_INIT:
    //   return {
    //     ...state,
    //     eventcleanpublishername_loading: true,
    //   };

      case constant.FRAUD_PUBLISHER_WISE:
        return {
          ...state,
          fraud_trend_publisher_loading: true,
        };

      // case constant.MONTHWISE_FRAUD_TREND_INIT:
      //   return {
      //     ...state,
      //     monthwise_fraud_trend_loading: true,
      //   };

    // case constant.EVENT_FRAUD_PUBLISHER_NAME_INIT:
    //   return {
    //     ...state,
    //     eventfraudpublishername_loading: true,
    //   };

    // case constant.EVENT_NOT_FRAUD_PUBLISHER_NAME_INIT:
    //   return {
    //     ...state,
    //     eventnotfraudpublishername_loading: true,
    //   };

    // case constant.TOTAL_EVENT_FRAUD_EVENT_INIT:
    //   return {
    //     ...state,
    //     totaleventfraudevent_loading: true,
    //   };

    // case constant.FRAUD_EVENT_BY_DATE_INIT:
    //   return {
    //     ...state,
    //     fraudeventbydate_loading: true,
    //   };
    // case constant.EVENT_TABEL_INIT:
    //   return {
    //     ...state,
    //     eventtabel_loading: true,
    //   };
    case constant.INSTALL_TOTAL_INSTALL_INIT:
      return {
        ...state,
        app_total_install_loading: true,
      };

    case constant.INSTALL_FRAUDULENT_INSTALL_INIT:
    return {
      ...state,
      app_total_fraud_install_loading: true,
    };

    case constant.INSTALL_CLEAN_EVENT_INIT:
    return {
      ...state,
      app_clean_install_loading: true,
    };

    case constant.INSTALL_FRAUDULENT_EVENT_INIT:
    return {
      ...state,
      app_fraudelent_install_loading: true,
    };

    case constant.INSTALL_FRAUD_CATEGORY_INIT:
    return {
      ...state,
      app_fraudcategory_loading: true,
    };

    case constant.INSTALL_FRAUD_SUB_CATEGORY_INIT:
    return {
      ...state,
      app_fraudsubcategory_loading: true,
    };

    case constant.INSTALL_CLEAN_DEVICE_COUNT_INIT:
    return {
      ...state,
      app_clean_device_count_loading: true,
    };

    case constant.INSTALL_CLICK_FRAUD_DATE_INIT:
    return {
      ...state,
      app_click_fraud_date_loading: true,
    };

    case constant.INSTALL_CLICK_FRAUD_PUBLISHER_INIT:
    return {
      ...state,
      app_click_fraud_publisher_loading: true,
    };

    case constant.INSTALL_FRAUD_DATE_INIT:
    return {
      ...state,
      app_install_fraud_date_loading: true,
    };

    case constant.INSTALL_FRAUD_PUBLISHER_INIT:
    return {
      ...state,
      app_install_fraud_publisher_loading: true,
    };

    case constant.INSTALL_SUB_FRAUD_CATEGORY_DATE_INIT:
    return {
      ...state,
      app_install_sub_fraud_category_date_loading: true,
    };

    case constant.INSTALL_SUB_FRAUD_CATEGORY_PUBLISHER_INIT:
    return {
      ...state,
      app_install_sub_fraud_category_publisher_loading: true,
    };

    case constant.INSTALL_SUB_PUBLISHER_DATE_INIT:
    return {
      ...state,
      app_install_sub_publisher_date_loading: true,
    };

    case constant.INSTALL_SUB_PUBLISHER_INIT:
    return {
      ...state,
      app_install_sub_publisher_loading: true,
    };

    case constant.COLORS_INIT:
    return {
      ...state,
      colors_loading: true,
    };

    case constant.APP_EVENT_TOTAL_EVENT_INIT:
    return {
      ...state,
      app_event_total_event_loading: true,
    };

    case constant.APP_EVENT_FRAUDULENT_EVENTS_INIT:
    return {
      ...state,
      app_event_total_fraud_event_loading: true,
    };

    case constant.APP_EVENT_FRAUD_CATEGORY_INIT:
    return {
      ...state,
      app_event_fraudcategory_loading: true,
    };

    case constant.APP_EVENT_FRAUD_SUB_CATEGORY_INIT:
    return {
      ...state,
      app_event_fraudsubcategory_loading: true,
    };

    case constant.APP_EVENT_CLEAN_EVENT_INIT:
    return {
      ...state,
      app_event_clean_event_loading: true,
    };

    case constant.APP_EVENT_CLEAN_DEVICE_COUNT_INIT:
    return {
      ...state,
      app_event_clean_device_count_loading: true,
    };

    case constant.APP_EVENT_FRAUDULENT_EVENT_INIT:
    return {
      ...state,
      app_event_fraudelent_event_loading: true,
    };

    case constant.APP_EVENT_CLICK_FRAUD_DATE_INIT:
    return {
      ...state,
      app_event_click_fraud_date_loading: true,
    };

    case constant.APP_EVENT_CLICK_FRAUD_PUBLISHER_INIT:
    return {
      ...state,
      app_event_click_fraud_publisher_loading: true,
    };

    case constant.APP_EVENT_FRAUD_DATE_INIT:
    return {
      ...state,
      app_event_fraud_date_loading: true,
    };

    case constant.APP_EVENT_FRAUD_PUBLISHER_INIT:
    return {
      ...state,
      app_event_fraud_publisher_loading: true,
    };

    case constant.APP_EVENT_FRAUD_SUB_PUBLISHER_INIT:
    return {
      ...state,
      app_event_sub_publisher_loading: true,
    };

    case constant.APP_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT:
    return {
      ...state,
      app_event_sub_fraud_category_date_loading: true,
    };

    case constant.APP_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT:
    return {
      ...state,
      app_event_sub_fraud_category_publisher_loading: true,
    };

    //REEN
    case constant.APP_REENGAGEMENT_EVENT_TOTAL_EVENT_INIT:
    return {
      ...state,
      app_reengagement_event_total_event_loading: true,
    };

    case constant.APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENTS_INIT:
    return {
      ...state,
      app_reengagement_event_total_fraud_event_loading: true,
    };

    case constant.APP_REENGAGEMENT_EVENT_FRAUD_CATEGORY_INIT:
    return {
      ...state,
      app_reengagement_event_fraudcategory_loading: true,
    };

    case constant.APP_REENGAGEMENT_EVENT_FRAUD_SUB_CATEGORY_INIT:
    return {
      ...state,
      app_reengagement_event_fraudsubcategory_loading: true,
    };

    // case constant.APP_REENGAGEMENT_EVENT_CLEAN_EVENT_INIT:
    // return {
    //   ...state,
    //   app_reengagement_event_clean_event_loading: true,
    // };

    case constant.APP_REENGAGEMENT_EVENT_CLEAN_DEVICE_COUNT_INIT:
    return {
      ...state,
      app_reengagement_event_clean_device_count_loading: true,
    };

    case constant.APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENT_INIT:
    return {
      ...state,
      app_reengagement_event_fraudelent_event_loading: true,
    };

    case constant.APP_REENGAGEMENT_EVENT_CLICK_FRAUD_DATE_INIT:
    return {
      ...state,
      app_reengagement_event_click_fraud_date_loading: true,
    };

    case constant.APP_REENGAGEMENT_EVENT_CLICK_FRAUD_PUBLISHER_INIT:
    return {
      ...state,
      app_reengagement_event_click_fraud_publisher_loading: true,
    };

    case constant.APP_REENGAGEMENT_EVENT_FRAUD_DATE_INIT:
    return {
      ...state,
      app_reengagement_event_fraud_date_loading: true,
    };

    case constant.APP_REENGAGEMENT_EVENT_FRAUD_PUBLISHER_INIT:
    return {
      ...state,
      app_reengagement_event_fraud_publisher_loading: true,
    };

    case constant.APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT:
    return {
      ...state,
      app_reengagement_event_sub_fraud_category_date_loading: true,
    };

    case constant.APP_REENGAGEMENT_EVENT_FRAUD_SUB_PUBLISHER_INIT:
    return {
      ...state,
      app_reengagement_event_sub_publisher_loading: true,
    };

    case constant.APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT:
    return {
      ...state,
      app_reengagement_event_sub_fraud_category_publisher_loading: true,
    };

    case constant.APP_REENGAGEMENT_EVENT_NOT_FRAUD_EVENT_INIT:
    return {
      ...state,
      app_reengagement_event_not_fraud_event_loading: true,
    };


    case constant.APP_REENGAGEMENT_CLICK_TOTAL_CLICK_INIT:
      return {
        ...state,
        app_reengagement_click_total_click_loading: true,
      };

    case constant.APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICKS_INIT:
      return {
        ...state,
        app_reengagement_click_total_fraud_click_loading: true,
      };

    case constant.APP_REENGAGEMENT_CLICK_FRAUD_CATEGORY_INIT:
      return {
        ...state,
        app_reengagement_click_fraudcategory_loading: true,
      };

    case constant.APP_REENGAGEMENT_CLICK_FRAUD_SUB_CATEGORY_INIT:
      return {
        ...state,
        app_reengagement_click_fraudsubcategory_loading: true,
      };

    case constant.APP_REENGAGEMENT_CLICK_CLEAN_CLICK_INIT:
      return {
        ...state,
        app_reengagement_click_clean_click_loading: true,
      };

    case constant.APP_REENGAGEMENT_CLICK_CLEAN_DEVICE_COUNT_INIT:
      return {
        ...state,
        app_reengagement_click_clean_device_count_loading: true,
      };
    //click
    //CLICK

    case constant.APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICK_INIT:
      return {
        ...state,
        app_reengagement_click_fraudelent_click_loading: true,
      };

    case constant.APP_REENGAGEMENT_CLICK_CLICK_FRAUD_DATE_INIT:
      return {
        ...state,
        app_reengagement_click_click_fraud_date_loading: true,
      };

    case constant.APP_REENGAGEMENT_CLICK_CLICK_FRAUD_PUBLISHER_INIT:
      return {
        ...state,
        app_reengagement_click_click_fraud_publisher_loading: true,
      };

      //sub publisher 

      case constant.APP_REENGAGEMENT_CLICK_SUB_PUBLISHER_INIT:
        return {
          ...state,
          app_reengagement_click_sub_publisher_loading: true,
        };




    case constant.APP_REENGAGEMENT_CLICK_FRAUD_DATE_INIT:
      return {
        ...state,
        app_reengagement_click_fraud_date_loading: true,
      };

    case constant.APP_REENGAGEMENT_CLICK_FRAUD_PUBLISHER_INIT:
      return {
        ...state,
        app_reengagement_click_fraud_publisher_loading: true,
      };

    case constant.APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_DATE_INIT:
      return {
        ...state,
        app_reengagement_click_sub_fraud_category_date_loading: true,
      };

    case constant.APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_PUBLISHER_INIT:
      return {
        ...state,
        app_reengagement_click_sub_fraud_category_publisher_loading: true,
      };
    

    // success
    case constant.DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboard_data_loading: false,
        dashboard_data: response.data,
      };

        case constant.TOTAL_INCIDENTS_SUCCESS:
            return {
                ...state,
                incidentloading: false,
                incident_data: response.data,
            };

        case constant.INCIDENT_VOLUMES_SUCCESS:
            return {
                ...state,
                incidentvolumneloading: false,
                incidentvolumne_data: response.data,
            };

        case constant.ACTIVECASE_CHANNEL_SUCCESS:
            return {
                ...state,
                activecaseloading: false,
                activecases_data: response.data,
            };

        case constant.SUBCHANNEL_SUCCESS:
            return {
                ...state,
                subchannelloading: false,
                subchannel_data: response.data,
            };

        case constant.TOPTEN_LOCATION_SUCCESS:
            return {
                ...state,
                toptenlocation_loading: false,
                toptenlocation_data: response.data,
            };

        case constant.CATEGORY_LEVEL_COUNT_SUCCESS:
            return {
                ...state,
                categorylevelcount_loading: false,
                categorylevelcount_data: response.data,
            };

        case constant.PUBLISHER_LEVEL_COUNT_SUCCESS:
            return {
                ...state,
                publisherlevelcount_loading: false,
                publisherlevelcount_data: response.data,
            };

            case constant.FRAUD_PUBLISHER_WISE_SUCCESS:
            return {
                ...state,
                fraud_trend_publisher_loading: false,
                fraud_trend_publisher_data: response.data,
            };




      //event

      // case constant.TOTAL_EVENT_LIST_INIT_SUCCESS:
      // return {
      //   ...state,
      //   totalevent_loading: false,
      //   totalevent: response,
      // };

      // case constant.FRAUD_PERCENT_EVENT_LIST_INIT_SUCCESS:
      //   return {
      //     ...state,
      //     fraudpercent_loading: false,
      //     fraudpercent: response,
      //   };

      // case constant.FRAUD_EVENT_LIST_INIT_SUCCESS:
      // return {
      //   ...state,
      //   fraudevent_loading: false,
      //   fraudevent: response,
      // };

      // case constant.EVENT_FRAUD_CATEGORY_INIT_SUCCESS:
      // return {
      //   ...state,
      //   eventfraudcategory_loading: false,
      //   eventfraudcategory: response,
      // };

      // case constant.EVENT_CLEAN_PUBLISHER_NAME_INIT_SUCCESS:
      // return {
      //   ...state,
      //   eventcleanpublishername_loading: false,
      //   eventcleanpublishername: response.data,
      // };

      // case constant.MONTHWISE_FRAUD_TREND_INIT_SUCCESS:
      //   return {
      //     ...state,
      //     monthwise_fraud_trend_loading: false,
      //     monthwise_fraud_trend: response.data,
      //   };

      // case constant.EVENT_FRAUD_PUBLISHER_NAME_INIT_SUCCESS:
      //   return {
      //     ...state,
      //     eventfraudpublishername_loading: false,
      //     eventfraudpublishername: response.data,
      //   };

        // case constant.EVENT_NOT_FRAUD_PUBLISHER_NAME_INIT_SUCCESS:
        // return {
        //   ...state,
        //   eventnotfraudpublishername_loading: false,
        //   eventnotfraudpublishername: response.data,
        // };

        // case constant.TOTAL_EVENT_FRAUD_EVENT_INIT_SUCCESS:
        // return {
        //   ...state,
        //   totaleventfraudevent_loading: false,
        //   totaleventfraudevent: response.data,
        // };

        // case constant.FRAUD_EVENT_BY_DATE_INIT_SUCCESS:
        // return {
        //   ...state,
        //   fraudeventbydate_loading: false,
        //   fraudeventbydate: response.data,
        // };

        // case constant.EVENT_TABEL_INIT_SUCCESS:
        //     return {
        //       ...state,
        //       eventtabel_loading: false,
        //       eventtabel: response.data,
        //     };
        
        case constant.INSTALL_TOTAL_INSTALL_INIT_SUCCESS:
            return {
              ...state,
              app_total_install_loading: false,
              app_total_install: response,
            };

        case constant.INSTALL_FRAUDULENT_INSTALL_INIT_SUCCESS:
        return {
          ...state,
          app_total_fraud_install_loading: false,
          app_total_fraud_install: response,
        };
        
        case constant.INSTALL_CLEAN_EVENT_INIT_SUCCESS:
        return {
          ...state,
          app_clean_install_loading: false,
          app_clean_install: response,
        };

        case constant.INSTALL_FRAUDULENT_EVENT_INIT_SUCCESS:
        return {
          ...state,
          app_fraudelent_install_loading: false,
          app_fraudelent_install: response,
        };

        case constant.INSTALL_FRAUD_CATEGORY_INIT_SUCCESS:
        return {
          ...state,
          app_fraudcategory_loading: false,
          app_fraudcategory: response,
        };

        case constant.INSTALL_FRAUD_SUB_CATEGORY_INIT_SUCCESS:
        return {
          ...state,
          app_fraudsubcategory_loading: false,
          app_fraudsubcategory: response,
        };

        case constant.INSTALL_CLEAN_DEVICE_COUNT_INIT_SUCCESS:
        return {
          ...state,
          app_clean_device_count_loading: false,
          app_clean_device_count: response,
        };

        case constant.INSTALL_CLICK_FRAUD_DATE_INIT_SUCCESS:
        return {
          ...state,
          app_click_fraud_date_loading: false,
          app_click_fraud_date: response,
        };

        case constant.INSTALL_CLICK_FRAUD_PUBLISHER_INIT_SUCCESS:
        return {
          ...state,
          app_click_fraud_publisher_loading: false,
          app_click_fraud_publisher: response,
        };

        case constant.INSTALL_FRAUD_DATE_INIT_SUCCESS:
        return {
          ...state,
          app_install_fraud_date_loading: false,
          app_install_fraud_date: response,
        };

        case constant.INSTALL_FRAUD_PUBLISHER_INIT_SUCCESS:
        return {
          ...state,
          app_install_fraud_publisher_loading: false,
          app_install_fraud_publisher: response,
        };

        case constant.INSTALL_SUB_FRAUD_CATEGORY_DATE_INIT_SUCCESS:
        return {
          ...state,
          app_install_sub_fraud_category_date_loading: false,
          app_install_sub_fraud_category_date: response,
        };

        case constant.INSTALL_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_SUCCESS:
        return {
          ...state,
          app_install_sub_fraud_category_publisher_loading: false,
          app_install_sub_fraud_category_publisher: response,
        };

        case constant.INSTALL_SUB_PUBLISHER_DATE_INIT_SUCCESS:
        return {
          ...state,
          app_install_sub_publisher_date_loading: false,
          app_install_sub_publisher_date: response,
        };

        case constant.INSTALL_SUB_PUBLISHER_INIT_SUCCESS:
        return {
          ...state,
          app_install_sub_publisher_loading: false,
          app_install_sub_publisher: response,
        };

        case constant.COLORS_INIT_SUCCESS:
        return {
          ...state,
          colors_loading: false,
          colors: response,
        };

        case constant.APP_INSTALL_TOTAL_INSTALL_INIT_SUCCESS:
        return {
          ...state,
          app_event_total_event_loading: false,
          app_event_total_event: response,
        };

        case constant.APP_EVENT_FRAUDULENT_EVENT_INIT_SUCCESS:
        return {
          ...state,
          app_event_total_fraud_event_loading: false,
          app_event_total_fraud_event: response,
        };

        case constant.APP_EVENT_FRAUD_CATEGORY_INIT_SUCCESS:
        return {
          ...state,
          app_event_fraudcategory_loading: false,
          app_event_fraudcategory: response,
        };

        case constant.APP_EVENT_FRAUD_SUB_CATEGORY_INIT_SUCCESS:
        return {
          ...state,
          app_event_fraudsubcategory_loading: false,
          app_event_fraudsubcategory: response,
        };

        case constant.APP_EVENT_CLEAN_EVENT_INIT_SUCCESS:
        return {
          ...state,
          app_event_clean_event_loading: false,
          app_event_clean_event: response,
        };

        case constant.APP_EVENT_CLEAN_DEVICE_COUNT_INIT_SUCCESS:
        return {
          ...state,
          app_event_clean_device_count_loading: false,
          app_event_clean_device_count: response,
        };

        case constant.APP_EVENT_FRAUDULENT_EVENTS_INIT_SUCCESS:
        return {
          ...state,
          app_event_fraudelent_event_loading: false,
          app_event_fraudelent_event: response,
        };

        case constant.APP_EVENT_CLICK_FRAUD_DATE_INIT_SUCCESS:
        return {
          ...state,
          app_event_click_fraud_date_loading: false,
          app_event_click_fraud_date: response,
        };

        case constant.APP_EVENT_CLICK_FRAUD_PUBLISHER_INIT_SUCCESS:
        return {
          ...state,
          app_event_click_fraud_publisher_loading: false,
          app_event_click_fraud_publisher: response,
        };

        case constant.APP_EVENT_FRAUD_DATE_INIT_SUCCESS:
        return {
          ...state,
          app_event_fraud_date_loading: false,
          app_event_fraud_date: response,
        };

        case constant.APP_EVENT_FRAUD_PUBLISHER_INIT_SUCCESS:
        return {
          ...state,
          app_event_fraud_publisher_loading: false,
          app_event_fraud_publisher: response,
        };

        case constant.APP_EVENT_FRAUD_SUB_PUBLISHER_INIT_SUCCESS:
        return {
          ...state,
          app_event_sub_publisher_loading: false,
          app_event_sub_publisher: response,
        };

        case constant.APP_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT_SUCCESS:
        return {
          ...state,
          app_event_sub_fraud_category_date_loading: false,
          app_event_sub_fraud_category_date: response,
        };

        case constant.APP_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_SUCCESS:
        return {
          ...state,
          app_event_sub_fraud_category_publisher_loading: false,
          app_event_sub_fraud_category_publisher: response,
        };

        case constant.APP_REENGAGEMENT_EVENT_TOTAL_INSTALL_INIT_SUCCESS:
        return {
          ...state,
          app_reengagement_event_total_event_loading: false,
          app_reengagement_event_total_event: response,
        };

        case constant.APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENT_INIT_SUCCESS:
        return {
          ...state,
          app_reengagement_event_total_fraud_event_loading: false,
          app_reengagement_event_total_fraud_event: response,
        };

        case constant.APP_REENGAGEMENT_EVENT_FRAUD_CATEGORY_INIT_SUCCESS:
        return {
          ...state,
          app_reengagement_event_fraudcategory_loading: false,
          app_reengagement_event_fraudcategory: response,
        };

        case constant.APP_REENGAGEMENT_EVENT_FRAUD_SUB_CATEGORY_INIT_SUCCESS:
        return {
          ...state,
          app_reengagement_event_fraudsubcategory_loading: false,
          app_reengagement_event_fraudsubcategory: response,
        };

        // case constant.APP_REENGAGEMENT_EVENT_CLEAN_EVENT_INIT_SUCCESS:
        // return {
        //   ...state,
        //   app_reengagement_event_clean_event_loading: false,
        //   app_reengagement_event_clean_event_error: response,
        // };

        case constant.APP_REENGAGEMENT_EVENT_CLEAN_DEVICE_COUNT_INIT_SUCCESS:
        return {
          ...state,
          app_reengagement_event_clean_device_count_loading: false,
          app_reengagement_event_clean_device_count: response,
        };

        case constant.APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENTS_INIT_SUCCESS:
        return {
          ...state,
          app_reengagement_event_fraudelent_event_loading: false,
          app_reengagement_event_fraudelent_event: response,
        };

        case constant.APP_REENGAGEMENT_EVENT_CLICK_FRAUD_DATE_INIT_SUCCESS:
        return {
          ...state,
          app_reengagement_event_click_fraud_date_loading: false,
          app_reengagement_event_click_fraud_date: response,
        };

        case constant.APP_REENGAGEMENT_EVENT_CLICK_FRAUD_PUBLISHER_INIT_SUCCESS:
        return {
          ...state,
          app_reengagement_event_click_fraud_publisher_loading: false,
          app_reengagement_event_click_fraud_publisher: response,
        };

        case constant.APP_REENGAGEMENT_EVENT_FRAUD_DATE_INIT_SUCCESS:
        return {
          ...state,
          app_reengagement_event_fraud_date_loading: false,
          app_reengagement_event_fraud_date: response,
        };

        case constant.APP_REENGAGEMENT_EVENT_FRAUD_PUBLISHER_INIT_SUCCESS:
        return {
          ...state,
          app_reengagement_event_fraud_publisher_loading: false,
          app_reengagement_event_fraud_publisher: response,
        };

        case constant.APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT_SUCCESS:
        return {
          ...state,
          app_reengagement_event_sub_fraud_category_date_loading: false,
          app_reengagement_event_sub_fraud_category_date: response,
        };

        case constant.APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_SUCCESS:
        return {
          ...state,
          app_reengagement_event_sub_fraud_category_publisher_loading: false,
          app_reengagement_event_sub_fraud_category_publisher: response,
        };

        case constant.APP_REENGAGEMENT_EVENT_FRAUD_SUB_PUBLISHER_SUCCESS:
        return {
          ...state,
          app_reengagement_event_sub_publisher_loading: false,
          app_reengagement_event_sub_publisher: response,
        };

        case constant.APP_REENGAGEMENT_EVENT_NOT_FRAUD_EVENT_INIT_SUCCESS:
          return {
            ...state,
            app_reengagement_event_not_fraud_event_loading: false,
            app_reengagement_event_not_fraud_event: response,
          };

    // reengagement app click

    case constant.APP_REENGAGEMENT_CLICK_TOTAL_INSTALL_INIT_SUCCESS:
      return {
        ...state,
        app_reengagement_click_total_click_loading: false,
        app_reengagement_click_total_click: response,
      };

    case constant.APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICK_INIT_SUCCESS:
      return {
        ...state,
        app_reengagement_click_total_fraud_click_loading: false,
        app_reengagement_click_total_fraud_click: response,
      };

    case constant.APP_REENGAGEMENT_CLICK_FRAUD_CATEGORY_INIT_SUCCESS:
      return {
        ...state,
        app_reengagement_click_fraudcategory_loading: false,
        app_reengagement_click_fraudcategory: response,
      };

    case constant.APP_REENGAGEMENT_CLICK_FRAUD_SUB_CATEGORY_INIT_SUCCESS:
      return {
        ...state,
        app_reengagement_click_fraudsubcategory_loading: false,
        app_reengagement_click_fraudsubcategory: response,
      };

    case constant.APP_REENGAGEMENT_CLICK_CLEAN_CLICK_INIT_SUCCESS:
      return {
        ...state,
        app_reengagement_click_clean_click_loading: false,
        app_reengagement_click_clean_click: response,
      };

    case constant.APP_REENGAGEMENT_CLICK_CLEAN_DEVICE_COUNT_INIT_SUCCESS:
      return {
        ...state,
        app_reengagement_click_clean_device_count_loading: false,
        app_reengagement_click_clean_device_count: response,
      };

    case constant.APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICKS_INIT_SUCCESS:
      return {
        ...state,
        app_reengagement_click_fraudelent_click_loading: false,
        app_reengagement_click_fraudelent_click: response,
      };

    case constant.APP_REENGAGEMENT_CLICK_CLICK_FRAUD_DATE_INIT_SUCCESS:
      return {
        ...state,
        app_reengagement_click_click_fraud_date_loading: false,
        app_reengagement_click_click_fraud_date: response,
      };

    case constant.APP_REENGAGEMENT_CLICK_CLICK_FRAUD_PUBLISHER_INIT_SUCCESS:
      return {
        ...state,
        app_reengagement_click_click_fraud_publisher_loading: false,
        app_reengagement_click_click_fraud_publisher: response,
      };

      //sub publisher 

      case constant.APP_REENGAGEMENT_CLICK_SUB_PUBLISHER_INIT_SUCCESS:
        return {
          ...state,
          app_reengagement_click_sub_publisher_loading: false,
          app_reengagement_click_sub_publisher: response,
        };



    case constant.APP_REENGAGEMENT_CLICK_FRAUD_DATE_INIT_SUCCESS:
      return {
        ...state,
        app_reengagement_click_fraud_date_loading: false,
        app_reengagement_click_fraud_date: response,
      };

    case constant.APP_REENGAGEMENT_CLICK_FRAUD_PUBLISHER_INIT_SUCCESS:
      return {
        ...state,
        app_reengagement_click_fraud_publisher_loading: false,
        app_reengagement_click_fraud_publisher: response,
      };

    case constant.APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_DATE_INIT_SUCCESS:
      return {
        ...state,
        app_reengagement_click_sub_fraud_category_date_loading: false,
        app_reengagement_click_sub_fraud_category_date: response,
      };

    case constant.APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_SUCCESS:
      return {
        ...state,
        app_reengagement_click_sub_fraud_category_publisher_loading: false,
        app_reengagement_click_sub_fraud_category_publisher: response,
      };




        


    // error

        case constant.TOTAL_INCIDENTS_ERROR:
            return {
                ...state,
                incidentloading: false,
                incidentdata_error: response,
            };

        case constant.INCIDENT_VOLUMES_ERROR:
            return {
                ...state,
                incidentvolumneloading: false,
                incidentvolumne_error: response,
            };

        case constant.ACTIVECASE_CHANNEL_ERROR:
            return {
                ...state,
                activecaseloading: false,
                activecases_error: response,
            };

        case constant.SUBCHANNEL_ERROR:
            return {
                ...state,
                subchannelloading: false,
                subchannel_error: response,
            };

        case constant.TOPTEN_LOCATION_ERROR:
            return {
                ...state,
                toptenlocation_loading: false,
                toptenlocation_error: response,
            };

        case constant.CATEGORY_LEVEL_COUNT_ERROR:
            return {
                ...state,
                categorylevelcount_loading: false,
                categorylevelcount_error: response,
            };

    case constant.PUBLISHER_LEVEL_COUNT_ERROR:
      return {
        ...state,
        publisherlevelcount_loading: false,
        publisherlevelcount_error: response,
      };
   //event
      // case constant.TOTAL_EVENT_LIST_INIT_ERROR:
      //   return {
      //     ...state,
      //     totalevent_loading: false,
      //     totalevent_error: response,
      //   };


        // case constant.FRAUD_PERCENT_EVENT_LIST_INIT_ERROR:
        //   return {
        //     ...state,
        //     fraudpercent_loading: false,
        //     fraudpercent_error: response,
        //   };



        // case constant.FRAUD_EVENT_LIST_INIT_ERROR:
        // return {
        //   ...state,
        //   fraudevent_loading: false,
        //   fraudevent_error: response,
        // };

        // case constant.EVENT_FRAUD_CATEGORY_INIT_ERROR:
        // return {
        //   ...state,
        //   eventfraudcategory_loading: false,
        //   eventfraudcategory_error: response,
        // };

        // case constant.EVENT_CLEAN_PUBLISHER_NAME_INIT_ERROR:
        // return {
        //   ...state,
        //   eventcleanpublishername_loading: false,
        //   eventcleanpublishername_error: response,
        // };

        
        // case constant.MONTHWISE_FRAUD_TREND_INIT_ERROR:
        // return {
        //   ...state,
        //   monthwise_fraud_trend_loading: false,
        //   monthwise_fraud_trend_error: response,
        // };

        // case constant.EVENT_FRAUD_PUBLISHER_NAME_INIT_ERROR:
        //     return {
        //       ...state,
        //       eventfraudpublishername_loading: false,
        //       eventfraudpublishername_error: response,
        //     };

            // case constant.EVENT_NOT_FRAUD_PUBLISHER_NAME_INIT_ERROR:
            //     return {
            //       ...state,
            //       eventnotfraudpublishername_loading: false,
            //       eventnotfraudpublishername_error: response,
            //     };

                // case constant.TOTAL_EVENT_FRAUD_EVENT_INIT_ERROR:
                //     return {
                //       ...state,
                //       totaleventfraudevent_loading: false,
                //       totaleventfraudevent_error: response,
                //     };
                    // case constant.FRAUD_EVENT_BY_DATE_INIT_ERROR:
                    //     return {
                    //       ...state,
                    //       fraudeventbydate_loading: false,
                    //       fraudeventbydate_error: response,
                    //     };

                        // case constant.EVENT_TABEL_INIT_ERROR:
                        // return {
                        //   ...state,
                        //   eventtabel_loading: false,
                        //   eventtabel_error: response,
                        // };



    //web

        case constant.WEB_TOTAL_INCIDENTS:
            return {
                ...state,
                web_incidentloading: true,
            };

            case constant.FRAUD_TREND:
            return {
                ...state,
                fraudtrendloading: true,
            };
            case constant.FRAUD_TREND_MONTHLY:
            return {
                ...state,
                fraudtrendmonthlyloading: true,
            };
            case constant.TOP_FIVE_CLEAN_PUBLISHER:
                return {
                    ...state,
                    cleanpublisherloading: true,
                };
                
            case constant.TOP_FIVE_FRAUD_PUBLISHER:
                return {
                    ...state,
                    fraudpublisherloading: true,
                };
        
        case constant.FRAUD_REASON_TREND:
            return {
                ...state,
                fraudreasontrendloading: true,
            };


        //yeah hai
        case constant.HIGH_FRAUD_TRAFFIC_COUNT:
            return {
                ...state,
                highfraudtrafficcount_loading: true,
            };    
        case constant.SET_SIDE_DRAWER:
            return {
                    ...state,
                    setSideDrawer: setSideDrawer,
                };    

           //YEAH HAI TOP 10
           case constant.TOP_TEN_PLACEMENT_COUNT:
            return {
                ...state,
                toptenplacement_loading: true,
            };               

        case constant.FRAUD_VISIT:
            return {
                ...state,
                fraudvisitloading: true,
            };

        case constant.CUMULATIVE_IMPRESSION_TREND_INIT:
            return {
                ...state,
                cumulativevisittrendloading: true,
            };

        case constant.TOPTEN_WEB_LOCATION_INIT:
            return {
                ...state,
                topexchangetraffic_loading: true,
            };

        case constant.TOP_EXCHANGE_TRAFFIC_COUNT:
            return {
                ...state,
                categorylevelcount_loading: true,
            };

        case constant.PUBLISHER_LEVEL_COUNT:
            return {
                ...state,
                publisherlevelcount_loading: true,
            };

            //CLICK
            case constant.CLICK_TOP_FIVE_CLEAN_PUBLISHER:
                return {
                    ...state,
                    click_cleanpublisherloading: true,
                };

            case constant.CLICK_TOP_FIVE_FRAUD_PUBLISHER:
                            return {
                                ...state,
                                click_fraudpublisherloading: true,
                            };
                        
            case constant.CLICK_FRAUD_TREND:
                        return {
                            ...state,
                            click_fraudtrendloading: true,
                        };
                    
            case constant.CLICK_FRAUD_TREND_MONTHLY:
                        return {
                            ...state,
                            click_fraudtrendmonthlyloading: true,
                        };
                    
            case constant.CLICK_TOTAL_INCIDENTS:
                  return {
                    ...state,
                    click_incidentloading: true,
                  };
              
            case constant.CLICK_FRAUD_VISIT:
            return {
                ...state,
                click_fraudvisitloading: true,
            };

        // success

        case constant.WEB_TOTAL_INCIDENTS_SUCCESS:
            return {
                ...state,
                web_incidentloading: false,
                web_incident_data: response.data,
            };


            case constant.FRAUD_TREND_SUCCESS:
                return {
                    ...state,
                    fraudtrendloading: false,
                    fraudtrend_data: response.data,
                };
            case constant.FRAUD_TREND_MONTHLY_SUCCESS:
                    return {
                        ...state,
                        fraudtrendmonthlyloading: false,
                        fraudtrendmonthly_data: response.data,
                    };   

            case constant.TOP_FIVE_CLEAN_PUBLISHER_SUCCESS:
                    return {
                        ...state,
                        cleanpublisherloading: false,
                        cleanpublisher_data: response,
                    };
            case constant.TOP_FIVE_FRAUD_PUBLISHER_SUCCESS:
                    return {
                        ...state,
                        fraudpublisherloading: false,
                        fraudpublisher_data: response,
                    };
        // high success
        case constant.HIGH_FRAUD_TRAFFIC_COUNT_SUCCESS:
            return {
                ...state,
                highfraudtrafficcount_loading: false,
                highfraudtrafficcount_data: response.data,
            };
        //yeah hai top 10
        case constant.TOP_TEN_PLACEMENT_COUNT_SUCCESS:
            return {
                ...state,
                toptenplacement_loading: false,
                toptenplacement_data: response.data,
            };

        case constant.FRAUD_REASON_TREND_SUCCESS:
            return {
                ...state,
                fraudreasontrendloading: false,
                fraudreasontrend_data: response,
            };

        case constant.FRAUD_VISIT_SUCCESS:
            return {
                ...state,
                fraudvisitloading: false,
                fraudvisit_data: response.data,
            };

        case constant.CUMULATIVE_IMPRESSION_TREND_SUCCESS:
            return {
                ...state,
                cumulativevisittrendloading: false,
                cumulativevisittrend_data: response.data,
            };

        case constant.TOPTEN_WEB_LOCATION_SUCCESS:
            return {
                ...state,
                topexchangetraffic_loading: false,
                topexchangetraffic_data: response.data,
            };

        case constant.TOP_EXCHANGE_TRAFFIC_SUCCESS:
            return {
                ...state,
                categorylevelcount_loading: false,
                categorylevelcount_data: response.data,
            };

        // case constant.PUBLISHER_LEVEL_COUNT_SUCCESS:
        //     return {
        //         ...state,
                        //         publisherlevelcount_loading: false,
                        //         publisherlevelcount_data: response.data,
                        //     };

                        case constant.CLICK_TOP_FIVE_CLEAN_PUBLISHER_SUCCESS:
                                    return {
                                        ...state,
                                        click_cleanpublisherloading: false,
                                        click_cleanpublisher_data: response.data,
                                    };
                                
                case constant.CLICK_TOP_FIVE_FRAUD_PUBLISHER_SUCCESS:
                                    return {
                                        ...state,
                                        click_fraudpublisherloading: false,
                                        click_fraudpublisher_data: response.data,
                                    };
                                
                    case constant.CLICK_FRAUD_TREND_SUCCESS:
                                return {
                                    ...state,
                                    click_fraudtrendloading: false,
                                    click_fraudtrend_data: response.data,
                                };
                            
                            
                            case constant.CLICK_FRAUD_TREND_MONTHLY_SUCCESS:
                                    return {
                                        ...state,
                                        click_fraudtrendmonthlyloading: false,
                                        click_fraudtrendmonthly_data: response.data,
                                    }; 
                                
                case constant.CLICK_TOTAL_INCIDENTS_SUCCESS:
                            return {
                                ...state,
                                click_incidentloading: false,
                                click_incident_data: response.data,
                            };
                        
                case constant.CLICK_FRAUD_VISIT_SUCCESS:
                            return {
                                 ...state,
                                 click_fraudvisitloading: false,
                                 click_fraudvisit_data: response.data,
            };

            

        // error


        case constant.WEB_TOTAL_INCIDENTS_ERROR:
            return {
                ...state,
                web_incidentloading: false,
                web_incidentdata_error: response,
            };

        case constant.FRAUD_REASON_TREND_ERROR:
            return {
                ...state,
                fraudreasontrendloading: false,
                fraudreasontrend_error: response,
            };
        case constant.FRAUD_TREND_MONTHLY_ERROR:
                return {
                    ...state,
                    fraudtrendmonthlyloading: false,
                    fraudtrendmonthly_error: response,
                };   

        case constant.FRAUD_TREND_ERROR:
                return {
                    ...state,
                    fraudtrendloading: false,
                    fraudtrend_error: response,
                };
        case constant.TOP_FIVE_CLEAN_PUBLISHER_ERROR:
                    return {
                        ...state,
                        cleanpublisherloading: false,
                        cleanpublisher_error: response,
                    };  
        case constant.TOP_FIVE_FRAUD_PUBLISHER_ERROR:
                        return {
                            ...state,
                            fraudpublisherloading: false,
                            fraudpublisher_error: response,
                        };               

        case constant.FRAUD_VISIT_ERROR:
            return {
                ...state,
                fraudvisitloading: false,
                fraudvisit_error: response,
            };

            case constant.FRAUD_PUBLISHER_WISE_ERROR:
              return {
                  ...state,
                  fraud_trend_publisher_loading: false,
                  fraud_trend_publisher_error: response,
              };

        case constant.CUMULATIVE_IMPRESSION_TREND_ERROR:
            return {
                ...state,
                cumulativevisittrendloading: false,
                cumulativevisittrend_error: response,
            };

        case constant.TOPTEN_WEB_LOCATION_ERROR:
            return {
                ...state,
                topexchangetraffic_loading: false,
                topexchangetraffic_error: response,
            };

        case constant.TOP_EXCHANGE_TRAFFIC_ERROR:
            return {
                ...state,
                categorylevelcount_loading: false,
                categorylevelcount_error: response,
            };
            //high fraud
            case constant.HIGH_FRAUD_TRAFFIC_COUNT_ERROR:
            return {
                ...state,
                highfraudtrafficcount_loading: false,
                highfraudtrafficcount_error: response,
            };
            ////// TOP 10
            case constant.TOP_TEN_PLACEMENT_COUNT_ERROR:
                return {
                    ...state,
                    toptenplacement_loading: false,
                    toptenplacement_error: response,
                };

                case constant.CLICK_TOP_FIVE_CLEAN_PUBLISHER_ERROR:
                    return {
                        ...state,
                        click_cleanpublisherloading: false,
                        click_cleanpublisher_error: response.data,
                    };

                case constant.CLICK_TOP_FIVE_FRAUD_PUBLISHER_ERROR:
                                    return {
                                        ...state,
                                        click_fraudpublisherloading: false,
                                        click_fraudpublisher_error: response.data,
                                    };
                                
                case constant.CLICK_FRAUD_TREND_ERROR:
                                return {
                                    ...state,
                                    click_fraudtrendloading: false,
                                    click_fraudtrend_error: response.data,
                                };
                            
                            
                case constant.CLICK_FRAUD_TREND_MONTHLY_ERROR:
                                return {
                                    ...state,
                                    click_fraudtrendmonthlyloading: false,
                                    click_fraudtrendmonthly_error: response.data,
                                }; 
                                
                case constant.CLICK_TOTAL_INCIDENTS_ERROR:
                            return {
                                ...state,
                                click_incidentloading: false,
                                click_incidentdata_error: response.data,
                            };
                        
                case constant.CLICK_FRAUD_VISIT_ERROR:
                            return {
                                ...state,
                                click_fraudvisitloading: false,
                                click_fraudvisit_error: response.data,
                            };

                case constant.INSTALL_TOTAL_INSTALL_INIT_ERROR:
                    return {
                        ...state,
                        app_total_install_loading: false,
                        app_total_install_error: error,
                    };

                case constant.INSTALL_FRAUDULENT_INSTALL_INIT_ERROR:
                return {
                    ...state,
                    app_total_fraud_install_loading: false,
                    app_total_fraud_install_error: error,
                };

                case constant.INSTALL_CLEAN_EVENT_INIT_ERROR:
                return {
                    ...state,
                    app_clean_install_loading: false,
                    app_clean_install_error: error,
                };

                case constant.INSTALL_FRAUDULENT_EVENT_INIT_ERROR:
                return {
                    ...state,
                    app_fraudelent_install_loading: false,
                    app_fraudelent_install_error: error,
                };

                case constant.INSTALL_FRAUD_CATEGORY_INIT_ERROR:
                return {
                    ...state,
                    app_fraudcategory_loading: false,
                    app_fraudcategory_error: error,
                };

                case constant.INSTALL_FRAUD_SUB_CATEGORY_INIT_ERROR:
                return {
                    ...state,
                    app_fraudsubcategory_loading: false,
                    app_fraudsubcategory_error: error,
                };

                case constant.INSTALL_CLEAN_DEVICE_COUNT_INIT_ERROR:
                return {
                    ...state,
                    app_clean_device_count_loading: false,
                    app_clean_device_count_error: error,
                };

                case constant.INSTALL_CLICK_FRAUD_DATE_INIT_ERROR:
                return {
                    ...state,
                    app_click_fraud_date_loading: false,
                    app_click_fraud_date_error: error,
                };

                case constant.INSTALL_CLICK_FRAUD_PUBLISHER_INIT_ERROR:
                return {
                    ...state,
                    app_click_fraud_publisher_loading: false,
                    app_click_fraud_publisher_error: error,
                };

                case constant.INSTALL_FRAUD_DATE_INIT_ERROR:
                return {
                    ...state,
                    app_install_fraud_date_loading: false,
                    app_install_fraud_date_error: error,
                };

                case constant.INSTALL_FRAUD_PUBLISHER_INIT_ERROR:
                return {
                    ...state,
                    app_install_fraud_publisher_loading: false,
                    app_install_fraud_publisher_error: error,
                };

                case constant.INSTALL_SUB_FRAUD_CATEGORY_DATE_INIT_ERROR:
                return {
                    ...state,
                    app_install_sub_fraud_category_date_loading: false,
                    app_install_sub_fraud_category_date_error: error,
                };

                case constant.INSTALL_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_ERROR:
                return {
                    ...state,
                    app_install_sub_fraud_category_publisher_loading: false,
                    app_install_sub_fraud_category_publisher_error: error,
                };

                case constant.INSTALL_SUB_PUBLISHER_DATE_INIT_ERROR:
                return {
                    ...state,
                    app_install_sub_publisher_date_loading: false,
                    app_install_sub_publisher_date_error: error,
                };

                case constant.INSTALL_SUB_PUBLISHER_INIT_ERROR:
                return {
                    ...state,
                    app_install_sub_publisher_loading: false,
                    app_install_sub_publisher_error: error,
                };


                case constant.APP_INSTALL_TOTAL_INSTALL_INIT_ERROR:
                    return {
                        ...state,
                        app_event_total_event_loading: false,
                        app_event_total_event_error: error,
                    };

                case constant.APP_EVENT_FRAUDULENT_EVENT_INIT_ERROR:
                return {
                    ...state,
                    app_event_total_fraud_event_loading: false,
                    app_event_total_fraud_event_error: error,
                };

                case constant.APP_EVENT_CLEAN_EVENT_INIT_ERROR:
                return {
                    ...state,
                    app_event_clean_event_loading: false,
                    app_event_clean_event_error: error,
                };

                case constant.APP_EVENT_FRAUD_CATEGORY_INIT_ERROR:
                return {
                    ...state,
                    app_event_fraudcategory_loading: false,
                    app_event_fraudcategory_error: error,
                };

                case constant.APP_EVENT_FRAUD_SUB_CATEGORY_INIT_ERROR:
                return {
                    ...state,
                    app_event_fraudsubcategory_loading: false,
                    app_event_fraudsubcategory_error: error,
                };

                case constant.APP_EVENT_CLEAN_DEVICE_COUNT_INIT_ERROR:
                return {
                    ...state,
                    app_event_clean_device_count_loading: false,
                    app_event_clean_device_count_error: error,
                };

                case constant.APP_EVENT_FRAUDULENT_EVENTS_INIT_ERROR:
                return {
                    ...state,
                    app_event_fraudelent_event_loading: false,
                    app_event_fraudelent_event_error: error,
                };

                case constant.APP_EVENT_CLICK_FRAUD_DATE_INIT_ERROR:
                return {
                    ...state,
                    app_event_click_fraud_date_loading: false,
                    app_event_click_fraud_date_error: error,
                };

                case constant.APP_EVENT_CLICK_FRAUD_PUBLISHER_INIT_ERROR:
                return {
                    ...state,
                    app_event_click_fraud_publisher_loading: false,
                    app_event_click_fraud_publisher_error: error,
                };

                case constant.APP_EVENT_FRAUD_DATE_INIT_ERROR:
                return {
                    ...state,
                    app_event_fraud_date_loading: false,
                    app_event_fraud_date_error: error,
                };

                case constant.APP_EVENT_FRAUD_PUBLISHER_INIT_ERROR:
                return {
                    ...state,
                    app_event_fraud_publisher_loading: false,
                    app_event_fraud_publisher_error: error,
                };

                case constant.APP_EVENT_FRAUD_SUB_PUBLISHER_INIT_ERROR:
                return {
                    ...state,
                    app_event_sub_publisher_loading: false,
                    app_event_sub_publisher_error: error,
                };

                
                case constant.APP_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT_ERROR:
                return {
                    ...state,
                    app_event_sub_fraud_category_date_loading: false,
                    app_event_sub_fraud_category_date_error: error,
                };

                case constant.APP_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_ERROR:
                return {
                    ...state,
                    app_event_sub_fraud_category_publisher_loading: false,
                    app_event_sub_fraud_category_publisher_error: error,
                };

                case constant.APP_REENGAGEMENT_EVENT_TOTAL_INSTALL_INIT_ERROR:
                return {
                    ...state,
                    app_reengagement_event_total_event_loading: false,
                    app_reengagement_event_total_event_error: error,
                };

                case constant.APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENT_INIT_ERROR:
                return {
                    ...state,
                    app_reengagement_event_total_fraud_event_loading: false,
                    app_reengagement_event_total_fraud_event_error: error,
                };

                case constant.APP_REENGAGEMENT_EVENT_FRAUD_CATEGORY_INIT_ERROR:
                return {
                    ...state,
                    app_reengagement_event_fraudcategory_loading: false,
                    app_reengagement_event_fraudcategory_error: error,
                };

                case constant.APP_REENGAGEMENT_EVENT_FRAUD_SUB_CATEGORY_INIT_ERROR:
                return {
                    ...state,
                    app_reengagement_event_fraudsubcategory_loading: false,
                    app_reengagement_event_fraudsubcategory_error: error,
                };

                // case constant.APP_REENGAGEMENT_EVENT_CLEAN_EVENT_INIT_ERROR:
                // return {
                //     ...state,
                //     app_reengagement_event_clean_event_loading: false,
                //     app_reengagement_event_clean_event_error: error,
                // };

                case constant.APP_REENGAGEMENT_EVENT_CLEAN_DEVICE_COUNT_INIT_ERROR:
                return {
                    ...state,
                    app_reengagement_event_clean_device_count_loading: false,
                    app_reengagement_event_clean_device_count_error: error,
                };

                case constant.APP_REENGAGEMENT_EVENT_FRAUDULENT_EVENTS_INIT_ERROR:
                return {
                    ...state,
                    app_reengagement_event_fraudelent_event_loading: false,
                    app_reengagement_event_fraudelent_event_error: error,
                };

                case constant.APP_REENGAGEMENT_EVENT_CLICK_FRAUD_DATE_INIT_ERROR:
                return {
                    ...state,
                    app_reengagement_event_click_fraud_date_loading: false,
                    app_reengagement_event_click_fraud_date_error: error,
                };

                case constant.APP_REENGAGEMENT_EVENT_CLICK_FRAUD_PUBLISHER_INIT_ERROR:
                return {
                    ...state,
                    app_reengagement_event_click_fraud_publisher_loading: false,
                    app_reengagement_event_click_fraud_publisher_error: error,
                };

                case constant.APP_REENGAGEMENT_EVENT_FRAUD_DATE_INIT_ERROR:
                return {
                    ...state,
                    app_reengagement_event_fraud_date_loading: false,
                    app_reengagement_event_fraud_date_error: error,
                };

                case constant.APP_REENGAGEMENT_EVENT_FRAUD_PUBLISHER_INIT_ERROR:
                return {
                    ...state,
                    app_reengagement_event_fraud_publisher_loading: false,
                    app_reengagement_event_fraud_publisher_error: error,
                };

                
                case constant.APP_REENGAGEMENT_EVENT_FRAUD_SUB_PUBLISHER_ERROR:
                return {
                    ...state,
                    app_reengagement_event_sub_publisher_loading: false,
                    app_reengagement_event_sub_publisher_error: error,
                };

                case constant.APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_DATE_INIT_ERROR:
                return {
                    ...state,
                    app_reengagement_event_sub_fraud_category_date_loading: false,
                    app_reengagement_event_sub_fraud_category_date_error: error,
                };

                case constant.APP_REENGAGEMENT_EVENT_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_ERROR:
                return {
                    ...state,
                    app_reengagement_event_sub_fraud_category_publisher_loading: false,
                    app_reengagement_event_sub_fraud_category_publisher_error: error,
                };

                case constant.APP_REENGAGEMENT_EVENT_NOT_FRAUD_EVENT_INIT_ERROR:
                return {
                    ...state,
                    app_reengagement_event_not_fraud_event_loading: false,
                    app_reengagement_event_not_fraud_event_error: error,
                };


 // app engagement click

    case constant.APP_REENGAGEMENT_CLICK_TOTAL_INSTALL_INIT_ERROR:
      return {
        ...state,
        app_reengagement_click_total_click_loading: false,
        app_reengagement_click_total_click_error: error,
      };

    case constant.APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICK_INIT_ERROR:
      return {
        ...state,
        app_reengagement_click_total_fraud_click_loading: false,
        app_reengagement_click_total_fraud_click_error: error,
      };

    case constant.APP_REENGAGEMENT_CLICK_FRAUD_CATEGORY_INIT_ERROR:
      return {
        ...state,
        app_reengagement_click_fraudcategory_loading: false,
        app_reengagement_click_fraudcategory_error: error,
      };

    case constant.APP_REENGAGEMENT_CLICK_FRAUD_SUB_CATEGORY_INIT_ERROR:
      return {
        ...state,
        app_reengagement_click_fraudsubcategory_loading: false,
        app_reengagement_click_fraudsubcategory_error: error,
      };

    case constant.APP_REENGAGEMENT_CLICK_CLEAN_CLICK_INIT_ERROR:
      return {
        ...state,
        app_reengagement_click_clean_click_loading: false,
        app_reengagement_click_clean_click_error: error,
      };

    case constant.APP_REENGAGEMENT_CLICK_CLEAN_DEVICE_COUNT_INIT_ERROR:
      return {
        ...state,
        app_reengagement_click_clean_device_count_loading: false,
        app_reengagement_click_clean_device_count_error: error,
      };

    case constant.APP_REENGAGEMENT_CLICK_FRAUDULENT_CLICKS_INIT_ERROR:
      return {
        ...state,
        app_reengagement_click_fraudelent_click_loading: false,
        app_reengagement_click_fraudelent_click_error: error,
      };

    case constant.APP_REENGAGEMENT_CLICK_CLICK_FRAUD_DATE_INIT_ERROR:
      return {
        ...state,
        app_reengagement_click_fraudelent_click_loading: false,
        app_reengagement_click_click_fraud_date_error: error,
      };

    case constant.APP_REENGAGEMENT_CLICK_CLICK_FRAUD_PUBLISHER_INIT_ERROR:
      return {
        ...state,
        app_reengagement_click_click_fraud_publisher_loading: false,
        app_reengagement_click_click_fraud_publisher_error: error,
      };

      //sub publisher 


      case constant.APP_REENGAGEMENT_CLICK_SUB_PUBLISHER_INIT_ERROR:
        return {
          ...state,
          app_reengagement_click_sub_publisher_loading: false,
          app_reengagement_click_sub_publisher_error: error,
        };



    case constant.APP_REENGAGEMENT_CLICK_FRAUD_DATE_INIT_ERROR:
      return {
        ...state,
        app_reengagement_click_fraud_date_loading: false,
        app_reengagement_click_fraud_date_error: error,
      };

    case constant.APP_REENGAGEMENT_CLICK_FRAUD_PUBLISHER_INIT_ERROR:
      return {
        ...state,
        app_reengagement_click_fraud_publisher_loading: false,
        app_reengagement_click_fraud_publisher_error: error,
      };

    case constant.APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_DATE_INIT_ERROR:
      return {
        ...state,
        app_reengagement_click_sub_fraud_category_date_loading: false,
        app_reengagement_click_sub_fraud_category_date_error: error,
      };

      case constant.APP_REENGAGEMENT_CLICK_SUB_FRAUD_CATEGORY_PUBLISHER_INIT_ERROR:
        return {
          ...state,
          app_reengagement_click_sub_fraud_category_publisher_loading: false,
          app_reengagement_click_sub_fraud_category_publisher_error: error,
        };







    case constant.PUBLISHER_APP_GET_INIT:
      return {
        ...state,
        app_publisher_loading: true,
      };
    case constant.PUBLISHER_APP_GET_INIT_SUCCESS:
      return {
        ...state,
        app_publisher_loading: false,
        app_publisher_data: response.data,
        total_count: response.Total_pages,
      };
    case constant.PUBLISHER_APP_GET_INIT_ERROR:
      return {
        ...state,
        app_publisher_loading: false,
        app_publisher_error: error,
      };

    case constant.PUBLISHER_APP_GET_DOWNLOAD_INIT:
      return {
        ...state,
        app_publisher_download_loading: true,
      };
    case constant.PUBLISHER_APP_GET_DOWNLOAD_INIT_SUCCESS:
      return {
        ...state,
        app_publisher_download_loading: false,
        // status: true,
        app_publisher_download_data: response,
      };
    case constant.PUBLISHER_APP_GET_DOWNLOAD_INIT_ERROR:
      return {
        ...state,
        app_publisher_download_loading: false,
        // error_status: error.response.status,
        app_publisher_download_error: error,
      };
    case constant.PUBLISHER_APP_GET_DOWNLOAD_INIT_CLEAR:
      return {
        ...state,
        app_publisher_download_loading: false,
        app_publisher_download_data: null,
        // error_status: error.response.status,
        app_publisher_download_error: null,
      };

                case constant.COLORS_INIT_ERROR:
                return {
                    ...state,
                    colors_loading: false,
                    colors_error: response,
                };

    default:
      return state;
  }
};
export default DashboardReducer;
