import React, { useState } from "react";
import { Row, Col, Card, Container, Tab, Nav, Button } from "react-bootstrap";
import { Form } from "formik";
import './index.css';
import toast, { Toaster } from "react-hot-toast";

const BrandHygen = () => {
  const [fieldValueImage, setFieldValueImage] = useState("");
  const [fieldValueVideo, setFieldValueVideo] = useState("");
  const [url, setUrl] = useState("");
  const [labelsNameArrayValues, setLabelsNameArrayValues] = useState([]);
  const [labelsNameArrayValuesForImage, setLabelsNameArrayValuesForImage] = useState([]);

  function submitImageVideo(typeOfFile) {
    if(typeOfFile == "image" && !fieldValueImage){
      toast.error("Please upload image file");
      return;
    }else if(typeOfFile == "video" && !fieldValueVideo){
      toast.error("Please upload video file");
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Im1maWx0ZXJpdF93ZWJzaXRlIn0.ZE98lcxkHDpvUcS_c111Q0Jeg6gvzeyZjL4k8n4PAXo"
    );

    var formdata = new FormData();
    typeOfFile == "image" ? formdata.append("filename", fieldValueImage) : formdata.append("filename", fieldValueVideo);
    
    formdata.append("function", "generic_all");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    toast.success("File is getting scanned.Please wait!")

    fetch("https://brandsafetydashboard.mfilterit.net/hygiene", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // const emotionArray =
        //   result?.body?.result?.response.find((item) => item.emotion)
        //     ?.emotion || [];
        // const genderArray =
        //   result?.body?.result?.response.find((item) => item.gender)?.gender ||
        //   [];
        // const celebrityObject =
        //   result?.body?.result?.response.find((item) => item.celebrity)
        //     ?.celebrity || {};
          let labelNamesArray;
          let labelNamesArrayforImage;
          if(typeOfFile == "video"){
            const detectLabelResponseArray =
          result?.body?.result?.response.find(
            (item) => item.detect_label_response
          )?.detect_label_response || [];
            labelNamesArray = detectLabelResponseArray.map(
              (label) => label.label_name
            );
            setLabelsNameArrayValues(labelNamesArray);
          }else{
            let arrayOfImagesWords = [];
                const detectLabelResponseArray =
              result?.body?.result?.response.map(
                (item) => {
                  item?.label_name && arrayOfImagesWords.push(item?.label_name)
                }
              )?.label_name || [];
              setLabelsNameArrayValuesForImage(arrayOfImagesWords)
          }
      })
      .catch((error) => {
        toast.error("Something went wrong!")
        console.log("error", error);
      });
  }

  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Toaster position="top-right" />
      <Row className="mr-0 ml-0">
        <Col sm={12} md={12} lg={12} xl={12} className="pl-0 pr-0">
          <div
            style={{
              backgroundColor: "white",
              height: "70px",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            <h3>Brand Hygene</h3>
          </div>
          <Nav
            variant="pills"
            className="flex-row d-flex justify-content-center mt-3"
          >
            <Nav.Item
              style={{
                backgroundColor: "#530093",
                color: "white",
                borderRadius: "4px",
              }}
            >
              <Nav.Link eventKey="first">Image Upload</Nav.Link>
            </Nav.Item>
            <Nav.Item
              style={{
                backgroundColor: "#530093",
                color: "white",
                borderRadius: "4px",
              }}
            >
              <Nav.Link eventKey="second">Video Upload</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={12} xs={12} md={12} lg={12} xl={12} className="pl-0 pr-0">
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <div
                className="d-flex align-items-center flex-column"
                style={{ height: "79vh" }}
              >
                <div
                  className="card d-flex justify-content-center"
                  style={{
                    height: "10%",
                    padding: "15px",
                    width: "40%",
                    marginTop: "20px",
                  }}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <label
                      htmlFor="file"
                      style={{ marginRight: "1rem", marginBottom: "0" }}
                    >
                      Choose Image File:
                    </label>
                    <input
                      type="file"
                      id="file"
                      name="file"
                      accept="image/*"
                      onChange={(e) => {
                        if(e.currentTarget.files[0].type.includes("video")) {
                          toast.error("Please upload image file");
                          return;
                        }
                        setFieldValueImage(e.currentTarget.files[0]);
                      }}
                    />
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={() => submitImageVideo("image")}
                    >
                      Upload
                    </Button>
                  </div>
                </div>
                <div className="mt-8" style={{ height: "10%" }}>
                  <h3>Categories and Objects of image</h3>
                </div>
                <div>
                <ul
                    style={{
                      height: "400px",
                      width: "300px",
                      overflowY: "auto",
                    }}
                    className="ulContainer"
                  >
                    {labelsNameArrayValuesForImage.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <div
                className="d-flex align-items-center flex-column"
                style={{ height: "79vh" }}
              >
                <div
                  className="card d-flex justify-content-center"
                  style={{
                    height: "10%",
                    padding: "15px",
                    width: "40%",
                    marginTop: "20px",
                  }}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <label
                      htmlFor="file"
                      style={{ marginRight: "1rem", marginBottom: "0" }}
                    >
                      Choose Video File:
                    </label>
                    <input
                      type="file"
                      id="file"
                      name="file"
                      accept="video/*"
                      onChange={(e) => {
                        if(e.currentTarget.files[0].type.includes("image")) {
                          toast.error("Please upload video file")
                          return;
                        }
                        setFieldValueVideo(e.currentTarget.files[0]);
                      }}
                    />
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={() => submitImageVideo("video")}
                    >
                      Upload
                    </Button>
                  </div>
                </div>
                <div className="mt-8" style={{ height: "10%" }}>
                  <h3>Categories and Objects of video</h3>
                </div>
                  <ul
                    style={{
                      height: "400px",
                      width: "300px",
                      overflowY: "auto",

                    }}
                    className="ulContainer"
                  >
                    {labelsNameArrayValues.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
               
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default BrandHygen;
