/* eslint-disable no-unreachable */
import { EndPoints, ApiUrl, AppInstall } from '../helpers/Endpoints';

const DashboardServices = {};

const token = localStorage.getItem('token');
const headers = {
  // Accept: '*/*',
  authorization: token,
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

DashboardServices.ViewTotalincidents = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.counts_data + '?' + searchParams;
  return fetch(url, {
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewIncidentVolumes = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.incident_volume + '?' + searchParams;
  return fetch(url, {
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewActivecasesbyChannel = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.activecases_channel + '?' + searchParams;
  return fetch(url, {
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewSubchannel = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.sub_channel + '?' + searchParams;
  return fetch(url, {
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewToptenlocation = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.topten_location + '?' + searchParams;
  return fetch(url, {
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewCategorylevelcount = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.category_level_count + '?' + searchParams;
  return fetch(url, {
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewPublisherlevelcount = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.publisher_level_count + '?' + searchParams;
  return fetch(url, {
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewTotalCount = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );

  let url = ApiUrl + EndPoints.web_counts_data + '?' + searchParams;
  console.log(url, 'this is the url123--------');
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      console.log(data);
      return data;

      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewHighFraudTraffic = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.web_high_fraud_traffic + '?' + searchParams;
  console.log(url, 'highfraudtraffic=====-----------');
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewTopfivefraudpublisher = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.web_fraud_publisher + '?' + searchParams;
  console.log(url, 'highfraudtraffic=====-----------');
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
DashboardServices.ViewTopfivecleanpublisher = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.web_clean_publisher + '?' + searchParams;
  console.log(url, 'highfraudtraffic=====-----------');
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewFraudtrend = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.web_fraud_trend_day + '?' + searchParams;
  console.log(url, 'fraudtrend');
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewFraudtrendMonthly = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.web_fraud_trend_month + '?' + searchParams;
  console.log(url, 'fraudtrendmonth');
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewTopTenPlacement = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.web_topten_placement + '?' + searchParams;
  // request(url)

  console.log(url, 'highfraudtraffic1111111111=====-----------');
  console.log('jhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh');
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewFraudReasonTrend = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );

  let url = ApiUrl + EndPoints.web_fraud_reason_trend + '?' + searchParams;
  console.log(url, 'fraud reason trend----');
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewFraudVisit = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.web_fraud_visit + '?' + searchParams;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewCumulativeImpressionTrend = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url =
    ApiUrl + EndPoints.web_cumulative_impression_trend + '?' + searchParams;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewTopexchangetraffic = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.web_top_exchange_traffic + '?' + searchParams;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
DashboardServices.ViewTotalEvent = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.total_events + '?' + searchParams;
  console.log('urlllllllll', url);
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewFraudPercent = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.event_fraud_percent + '?' + searchParams;
  //console.log("urlllllllll" , url )
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewFraudEvent = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.fraud_events + '?' + searchParams;
  console.log('urlllllllll', url);
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewEventFraudCategory = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.event_fraud_category + '?' + searchParams;
  console.log('urlllllllll', url);
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewEventCleanPublisherName = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.event_clean_publisher_name + '?' + searchParams;
  //  let url="https://dashboard-api.mfilterit.net/api/web-bi/event/plots/total_events?package_name=web.nutrabay.cpv&fromDate=2020-01-01&toDate=2023-01-01&page=all&channel=all&campaign=all&publisher=all&event=all"
  console.log('urlllllllll', url);
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewMonthwiseFraud = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.monthwise_fraud_event + '?' + searchParams;
  //  let url="https://dashboard-api.mfilterit.net/api/web-bi/event/plots/total_events?package_name=web.nutrabay.cpv&fromDate=2020-01-01&toDate=2023-01-01&page=all&channel=all&campaign=all&publisher=all&event=all"
  console.log('urlllllllll', url);
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewEventFraudPublisherName = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.event_fraud_publisher_name + '?' + searchParams;
  //  let url="https://dashboard-api.mfilterit.net/api/web-bi/event/plots/total_events?package_name=web.nutrabay.cpv&fromDate=2020-01-01&toDate=2023-01-01&page=all&channel=all&campaign=all&publisher=all&event=all"
  console.log('urlllllllll', url);
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewEventNotFraudPublisherName = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url =
    ApiUrl + EndPoints.event_not_fraud_publisher_name + '?' + searchParams;
  console.log('urlllllllll', url);
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewTotalEventFraudEvent = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.total_event_fraud_event + '?' + searchParams;
  console.log('urlllllllll', url);
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewFraudEventByDate = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.fraud_event_by_date + '?' + searchParams;
  console.log('urlllllllll', url);
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewEventTable = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.event_tabel + '?' + searchParams;
  console.log('urlllllllll', url);
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

//click

DashboardServices.ViewClickTopfivecleanpublisher = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.web_click_clean_publisher + '?' + searchParams;
  // console.log(url, 'highfraudtraffic=====-----------');
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewClickTopfivefraudpublisher = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.web_click_fraud_publisher + '?' + searchParams;
  // console.log(url, 'highfraudtraffic=====-----------');
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewClickFraudtrend = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.web_click_fraud_trend_day + '?' + searchParams;
  // console.log(url, 'fraudtrend');
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewClickFraudtrendMonthly = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.web_click_fraud_trend_month + '?' + searchParams;
  // console.log(url, 'fraudtrendmonth');
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewClickTotalIncidentsSaga = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.web_click_counts_data + '?' + searchParams;
  // console.log(url, 'fraudtrendmonth');
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewClickFraudVisit = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = ApiUrl + EndPoints.web_click_fraud_visit + '?' + searchParams;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewInstallTotalInstalls = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = AppInstall + EndPoints.app_total_install + '?' + searchParams;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewInstallTotalFraudInstalls = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = AppInstall + EndPoints.app_fraudulent_install + '?' + searchParams;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

DashboardServices.ViewInstallCleanInstalls = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = AppInstall + EndPoints.app_clean_install + '?' + searchParams;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

//App re-enagegment dashboard services
//1
DashboardServices.ViewReengagementTotalClick = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = AppInstall + EndPoints.app_clean_install + '?' + searchParams;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
//2
DashboardServices.ViewReengagementFraudulentClick = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = AppInstall + EndPoints.app_clean_install + '?' + searchParams;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
//3
DashboardServices.ViewReengagementFraudCategory = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = AppInstall + EndPoints.app_clean_install + '?' + searchParams;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
//4
DashboardServices.ViewReengagementFraudSubCategory = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = AppInstall + EndPoints.app_clean_install + '?' + searchParams;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
//5
DashboardServices.ViewReengagementCleanClick = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = AppInstall + EndPoints.app_clean_install + '?' + searchParams;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
//6
DashboardServices.ViewReengagementClickCleanDeviceCount = function(
  queryParams
) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = AppInstall + EndPoints.app_clean_install + '?' + searchParams;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
//7
DashboardServices.ViewReengagementClickFraudClick = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = AppInstall + EndPoints.app_clean_install + '?' + searchParams;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
//8
DashboardServices.ViewReengagementClickFraudDate = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = AppInstall + EndPoints.app_clean_install + '?' + searchParams;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
//9

DashboardServices.ViewReengagementClickFraudPublisher = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = AppInstall + EndPoints.app_clean_install + '?' + searchParams;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
//10
DashboardServices.ViewReengagementFraudDate = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = AppInstall + EndPoints.app_clean_install + '?' + searchParams;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
//11
DashboardServices.ViewReengagementClickFraudPublisher = function(queryParams) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = AppInstall + EndPoints.app_clean_install + '?' + searchParams;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
//12
DashboardServices.ViewReengagementClickSubFraudCategoryDate = function(
  queryParams
) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = AppInstall + EndPoints.app_clean_install + '?' + searchParams;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};
//13
DashboardServices.ViewReengagementClickSubFraudPublisher = function(
  queryParams
) {
  let searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key =>
    searchParams.append(key, queryParams[key])
  );
  let url = AppInstall + EndPoints.app_clean_install + '?' + searchParams;
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers,
  })
    .then(async response => {
      const data = await response.json();
      return data;
      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
};

export default DashboardServices;
