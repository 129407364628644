import WebDashboardConst from "./constant";
import { combineReducers } from "redux";

const DASHBOARD_TYPE = {
  CLICK: "CLICK",
  EVENT: "EVENT",
  IMPRESSION: "IMPRESSION",
  VISIT: "VISIT",
  VAST: "VAST",
  REPORT_FILTERS: "REPORT_FILTERS",
};

function DashboardReducer(type) {
  return function(state = {}, action) {
    if (action.id === undefined) return state;
    switch (action.type) {
      case WebDashboardConst.DASHBOARD[type][action.id]?.INIT: {
        const data = { loading: true, data: null, error: null };
        const newState = { ...state };
        newState[`${action.id}`] = data;
        return newState;
      }
      case WebDashboardConst.DASHBOARD[type][action.id]?.SUCCESS: {
        const data = { loading: false, data: action.data, error: null };
        const newState = { ...state };
        newState[`${action.id}`] = data;
        return newState;
      }
      case WebDashboardConst.DASHBOARD[type][action.id]?.ERROR: {
        const data = { loading: false, data: null, error: action.error };
        const newState = { ...state };
        newState[`${action.id}`] = data;
        return newState;
      }
      default:
        return state;
    }
  };
}

function FilterReducer(type) {
  return function(state = {}, action) {
    if (action.id === undefined) return state;
    switch (action.type) {
      case WebDashboardConst.FILTER[type][action.id]?.INIT: {
        const data = { loading: true, data: null, error: null };
        const newState = { ...state };
        newState[`${action.id}`] = data;
        return newState;
      }
      case WebDashboardConst.FILTER[type][action.id]?.SUCCESS: {
        const data = { loading: false, data: action.data, error: null };
        const newState = { ...state };
        newState[`${action.id}`] = data;
        return newState;
      }
      case WebDashboardConst.FILTER[type][action.id]?.ERROR: {
        const data = { loading: false, data: null, error: action.error };
        const newState = { ...state };
        newState[`${action.id}`] = data;
        return newState;
      }
      default:
        return state;
    }
  };
}

function ColorReducer(state = {}, action) {
  switch (action.type) {
    case WebDashboardConst.DASHBOARD.COLORS[action.id]?.INIT: {
      const data = { loading: true, data: null, error: null };
      const newState = { ...state };
      newState[`${action.id}`] = data;
      return newState;
    }
    case WebDashboardConst.DASHBOARD.COLORS[action.id]?.SUCCESS: {
      const data = { loading: false, data: action.response, error: null };
      const newState = { ...state };
      newState[`${action.id}`] = data;
      return newState;
    }
    case WebDashboardConst.DASHBOARD.COLORS[action.id]?.ERROR: {
      const data = { loading: false, data: null, error: action.error };
      const newState = { ...state };
      newState[`${action.id}`] = data;
      return newState;
    }
    default:
      return state;
  }
}

function PublisherReportReducer(state = {}, action) {
  return Reducer(
    WebDashboardConst.DASHBOARD.PUBLISHER_REPORT[action.id],
    state,
    action
  );
}

// function VASTTrackerReducer(state = {}, action) {
//   return Reducer(
//     // WebDashboardConst.DASHBOARD.PUBLISHER_REPORT[action.id],
//     WebDashboardConst.VAST[action.id],
//     state,
//     action
//   );
// }

function GenerateReportReducer(state = {}, action) {
  return Reducer(
    WebDashboardConst.DASHBOARD.REPORT.generateReport,
    state,
    action
  );
}

function VastDashboardReducer(state = {}, action) {
  return Reducer(WebDashboardConst.DASHBOARD.VAST[action.id], state, action);
}

function VastFilterReducer(state = {}, action) {
  return FilterReducer(WebDashboardConst.FILTER.VAST[action.id], state, action);
}

function WebReportReducer(state = {}, action) {
  return Reducer(WebDashboardConst.DASHBOARD.REPORT[action.id], state, action);
}

export default combineReducers({
  VisitDashboard: DashboardReducer(DASHBOARD_TYPE.VISIT),
  ImpressionDashboard: DashboardReducer(DASHBOARD_TYPE.IMPRESSION),
  ClickDashboard: DashboardReducer(DASHBOARD_TYPE.CLICK),
  EventDashboard: DashboardReducer(DASHBOARD_TYPE.EVENT),
  VastDashboard: VastDashboardReducer,
  // VastDashboardFilter: VastFilterReducer,
  Colors: ColorReducer,
  PublisherReport: PublisherReportReducer,
  // VASTTracker: VASTTrackerReducer,
  Report: WebReportReducer,
  GenerateReport: GenerateReportReducer,
  ClickFilter: FilterReducer(DASHBOARD_TYPE.CLICK),
  VastDashboardFilter: FilterReducer(DASHBOARD_TYPE.VAST),
  EventFilter: FilterReducer(DASHBOARD_TYPE.EVENT),
  ImpressionFilter: FilterReducer(DASHBOARD_TYPE.IMPRESSION),
  VisitFilter: FilterReducer(DASHBOARD_TYPE.VISIT),
  ReportFilter: FilterReducer(DASHBOARD_TYPE.REPORT_FILTERS),
});

function Reducer(key, state, action) {
  if (action.id === undefined) return state;

  switch (action.type) {
    case key?.INIT: {
      const data = { loading: true, data: null, error: null };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case key?.SUCCESS: {
      const data = {
        loading: false,
        data: action.data,
        error: null,
        refetch: false,
      };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case key?.REFETCH: {
      const data = { loading: false, refetch: true };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case key?.ERROR: {
      const data = { loading: false, data: null, error: action.error };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case key?.CLEAR: {
      const data = { loading: false, data: null, error: null };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    default:
      return state;
  }
}
