import * as constant from "../constants/PackageConstants"

const initialState = {
    //Package
    package_details: [],
    package_save: [],
    package_update: [],
    package_delete: [],
    package_loading: true,
    package_error: null,



    getonepackage:[],
    getonepackage_loading:true,
    getonepackage_error: null,
}

const PackageReducer = (state = initialState, { type, response }) => {
    switch (type) {
        case constant.PACKAGE_DETAILS_INIT:
            case constant.PACKAGE_SAVE_INIT:
            case constant.PACKAGE_UPDATE_INIT:
            case constant.PACKAGE_DELETE_INIT:
              
                return {
                    ...state,
                    package_loading: true,
                  };
                  case constant.GETONE_PACKAGE_INIT:
                    return {
                      ...state,
                      getonepackage_loading:true,
                      // getonepackage:null
                    };




                  case constant.PACKAGE_DETAILS_INIT_SUCCESS:
      // console.log(response.result, "data");
      return {
        ...state,
        package_loading: false,
        package_details: response.result,
      };

      case constant.GETONE_PACKAGE_INIT_SUCCESS:
      // console.log(response, "getonepackage1111");
      return {
        ...state,
        getonepackage_loading:false,
        getonepackage: response,
      };


    case constant.PACKAGE_SAVE_INIT_SUCCESS:
      // console.log(response.result, "dataof package reducer");
      return {
        ...state,
        // package_loading: false,
        package_save: response,
      };

    case constant.PACKAGE_UPDATE_INIT_SUCCESS:
      // console.log(response, "data");
      return {
        ...state,
        // loading: false,
        package_update: response,
      };

    case constant.PACKAGE_DELETE_INIT_SUCCESS:
      // console.log(response, "data");
      return {
        ...state,
        // loading: false,
        package_delete: response,
      };



      case constant.PACKAGE_DETAILS_INIT_ERROR:
        case constant.PACKAGE_SAVE_INIT_ERROR:
        case constant.PACKAGE_UPDATE_INIT_ERROR:
        case constant.PACKAGE_DELETE_INIT_ERROR:
          
            return {
                ...state,
                error: response,
                package_loading: false,
              };
              case constant.GETONE_PACKAGE_INIT_ERROR:
              return {
                ...state,
                getonepackage_loading:false,
                
                getonepackage_error:  response,
              }

            default:
              return state;
          }
        };

        export default PackageReducer