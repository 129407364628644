
import * as constant from './constants'
const initialState = {
  publisherAdd_data: '',
  publisherAdd_loading: true,
  publisherAdd_error: null,
  publisherGet_data: '',
  publisherGet_loading: true,
  publisherGet_error: null,
  publisherUpdate_data: '',
  publisherUpdate_loading: true,
  publisherUpdate_error: null,
  publisherDelete_data: '',
  publisherDelete_loading: true,
  publisherDelete_error: null,
  // publisherconfigdash_data: '',
  publisherconfigdash_data: [],
  publisherconfigdash_loading: true,
  publisherconfigdash_error: null,
  publisherconfiglist_data: [],
  publisherconfiglist_loading: true,
  publisherconfiglist_error: null,
  publisherconfigfraudcategorylist_data: [],
  publisherconfigfraudcategorylist_loading: true,
  publisherconfigfraudcategorylist_error: null,
  publisherconfigfraudsubcategorylist_data: [],
  publisherconfigfraudsubcategorylist_loading: true,
  publisherconfigfraudsubcategorylist_error: null,
  publisherconfigvariation_data: [],
  publisherconfigvariation_event_data: [],
  publisherconfigvariation_loading: true,
  publisherconfigvariation_error: null,
  publisherSave_data: '',
  publisherSave_loading: true,
  publisherSave_error: null,
  publisherconfigtabel_data: [],
  publisherconfigtabel_loading: true,
  publisherconfigtabel_error: null,
  publisherUpdate_data: '',
  publisherUpdate_loading: true,
  publisherUpdate_error: null,
  publisherconfigUpdate_data: '',
  publisherconfigUpdate_loading: true,
  publisherconfigUpdate_error: null,
  publisherconfigDelete_data: '',
  publisherconfigDelete_loading: true,
  publisherconfigDelete_error: null,
};
const PublisherReducer = (state = initialState, { type, response, error }) => {
  switch (type) {
    //event config
    case constant.PUB_CONFIG_DASHBOARD_INIT:
    case constant.PUB_CONFIG_DASHBOARD_INIT_ERROR:
    case constant.PUB_CONFIG_LIST_INIT:
    case constant.PUB_CONFIG_LIST_INIT_ERROR:
    case constant.PUB_CONFIG_FRAUD_CATEGORIES_LIST_INIT:
    case constant.PUB_CONFIG_FRAUD_CATEGORIES_LIST_INIT_ERROR:
    case constant.PUB_CONFIG_SUB_FRAUD_CATEGORY_LIST_INIT:
    case constant.PUB_CONFIG_SUB_FRAUD_CATEGORY_LIST_INIT_ERROR:
    case constant.PUB_CONFIG_VARIATION_LIST_INIT:
    case constant.PUB_CONFIG_VARIATION_LIST_INIT_ERROR:
    case constant.PUB_CONFIG_VARIATION_LIST_EVENT_INIT_SUCCESS:
      return { ...state, publisherconfigvariation_event_data: response?.data };
    case constant.CONFIG_REPORT_LIST_INIT:
      return {
        ...state,
        publisherconfigtabel_loading: true,
      };
    case constant.CONFIG_REPORT_LIST_INIT_ERROR:
      return {
        ...state,
        publisherconfigtabel_loading: false,
        publisherconfigtabel_error: response,
      };
    case constant.CONFIG_EDIT_LIST_INIT:
    case constant.CONFIG_EDIT_LIST_INIT_ERROR:
    // CONFIG_REPORT_LIST_INIT_SUCCESS
    case constant.PUBLISHER_ADD:
      return {
        ...state,
        publisherAdd_loading: true,
      };
    case constant.PUBLISHER_ADD_SUCCESS:
      return {
        ...state,
        publisherAdd_loading: false,
        publisherAdd_data: response,
      };
    //pub config dash type
    case constant.PUB_CONFIG_DASHBOARD_INIT_SUCCESS:
      return {
        ...state,
        publisherconfigdash_loading: false,
        publisherconfigdash_data: response.dashboardType,
      };
    //pub config list
    case constant.PUB_CONFIG_LIST_INIT_SUCCESS:
      return {
        ...state,
        publisherconfiglist_loading: false,
        publisherconfiglist_data: response,
      };
    case constant.PUB_CONFIG_FRAUD_CATEGORIES_LIST_INIT_SUCCESS:
      return {
        ...state,
        publisherconfigfraudcategorylist_loading: false,
        publisherconfigfraudcategorylist_data: response.data,
      };
    case constant.PUB_CONFIG_SUB_FRAUD_CATEGORY_LIST_INIT_SUCCESS:
      return {
        ...state,
        publisherconfigfraudsubcategorylist_loading: false,
        publisherconfigfraudsubcategorylist_data: response.data,
      };
    case constant.PUB_CONFIG_VARIATION_LIST_INIT_SUCCESS:
      return {
        ...state,
        publisherconfigvariation_loading: false,
        publisherconfigvariation_data: response.data,
      };
    case constant.CONFIG_REPORT_LIST_INIT_SUCCESS:
      return {
        ...state,
        publisherconfigtabel_loading: false,
        publisherconfigtabel_data: response.data.data,
      };
    case constant.PUB_CONFIG_SAVE_INIT:
      return {
        ...state,
        publisherSave_loading: true,
      };
    case constant.PUB_CONFIG_SAVE_SUCCESS:
      return {
        ...state,
        publisherSave_loading: false,
        publisherSave_data: response,
      };
    case constant.PUB_CONFIG_SAVE_ERROR:
      return {
        ...state,
        publisherSave_loading: false,
        publisherSave_error: error,
      };
    // publisher edit config
    case constant.CONFIG_EDIT_LIST_INIT_SUCCESS:
      return {
        ...state,
        publisherconfigUpdate_loading: false,
        publisherconfigUpdate_data: response,
      };
    case constant.PUB_CONFIG_SAVE_CLEAR:
      return {
        ...state,
        publisherSave_error: response,
        publisherSave_data: [],
      };
    case constant.PUBLISHER_ADD_ERROR:
      return {
        ...state,
        publisherAdd_loading: false,
        publisherAdd_error: error,
      };
    case constant.PUBLISHER_ADD_CLEAR:
      return {
        ...state,
        publisherAdd_error: response,
        publisherAdd_data: [],
      };
    case constant.PUBLISHER_GET:
      return {
        ...state,
        publisherGet_loading: true,
      };
    case constant.PUBLISHER_GET_SUCCESS:
      return {
        ...state,
        publisherGet_loading: false,
        publisherGet_data: response.result,
      };
    case constant.PUBLISHER_GET_ERROR:
      return {
        ...state,
        publisherGet_loading: false,
        publisherGet_error: response,
      };
    case constant.PUBLISHER_GET_CLEAR:
      return {
        ...state,
        publisherGet_data: {},
        publisherGet_error: {},
      };
      case constant.PUBLISHER_GET_MTRACKIT:
        return {
          ...state,
          publisherGet_loading: true,
        };
      case constant.PUBLISHER_GET_SUCCESS_MTRACKIT:
        return {
          ...state,
          publisherGet_loading: false,
          publisherGet_data: response.result,
        };
      case constant.PUBLISHER_GET_ERROR_MTRACKIT:
        return {
          ...state,
          publisherGet_loading: false,
          publisherGet_error: response,
        };
      case constant.PUBLISHER_GET_CLEAR_MTRACKIT:
        return {
          ...state,
          publisherGet_data: {},
          publisherGet_error: {},
        };
      case constant.PUBLISHER_UPDATE:
      return {
        ...state,
        publisherUpdate_loading: true,
      };
    case constant.PUBLISHER_UPDATE_SUCCESS:
      return {
        ...state,
        publisherUpdate_loading: false,
        publisherUpdate_data: response,
      };
    case constant.PUBLISHER_UPDATE_ERROR:
      return {
        ...state,
        publisherUpdate_loading: false,
        publisherUpdate_error: response,
      };
    case constant.PUBLISHER_UPDATE_CLEAR:
      return {
        ...state,
        publisherUpdate_data: {},
        publisherUpdate_error: {},
      };
    case constant.PUBLISHER_DELETE:
      return {
        ...state,
        publisherDelete_loading: true,
      };
    case constant.PUBLISHER_DELETE_SUCCESS:
      return {
        ...state,
        publisherDelete_loading: false,
        publisherDelete_data: response,
      };
    case constant.PUBLISHER_DELETE_ERROR:
      return {
        ...state,
        publisherDelete_loading: false,
        publisherDelete_error: response,
      };
    case constant.PUBLISHER_DELETE_CLEAR:
      return {
        ...state,
        publisherDelete_data: {},
        publisherDelete_error: {},
      };
    // publisher config delete
    case constant.PUBLISHER_CONFIG_DELETE:
      return {
        ...state,
        publisherconfigDelete_loading: true,
      };
    case constant.PUBLISHER_CONFIG_DELETE_SUCCESS:
      return {
        ...state,
        publisherconfigDelete_loading: false,
        publisherconfigDelete_data: response,
      };
    case constant.PUBLISHER_CONFIG_DELETE_ERROR:
      return {
        ...state,
        publisherconfigDelete_loading: false,
        publisherconfigDelete_error: response,
      };
    case constant.PUBLISHER_CONFIG_DELETE_CLEAR:
      return {
        ...state,
        publisherconfigDelete_data: {},
        publisherconfigDelete_error: {},
      };
    //event publisher config dash type
    //   case constant.PUB_CONFIG_DASHBOARD_INIT:
    //   return {
    //     ...state,
    //     publisherconfigdash_loading: true,
    //   };
    // case constant.PUB_CONFIG_DASHBOARD_INIT_SUCCESS:
    //   return {
    //     ...state,
    //     publisherconfigdash_loading: false,
    //     publisherconfigdash_data: response.dashboardType,
    //   };
    // case constant.PUB_CONFIG_DASHBOARD_INIT_ERROR:
    //   return {
    //     ...state,
    //     publisherconfigdash_loading: false,
    //     publisherconfigdash_error: response,
    //   }
    default:
      return state;
  }
};
export default PublisherReducer;